import React,{ Component } from 'react';
import {
    Card,
    Elevation,
    Navbar,
    ButtonGroup,
    Alignment,
    Button,
    Dialog,
    FormGroup,
    FileInput,
    InputGroup,
    TextArea,
    Intent,
    Tooltip,
    Classes,
    AnchorButton,
    NavbarDivider
} from '@blueprintjs/core';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Select from "react-select";
import axios from 'axios';
import i18next from "i18next";
import { Cargando } from '../Cargando';

class Adjuntos extends Component {
    state={
        adjuntoSeleccionado:null,
        nombre:'',
        archivo:null,
        tipoSeleccionado:null,
        descripcion:'',
        modalAbierto:false,
        nuevoActivado:true,
        cargando:false
    }

    render() {

        const cerrarModal=()=>{
            this.setState({
                modalAbierto:false,
                nuevoActivado:true,
                cargando:false
            });
        }

        const abrirModalNuevo=()=>{
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            this.setState({
                nombre:'',
                archivo:null,
                tipoSeleccionado:null,
                descripcion:'',
                adjuntoSeleccionado:null,
                modalAbierto: true,
                nuevoActivado:true,
                cargando:true
            })
        }

        const abrirModalEditar=()=>{
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.adjuntoSeleccionado){
                alert("Debes seleccionar un adjunto")
                return
            }

            let tipoSel=this.props.tipoadjuntos.find(tipo=>{
               return tipo.value===this.state.adjuntoSeleccionado.idTipo
            })
            
            this.setState({
                nombre:this.state.adjuntoSeleccionado.nombre,
                archivo:null,
                tipoSeleccionado:tipoSel,
                descripcion:this.state.adjuntoSeleccionado.descripcion,
                modalAbierto: true,
                nuevoActivado:false,
                cargando:true
            })
        }

        const guardarCambios=()=>{
            const api = axios.create({
                withCredentials: true
            });

            let formData = new FormData();
            formData.append("files",this.state.archivo);
            api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:${this.state.tipoSeleccionado.value},NOMBRE:"${this.state.nombre}",ID_ENTIDAD_ASOCIADA:${this.props.entidad},ID_ASOCIADO:${this.props.id_asociado},DESCRIPCION:"${this.state.descripcion}"}){ID_ADJUNTO}}`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }).then(async(response)=> {
                await this.props.cargarAdjuntos();
                alert("Adjunto creado");
                cerrarModal();
                return true;
            }).catch(err=>{
                alert("Ha ocurrido un error al crear el adjunto. Contacte con el soporte técnico.")
                cerrarModal();
                return false;
            })
        }

        const actualizarCambios=()=>{
            const api = axios.create({
                withCredentials: true
            });

            api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{actualizarAdjunto(ID_ADJUNTO:${this.state.adjuntoSeleccionado.id},VALORES:{NOMBRE:"${this.state.nombre!==''?this.state.nombre:this.state.adjuntoSeleccionado.nombre}",ID_TIPO_ADJUNTO:${this.state.tipoSeleccionado?this.state.tipoSeleccionado.value:this.state.adjuntoSeleccionado.idTipo},DESCRIPCION:"${this.state.descripcion!==''?this.state.descripcion:this.state.adjuntoSeleccionado.descripcion}"}){ID_ADJUNTO}}`).then(async (response)=> {
                // handle success
                await this.props.cargarAdjuntos();
                alert("actualizado correctamente");
                cerrarModal();
                return true;
            }).catch(err=>{
                alert("Ha ocurrido un error al actualizar el adjunto. Contacte con el soporte técnico.")
                return false;
            })
        }

        const borrarCambios=()=>{
            const api = axios.create({
                withCredentials: true
            });
            api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.adjuntoSeleccionado.id}){ID_ADJUNTO}}`).then(async (response)=>{
                // handle success  
                await this.props.cargarAdjuntos();
                alert("eliminado correctamente");
                cerrarModal();
                return true;
            }).catch(err=>{
                alert("Ha ocurrido un error al eliminar el adjunto. Contacte con el soporte técnico.")
                return false;
            })
        }
        
        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    adjuntoSeleccionado: row
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };

        const columns = [{
            dataField: 'nombre',
            text: 'Nombre'
            }, {
            dataField: 'tipo',
            text: 'Tipo'
            }, {
            dataField: 'mime',
            text: 'Tipo de archivo'
        }];

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Mostrando { from } a { to } de { size } Resultados
            </span>
        );

        const options = {
            paginationSize: 4,
            pageStartIndex: 0,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'Primero',
            prePageText: 'Atrás',
            nextPageText: 'Próxima',
            lastPageText: 'Última',
            nextPageTitle: 'Primera página',
            prePageTitle: 'página previa',
            firstPageTitle: 'Siguiente página',
            lastPageTitle: 'Última página',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '5', value: 5
              }] // A numeric array is also available. the purpose of above example is custom the text
        };

        const archivoSubido=(ev)=>{           

            let extension=ev.target.files[0].name.split('.').pop();
            this.setState({
                archivo:ev.target.files[0],
                tipoArchivo:ev.target.files[0].type,
                nombre:ev.target.files[0].name.replace("."+extension,"")
            })
          
        }

        const seleccionTipo  = (value) => { 
            this.setState({tipoSeleccionado: value})
        }

        const handleCambiarNombre = (ev) => {
            this.setState({nombre:ev.currentTarget.value})
        };
        
        const handleCambiarDescripcion = (ev) => {
            this.setState({descripcion:ev.currentTarget.value})
        };

        const agregarAdjunto =async () => {

            if (!this.state.archivo) {
                alert("Seleccionar nuevo archivo");
                return
            }
            if (!this.state.tipoSeleccionado) {
                alert("Seleccionar tipo de archivo");
                return
            }
            if (!this.state.nombre) {
                alert("Ingresar nombre de archivo");
                return
            }
            if (!this.state.descripcion) {
                alert("Ingresar descripción del archivo");
                return
            }

            this.setState({cargando:true},async ()=>{
                await guardarCambios();
            })
            
            
        }

        const ActualizarAdjunto=async () => {
            if (!this.state.tipoSeleccionado) {
                alert("Seleccionar tipo de archivo");
                return
            }
            if (!this.state.nombre) {
                alert("Ingresar nombre de archivo");
                return
            }
            if (!this.state.descripcion) {
                alert("Ingresar descripción del archivo");
                return
            }

            this.setState({cargando:true},async ()=>{
                await actualizarCambios();
            })
        }

        const eliminarAdjunto =async () => {
            if(!this.state.adjuntoSeleccionado){
                alert("Debes seleccionar un adjunto")
                return
            }
            if (!window.confirm('¿Desea eliminar el archivo adjunto seleccionado?')) {
                return
            }

            this.setState({cargando:true},async ()=>{
                await borrarCambios();
            })
            
            
        };

        const descargarArchivo = () => {
            let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.adjuntoSeleccionado.ruta.replace(".",""), '_blank')
            win.focus()
        }

        return (
            <>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoActivado?"Añadir nuevo archivo adjunto":"Editar archivo adjunto"}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                >
                    {this.state.cargando?
                        <Cargando/>
                    :
                    <div id="dialogo-actas">
                        <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                            {this.state.nuevoActivado?
                                <FormGroup
                                    label="Archivo adjunto"
                                    labelFor="informacion"
                                    intent="danger"
                                    helperText={this.state.archivo?"":"Seleccione el archivo adjunto."}
                                >
                                    <FileInput  text={this.state.archivo?this.state.archivo.name:""} onInputChange={archivoSubido} name="files" style={this.state.archivo?{}:{border:"1px solid red"}} />
                                </FormGroup>
                                :<></>
                            }
                            <FormGroup
                                label="Tipo Documento"
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.tipoSeleccionado?"":"Seleccione el tipo de adjunto."}
                            >
                                <div style={this.state.tipoSeleccionado?{}:{border:"1px solid red"}}>
                                    <Select 
                                        options={this.props.tipoadjuntos}
                                        onChange={seleccionTipo}
                                        placeholder="Seleccion el tipo de archivo"
                                        value={this.state.tipoSeleccionado}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup
                                    label="Nombre Documento"
                                    labelFor="informacion"
                                    intent="danger"
                                    helperText={this.state.nombre!==''?"":"Escriba el nombre del documento."}
                            >
                                <InputGroup id="nombre-documento" onChange={handleCambiarNombre}  value={this.state.nombre} placeholder="Escriba aqui..." intent={this.state.nombre!==''?"primary":"danger"} />
                            </FormGroup>
                            <FormGroup
                                    label="Descripción"
                                    labelFor="descripcion"
                                    intent="danger"
                                    helperText={this.state.descripcion?"":"Escriba una descripción para el documento."}
                            >
                                <TextArea
                                    large={true}
                                    fill={true}
                                    intent={this.state.descripcion!==''?Intent.PRIMARY:Intent.DANGER}
                                    onChange={handleCambiarDescripcion}
                                    value={this.state.descripcion}
                                />                    
                            </FormGroup>
                        </Card>
                    </div>
                    }
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>Cerrar</Button>
                            </Tooltip>
                            <Tooltip content={this.state.nuevoActivado?"Añadir adjunto":"Editar adjunto"} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?agregarAdjunto:ActualizarAdjunto}
                                >
                                    {this.state.nuevoActivado?"Añadir adjunto":"Editar adjunto"}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    
                </Dialog>
                <Card interactive={true} elevation={Elevation.TWO} >
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                <Button className="bp3-minimal" icon="add" text="Nuevo"   onClick={abrirModalNuevo} />
                                <Button className="bp3-minimal" icon="edit" text="Editar"  onClick={abrirModalEditar}/>
                                <Button className="bp3-minimal" icon="delete" text="Borrar"  onClick={eliminarAdjunto}/>
                                <NavbarDivider />
                                <Button className="bp3-minimal" icon="document-open" text="Abrir el archivo" disabled={this.state.adjuntoSeleccionado?false:true} onClick={descargarArchivo}></Button>
                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    {this.state.cargando?
                        <Cargando/>
                    :
                        <BootstrapTable
                            keyField='id'
                            data={ this.props.adjuntos }
                            columns={ columns }
                            selectRow={ selectRow }
                            pagination={ paginationFactory(options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                    }
                </Card>
            </>
        );
    }
}

export default Adjuntos;