import React , { Component } from "react"
import { Button, ButtonGroup,Navbar, AnchorButton, Classes,Popover, Intent, Tooltip, Alignment, Dialog, FormGroup, Switch } from "@blueprintjs/core"
import { Table, Column, Cell,Regions,RegionCardinality } from "@blueprintjs/table"
import Select from "react-select"
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import { DatosPersonasJuridicas } from "../DatosPersonasJuridicas";
import { DatosPersonasFisicas } from "../Elementos/DatosPersonasFisicas";
import InformacionGeneralNuevo from  "../../Pantallas/Elementos/InformacionGeneralNuevo"
import i18next from 'i18next';

export default class DatosActa extends Component{

    state={
        asistentes: [
        ],
        modalAbierto: false,
        modalBorrarAsistente: false,
        vota: false,
        personaSeleccionada:null,
        personaSeleccionadaRepresentada:null,
        nuevaPresonaReperesentadaActivado:false,
        editarRepresentadaActivado:false,
        editarActivado:false,
        nuevaPresonaActivado:false,
        personas:[],
        regionSeleccionada:null
    }

    cargarPersonas=async ()=>{

        let client = new ApolloClient({
            uri: process.env.REACT_APP_DOMINIO+"/api",
            credentials: 'include',
        });

        let personas=[]

        await client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1"){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},NOMBRE,APELLIDO1,APELLIDO2}}`
        }).then(async(result)=>{
            //console.log(result)
            result.data.personasFisicas.forEach(async(persona)=>{
            
                personas.push({"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 

            })
            
        })

        await client.query({
            query:gql`{personasJuridicas{ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},RAZON_SOCIAL}}`
        }).then(async(result)=>{
            //console.log(result)
            result.data.personasJuridicas.forEach(async(persona)=>{
            
                personas.push({"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 

            })

        })

        this.setState({personas:personas})

    }

    componentDidUpdate=(props)=>{
        if(props!==this.props){
            let asistentes=[]
            if(this.props.convocados ){
                console.log(this.props.convocados)
                for(let convocado of this.props.convocados){
                    asistentes.push({
                        value:convocado.value,
                        label:convocado.label,
                        vota:convocado.vota,
                        representa:convocado.representa,
                        idRepresenta:convocado.idRepresenta
                    })
                }
                this.setState({asistentes:asistentes})
            }

        }
    }


    componentDidMount=async()=>{
        
        await this.cargarPersonas()    
        //si vamos a editar...
        if(this.props.acta && Object.keys(this.props.acta).length >0){

            this.setState({asistentes:this.props.acta.asistentes})

        }


    }

    render(){

        const abrirModalNuevo = () =>{
            this.setState({ 
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                personaSeleccionada:null,
                personaSeleccionadaRepresentada:null,
                asistenteSeleccionado:null,
                vota:null,
                modalAbierto: true 
            })
        }

        const abrirModalEditar = () =>{

            console.log(this.state.asistenteSeleccionado)
            if(!this.state.asistenteSeleccionado){
                alert("Debe seleccionar un asistente")
                return
            }

            let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.asistenteSeleccionado.value})
            let personaSelRepresentada=this.state.asistenteSeleccionado.idRepresenta?this.state.personas.find(persona=>{return persona.value===this.state.asistenteSeleccionado.idRepresenta}):null
            this.setState({ 
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                personaSeleccionada:personaSel,
                personaSeleccionadaRepresentada:personaSelRepresentada,
                vota:this.state.asistenteSeleccionado.vota,
                modalAbierto: true
             })
        }

        const abrirModalBorrar = () =>{

            if(!this.state.asistenteSeleccionado){
                alert("Debe seleccionar un asistente")
                return
            }

            this.setState({ 
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                modalBorrarAsistente: true })
        }

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalBorrarAsistente: false,
            })
        }

        const cellRendererNombre = (rowIndex) => {
            return <Cell>{this.state.asistentes[rowIndex].label}</Cell>
        }

        const cellRendererVoto = (rowIndex) => {
            return <Cell>{this.state.asistentes[rowIndex].vota?"SI":"NO"}</Cell>
        }

        const cellRendererRepresenta = (rowIndex) => {
            return <Cell>{this.state.asistentes[rowIndex].representa}</Cell>
        }

        const seleccionPersona  = (value) => { 
            this.setState({personaSeleccionada: value})
        }

        const seleccionPersonaRepresentada  = (value) => { 

            if(!this.state.personaSeleccionada){
                alert("Antes debe seleccionar el asistente")
                return
            }
            if(this.state.personaSeleccionada.value===value.value){
                alert("El asistente y el representado no pueden ser la misma persona")
                return
            }

            this.setState({personaSeleccionadaRepresentada: value})
        }

        const abrirModalNuevaPersona=()=>{
    
            //abrir modal de persona física
            this.setState({
                modalPersonaAbierto:true,
                popoverAbierto:false,
                nuevaPresonaActivado:true,
                editarPersonaActivado:false,
                personaSeleccionada:null,
                tipo:"persona"
            })

        }

        const abrirModalNuevaPersonaRepresentada=()=>{
    
            //abrir modal de persona física
            this.setState({
                modalPersonaRepresentadaAbierto:true,
                popoverAbierto:false,
                nuevaPresonaReperesentadaActivado:true,
                editarRepresentadaActivado:false,
                personaSeleccionadaRepresentada:null,
                tipo:"representada"
            })

        }

        const abrirModalEditarPersona=()=>{

            if(!this.state.personaSeleccionada){
                alert("Debe seleccionar una persona.")
                return
            }

            this.setState({nuevaPresonaReperesentadaActivado:false,tipo:"persona"})
            this.setState({editarPersonaActivado:true})
                //si es personas física
            if(this.state.personaSeleccionada.tipo===1){
                this.setState({modalPersonaAbierto:true})

                //si es persona jurídica
            }else if(this.state.personaSeleccionada.tipo===2){
                this.setState({modalEditarPersonaJuridica:true})
            }



        }

        const abrirModalEditarPersonaRepresentada=()=>{

            if(!this.state.personaSeleccionadaRepresentada){
                alert("Debe seleccionar una persona.")
                return
            }

            this.setState({nuevaPresonaReperesentadaActivado:false,editarRepresentadaActivado:true,borrarRepresentado:false,tipo:"representada"})
            this.setState({abrirModalEditarPersonaRepresentada:true})
                //si es personas física
            if(this.state.personaSeleccionadaRepresentada.tipo===1){
                this.setState({modalPersonaRepresentadaAbierto:true})

                //si es persona jurídica
            }else if(this.state.personaSeleccionadaRepresentada.tipo===2){
                this.setState({modalEditarPersonaJuridicaRepresentada:true})
            }

        }

        const quitarRepresentado=()=>{

            this.setState({
                nuevaPresonaReperesentadaActivado:false,
                editarRepresentadaActivado:false,
                borrarRepresentado:true,
                personaSeleccionadaRepresentada:null
            })
        }

        const añadirNuevo = async(value) => {
            
            if(!this.state.personaSeleccionada)return
            //comporbar que no existe el asistente ya en la tabla
            let existe=this.state.asistentes.find(asistente=>{
                return asistente.value===this.state.personaSeleccionada.value
            })
            if(existe){
                alert("Ya existe el asistente en la lista")
                return
            }

            //añadimos el elemento a la tabla
            let asistentes=this.state.asistentes
            asistentes.push({ value: this.state.personaSeleccionada.value, label: this.state.personaSeleccionada.label, vota: this.state.vota?this.state.vota:false, representa: this.state.personaSeleccionadaRepresentada?this.state.personaSeleccionadaRepresentada.label:null,idRepresenta:this.state.personaSeleccionadaRepresentada?this.state.personaSeleccionadaRepresentada.value:null })
            await this.props.setNumeroAsistentes(asistentes.length)
            await this.props.setAsistentes(asistentes)
            await this.props.setConvocados(asistentes)

            await this.setState({
                asistentes:asistentes,
                asistenteSeleccionado:asistentes[asistentes.length-1],
                regionSeleccionada:[Regions.row(asistentes.length-1)]
            })



            cerrarModal()
        }

        const actualizarAsistente = async() => {

            let asistentes=this.state.asistentes
            let asistente=await asistentes.find(asistente=>{return asistente.value===this.state.asistenteSeleccionado.value})
            asistente.value=this.state.personaSeleccionada.value
            asistente.label=this.state.personaSeleccionada.label
            asistente.vota=this.state.vota
            asistente.representa=this.state.personaSeleccionadaRepresentada?this.state.personaSeleccionadaRepresentada.label:null
            asistente.idRepresenta=this.state.personaSeleccionadaRepresentada?this.state.personaSeleccionadaRepresentada.value:null
            await this.setState(this.state)
            await  this.props.setAsistentes(asistentes)
            await this.props.setConvocados(asistentes)

            cerrarModal()
        }   

        const handleDerechoVoto = () => {
            this.setState ({ vota: !this.state.vota})
        }

        const abrirPopover = () => {
            this.setState({popoverAbierto: true})
        }
      
        const Botonera = () => {
            return(
                <>
                    <Button className="bp3-minimal" icon="new-link" text="Nueva Persona Física" fill={true} onClick={abrirModalNuevaPersona} />
                    <br />
                    <Button className="bp3-minimal" icon="git-new-branch" text="Nueva Persona Jurídica" onClick={abrirModalNuevaPersonaJurídica} />
                </>
            )
        }

        const BotoneraRepresentada = () => {
            return(
                <>
                    <Button className="bp3-minimal" icon="new-link" text="Nueva Persona Física" fill={true} onClick={abrirModalNuevaPersonaRepresentada} />
                    <br />
                    <Button className="bp3-minimal" icon="git-new-branch" text="Nueva Persona Jurídica" onClick={abrirModalNuevaPersonaJurídicaRepresentada} />
                </>
            )
        }

        const cerrarModal2 = async () => {
            await this.cargarPersonas()
            if(this.state.tipo==="persona"){
                if(this.state.personaSeleccionada && this.state.personaSeleccionada.tipo===2){
                    let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                    this.setState({personaSeleccionada:personaSel})
                    this.setState({modalNuevaJuridicaAbierto:false})
                }
            }else{
                if(this.state.personaSeleccionadaRepresentada && this.state.personaSeleccionadaRepresentada.tipo===2){
                    let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionadaRepresentada.value})
                    this.setState({personaSeleccionadaRepresentada:personaSel})
                    this.setState({modalNuevaJuridicaRepresentadaAbierto:false})
                }
            }
         
            this.setState({
                modalEditarPersonaJuridica:false,
                modalPersonaAbierto:false,
                modalPersonaRepresentadaAbierto:false,
                modalEditarPersonaJuridicaRepresentada:false
            })
        }

        const actualizarPersona = async () => {
            await this.datosPersona.actualizarPersona();
            if (this.datosPersona.state.idPersonaActualizada) {
                await this.cargarPersonas();
                if(this.state.tipo==="persona"){
                    let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                    this.setState({personaSeleccionada:personaSel})
                    await this.setState({modalNuevaJuridicaAbierto:false})
                }else{
                    let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionadaRepresentada.value})
                    this.setState({personaSeleccionadaRepresentada:personaSel})
                    await this.setState({modalNuevaJuridicaRepresentadaAbierto:false})
                }
           
                //cerrar el modal
                cerrarModal2();
            }
        };

        
        const crearPersona = async () => {
            await this.datosPersona.crearPersona();
            if (this.datosPersona.state.idPersonaCreada) {
                cerrarModal2();
                await this.cargarPersonas();
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})
                if(this.state.tipo==="persona"){
                    this.setState({personaSeleccionada:personaSel})
                }else{
                    this.setState({personaSeleccionadaRepresentada:personaSel})
                }
            }
        };

        const guardarPersonaJuridica=async ()=>{


            let result=await this.informacion.handleGuardarDatos()
            if(!result)return
            await this.cargarPersonas()
            //seleccionamos a al persona creada en la lista.
            let personaSel=this.state.personas.find(persona=>{return persona.value===this.informacion.state.personaCreada})
            if(this.state.tipo==="persona"){
                this.setState({personaSeleccionada:personaSel})
                await this.setState({modalNuevaJuridicaAbierto:false})
            }else{
                this.setState({personaSeleccionadaRepresentada:personaSel})
                await this.setState({modalNuevaJuridicaRepresentadaAbierto:false})
            }
 

        }

        const abrirModalNuevaPersonaJurídica=()=>{

            //abrir modal de persona jurídica
            this.setState({modalNuevaJuridicaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})
            this.setState({tipo:"persona"})

        }

        const abrirModalNuevaPersonaJurídicaRepresentada=()=>{

            //abrir modal de persona jurídica
            this.setState({modalNuevaJuridicaRepresentadaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaReperesentadaActivado:true})
            this.setState({editarRepresentadaActivado:false})
            this.setState({tipo:"representada"})

        }


        const seleccionarAsistente=(value)=>{
            if(value.length===0)return
            
            let seleccionada=this.state.asistentes[value[0].rows[0]]      
            this.setState({
                asistenteSeleccionado:seleccionada,
                regionSeleccionada:value
            })
        }

        const eliminarAsistente=async()=>{
            
            let asistentes=this.state.asistentes
            asistentes=await asistentes.filter(asistente=>{return asistente.value!==this.state.asistenteSeleccionado.value})
            await  this.props.setAsistentes(asistentes)
            await this.props.setNumeroAsistentes(asistentes.length)
            await this.props.setConvocados(asistentes)

            await this.setState({
                asistentes:asistentes,
                asistenteSeleccionado:null,
                regionSeleccionada:null
            },()=>{this.tablaAsistentes.clearSelection()})

            cerrarModal()

        }

      

        return(
            <>
            <Dialog
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal2}
            title="Propiedades de la persona"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            className="dialog-ancho"
            isOpen={this.state.modalPersonaAbierto}
            >
            <DatosPersonasFisicas
                datosPersona={
                this.state.personaSeleccionada
                    ? this.state.personaSeleccionada.value
                    : null
                }
                ref={input => {
                this.datosPersona = input;
                }}
                i18next={i18next}
            />
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Tooltip content="Cerrar y volver." position="top">
                    <Button onClick={cerrarModal2}>Cerrar</Button>
                </Tooltip>
                <Tooltip
                    content={
                    this.state.nuevaPresonaActivado
                        ? "Añadir persona."
                        : "Actualizar persona."
                    }
                    position="top"
                >
                    <AnchorButton
                    intent={Intent.PRIMARY}
                    onClick={
                        this.state.nuevaPresonaActivado
                        ? crearPersona
                        : actualizarPersona
                    }
                    >
                    {this.state.nuevaPresonaActivado
                        ? "Añadir persona"
                        : "Actualizar Persona"}
                    </AnchorButton>
                </Tooltip>
                </div>
            </div>
            </Dialog>
            <Dialog
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal2}
            title="Propiedades de la persona"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            className="dialog-ancho"
            isOpen={this.state.modalPersonaRepresentadaAbierto}
            >
            <DatosPersonasFisicas
                datosPersona={
                this.state.personaSeleccionadaRepresentada
                    ? this.state.personaSeleccionadaRepresentada.value
                    : null
                }
                ref={input => {
                this.datosPersona = input;
                }}
            />
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Tooltip content="Cerrar y volver." position="top">
                    <Button onClick={cerrarModal2}>Cerrar</Button>
                </Tooltip>
                <Tooltip
                    content={
                    this.state.nuevaPresonaActivado
                        ? "Añadir persona."
                        : "Actualizar persona."
                    }
                    position="top"
                >
                    <AnchorButton
                    intent={Intent.PRIMARY}
                    onClick={
                        this.state.nuevaPresonaReperesentadaActivado
                        ? crearPersona
                        : actualizarPersona
                    }
                    >
                    {this.state.nuevaPresonaReperesentadaActivado
                        ? "Añadir persona"
                        : "Actualizar Persona"}
                    </AnchorButton>
                </Tooltip>
                </div>
            </div>
            </Dialog>
            <Dialog transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
            title="Propiedades de la empresa"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalNuevaJuridicaAbierto}
            className="datos-empresa-dialog"
        >
                <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} />  
            <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Tooltip content="Cancelar." position="top">          
                    <Button
                        alignText="left"
                        icon="cross"
                        text="Cancelar"
                        intent="danger"
                        onClick={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                        className="iconoIzquierda bp3-minimal"
                    />
                </Tooltip>
                <Tooltip content="Guardar." position="top">
                <Button
                    alignText="left"
                    icon="floppy-disk"
                    text="Guardar"
                    intent="danger"
                    onClick={guardarPersonaJuridica}
                    className="iconoIzquierda bp3-minimal"
                />
                </Tooltip>
            </div>
        </div>  
        </Dialog>
        <Dialog transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={()=>{this.setState({modalNuevaJuridicaRepresentadaAbierto:false})}}
            title="Propiedades de la empresa"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalNuevaJuridicaRepresentadaAbierto}
            className="datos-empresa-dialog"
        >
                <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} />  
            <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Tooltip content="Cancelar." position="top">          
                    <Button
                        alignText="left"
                        icon="cross"
                        text="Cancelar"
                        intent="danger"
                        onClick={()=>{this.setState({modalNuevaJuridicaRepresentadaAbierto:false})}}
                        className="iconoIzquierda bp3-minimal"
                    />
                </Tooltip>
                <Tooltip content="Guardar." position="top">
                <Button
                    alignText="left"
                    icon="floppy-disk"
                    text="Guardar"
                    intent="danger"
                    onClick={guardarPersonaJuridica}
                    className="iconoIzquierda bp3-minimal"
                />
                </Tooltip>
            </div>
        </div>  
        </Dialog>
            <Dialog
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModal2}
                title="Propiedades de la empresa"
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalEditarPersonaJuridica}
                className="datos-empresa-dialog"
                >
                <DatosPersonasJuridicas
                    animacion={this.animacion}
                    cambiarPestaña={this.cambiarPestaña}
                    pestaña={this.state.pestaña}
                    empresaSeleccionada={this.state.personaSeleccionada?this.state.personaSeleccionada:null}
                />
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS} />
                    <ButtonGroup>
                        <Button 
                            alignText="right"
                            icon="log-out"
                            intent="danger"
                            text="salir"
                            onClick={cerrarModal2}
                        />
                    </ButtonGroup>
                </div>
            </Dialog>
            <Dialog
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModal}
                title="Propiedades de la empresa"
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalEditarPersonaJuridicaRepresentada}
                className="datos-empresa-dialog"
                >
                <DatosPersonasJuridicas
                    animacion={this.animacion}
                    cambiarPestaña={this.cambiarPestaña}
                    pestaña={this.state.pestaña}
                    empresaSeleccionada={this.state.personaSeleccionadaRepresentada?this.state.personaSeleccionadaRepresentada:null}
                />
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS} />
                    <ButtonGroup>
                        <Button 
                            alignText="right"
                            icon="log-out"
                            intent="danger"
                            text="salir"
                            onClick={cerrarModal2}
                        />
                    </ButtonGroup>
                </div>
            </Dialog>
        
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoActivado?"Crear asistente":"Editar asistentes"}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                >
                    <div className={Classes.DIALOG_BODY + "pixelAlrededor"}>
                        <FormGroup
                            label="Persona que asiste:"
                            labelFor="persona"
                            intent="danger"
                            helperText={!this.state.personaSeleccionada?"Debe seleccionar el apoderado.":""}
                        >
                            <div id="select-persona-asiste" style={this.state.personaSeleccionada?{}:{border:"1px solid red"}}>
                                <Select
                                    isDisabled={this.state.editarActivado}
                                    options={this.state.personas}
                                    className="pixelAlrededor"
                                    onChange={seleccionPersona}
                                    value={this.state.personaSeleccionada}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                        <div id="botones-creacion-persona">
                            <Popover  disabled={this.state.editarActivado} content={<Botonera />} transitionDuration={400} target={<Button  disabled={this.state.editarActivado} className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevaPresonaActivado} onClick={abrirPopover} />} />
                            <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarPersonaActivado} onClick={abrirModalEditarPersona} />
                        </div>
                        </FormGroup>

                        <FormGroup
                            label="Persona a la que representa:"
                            labelFor="persona"
                            intent="danger"
                        >
                            <div id="select-persona-representada">
                                <Select
                                    isDisabled={this.state.editarActivado && this.state.asistenteSeleccionado.idRepresenta}
                                    options={this.state.personas}
                                    className="pixelAlrededor"
                                    onChange={seleccionPersonaRepresentada}
                                    value={this.state.personaSeleccionadaRepresentada}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Popover  disabled={this.state.editarActivado} content={<BotoneraRepresentada />} transitionDuration={400} target={<Button  disabled={this.state.editarActivado} className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevaPresonaReperesentadaActivado} onClick={abrirPopover} />} />
                            <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarRepresentadaActivado} onClick={abrirModalEditarPersonaRepresentada} />
                            <Button className="bp3-minimal" icon="trash" text="Eliminar persona representada" active={this.state.borrarRepresentado} onClick={quitarRepresentado} />
                        </FormGroup>
                        <FormGroup
                            label="¿Tiene derecho a voto?"
                            inline={true}
                        >
                            <Switch id="boleano-derecho-voto" innerLabelChecked="Si" innerLabel="No" onChange={handleDerechoVoto} checked={this.state.vota} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevoActivado?"Añadir asistente.":"Editar asistente"} position="top">
                            <AnchorButton
                                id="agregar-asistente"
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevoActivado?añadirNuevo:actualizarAsistente}
                            >
                            {this.state.nuevoActivado?"Añadir asistente":"Editar asistente"}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Borrar asistente"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAsistente}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>¿Desea eliminar el asistente seleccionado?</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Eliminar credito." position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarAsistente}
                                >
                                    Si
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>No</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>

              
                <Navbar>
                    <Navbar.Group>
                        <ButtonGroup id="botonera-asistentes" align={Alignment.LEFT}>
                            <Button className="bp3-minimal" id="btn-nuevo-asistente" icon="add" text="Nuevo" active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                            <Button className="bp3-minimal" id="btn-editar-asistente" text="Editar" active={this.state.editarActivado} onClick={abrirModalEditar} />
                            <Button className="bp3-minimal" id="btn-eliminar-asistente" text="Borrar" active={this.state.borrarActivado} onClick={abrirModalBorrar} />
                        </ButtonGroup>
                    </Navbar.Group>
                </Navbar>
                <div id="tabla-asistentes">
                    <Table defaultColumnWidth={325} enableRowHeader={false} onSelection={seleccionarAsistente}  selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} ref={(input) => { this.tablaAsistentes= input; }} enableMultipleSelection={false} selectedRegions={this.state.regionSeleccionada} numRows={this.state.asistentes?this.state.asistentes.length:0}>
                        <Column name="Nombre" cellRenderer={cellRendererNombre}/>   
                        <Column name="Derecho a voto" cellRenderer={cellRendererVoto}/>   
                        <Column name="Representa a" cellRenderer={cellRendererRepresenta}/>   
                    </Table>
                </div>

            </>
        )
    }
}