import React , { Component } from "react"
import axios from 'axios';
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";

export default class DatosActa extends Component{

    state={
        resumen:null,
        fileAdjunto:null,
        fileName:null
    }
    
    componentDidMount=async()=>{
        
    let client = new ApolloClient({
        uri: process.env.REACT_APP_DOMINIO+"/api",
        credentials: 'include',
      });

        let usuarioActual=await client
        .query({
            query: gql`
            {obtenerUsuarioActual}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.obtenerUsuarioActual
        })
        await this.setState({ idUsuarioActual:usuarioActual})
    }

    render(){

        const subirActa=(e)=>{
            e.preventDefault()
            this.adjunto.click()
        }
        
        const archivoSubir=async(ev)=>{           

          if(ev.target.files[0].type!=="application/pdf"){
              alert("El documento debe ser un pdf")
              return
          }
          this.setState({
            fileAdjunto:ev.target.files[0],
            fileName: ev.target.files[0].name
          })
        }  

        const archivoSubido=async(ev)=>{           

            if(ev.target.files[0].type!=="application/pdf"){
                alert("El documento debe ser un pdf")
                return
            }
            
            let archivo=ev.target.files[0]
            //insertamos el archivo

            const api = axios.create({
                withCredentials: true
            });

            var formData = new FormData();
            formData.append("files",archivo);

            //si no tenemos adjunto
            if(!this.props.actaAdjuntos){

                await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearActaPdf(VALORES:{ID_ACTA:${this.props.acta.ID_ACTA}}){ID_ACTA,RUTA_ARCHIVO}}`, formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then(async(response)=> {
                    alert("creado correctamente")
                    await this.props.setAdjuntos(response);     
    
                }).catch(err=>{
                    alert("Ha ocurrido un problema al crear el adjunto.Pongase en contacto con el soporte técnico")
                })

            }else{

                await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarActaPdf(ID_ACTA:${this.props.acta.ID_ACTA}){ID_ACTA}}`
                ).catch(err=>{
                    alert("Ha ocurrido un problema al actualizar el adjunto.Pongase en contacto con el soporte técnico")
                }) 

                //de lo contrario, lo eliminamos primero y lo adjuntamos de nuevo.
                await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearActaPdf(VALORES:{ID_ACTA:${this.props.acta.ID_ACTA}}){ID_ACTA,RUTA_ARCHIVO }}`, formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then(async(response)=> {
                    alert("actualizado correctamente") ;
                    await this.props.setAdjuntos(response);       
    
                }).catch(err=>{
                    alert("Ha ocurrido un problema al actualizar el adjunto.Pongase en contacto con el soporte técnico")
                })

            }
   
        }

        const descargarActa=(e)=>{

            e.preventDefault()
            let win = window.open (process.env.REACT_APP_DOMINIO+""+this.props.actaAdjuntos[0].RUTA_ARCHIVO.replace(".",""), '_blank')
            win.focus()
        }

        const eliminarActa=(e)=>{

            if (!window.confirm('¿Desea eliminar acta pdf?')) {
                return
            }

            e.preventDefault()

            const api = axios.create({
                withCredentials: true
            });

            api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarActaPdf(ID_ACTA:${this.props.acta.ID_ACTA}){ID_ACTA}}`
            ).then(async(response)=> {

                alert("eliminado correctamente");
                this.props.setAdjuntos([]);
            
            }).catch(err=>{
                alert("Ha ocurrido un problema al eliminar el adjunto.Pongase en contacto con el soporte técnico")
            })            

        }

        return(
            <>
              {this.props.isNew?
                <>
                      {this.state.fileName?
                        <span style={{marginRight: 10 }}>{this.state.fileName}</span>
                      :
                        <p>No se ha adjuntado el acta escaneada.</p>
                      }  
                        <a href="#top" onClick={subirActa}>Adjuntar</a>
                        <input 
                            ref={fileInput => this.adjunto = fileInput} 
                            type="file"
                            onChange={archivoSubir}
                            style={{display:"none"}}
                        />
                    </>
                :
                <>
                {this.props.actaAdjuntos.length===0?
                    <>
                        <p>No se ha adjuntado el acta escaneada.</p><a href="#top" onClick={subirActa}>Adjuntar</a>
                        <input 
                            ref={fileInput => this.adjunto = fileInput} 
                            type="file"
                            onChange={archivoSubido}
                            style={{display:"none"}}
                        />
                    </>
                :<>
                    <a href="#top" onClick={descargarActa}>Ver</a>&nbsp;
                    <a href="#top" onClick={eliminarActa}>Eliminar</a>&nbsp;
                    <a href="#top" onClick={subirActa}>Adjuntar de nuevo</a>
                    <input 
                        ref={fileInput => this.adjunto = fileInput} 
                        type="file"
                        onChange={archivoSubido}
                        style={{display:"none"}}
                    />
                </>}
                </>
              }
            </>
        )
    }
}