import React, {Component} from 'react';
import {
    Navbar,
    ButtonGroup,
    Button,
    Alignment,
    Dialog,
    Classes,
    FormGroup,
    Popover,
    Tooltip,
    AnchorButton,
    Intent,
    InputGroup
} from "@blueprintjs/core";
import { Cargando } from '../Cargando';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Select from "react-select";
import { DatosPersonasFisicas } from "./DatosPersonasFisicas";
import InformacionGeneralNuevo from  "./InformacionGeneralNuevo"
import i18next from 'i18next';

class Partes extends Component {
    state={
        cargando:false,
        nuevoActivado:false,
        modalAbierto:false,
        personaSeleccionada:null,
        calidad:'',
        domicilio:'',
        nif:'',
        parteSeleccionado:null,
        modalBorrarParte: false,
        modalPersonaAbierto:false,
        modalNuevaJuridicaAbierto:false
    }
    render() {
        const seleccionPersona=(value)=>{
            this.setState({
                personaSeleccionada: value,
                domicilio:value.domicilio,
                nif:value.nif
            })
        }

        const abrirModalNuevo = () =>{
            this.setState({ 
                nuevoActivado:true,
                modalAbierto: true,
                personaSeleccionada:null,
                calidad:'' 
            })
        }

        const abrirModalEditar = () =>{
            if(!this.state.parteSeleccionado){
                alert("Debe seleccionar una de las partes")
                return
            }

            let personaSel=this.props.personas.find(persona=>{return persona.value===this.state.parteSeleccionado.value})
            this.setState({ 
                nuevoActivado:false,
                personaSeleccionada:personaSel,
                calidad:this.state.parteSeleccionado.calidad,
                modalAbierto: true
             })
        }

        const abrirModalBorrar = () =>{
            if(!this.state.parteSeleccionado){
                alert("Debe seleccionar una de las partes")
                return
            }

            this.setState({ 
                nuevoActivado:false,
                modalBorrarParte: true 
            });
        }
        const cerrarModal=()=>{
            this.setState({
                nuevoActivado:false,
                modalAbierto:false
            });
        }
        const agregarParte=async ()=>{
            if(!this.state.personaSeleccionada){
                alert("Antes debe seleccionar persona")
                return
            }
            if(!this.state.calidad){
                alert("Ingresar valor en campo en calidad de...")
                return
            }
            let existe=this.props.partes.find(parte=>{
                return parte.value===this.state.personaSeleccionada.value
            });
            if(existe){
                alert("Ya existe la persona en la lista")
                return
            }

            let partes=this.props.partes
            partes.push({ 
                value: this.state.personaSeleccionada.value, 
                label: this.state.personaSeleccionada.label, 
                nif: this.state.personaSeleccionada.nif, 
                domicilio: this.state.personaSeleccionada.domicilio, 
                calidad: this.state.calidad 
            })
            await this.props.setPartes(partes)

            await this.setState({
                cargando:true
            },()=>{
                cerrarModal();
                this.setState({cargando:false});
            });
            
        }
        const actualizarParte=async ()=>{
            if(!this.state.parteSeleccionado){
                alert("Debe seleccionar la parte")
                return
            }
            if(!this.state.personaSeleccionada){
                alert("Antes debe seleccionar persona")
                return
            }
            if(!this.state.calidad){
                alert("Ingresar valor en campo en calidad de...")
                return
            }
            let existe=this.props.partes.find(parte=>{
                return parte.value===this.state.personaSeleccionada.value
            })
            if(existe && this.state.parteSeleccionado.value!==this.state.personaSeleccionada.value){
                alert("Ya existe la persona en la lista")
                return
            }

            let partes=this.props.partes
            let parte=await partes.find(parte=>{return parte.value===this.state.parteSeleccionado.value})
            parte.value=this.state.personaSeleccionada.value
            parte.label=this.state.personaSeleccionada.label
            parte.nif=this.state.personaSeleccionada.nif 
            parte.domicilio=this.state.personaSeleccionada.domicilio
            parte.calidad=this.state.calidad
            await  this.props.setPartes(partes)
            
            await this.setState({
                cargando:true
            },()=>{
                cerrarModal();
                this.setState({cargando:false});
            });
        }
        const columns = [
            {
                dataField: 'label',
                text: 'Nombre y Apellido o Razón Social',
                sort: true
            },
            {
                dataField: 'nif',
                text: 'NIF',
                sort: true
            },
            {
                dataField: 'domicilio',
                text: 'Domicilio',
                sort: true
            },
            {
                dataField: 'calidad',
                text: 'En calidad de',
                sort: true
            }
        ];
        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Mostrando { from } a { to } de { size } Resultados
            </span>
        );
        const options = {
            paginationSize: 4,
            pageStartIndex: 0,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'Primero',
            prePageText: 'Atrás',
            nextPageText: 'Próxima',
            lastPageText: 'Última',
            nextPageTitle: 'Primera página',
            prePageTitle: 'página previa',
            firstPageTitle: 'Siguiente página',
            lastPageTitle: 'Última página',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '5', value: 5
              }] // A numeric array is also available. the purpose of above example is custom the text
        };
        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    parteSeleccionado: row
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }
        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };
        const abrirModalNuevaPersona=()=>{
    
            //abrir modal de persona física
            this.setState({
                modalPersonaAbierto:true,
                personaSeleccionada:null
            })

        }

        const abrirModalNuevaPersonaJurídica=()=>{

            //abrir modal de persona jurídica
            this.setState({
                modalNuevaJuridicaAbierto:true,
                personaSeleccionada:null
            })
        
        }
        const Botonera = () => {
            return(
                <>
                    <Button className="bp3-minimal" icon="new-link" text="Nueva Persona Física" fill={true} onClick={abrirModalNuevaPersona} />
                    <br />
                    <Button className="bp3-minimal" icon="git-new-branch" text="Nueva Persona Jurídica" onClick={abrirModalNuevaPersonaJurídica} />
                </>
            )
        }
        const crearPersona=async ()=>{
            await this.datosPersona.crearPersona();
            if (this.datosPersona.state.idPersonaCreada) {
                cerrarModalPersona();
                await this.props.cargarPersonas();
                let personaSel=this.props.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})
                this.setState({personaSeleccionada:personaSel})
            }
        }
        const guardarPersonaJuridica=async ()=>{
            
            let result=await this.informacion.handleGuardarDatos()
            if(!result)return
            await this.cargarPersonas()
            //seleccionamos a al persona creada en la lista.
            let personaSel=this.state.personas.find(persona=>{return persona.value===this.informacion.state.personaCreada})
            this.setState({personaSeleccionada:personaSel})
            await this.setState({modalNuevaJuridicaAbierto:false})

        }
        const handleCalidad = (e) => {
            this.setState({ calidad: e.target.value })
        }
        const cerrarModalPersona=()=>{
            this.setState({
                modalPersonaAbierto:false,
            });
        }
        const cerrarModalBorrar=()=>{
            this.setState({
                nuevoActivado:false,
                modalBorrarParte:false
            });
        }
        const eliminarParte=async()=>{
            
            let partes=this.props.partes
            partes=await partes.filter(parte=>{return parte.value!==this.state.parteSeleccionado.value})
            await  this.props.setPartes(partes)
            
            await this.setState({
                parteSeleccionado:null,
                cargando:true,
                modalBorrarParte:false
            },()=>{
                cerrarModal();
                this.setState({cargando:false});
            })

        
        }
        return (
            <>
            {this.state.cargando===true?
                <Cargando/>
            :
            <>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModalPersona}
                    title="Propiedades de la persona"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPersonaAbierto}
                    >
                    <DatosPersonasFisicas
                        datosPersona={
                        this.state.personaSeleccionada
                            ? this.state.personaSeleccionada.value
                            : null
                        }
                        ref={input => {
                        this.datosPersona = input;
                        }}
                        i18next={i18next}
                    />
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModalPersona}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip
                            content="Añadir persona"
                            position="top"
                        >
                            <AnchorButton
                            intent={Intent.PRIMARY}
                            onClick={crearPersona}
                            >
                            Añadir persona
                            </AnchorButton>
                        </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                    title="Propiedades de la empresa"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevaJuridicaAbierto}
                    className="datos-empresa-dialog"
                >
                        <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} />  
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cancelar." position="top">          
                            <Button
                                alignText="left"
                                icon="cross"
                                text="Cancelar"
                                intent="danger"
                                onClick={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                                className="iconoIzquierda bp3-minimal"
                            />
                        </Tooltip>
                        <Tooltip content="Guardar." position="top">
                        <Button
                            alignText="left"
                            icon="floppy-disk"
                            text="Guardar"
                            intent="danger"
                            onClick={guardarPersonaJuridica}
                            className="iconoIzquierda bp3-minimal"
                        />
                        </Tooltip>
                    </div>
                </div>  
                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoActivado?"Crear parte":"Editar parte"}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <div id="dialogo-partes" className="bp3-card separarBordes">
                            <FormGroup>
                                <div id="botones-creacion-persona">
                                    <Popover  content={<Botonera />} transitionDuration={400} target={<Button  className="bp3-minimal" icon="add" text="Nueva Persona" />} />
                                </div>
                            </FormGroup>
                            <FormGroup
                                label="Persona:"
                                labelFor="persona"
                                intent="danger"
                                helperText={!this.state.personaSeleccionada?"Debe seleccionar la persona parte.":""}
                            >
                                <div id="select-persona-parte" style={this.state.personaSeleccionada?{}:{border:"1px solid red"}}>
                                    <Select
                                        options={this.props.personas}
                                        className="pixelAlrededor"
                                        onChange={seleccionPersona}
                                        value={this.state.personaSeleccionada}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup
                                label="NIF:"
                                labelFor="nif"
                            >
                                <InputGroup id="nif" disabled={true} value={this.state.nif?this.state.nif:''} />   
                            </FormGroup>
                            <FormGroup
                                label="Domicilio:"
                                labelFor="domicilio"
                            >
                                <InputGroup id="domicilio" disabled={true} value={this.state.domicilio?this.state.domicilio:''} />   
                            </FormGroup>
                            <FormGroup
                                label="En calidad de:"
                                labelFor="calidad"
                            >
                                <InputGroup id="calidad" value={this.state.calidad} onChange={handleCalidad} intent={this.state.calidad?"primary":"danger"} />   
                            </FormGroup>
                        </div> 
                    </div>   
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>Cerrar</Button>
                            </Tooltip>
                            <Tooltip content={this.state.nuevoActivado?"Añadir parte":"Editar parte"} position="top">
                                <AnchorButton
                                    id="agregar-parte"
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?agregarParte:actualizarParte}
                                >
                                {this.state.nuevoActivado?"Añadir parte":"Editar parte"}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModalBorrar}
                    title="Borrar asistente"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarParte}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h3>¿Desea eliminar la parte seleccionada?</h3>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Eliminar parte" position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarParte}
                                >
                                    Si
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModalBorrar}>No</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Navbar>
                    <Navbar.Group>
                        <ButtonGroup id="botonera-partes" align={Alignment.LEFT}>
                            <Button className="bp3-minimal" id="btn-nuevo-partes" icon="add" text="Nuevo" onClick={abrirModalNuevo} />
                            <Button className="bp3-minimal" icon="edit" id="btn-editar-partes" text="Editar"  onClick={abrirModalEditar} />
                            <Button className="bp3-minimal" icon="delete" id="btn-eliminar-partes" text="Borrar"  onClick={abrirModalBorrar} />
                        </ButtonGroup>
                    </Navbar.Group>
                </Navbar>
                <BootstrapTable
                    keyField='value'
                    data={ this.props.partes }
                    columns={ columns }
                    selectRow={ selectRow }
                    pagination={ paginationFactory(options) }
                    headerClasses="header-class"
                    rowClasses="row-class"
                    hover
                    condensed
                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                />
            </>
            }
            </>
        );
    }
}

export default Partes;