import React, {Component} from 'react';
import { withApollo } from 'react-apollo';
import { Navbar,
    ButtonGroup,
    Alignment,
    Button,
    Dialog,
    Classes,
    Tooltip,
    AnchorButton,
    Intent,
    FormGroup,
    Popover,
    Switch
} from "@blueprintjs/core";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Select from "react-select";
import { Cargando } from '../Cargando';
import { DatosPersonasFisicas } from "./DatosPersonasFisicas";
import InformacionGeneralNuevo from  "./InformacionGeneralNuevo"
import i18next from 'i18next';

class AsistentesActa extends Component {
    state={
        asistenteSeleccionado: {},
        nuevoActivado: false,
        modalAbierto: false,
        modalPersonaAbierto:false,
        modalNuevaJuridicaAbierto:false,
        personaSeleccionada:null,
        personaSeleccionadaRepresentada:null,
        vota: false,
        modalBorrarAsistente:false,
        cargando:false
    }

    render() {
        function boolFormatter(cell, row) {
            return (
                (cell)?'SI':'NO'
            );
        }

        const handleDerechoVoto = () => {
            this.setState ({ vota: !this.state.vota})
        }

        const abrirModalNuevo = () =>{
            this.setState({ 
                nuevoActivado:true,
                modalAbierto: true,
                personaSeleccionada:null,
                personaSeleccionadaRepresentada:null,
                vota:false 
            })
        }

        const abrirModalEditar = () =>{
            if(!this.state.asistenteSeleccionado){
                alert("Debe seleccionar un asistente")
                return
            }

            let personaSel=this.props.personas.find(persona=>{return persona.value===this.state.asistenteSeleccionado.value})
            let personaSelRepresentada=this.state.asistenteSeleccionado.idRepresenta?this.props.personas.find(persona=>{return persona.value===this.state.asistenteSeleccionado.idRepresenta}):null
            this.setState({ 
                nuevoActivado:false,
                personaSeleccionada:personaSel,
                personaSeleccionadaRepresentada:personaSelRepresentada,
                vota:this.state.asistenteSeleccionado.vota,
                modalAbierto: true
             })
        }

        const abrirModalBorrar = () =>{
            if(!this.state.asistenteSeleccionado){
                alert("Debe seleccionar un asistente")
                return
            }

            this.setState({ 
                nuevoActivado:false,
                modalBorrarAsistente: true 
            });
        }

        const defaultSorted = [{
            dataField: 'NUMERO',
            order: 'asc'
        }];
        const columns = [
            {
                dataField: 'label',
                text: 'Nombre',
                sort: true
            },
            {
                dataField: 'vota',
                text: 'Derecho a voto',
                sort: true,
                formatter: boolFormatter
            },
            {
                dataField: 'representa',
                text: 'Representa a',
                sort: true
            }
        ];

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    asistenteSeleccionado: row
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Mostrando { from } a { to } de { size } Resultados
            </span>
        );

        const options = {
            paginationSize: 4,
            pageStartIndex: 0,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'Primero',
            prePageText: 'Atrás',
            nextPageText: 'Próxima',
            lastPageText: 'Última',
            nextPageTitle: 'Primera página',
            prePageTitle: 'página previa',
            firstPageTitle: 'Siguiente página',
            lastPageTitle: 'Última página',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '5', value: 5
              }] // A numeric array is also available. the purpose of above example is custom the text
        };

        const cerrarModal=()=>{
            this.setState({
                nuevoActivado:false,
                modalAbierto:false
            });
        }

        const agregarAsistente=async ()=>{
            if(!this.state.personaSeleccionada){
                alert("Antes debe seleccionar el asistente")
                return
            }
            let existe=this.props.asistentes.find(asistente=>{
                return asistente.value===this.state.personaSeleccionada.value
            })
            if(existe){
                alert("Ya existe el asistente en la lista")
                return
            }

            let asistentes=this.props.asistentes
            asistentes.push({ value: this.state.personaSeleccionada.value, label: this.state.personaSeleccionada.label, vota: this.state.vota?this.state.vota:false, representa: this.state.personaSeleccionadaRepresentada?this.state.personaSeleccionadaRepresentada.label:null,idRepresenta:this.state.personaSeleccionadaRepresentada?this.state.personaSeleccionadaRepresentada.value:null })
            await this.props.setAsistentes(asistentes)

            await this.setState({
                cargando:true
            },()=>{
                cerrarModal();
                this.setState({cargando:false});
            });
            
        }

        const eliminarAsistente=async()=>{
            
            let asistentes=this.props.asistentes
            asistentes=await asistentes.filter(asistente=>{return asistente.value!==this.state.asistenteSeleccionado.value})
            await  this.props.setAsistentes(asistentes)
            
            await this.setState({
                asistenteSeleccionado:null,
                cargando:true,
                modalBorrarAsistente:false
            },()=>{
                cerrarModal();
                this.setState({cargando:false});
            })

        
        }
        
        const actualizarAsistente=async ()=>{
            if(!this.state.asistenteSeleccionado){
                alert("Debe seleccionar un asistente")
                return
            }

            if(!this.state.personaSeleccionada){
                alert("Antes debe seleccionar el asistente")
                return
            }

            let existe=this.props.asistentes.find(asistente=>{
                return asistente.value===this.state.personaSeleccionada.value
            })
            if(existe && this.state.asistenteSeleccionado.value!==this.state.personaSeleccionada.value){
                alert("Ya existe el asistente en la lista")
                return
            }

            let asistentes=this.props.asistentes
            let asistente=await asistentes.find(asistente=>{return asistente.value===this.state.asistenteSeleccionado.value})
            asistente.value=this.state.personaSeleccionada.value
            asistente.label=this.state.personaSeleccionada.label
            asistente.vota=this.state.vota
            asistente.representa=this.state.personaSeleccionadaRepresentada?this.state.personaSeleccionadaRepresentada.label:null
            asistente.idRepresenta=this.state.personaSeleccionadaRepresentada?this.state.personaSeleccionadaRepresentada.value:null
            await  this.props.setAsistentes(asistentes)
            
            await this.setState({
                cargando:true
            },()=>{
                cerrarModal();
                this.setState({cargando:false});
            });
        }

        const seleccionPersona=(value)=>{
            this.setState({personaSeleccionada: value})
        }

        const seleccionPersonaRepresentada=(value)=>{
            if(!this.state.personaSeleccionada){
                alert("Antes debe seleccionar el asistente")
                return
            }
            if(this.state.personaSeleccionada.value===value.value){
                alert("El asistente y el representado no pueden ser la misma persona")
                return
            }

            this.setState({personaSeleccionadaRepresentada: value})
        }

        const abrirModalNuevaPersona=()=>{
    
            //abrir modal de persona física
            this.setState({
                modalPersonaAbierto:true,
                personaSeleccionada:null
            })

        }

        const abrirModalNuevaPersonaJurídica=()=>{

            //abrir modal de persona jurídica
            this.setState({
                modalNuevaJuridicaAbierto:true,
                personaSeleccionada:null
            })
        
        }

        const Botonera = () => {
            return(
                <>
                    <Button className="bp3-minimal" icon="new-link" text="Nueva Persona Física" fill={true} onClick={abrirModalNuevaPersona} />
                    <br />
                    <Button className="bp3-minimal" icon="git-new-branch" text="Nueva Persona Jurídica" onClick={abrirModalNuevaPersonaJurídica} />
                </>
            )
        }

        const cerrarModalPersona=()=>{
            this.setState({
                modalPersonaAbierto:false,
            });
        }

        const cerrarModalBorrar=()=>{
            this.setState({
                nuevoActivado:false,
                modalBorrarAsistente:false
            });
        }

        const crearPersona=async ()=>{
            await this.datosPersona.crearPersona();
            if (this.datosPersona.state.idPersonaCreada) {
                cerrarModalPersona();
                await this.props.cargarPersonas();
                let personaSel=this.props.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})
                this.setState({personaSeleccionada:personaSel})
            }
        }

        const guardarPersonaJuridica=async ()=>{
            
            let result=await this.informacion.handleGuardarDatos()
            if(!result)return
            await this.cargarPersonas()
            //seleccionamos a al persona creada en la lista.
            let personaSel=this.state.personas.find(persona=>{return persona.value===this.informacion.state.personaCreada})
            this.setState({personaSeleccionada:personaSel})
            await this.setState({modalNuevaJuridicaAbierto:false})

        }

        return (
            <>
                {this.state.cargando===true?
                    <Cargando/>
                :
                <>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModalPersona}
                    title="Propiedades de la persona"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPersonaAbierto}
                    >
                    <DatosPersonasFisicas
                        datosPersona={
                        this.state.personaSeleccionada
                            ? this.state.personaSeleccionada.value
                            : null
                        }
                        ref={input => {
                        this.datosPersona = input;
                        }}
                        i18next={i18next}
                    />
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModalPersona}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip
                            content="Añadir persona"
                            position="top"
                        >
                            <AnchorButton
                            intent={Intent.PRIMARY}
                            onClick={crearPersona}
                            >
                            Añadir persona
                            </AnchorButton>
                        </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                    title="Propiedades de la empresa"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevaJuridicaAbierto}
                    className="datos-empresa-dialog"
                >
                        <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} />  
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cancelar." position="top">          
                            <Button
                                alignText="left"
                                icon="cross"
                                text="Cancelar"
                                intent="danger"
                                onClick={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                                className="iconoIzquierda bp3-minimal"
                            />
                        </Tooltip>
                        <Tooltip content="Guardar." position="top">
                        <Button
                            alignText="left"
                            icon="floppy-disk"
                            text="Guardar"
                            intent="danger"
                            onClick={guardarPersonaJuridica}
                            className="iconoIzquierda bp3-minimal"
                        />
                        </Tooltip>
                    </div>
                </div>  
                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoActivado?"Crear asistente":"Editar asistentes"}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <div id="dialogo-asistentes" className="bp3-card separarBordes">
                            <FormGroup>
                                <div id="botones-creacion-persona">
                                    <Popover  content={<Botonera />} transitionDuration={400} target={<Button  className="bp3-minimal" icon="add" text="Nueva Persona" />} />
                                </div>
                            </FormGroup>
                            <FormGroup
                                label="Persona que asiste:"
                                labelFor="persona"
                                intent="danger"
                                helperText={!this.state.personaSeleccionada?"Debe seleccionar el apoderado.":""}
                            >
                                <div id="select-persona-asiste" style={this.state.personaSeleccionada?{}:{border:"1px solid red"}}>
                                    <Select
                                        options={this.props.personas}
                                        className="pixelAlrededor"
                                        onChange={seleccionPersona}
                                        value={this.state.personaSeleccionada}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup
                                label="Persona a la que representa:"
                                labelFor="persona"
                                intent="danger"
                            >
                                <div id="select-persona-representada">
                                    <Select
                                        options={this.props.personas}
                                        className="pixelAlrededor"
                                        onChange={seleccionPersonaRepresentada}
                                        value={this.state.personaSeleccionadaRepresentada}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup
                                label="¿Tiene derecho a voto?"
                                inline={true}
                            >
                                <Switch id="boleano-derecho-voto" innerLabelChecked="Si" innerLabel="No" onChange={handleDerechoVoto} checked={this.state.vota} />
                            </FormGroup>
                            
                        </div> 
                    </div>   
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>Cerrar</Button>
                            </Tooltip>
                            <Tooltip content={this.state.nuevoActivado?"Añadir asistente.":"Editar asistente"} position="top">
                                <AnchorButton
                                    id="agregar-asistente"
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?agregarAsistente:actualizarAsistente}
                                >
                                {this.state.nuevoActivado?"Añadir asistente":"Editar asistente"}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModalBorrar}
                    title="Borrar asistente"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAsistente}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>¿Desea eliminar el asistente seleccionado?</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Eliminar asistente" position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarAsistente}
                                >
                                    Si
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModalBorrar}>No</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Navbar>
                    <Navbar.Group>
                        <ButtonGroup id="botonera-asistentes" align={Alignment.LEFT}>
                            <Button className="bp3-minimal" id="btn-nuevo-asistente" icon="add" text="Nuevo" onClick={abrirModalNuevo} />
                            <Button className="bp3-minimal" icon="edit" id="btn-editar-asistente" text="Editar"  onClick={abrirModalEditar} />
                            <Button className="bp3-minimal" icon="delete" id="btn-eliminar-asistente" text="Borrar"  onClick={abrirModalBorrar} />
                        </ButtonGroup>
                    </Navbar.Group>
                </Navbar>
                <BootstrapTable
                    keyField='value'
                    data={ this.props.asistentes }
                    columns={ columns }
                    selectRow={ selectRow }
                    pagination={ paginationFactory(options) }
                    defaultSorted={ defaultSorted }
                    headerClasses="header-class"
                    rowClasses="row-class"
                    hover
                    condensed
                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                />
                </>
                }
            </>
        )
    }
}
export default withApollo(AsistentesActa);