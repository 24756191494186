import React, { Component } from 'react';
import { Tab, Tabs,Classes,AnchorButton,Dialog,Intent } from "@blueprintjs/core"
import { Cargando } from "../Pantallas/Cargando"

import CapitalSocialInicial from "../Pantallas/Elementos/CapitalSocialInicial"
import Variaciones from "../Pantallas/Elementos/Variaciones"
import TransmisionTitulos from "../Pantallas/Elementos/TransmisionTitulos"
import EstadoCapitalSocial from "../Pantallas/Elementos/EstadoCapitalSocial"
import Libros from "../Pantallas/Elementos/Libros"
import PactosReservados from "../Pantallas/Elementos/PactosReservados"
import OtrosDatos from "../Pantallas/Elementos/OtrosDatos"
import FormaSocietaria from "../Pantallas/FormaSocietaria"
import ObligacionesEmitidas from "../Pantallas/ObligacionesEmitidas"
import ImprimirInformacionEstructuraSocietaria from "../Pantallas/Elementos/ImprimirInformacionEstructuraSocietaria"

import gql from "graphql-tag";
import { withApollo } from 'react-apollo';


class CapitalSocial extends Component {

    state = {
        animacion: true,
        mostrarMenuCapital:false,
        datosEmpresa:[],
        logotipo:null, 
        personasFisicas:null,
        personasJuridicas:null,
        empresaPrincipal:null,
        capSocialInicial:null,
        capSocialInicialDistribucion: null,
        tiposAccion:null,
        tiposParticipacion:null

    };
     CargarDatosEmpresa= async () =>{
        let datosEmpresa= await this.props.client
        .query({
            query:gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `, fetchPolicy:'network-only'
        }).then(result => {
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }
            }
        })
        return datosEmpresa
    }
    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }
    cargarPersonasFisicas= async()=>{
		let personasFisicas=await this.props.client
		.query({
			query: gql`
            {personasFisicas(ORDEN:"APELLIDO1"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}`,
			fetchPolicy:'network-only'

		}).then(result=>{ 
			return result.data.personasFisicas
		})
		return personasFisicas
	}
	cargarPersonasJuridicas=async()=>{
		let personasJuridicas= await  this.props.client
		.query({
			query: gql`
            {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}`,
			fetchPolicy:'network-only'

		}).then(result=>{ 
			return result.data.personasJuridicas
		})
		return personasJuridicas	
	}
    cargarEmpresaPrincipal= async()=>{
        let empresaPrincipal= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{EMPRESA_PRINCIPAL:true}){
                    ID_PERSONA,
                    ID_TIPO_SOCIEDAD,
                    TIPO_SOCIEDAD{ID_TIPO_SOCIEDAD,DESCRIPCION},
                    ID_TIPO_CAPITAL_SOCIAL,
                    TIPO_CAPITAL_SOCIAL{ID_TIPO_CAPITAL_SOCIAL,DESCRIPCION},
                    FECHA_FINALIZACION}
            }
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.personasJuridicas
        })
        return empresaPrincipal
    }
    
  cargarCapitalInicial=async()=>{
	let capital=await this.props.client.query({
		query:gql`{capSocialInicialDistribucion{
		  ID_DISTRIBUCION,
		  ID_PERSONA,
		  PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
		  TIPO_TITULO,
		  NUMERO_TITULOS,
		  NUMERACION_TITULOS
		}}`,fetchPolicy:'network-only'
	  }).then(async(result)=>{
		  return result.data.capSocialInicialDistribucion
		  
	  })
	  return capital
  }
    cargarCapitalSocial= async()=>{
        let capitalInicial= await this.props.client.query({
			query:gql`{capSocialInicial{FECHA_VALIDEZ,VALOR_TITULO}}`,fetchPolicy:'network-only'
		}).then(result=>{
			return result.data.capSocialInicial
		})
        return capitalInicial
    }
    cargarTiposAccion=async() =>{
        let tiposAccion=await this.props.client.query({
			query:gql`{tiposAccion{
				ID_TIPO_ACCION,
				DESCRIPCION,
				INCOMPATIBLE,
			}}`
		}).then(async(result)=>{
			return result.data.tiposAccion
		})
        return tiposAccion
    }
    cargarTiposParticipacion= async() =>{
        let tiposParticipacion= await this.props.client.query({
			query:gql`{tiposParticipacion{
				ID_TIPO_PARTICIPACION,
				DESCRIPCION,
				INCOMPATIBLE,
			}}`
		}).then(async(result)=>{
			return result.data.tiposParticipacion
		})
        return tiposParticipacion
    }
   
    
    cargarDatos= async()=>{
		let personasFisicas= await this.cargarPersonasFisicas()
		let personasJuridicas= await this.cargarPersonasJuridicas()
        let datosEmpresa= await this.CargarDatosEmpresa();
        let empresaPrincipal= await this.cargarEmpresaPrincipal()
        let capSocialInicialDistribucion= await this.cargarCapitalInicial()
        let capSocialInicial= await this.cargarCapitalSocial()
        let tiposAccion= await this.cargarTiposAccion()
        let tiposParticipacion=await this.cargarTiposParticipacion()
        this.setState({
			personasFisicas:personasFisicas,
			personasJuridicas:personasJuridicas,
            datosEmpresa:datosEmpresa,
            empresaPrincipal: empresaPrincipal,
            capSocialInicialDistribucion: capSocialInicialDistribucion,
            capSocialInicial: capSocialInicial,
            tiposAccion:tiposAccion,
            tiposParticipacion:tiposParticipacion

		})
	}

    
    enviarCapSocial=()=>{
        localStorage.setItem("pestanaCapitalSocial", "forma-societaria")

        this.setState({
            mostrarMenuCapital:false,
        })
    }

    componentDidMount(props){

        this.setState({
            cargando: true
        },async () => {
            await this.obtenerLogoEmpresa();
            await this.cargarDatos()           
            this.setState({              
                cargando:false});
        });
    }    

    render() {
        
        return (
            <>
            {this.state.cargando===true?
                <Cargando/>
            :
            <>    
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={this.cerrarModal}
                    title="Datos forma societaria"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.mostrarMenuCapital}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <p>Antes de introducir el capital social debe guardar cierta información
                            sobre la forma societaria de la empresa.Elija 'Forma societaria' en el menú
                            y complete la información que se le requiera.
                        </p>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.enviarCapSocial}
                            >
                                Aceptar
                            </AnchorButton>
                    </div>
                </div>
                </Dialog>

                    <Tabs
                        id="pestanas"
                        animate={this.animacion} 
                        onChange={this.cambiarPestaña} 
                        selectedTabId={localStorage.getItem("pestanaCapitalSocial")?localStorage.getItem("pestanaCapitalSocial"):"forma-societaria"}
                        renderActiveTabPanelOnly={true}
                    >
                        <Tab id="forma-societaria" title="Forma societaria" panel={<FormaSocietaria editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} empresaPrincipal={this.state.empresaPrincipal}/>} />
                        <Tab id="capital-social" title="Capital social inicial" panel={<CapitalSocialInicial editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} capSocialInicialDistribucion={this.state.capSocialInicialDistribucion} empresaPrincipal={this.state.empresaPrincipal} capSocialInicial={this.state.capSocialInicial} tiposAccion={this.state.tiposAccion}tiposParticipacion={this.state.tiposParticipacion}/>} />                       
                        <Tab id="variaciones" title="Variaciones" panel={<Variaciones editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa}  capSocialInicialDistribucion={this.state.capSocialInicialDistribucion} personasFisicas={this.state.personasFisicas} personasJuridicas={this.state.personasJuridicas}  empresaPrincipal={this.state.empresaPrincipal} capSocialInicial={this.state.capSocialInicial} tiposAccion={this.state.tiposAccion} tiposParticipacion={this.state.tiposParticipacion}/>} />                       
                       <Tab id="transmision-titulos" title="Transmisión de títulos" panel={<TransmisionTitulos editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} personasFisicas={this.state.personasFisicas} personasJuridicas={this.state.personasJuridicas} empresaPrincipal={this.state.empresaPrincipal} capSocialInicialDistribucion={this.state.capSocialInicialDistribucion} tiposAccion={this.state.tiposAccion}  tiposParticipacion={this.state.tiposParticipacion} />} />                        
                        <Tab id="estado-capital-social" title="Capital Social Actual" panel={<EstadoCapitalSocial editable={this.props.editable}  logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} empresaPrincipal={this.state.empresaPrincipal} capSocialInicial={this.state.capSocialInicial} tiposAccion={this.state.tiposAccion} tiposParticipacion={this.state.tiposParticipacion} />} />                        
                        <Tab id="libros" title="Libros" panel={<Libros capSocialInicialDistribucion={this.state.capSocialInicialDistribucion} capSocialInicial={this.state.capSocialInicial}  logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} personasFisicas={this.state.personasFisicas} personasJuridicas={this.state.personasJuridicas}  />} />
                        <Tab id="pactos-reservados" title="Pactos reservados" panel={<PactosReservados editable={this.props.editable}  logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} capSocialInicialDistribucion={this.state.capSocialInicialDistribucion} personasFisicas={this.state.personasFisicas} personasJuridicas={this.state.personasJuridicas} />} />                       
                        <Tab id="obligaciones-emitidas" title="Obligaciones emitidas" panel={<ObligacionesEmitidas editable={this.props.editable}  logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa}/>} />
                        <Tab id="otros-datos" title="Otros datos" panel={<OtrosDatos editable={this.props.editable}  logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} capSocialInicialDistribucion={this.state.capSocialInicialDistribucion} />} />
                        <Tab id="imprimir" title="Imprimir información" panel={<ImprimirInformacionEstructuraSocietaria editable={this.props.editable}  logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} empresaPrincipal={this.state.empresaPrincipal} personasFisicas={this.state.personasFisicas} personasJuridicas={this.state.personasJuridicas} capSocialInicialDistribucion={this.state.capSocialInicialDistribucion} capSocialInicial={this.state.capSocialInicial} />} />
                    </Tabs>
            </>
            }
          </>  
        )
    }

    cambiarPestaña =async (navbarTabId) => {


        let personaJuridica= this.state.empresaPrincipal
        personaJuridica.forEach(persona=>{
            if(!persona.ID_TIPO_SOCIEDAD && !persona.ID_CAPITAL_SOCIAL && (navbarTabId==="capital-social" || navbarTabId==="estado-capital-social")){
                this.setState({mostrarMenuCapital:true})
            }else{
                localStorage.setItem("pestanaCapitalSocial",navbarTabId)
                this.setState(this.state)
            }
        })
    }
}
export default withApollo(CapitalSocial)