import React, {Component} from 'react';
import { Navbar,
    Button,
    Tooltip,
    Dialog,
    Classes,
    RadioGroup,
    Radio,
    AnchorButton,
    Intent,
    Card,
    Elevation
} from "@blueprintjs/core";
import { Cargando } from '../Cargando';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import i18next from "i18next";

class VotosAcuerdo extends Component {
    state={
        votoSeleccionado:null,
        cargando:false,
        modalAbierto:false,
        votacion: 1
    }

    render() {

        const abrirModal = () => {

            if(!this.state.votoSeleccionado){
                alert("Debe seleccionar un voto")
                return
            }

            this.setState({
                votacion:this.state.votoSeleccionado.voto,
                modalAbierto: true
            })

        }

        function boolFormatter(cell, row) {
            let tipo=""
            switch(cell) {
                case -1:
                    tipo="En contra"
                break;
                case 1:
                    tipo="A Favor"
                break;
                case 0:
                    tipo="Abstención"
                break;
                default:
                    tipo="";
                break;
            }
            return (
                tipo
            );
        }

        const columns = [
            {
                dataField: 'nombre',
                text: 'Nombre'
            },
            {
                dataField: 'voto',
                text: 'Voto',
                formatter: boolFormatter
            }
        ];

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    votoSeleccionado: row
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Mostrando { from } a { to } de { size } Resultados
            </span>
        );

        const options = {
            paginationSize: 4,
            pageStartIndex: 0,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'Primero',
            prePageText: 'Atrás',
            nextPageText: 'Próxima',
            lastPageText: 'Última',
            nextPageTitle: 'Primera página',
            prePageTitle: 'página previa',
            firstPageTitle: 'Siguiente página',
            lastPageTitle: 'Última página',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '5', value: 5
              }] // A numeric array is also available. the purpose of above example is custom the text
        };

        const cerrarModal=()=>{
            this.setState({modalAbierto:false});
        }

        const handleVotacion = (event) => {
            this.setState({votacion: parseInt(event.currentTarget.value)})
        }

        const guardar=async ()=>{
            let votos=this.props.votos
            let voto=await votos.find(voto=>{return voto.idPersona===this.state.votoSeleccionado.idPersona})
            voto.voto=this.state.votacion
            await  this.props.setVotos(votos)
            cerrarModal()
        }

        return (
            <>
                {this.state.cargando===true?
                    <Cargando/>
                :
                <>
                    <Dialog
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title="Votación"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAbierto}
                    >
                        <div id="dialogo-actas">
                            <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                                <p>Seleccione el sentido del voto:</p>
                                <div id="sentido-voto">
                                    <RadioGroup
                                        onChange={handleVotacion}
                                        selectedValue={this.state.votacion}
                                        large={true}
                                    >
                                        <Radio label="A Favor" value={1} />
                                        <Radio label="En contra" value={-1} />
                                        <Radio label="Abstención" value={0} />
                                    </RadioGroup>
                                </div>
                            </Card>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <AnchorButton id="guardar-sentido-voto" intent={Intent.PRIMARY} onClick={guardar}>
                                Aceptar
                            </AnchorButton>
                            <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal}>
                                Cancelar
                            </AnchorButton>
                            </div>
                        </div>   
                    </Dialog>
                    <Navbar>
                        <Navbar.Group>
                            <Tooltip content="Añadir resultado de votación">
                                <Button
                                    icon="insert"
                                    id="btn-cambiar-voto"
                                    text="Voto"
                                    intent="danger"
                                    onClick={abrirModal}
                                    className="iconoDerecha bp3-minimal"
                                />
                            </Tooltip>
                        </Navbar.Group>
                    </Navbar>
                    <BootstrapTable
                        keyField='idPersona'
                        data={ this.props.votos }
                        columns={ columns }
                        selectRow={ selectRow }
                        pagination={ paginationFactory(options) }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                    />
                </>
                }
            </>  
        );
    }
}

export default VotosAcuerdo;