/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Elevation, Callout, Card,Popover,Navbar, ButtonGroup,FormGroup,Alignment, Button, NavbarDivider, Dialog, Classes, Tooltip, AnchorButton, Intent, Spinner } from "@blueprintjs/core"
import { Table, Column, Cell, RegionCardinality, Regions} from "@blueprintjs/table"
import Select from "react-select"
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import { DatosPersonasJuridicas } from "./DatosPersonasJuridicas";
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas";
import InformacionGeneralNuevo from  "../Pantallas/Elementos/InformacionGeneralNuevo"
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from 'i18next';
import styles from './stylesPrint';

class SociosGestores extends Component {

    state={
        modalNuevoAbierto: false,
        editarActivado: false,
        borrarActivado: false,
        homologado: false,
        abierto: false,
        value: null,
        enabled: true,
        personasSeleccionada:null,
        sociosGestores:[], 
        modalBusqueda:false,
        personas:[],
        tablaActas:false,
        socioSeleccionado:null,
        cargando:false,
        regionSeleccionada:null,
        cargandoForm:false,
        columnWidths: [100],
        resized: false,
        datosEmpresa:null,
        cargandoImprimirG:false,
        modalImprimirG: false,
        logotipo:null,
        personasFisicas:null,
        personasJuridicas:null,
    }

    cambiarPestaña = (navbarTabId) => this.setState({ pestaña: navbarTabId });

    
    obtenerNombresocios=async(socio)=>{
        if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            //obtenemos el nombre
            this.state.personasFisicas.find((persona)=>{
                if (persona.ID_PERSONA===socio.ID_PERSONA) {
                    socio.nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                }
            }) 

        }else if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){

            this.state.personasJuridicas.find((persona)=>{
                if (persona.ID_PERSONA===socio.ID_PERSONA) {
                    socio.nombre=`${persona.RAZON_SOCIAL}`
                }
            }) 

        }
        
    }


    cargarSociosGestores=async()=>{

        this.setState({cargando:true})

        let socios=await this.props.client.query({
            query:gql`{sociosGestores{ID_SOCIO_GESTOR,PERSONA{ID_PERSONA,ID_TIPO_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return JSON.parse(JSON.stringify(result.data.sociosGestores))
        })

        if(!socios || socios.length===0){
            this.setState({sociosGestores:[]})
            this.setState({cargando:false})
            return
        }

        let index=0
        for(let socio of socios){
            socios[index].ID_PERSONA=socios[index].ID_SOCIO_GESTOR
            await this.obtenerNombresocios(socio)
            index++
        }

        this.setState({
            sociosGestores:socios,
            cargando:false
        })

    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }

     resizeColumns() {
        
        let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        for (let index = 0; index < this.sociosGestores.locator.grid.numCols; index++) {
            totalMinSizeReq = totalMinSizeReq + this.sociosGestores.locator.getWidestVisibleCellInColumn(index);
            cumulativeColumnWidths.push(this.sociosGestores.locator.getWidestVisibleCellInColumn(index));
        }
        if (totalMinSizeReq > containerSize) {
            this.setState({
                columnWidths: cumulativeColumnWidths,
                resized: true
            });
        } else {
            let diff = ((containerSize - totalMinSizeReq) / this.sociosGestores.locator.grid.numCols);
            this.setState({
                columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                resized: true
            });
        }
        
    }


    
    generarImprimirG= async() => {
        this.setState({
            cargandoImprimirG:true,
            modalImprimirG:true
        }, async () => {
             this.setState({cargandoImprimirG:false}, () => {
                 ReactDOM.render(this.ImprimirG(), document.getElementById('generarG-pdf'))
             })
        })
    }

    
    ImprimirG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>SOCIOS GESTORES</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>LISTA DE SOCIOS</Text>
                </View>
                    {this.state.sociosGestores?this.state.sociosGestores.map((socio, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>NOMBRE</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{socio.nombre?socio.nombre:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        let personasFisicas=await this.cargarPersonasFisicas()
        let personasJuridicas=await this.cargarPersonasJuridicas()
        
        this.setState({
            datosEmpresa:datosEmpresa,
            personasFisicas: personasFisicas,
            personasJuridicas: personasJuridicas,
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

    cargarPersonasFisicas= async () => {
        let personasFisicas=this.props.client
        .query({
            query: gql` 
            {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
         return result.data.personasFisicas;
        })
        
        return personasFisicas;
       
    }
    
    cargarPersonasJuridicas= async () => {
        let personasJuridicas=this.props.client
        .query({
            query: gql`
            {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

       }).then(result=>{
         return result.data.personasJuridicas;
        })
        
        return personasJuridicas;
        
    }

    cargarPersonas=async ()=>{

        let personas=[]

        for(let persona of this.state.personasFisicas){
            personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 

        }

        for(let persona of this.state.personasJuridicas){
            personas.push({"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 
        }

        this.setState({personas:personas})
    }

        
   
    componentDidMount(props){
        this.setState({
            cargandoForm: true
        },async () => {
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            await this.cargarPersonas()
            await this.cargarSociosGestores()
            this.setState({cargandoForm: false})
        }); 
    }

    
    cellRendererNombre = (rowIndex) => {
        return <Cell loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.sociosGestores[rowIndex].nombre}`}</Cell>
    }

    
    render() {

        document.title ="LexControl | Socios gestores"

        const abrirModalBusqueda = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            this.setState({
                personasSeleccionada:null,
                modalBusqueda: true,
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:false,
                busquedaActivado:true,
            })
        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }
            
            if(!this.state.socioSeleccionado){
                alert("Debe seleccionar una persona")
                return
            }

            this.setState({
                modalBorrarAbierto: true,
                nuevaPresonaActivado:false,
                editarPersonaActivado:false,
                borrarActivado:true,
                busquedaActivado:false
            })
        }

        const cellRendererNombre = (rowIndex) => {
                return <Cell loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.sociosGestores[rowIndex].nombre}`}</Cell>
        }

        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
                modalBusqueda:false
            })
        }

        
        const handleSeleccionarPersona=(value)=>{

            this.setState({personasSeleccionada:value})

        }

        
        const anadirPersona=async()=>{

            //comporbar que no este ya insertada
            let existe=this.state.sociosGestores.find(socio=>{return socio.ID_SOCIO_GESTOR===this.state.personasSeleccionada.value})
         
            if(existe){
                alert("Ya existe este socio")
                return
            }
            
            await this.props.client.mutate({
                mutation:gql`mutation{crearSocioGestor(VALORES:{ID_SOCIO_GESTOR:${this.state.personasSeleccionada.value}}){ID_SOCIO_GESTOR}}`
            }).then(async result=>{
             
                alert("El registro se ha creado correctamente.")
         
                await this.cargarSociosGestores()

                //reseleccionamos la que teniamos seleccionada
                let sel=this.state.sociosGestores.find((socio,index)=>{
                    if(socio.ID_SOCIO_GESTOR===result.data.crearSocioGestor.ID_SOCIO_GESTOR){
                        this.setState({regionSeleccionada:[Regions.row(index)]})
                    }
                    return socio.ID_SOCIO_GESTOR===result.data.crearSocioGestor.ID_SOCIO_GESTOR

                })

                this.setState({
                    socioSeleccionado:sel
                })

            }).catch(err=>{
                console.log(err)
                alert("Ha ocurrido un error al crear el socio gestor.Pongase en contacto con el soporte tecnico")
            })

            cerrarModal()
        }

        const eliminarSocio = () => {

            this.props.client.mutate({
                mutation:gql`mutation{eliminarSocioGestor(ID_SOCIO_GESTOR:${this.state.socioSeleccionado.ID_SOCIO_GESTOR}){ID_SOCIO_GESTOR}}`
            }).then(result=>{
             
                alert("El registro se ha eliminado correctamente.")
                this.setState({
                    socioSeleccionado:null,
                    regionSeleccionada:null
                },()=>{this.sociosGestores.clearSelection()})
                this.cargarSociosGestores()

            }).catch(err=>{
                alert("Ha ocurrido un error al eliminar el socio gestor.Pongase en contacto con el soporte tecnico")
            })

            cerrarModal()
        };

        const handleSeleccionarSocio = (value) => {
            if(value.length===0)return
            let seleccionada=this.state.sociosGestores[value[0].rows[0]]      
            this.setState({
                socioSeleccionado:seleccionada,
                regionSeleccionada:value
            })
        }

        const crearPersona = async () => {
            await this.datosPersona.crearPersona();
            if (this.datosPersona.state.idPersonaCreada) {
                
                await this.cargarPersonas();
                //creamos el socios gestor/*
                await this.props.client.mutate({
                    mutation:gql`mutation{crearSocioGestor(VALORES:{ID_SOCIO_GESTOR:${this.datosPersona.state.idPersonaCreada}}){ID_SOCIO_GESTOR}}`
                }).then(async result=>{
                 
                    alert("El registro se ha creado correctamente.")
             
                    await this.cargarSociosGestores()

                    //reseleccionamos la que teniamos seleccionada
                    let sel=this.state.sociosGestores.find((socio,index)=>{
                        if(socio.ID_SOCIO_GESTOR===result.data.crearSocioGestor.ID_SOCIO_GESTOR){
                            this.setState({regionSeleccionada:[Regions.row(index)]})
                        }
                        return socio.ID_SOCIO_GESTOR===result.data.crearSocioGestor.ID_SOCIO_GESTOR

                    })

                    this.setState({
                        socioSeleccionado:sel
                    })

                }).catch(err=>{
                    alert("Ha ocurrido un error al crear el socio gestor.Pongase en contacto con el soporte tecnico")
                })
                //cerrar el modal
                cerrarModal2();
            }
        };
      
        const actualizarPersona = async () => {
            await this.datosPersona.actualizarPersona();
            if (this.datosPersona.state.idPersonaActualizada) {
                await this.cargarPersonas();
                await this.setState({modalPersonaAbierto:false})
                await this.cargarSociosGestores()
            }
        };

        const abrirPopover = () => {
            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                this.setState({popoverAbierto: false})
                return
            }else{
                this.setState({popoverAbierto: true})
            }

        }
      
        const Botonera = () => {
            return(
                <>
                    <Button className="bp3-minimal" icon="new-link" text="Nueva Persona Física" fill={true} onClick={abrirModalNuevaPersona} />
                    <br />
                    <Button className="bp3-minimal" icon="git-new-branch" text="Nueva Persona Jurídica" onClick={abrirModalNuevaPersonaJurídica} />
                </>
            )
        }

        const abrirModalNuevaPersona=()=>{

            //abrir modal de persona física
            this.setState({modalPersonaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})
            this.setState({borrarActivado:false})
            this.setState({busquedaActivado:false})
            this.setState({socioSeleccionado:null})

        }

        const abrirModalNuevaPersonaJurídica=()=>{

            //abrir modal de persona jurídica
            this.setState({modalNuevaJuridicaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})
            this.setState({borrarActivado:false})
            this.setState({busquedaActivado:false})
            this.setState({socioSeleccionado:null})

        }

        const abrirModalEditarPersona=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.socioSeleccionado){
                alert("Debe seleccionar un socio gestor.")
                return
            }

            this.setState({nuevaPresonaActivado:false})
            this.setState({borrarActivado:false})
            this.setState({editarPersonaActivado:true})
                //si es personas física
            if(this.state.socioSeleccionado.PERSONA.ID_TIPO_PERSONA===1){
                this.setState({modalPersonaAbierto:true})

                //si es persona jurídica
            }else if(this.state.socioSeleccionado.PERSONA.ID_TIPO_PERSONA===2){
                this.setState({modalEditarPersonaJuridica:true})
            }


        }

        const guardarPersonaJuridica=async ()=>{

            
            let result=await this.informacion.handleGuardarDatos()
            if(!result)return
            await this.cargarPersonas()
            
            await this.props.client.mutate({
                mutation:gql`mutation{crearSocioGestor(VALORES:{ID_SOCIO_GESTOR:${this.informacion.state.personaCreada}}){ID_SOCIO_GESTOR}}`
            }).then(async result=>{
             
                alert("El registro se ha creado correctamente.")
                await this.cargarSociosGestores()
                //reseleccionamos la que teniamos seleccionada
                let sel=await this.state.sociosGestores.find((socio,index)=>{
                    if(socio.ID_SOCIO_GESTOR===this.informacion.state.personaCreada){
                        this.setState({regionSeleccionada:[Regions.row(index)]})
                    }
                    return socio.ID_SOCIO_GESTOR===this.informacion.state.personaCreada
                })

                this.setState({socioSeleccionado:sel})

            }).catch(err=>{
                alert("Ha ocurrido un error al crear el socio gestor.Pongase en contacto con el soporte tecnico")
            })
            
            await this.setState({modalNuevaJuridicaAbierto:false})

        }

        const cerrarModal2 = async () => {
            await this.cargarPersonas()
            if(this.state.personaSeleccionada && this.state.personaSeleccionada.tipo===2){
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                this.setState({personaSeleccionada:personaSel})
                this.setState({modalNuevaJuridicaAbierto:false})
            }
            this.cargarSociosGestores()
            this.setState({
                modalEditarPersonaJuridica:false,
                modalPersonaAbierto:false
            })
        }

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimirG:false
            })
        }

        return (
            <>
            {this.state.cargandoForm===true?
                <Cargando/>
            :
            <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir socios gestores"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirG}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirG?
                            <div style={{ width: '100%', height: '500px'}} id='generarG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Borrar un socio"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>¿Desea eliminar el socio seleccionado?</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Eliminar socio." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarSocio}
                            >
                                Si
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>No</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
                 <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title="Personas"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBusqueda}
                >
                    <div className="pixelAlrededor">
                        <FormGroup
                            label="Personas:"
                            labelFor="personas"
                            fill={true}
                            intent="danger"
                            helperText={!this.state.personasSeleccionada?"Debe seleccionar una persona.":""}
                        >
                        <div style={!this.state.personasSeleccionada?{border:"1px solid red"}:{}}>
                            <Select
                                isClearable
                                isSearchable
                                name="socios"
                                onChange={handleSeleccionarPersona}
                                options={this.state.personas}
                                value={this.state.personasSeleccionada}
                            />
                            </div>
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip content={"Añadir Persona"} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirPersona}
                            >
                                Seleccionar persona
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title="Propiedades de la persona"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPersonaAbierto}
                >
                    <DatosPersonasFisicas
                        datosPersona={
                        this.state.socioSeleccionado
                            ? this.state.socioSeleccionado.ID_SOCIO_GESTOR
                            : null
                        }
                        ref={input => {
                        this.datosPersona = input;
                        }}
                        i18next={i18next}
                    />
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content="Cerrar y volver." position="top">
                        <Button onClick={cerrarModal2}>Cerrar</Button>
                    </Tooltip>
                    <Tooltip
                        content={
                        this.state.nuevaPresonaActivado
                            ? "Añadir persona."
                            : "Actualizar persona."
                        }
                        position="top"
                    >
                        <AnchorButton
                        intent={Intent.PRIMARY}
                        onClick={
                            this.state.nuevaPresonaActivado
                            ? crearPersona
                            : actualizarPersona
                        }
                        >
                        {this.state.nuevaPresonaActivado
                            ? "Añadir persona"
                            : "Actualizar Persona"}
                        </AnchorButton>
                    </Tooltip>
                    </div>
                </div>
        </Dialog>
        <Dialog
            transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal}
            title="Propiedades de la empresa"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalEditarPersonaJuridica}
            className="datos-empresa-dialog"
            >
            <DatosPersonasJuridicas
                animacion={this.animacion}
                cambiarPestaña={this.cambiarPestaña}
                pestaña={this.state.pestaña}
                empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado:null}
            />
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS} />
                <ButtonGroup>
                    <Button 
                        alignText="right"
                        icon="log-out"
                        intent="danger"
                        text="salir"
                        onClick={cerrarModal2}
                    />
                </ButtonGroup>
            </div>
        </Dialog>
        <Dialog transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal2}
            title="Propiedades de la empresa"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalNuevaJuridicaAbierto}
            className="datos-empresa-dialog"
        >
                <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} />  
            <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Tooltip content="Guardar." position="top">
                <Button
                    alignText="left"
                    icon="floppy-disk"
                    text="Guardar"
                    intent="danger"
                    onClick={guardarPersonaJuridica}
                    className="iconoIzquierda bp3-minimal"
                />
                </Tooltip>
            </div>
        </div>  
        </Dialog>
            <div className="tablaSesenta pixelAlrededor">
                <Card>
                    <h4>Socios Gestores</h4>
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                <Popover content={<Botonera />} transitionDuration={400} target={<Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevaPresonaActivado} onClick={abrirPopover} />} />
                                <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarPersonaActivado}  onClick={abrirModalEditarPersona} />
                                <Button className="bp3-minimal" icon="delete" text="Borrar" active={this.state.borrarActivado}  onClick={abrirModalBorrar}/>
                                <Button className="bp3-minimal" icon="geosearch" text="Buscar en lista de personas" active={this.state.busquedaActivado} onClick={abrirModalBusqueda} />
                                <NavbarDivider />
                                <Tooltip 
                                    content="Desea imprimir todos los socios gestores" 
                                    position="right" 
                                >
                                    <Button 
                                        className="bp3-minimal" 
                                        icon="print" 
                                        text="Imprimir" 
                                        onClick={()=>this.generarImprimirG()}
                                    />
                                </Tooltip>
                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    <Table selectedRegions={this.state.regionSeleccionada} enableMultipleSelection={false} ref={input => {this.sociosGestores = input;}} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} onSelection={handleSeleccionarSocio} numRows={this.state.sociosGestores.length} enableColumnResizing={false} columnWidths={this.state.columnWidths} onCompleteRender={() => {
                                            if (!this.state.resized) {
                                                this.resizeColumns();
                                            }
                                        }}>
                        <Column name="Nombre"  cellRenderer={cellRendererNombre}/>
                    </Table>
                    <br />
                </Card>
            </div>
            <div className="pixelAlrededor tablaCuarenta">
                <Card interactive={true} elevation={Elevation.TWO}>
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Ley 22/2014, de 12 de noviembre, por la que se regulan las entidades de capital-riesgo</Callout>
                    <br />
                    <AnchorButton onClick={()=> window.open("https://www.boe.es/eli/es/l/2014/11/12/22/con", "_blank")}>Ley 22/2014</AnchorButton>
                    <br />
                </Card>
            </div>
            </>
            }
            </>
        )
    }
}
export default withApollo(SociosGestores)