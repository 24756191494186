import React from "react";
import { Card, Elevation, FormGroup,Tooltip,Button,InputGroup,Intent,Classes,AnchorButton,Dialog } from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime";
import Select from "react-select";
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import moment from 'moment';

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: "include"
});

export default class Anulacion extends React.Component{

    state = {
        fechaFirmeza: null,
        registroSeleccionado: null,
        diario: '',
        asiento: '',
        tomo: '',
        folio: '',
        inscripcion: '',
        hoja: '',
        registroOptions: [
        ],
        registroNuevo:null
    }

     //carga del select de registros
     cargarRegistros=async()=>{

        await client.query({
            query:gql`{registros{ID_REGISTRO,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
  
            let registros=[]
            for(let registro of result.data.registros){
                registros.push({
                    value:registro.ID_REGISTRO,
                    label:registro.DESCRIPCION
                })
            }
            this.setState({registroOptions:registros})
  
        })
  
    }
  
    componentDidMount=async()=>{

        await this.cargarRegistros()

        if(this.props.datosAnulacion && Object.keys(this.props.datosAnulacion).length>0){
            let registroSel=await this.state.registroOptions.find(registro=>{return registro.value===this.props.datosAnulacion.ID_REGISTRO})
            await this.setState({
              fechaFirmeza:new Date(this.props.datosAnulacion.FECHA_FIRMEZA),
              registroSeleccionado: registroSel,
              diario: this.props.datosAnulacion.DIARIO,
              asiento: this.props.datosAnulacion.ASIENTO,
              tomo: this.props.datosAnulacion.TOMO,
              folio: this.props.datosAnulacion.FOLIO,
              inscripcion: this.props.datosAnulacion.INSCRIPCION,
              hoja: this.props.datosAnulacion.HOJA,
            })
        }

    }

    render(){

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const escogeFechaFirmeza = nuevaFecha => {
            this.setState({ fechaFirmeza: nuevaFecha });
        };

        const cambiarDiario = e => {
            this.setState({ diario: e.currentTarget.value });
        };
    
        const cambiarAsiento = e => {
            this.setState({ asiento: e.currentTarget.value });
        };
    
        const cambiarTomo = e => {
            this.setState({ tomo: e.currentTarget.value });
        };
    
        const cambiarFolio = e => {
            this.setState({ folio: e.currentTarget.value });
        };
      
        const cambiarInscripcion = e => {
            this.setState({ inscripcion: e.currentTarget.value });
        };
      
        const cambiarHoja = e => {
            this.setState({ hoja: e.currentTarget.value });
        };

        const seleccionRegistro = value => {
            this.setState({ registroSeleccionado: value });
        };
    
        const abrirModalNuevoRegistro = () => {
            this.setState({ modalNuevoRegistro: true });
        };

        const cerrarModal2=()=>{
            this.setState({modalNuevoRegistro: false})
        }

        const cambiarNuevoRegistro=(ev)=>{
            this.setState({registroNuevo:ev.currentTarget.value})
        }

        const guardarRegistro=()=>{

            if(!this.state.registroNuevo)return
      
            //comprobar que no exista ya un registro 
            let existe=this.state.registroOptions.find(reg=>{return reg.label===this.state.registroNuevo})
            if(existe){
                alert("Ya existe un registro con esta descripción")
                return
            }
      
            client.mutate({
                mutation:gql`mutation{crearRegistro(VALORES:{          
                    DESCRIPCION:"${this.state.registroNuevo}"
                }){ID_REGISTRO,DESCRIPCION}}`
            }).then(async(result)=>{
      
                await this.cargarRegistros()
      
                //seleccionamos el registro creado
                let creado=await this.state.registroOptions.find(reg=>{return reg.value===result.data.crearRegistro.ID_REGISTRO})
                this.setState({registroSeleccionado:creado})
                cerrarModal2()
      
            }).catch(err=>{
                alert("Ha ocurrido un error al crear el registro.Pongase en contacto con el soporte técnico.")
                console.log(err)
            })
      
        }
      

        return(
            <>
                <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal2}
                    title="Nuevo registro"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevoRegistro}
                    >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup label="Introduzca el nuevo registro:" inline={true}>
                        <InputGroup
                            value={this.state.registroNuevo}
                            onChange={cambiarNuevoRegistro}
                            intent={this.state.registroNuevo ? "primary" : "danger"}
                        />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <AnchorButton intent={Intent.PRIMARY} onClick={guardarRegistro}>
                            Aceptar
                        </AnchorButton>
                        <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal2}>
                            Cancelar
                        </AnchorButton>
                        </div>
                    </div>
                </Dialog>

                <Card  id="card-anulacion" interactive={true} elevation={Elevation.TWO}>
                    <FormGroup label="fecha de firmeza:" inline={true}>
                        <div style={this.state.registroSeleccionado && !this.state.fechaFirmeza?{border:"1px solid red"}:{}}>
                        <DateInput minDate={new Date(1900, 1 ,1)}
                            {...jsDateFormatter}
                            onChange={escogeFechaFirmeza}
                            value={this.state.fechaFirmeza}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup label="Registro:">
                    <div style={this.state.fechaFirmeza && !this.state.registroSeleccionado?{border:"1px solid red"}:{}}>
                    <Select
                        options={this.state.registroOptions}
                        className="pixelAlrededor"
                        onChange={seleccionRegistro}
                        backspaceRemovesValue={true}
                        isClearable={true}
                        value={this.state.registroSeleccionado}
                    />
                    </div>
                    <Tooltip content="Añadir nuevo registro">
                    <Button icon="add" onClick={abrirModalNuevoRegistro} />
                    </Tooltip>
                </FormGroup>
                <FormGroup label="Diario:">
                    <InputGroup
                    value={this.state.diario}
                    onChange={cambiarDiario}
                    intent={"primary"}
                    fill={true}
                    />
                </FormGroup>
                <FormGroup label="Asiento:">
                    <InputGroup
                    value={this.state.asiento}
                    onChange={cambiarAsiento}
                    intent={"primary"}
                    fill={true}
                    />
                </FormGroup>
                <FormGroup label="Tomo:">
                    <InputGroup
                    value={this.state.tomo}
                    onChange={cambiarTomo}
                    intent={"primary"}
                    fill={true}
                    />
                </FormGroup>
                <FormGroup label="Folio:">
                    <InputGroup
                    value={this.state.folio}
                    onChange={cambiarFolio}
                    intent={"primary"}
                    fill={true}
                    />
                </FormGroup>
                <FormGroup label="Inscripcion:">
                    <InputGroup
                    value={this.state.inscripcion}
                    onChange={cambiarInscripcion}
                    intent={"primary"}
                    fill={true}
                    />
                </FormGroup>
                <FormGroup label="Hoja:">
                    <InputGroup
                    value={this.state.hoja}
                    onChange={cambiarHoja}
                    intent={"primary"}
                    fill={true}
                    />
                </FormGroup>
            </Card>
            </>
        )
    }
}