/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import React from "react";
import {
  Spinner,
  Card,
  Elevation,
  Classes,
  Tooltip,
  AnchorButton,
  Intent,
  Dialog,
  FormGroup,
  Navbar,
  NavbarHeading,
  ButtonGroup,
  Button,
  InputGroup,
  Alignment,
  Callout
} from "@blueprintjs/core";
import { Table, Column, Cell,Regions ,RegionCardinality } from "@blueprintjs/table";
import { DateInput } from "@blueprintjs/datetime";
import moment from "moment";
import gql from "graphql-tag";
import { NuevoTituloSocios } from "./NuevoTituloSocios";
import { withApollo } from "react-apollo";
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { CSVLink } from "react-csv";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Cargando } from "../Cargando";
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom'

import styles from '../stylesPrint';



class CapitalSocialInicial extends React.Component {
  _isMounted = false;

  state = {
	fecha: "",
	fechaSeleccionada: new Date(),
	valorTitulo: 0,
	numTitulos: 0,
	capitalSocialInicial: "0.00",
	existeCapitaSocial: false,
	abrirModalGuardarDatos: false,
	abrirModalDistribucion: false,
	capitalSocial: [
	],
	distribucionSeleccionada:null,
	nuevoActivado:true,
	editarActivado:false,
	borrarActivado:false,
	tiposParticipacion:null,
	regionSeleccionada:null,
	tipoCapital:null,
	indiceRegistros:0,
	totalRegistros:0,
	cargandoCsv:true,
	datosCsv:[],
	datosImpresion:[],
	valorTituloValido:true,
	pasoGuia:0,
	cargando: false,
	imprimirActivado:false,
	modalImprimir:false,
	cargandoImprimir:false,
	columnWidths: [100,100,100,100,100,100,100,100],
	resized: false,
	personasFisicas:null,
	personasJuridicas: null,
	loading : false,
	
	
	steps: [

		{
			target: '#datos-capital-social-inicial',
			content: 'Aqui insertaremos la información del capital social inicial.',
			selector: '#datos-capital-social-inicial',
			position: 'auto',
			disableBeacon: true,
			type: 'hover',
			isFixed: true,
			stepIndex: 0,
		},
		
		{
			target: '#nuevo-capital-social',
			content: "Para agregar una nueva distribución al capital social, hacemos clic en 'Nuevo'.",
			selector: '#nuevo-capital-social',
			position: 'auto',
			disableBeacon: true,
			type: 'hover',
			isFixed: true,
			stepIndex: 1,
		},
		{
			target: '#modal-nuevo-titulo',
			content: "A continuación se abrirá un diálogo con la siguiente información:",
			selector: '#modal-nuevo-titulo',
			position: 'auto',
			disableBeacon: true,
			type: 'hover',
			isFixed: true,
			stepIndex: 2,
		},
		{
			target: '#select-socio',
			content: "Seleccionamos el socio al que deseamos insertarle los títulos.",
			selector: '#select-socio',
			position: 'auto',
			disableBeacon: true,
			type: 'hover',
			isFixed: true,
			stepIndex: 3,
		},
		{
			target: '#boton-crear-socio',
			content: "Si el socio no existe, podemos crearlo.Hacemos clic, elegimos entre persona física o jurídica y rellenamos los datos solicitados.",
			selector: '#boton-crear-socio',
			position: 'auto',
			disableBeacon: true,
			type: 'hover',
			isFixed: true,
			stepIndex: 4,
		},
		{
			target: '#boton-editar-socio',
			content: "También podemos seleccionar un socio de la lista y editar su información.",
			selector: '#boton-editar-socio',
			position: 'auto',
			disableBeacon: true,
			type: 'hover',
			isFixed: true,
			stepIndex: 5,
		},
		{
			target: '#input-numeracion-titulos',
			content: "Le asignaremos una numerácion a los títulos del socio y el numero de títulos se calculará en función a está.La numeración puede ser un rango (1-10) y/o numeros separados por comas.",
			selector: '#input-numeracion-titulos',
			position: 'auto',
			disableBeacon: true,
			type: 'hover',
			isFixed: true,
			stepIndex:6,
		},
		{
			target: '#input-numeros-titulos',
			content: "Como podemos observar, el número de títulos ha sido calculado automáticamente partiendo de la numeración.Para poder hacer uso de una numeración esta no debe encontrarse en posesión de otro socio.Una numeración puede quedar libre si decrementamos el número de títulos.",
			selector: '#input-numeros-titulos',
			position: 'auto',
			disableBeacon: true,
			type: 'hover',
			isFixed: true,
			stepIndex: 7,
		},
		{
			target: '#checks-titulos',
			content: "Ahora debemos seleccionar el tipo de título del socio.",
			selector: '#checks-titulos',
			position: 'auto',
			disableBeacon: true,
			type: 'hover',
			isFixed: true,
			stepIndex: 8,
		},
	]
  };

  registrosMostrar=10


  resizeColumns() {
	
	let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
	let totalMinSizeReq = 0;
	let cumulativeColumnWidths= [];
	for (let index = 0; index < this.tablaDistribuciones.locator.grid.numCols; index++) {
		totalMinSizeReq = totalMinSizeReq + this.tablaDistribuciones.locator.getWidestVisibleCellInColumn(index);
		cumulativeColumnWidths.push(this.tablaDistribuciones.locator.getWidestVisibleCellInColumn(index));
	}
	if (totalMinSizeReq > containerSize) {
		this.setState({
			columnWidths: cumulativeColumnWidths,
			resized: true
		});
	} else {
		let diff = ((containerSize - totalMinSizeReq) / this.tablaDistribuciones.locator.grid.numCols);
		this.setState({
			columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
			resized: true
		});
	}
  }

  obtenerPotencias=(n)=>{

	let array = [];
	let binaryRepresentation = parseInt(n, 10).toString(2);
	binaryRepresentation = binaryRepresentation.split("").reverse().join(""); // You need to reverse the string to get the power of 2 corresponding
	for(let i = binaryRepresentation.length - 1; i >= 0; i--){
		if(parseInt(binaryRepresentation[i]) === 1){
			array[Math.pow(2, i)]=true
		}

	}

	return array
  }

 
//   cargarCapitalInicial=async()=>{
// 	let capital=
// 	await this.props.client.query({
// 		query:gql`{capSocialInicialDistribucion{
// 		  ID_DISTRIBUCION,
// 		  ID_PERSONA,
// 		  PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
// 		  TIPO_TITULO,
// 		  NUMERO_TITULOS,
// 		  NUMERACION_TITULOS
// 		}}`,fetchPolicy:'network-only'
// 	  }).then(async(result)=>{
// 		  return result.data.capSocialInicialDistribucion
		  
// 	  })
// 	  return capital
//   }
cargarPersonasFisicas= async() =>{
	let personasFisicas=await this.props.client
	  .query({
		  query: gql` 
			  {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
		  `,fetchPolicy:'network-only'
	  }).then(result=>{ 
		  return result.data.personasFisicas
	  })
	  return personasFisicas	  
}
cargarPersonasJuridicas= async() =>{
	let personasJuridicas=await this.props.client
	  .query({
		  query: gql`
			  {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
		  `,fetchPolicy:'network-only'
	  }).then(result=>{ 
		  return result.data.personasJuridicas
	  })
	  return personasJuridicas	  
}
  cargarDatos=async()=>{
	  let personasFisicas= await this.cargarPersonasFisicas()
	  let personasJuridicas= await this.cargarPersonasJuridicas()
	//   let capitalInicial= await this.cargarCapitalInicial()
	  this.setState({
		  personasFisicas:personasFisicas,
		  personasJuridicas:personasJuridicas,
		//   capitalSocInicial: capitalInicial
	  })
  }
  cargarDatosCsv=async()=>{

	this.setState({cargandoCsv:true})
	let datos=[]
	
	let capital=this.props.capSocialInicialDistribucion
	let index=1
	for(let cap of capital){
	  //buscamos el nombre de la persona
	  let nombre=""
	  if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
		this.state.personasFisicas.find(persona=>{
			if(persona.ID_PERSONA===cap.ID_PERSONA){
			  cap.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:""
			  cap.GRAVAMENES=persona.GRAVAMENES
			  nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
  
			}
		})

		

	  //Si es persona juridica
	  }else if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
		this.state.personasJuridicas.find(persona=>{
			if(persona.ID_PERSONA===cap.ID_PERSONA){
				cap.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:""
				cap.GRAVAMENES=persona.GRAVAMENES
				cap.nombre= persona.RAZON_SOCIAL
			}
		})

		

		}
		cap.nombre=nombre
		let tiposKeys=Object.keys(this.obtenerPotencias(cap.TIPO_TITULO))
		let tipos=""

		tiposKeys.forEach(t=>{

			if(this.state.tiposParticipacion){

				let tipoSel=null
				if(this.state.tipoCapital===1){
				tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_ACCION===parseInt(t)})
				}else{
				tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
				}
				if(tipos===""){
				tipos+=tipoSel.DESCRIPCION
				}else{

				tipos+=","+tipoSel.DESCRIPCION

				}

			}

		})
		cap.tipo=tipos

		datos.push({
		  "Nº":index,
		  "Socio":nombre,
		  "Tipo de titulo":tipos,
		  "Número de titulos":cap.NUMERO_TITULOS,
		  "%":parseFloat((cap.NUMERO_TITULOS/this.state.numTitulos)*100).toFixed(2),
		  "Numeración":cap.NUMERACION_TITULOS.replace("-"," a "),
		  "Gravamenes":cap.GRAVAMENES
		})


		index++;
	}
	this.setState({datosCsv:datos,datosImpresion:capital,cargandoCsv:false})

  }
  cargarCapital=async()=>{

	let capital=await this.props.client.query({
	  query:gql`{capSocialInicialDistribucionPaginacion(CAMPOS:"ID_DISTRIBUCION,ID_PERSONA,TIPO_TITULO,NUMERO_TITULOS,NUMERACION_TITULOS",INDICE_MOSTRAR:${this.state.indiceRegistros},NUMERO_MOSTRAR:${this.registrosMostrar}){
		CAP_SOCIAL_INICIAL_DISTRIBUCION{ID_DISTRIBUCION,
		ID_PERSONA,
		PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
		TIPO_TITULO,
		NUMERO_TITULOS,
		NUMERACION_TITULOS},
		total
	  }}`,fetchPolicy:'network-only'
	}).then(async(result)=>{
	  this.setState({totalRegistros:result.data.capSocialInicialDistribucionPaginacion.total})
		return result.data.capSocialInicialDistribucionPaginacion.CAP_SOCIAL_INICIAL_DISTRIBUCION
	})

	for(let cap of capital){
	  //buscamos el nombre de la persona
	
	  if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
		this.state.personasFisicas.find(persona=>{
			if(persona.ID_PERSONA===cap.ID_PERSONA){				
				cap.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:''
				cap.GRAVAMENES=persona.GRAVAMENES
				cap.nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
			}
		})

	

	  //Si es persona juridica
	  }else if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
		this.state.personasJuridicas.find(persona=>{
			if(persona.ID_PERSONA===cap.ID_PERSONA){
				cap.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:""
				cap.GRAVAMENES=persona.GRAVAMENES
				cap.nombre= persona.RAZON_SOCIAL
			}
		})


		

		}
	
		let tiposKeys=Object.keys(this.obtenerPotencias(cap.TIPO_TITULO))
		let tipos=""

		
		tiposKeys.forEach(t=>{
				
			 let tipoSel=null
			if(this.state.tipoCapital===1){
				tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_ACCION===parseInt(t)})
			}else{
				tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
			}
			if(tipos===""){
				tipos+=tipoSel.DESCRIPCION
			}else{

				tipos+=","+tipoSel.DESCRIPCION

			}			

		})
		cap.tipo=tipos


	}

	this.setState({capitalSocial:capital})
	this.cargarDatosCsv()

  }

  cargarNumTitulos=async()=>{

	//obtencion de los titulos del capital social.
	let numTitulos = 0;
	// let capitalInicial= await this.props.client
	// .query({
	//   query: gql`
	// 	{
	// 	  capSocialInicialDistribucion {
	// 		ID_PERSONA
	// 		NUMERO_TITULOS
	// 		PERSONA {
	// 		  TIPO_PERSONA {
	// 			ID_TIPO_PERSONA}}}}
	//   `,fetchPolicy:'network-only'
	// })
	// .then(result => {
	// 	return result.data.capSocialInicialDistribucion
	// })	
	  let capitalInicial=this.props.capSocialInicialDistribucion
	  capitalInicial.forEach(socio => {
		numTitulos += socio.NUMERO_TITULOS;
	  });
	  this.setState({ numTitulos: numTitulos });


  }
  tipoCapital= async()=>{
	let personas= this.props.empresaPrincipal
	// await this.props.client
	//   .query({
	// 	  query: gql`
	// 	  {
	// 		  personasJuridicas(CAMPOS:"ID_PERSONA,ID_TIPO_CAPITAL_SOCIAL",FILTROS:{EMPRESA_PRINCIPAL:true}){ID_PERSONA,ID_TIPO_CAPITAL_SOCIAL}
	// 	  }
	// 	  `,
	// 	  fetchPolicy:'network-only'
	//   }).then(result=>{ 
	// 		return result.data.personasJuridicas  
	//   })
		//   let tiposAccion= await this.props.client
		//   .query({
		// 	query:gql`{tiposAccion{
		// 		ID_TIPO_ACCION,
		// 		DESCRIPCION,
		// 		INCOMPATIBLE,
		// 	}}`
		//   }).then(async(result)=>{
		// 	  return result.data.tiposAccion
		//   })
		//  let tiposParticipacion= await this.props.client
		//   .query({
		// 	query:gql`{tiposParticipacion{
		// 		ID_TIPO_PARTICIPACION,
		// 		DESCRIPCION,
		// 		INCOMPATIBLE,
		// 	}}`,fetchPolicy:'network-only'
		//   }).then(async(result)=>{
		// 	  return result.data.tiposParticipacion
		//   })
		
		 for(let persona of personas){
			if(persona.ID_TIPO_CAPITAL_SOCIAL===1){
				this.setState({
					tiposParticipacion:this.props.tiposAccion
				})
			}else{
				this.setState({
					tiposParticipacion:this.props.tiposParticipacion
				})
			}
			this.setState({tipoCapital: persona.ID_TIPO_CAPITAL_SOCIAL})
		}
  }
   cargarcapIncial= async ()=>{
	    //CAP SOCIAL INICIAL
	//   await this.props.client
	//   .query({
	// 	query: gql`
	// 	  {
	// 		capSocialInicial {
	// 		  FECHA_VALIDEZ
	// 		  VALOR_TITULO
	// 		}
	// 	  }
	// 	`,fetchPolicy:'network-only'
	//   })
	//   .then(result => {
		let capitalInicial= this.props.capSocialInicial
		//si tenemos capita social inicial.
		if (capitalInicial.length > 0) {
			capitalInicial.forEach(capital=>{
				this.setState({
					existeCapitaSocial: true,
					valorTitulo:Intl.NumberFormat('es-ES').format(capital.VALOR_TITULO),
					fechaSeleccionada: capital.FECHA_VALIDEZ
				  });
			})
		 
		}
   }
   generarImprimir=async()=>{
	this.setState({
		cargandoImprimir:true,
		modalImprimir:true}, async ()=>{
			this.setState({cargandoImprimir:false}, () =>{
				ReactDOM.render(this.ImprimirCapInit(), document.getElementById('generarImprimirCI-pdf'))
			})
		})
	}
	ImprimirCapInit=()=>(
		<PDFViewer style={{width: '100%', height: '100%'}}>
			<Document>
			<Page size="A4" orientation="landscape" style={styles.page}>
				<View style={styles.body}>
					<View fixed style={styles.row}>
					<Image
						style={styles.imageHeadL}
						src={this.props.logotipo?this.props.logotipo:pantallaInicio}
						/>
					</View>
					<View fixed style={styles.rowHead}>
						<Text style={styles.labelHead}>EMPRESA:</Text>
						<Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
					</View>
					<View fixed style={styles.rowHeadTwo}>
						<Text style={styles.labelHead}>INFORME:</Text>
						<Text style={styles.text}>ESTRUCTURA SOCIETARIA</Text>
					</View>
					<View style={styles.rowTitleOne}>
						<Text style={styles.labelTitle}>CAPITAL SOCIAL INICIAL</Text>
					</View>
						{this.state.datosImpresion?this.state.datosImpresion.map((dato, index)=>{ 
							
							return(
								<View key={index}>
								{index ===0 && (
								<View style={styles.colHead}>
									<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>Nº</Text>
									<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>SOCIO</Text>
									<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>NACIONALIDAD</Text>
									<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>TIPO DE TÍTULOS</Text>
									<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>NUM. DE TÍTULOS</Text>
									<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>%</Text>
									<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>NUMERACIÓN DE TÍTULOS</Text>
									<Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'10%'}}>GRAVÁMENES</Text>
																   
								</View>
								)}
									<View style={styles.colHead}>
										<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{index+1}</Text>
										<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{dato.nombre?dato.nombre:''}</Text>
										<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{dato.NACIONALIDAD?dato.NACIONALIDAD:''}</Text>
										<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'30%'}}>{dato.tipo?dato.tipo:''}</Text>
										<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{dato.NUMERO_TITULOS?dato.NUMERO_TITULOS:''}</Text>
										<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{parseFloat((dato.NUMERO_TITULOS/this.state.numTitulos)*100).toFixed(2)}</Text>
										<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{dato.NUMERACION_TITULOS?dato.NUMERACION_TITULOS:''}</Text>
										<Text style={{textAlign:'center',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'10%'}}>{dato.GRAVAMENES?dato.GRAVAMENES:''}</Text>
									</View>                               
								
							</View>
							)
						}):<></>}      
				</View>
	
				<Text
					style={styles.footerPage}
					render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
					fixed
				/>
				<Image
						style={styles.icono}
						src={iconoFooter}
						fixed
						/>
				<Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
			</Page>
			</Document>
		 </PDFViewer>
		)

  componentDidMount=async()=>{
	 this.setState({cargando: true}, async()=>{
		this._isMounted = true;
		 await this.tipoCapital()
		 await this.cargarDatos()		 
		 await this.cargarNumTitulos()
		 await this.cargarCapital()
		
		 //si el componente ha terminado de montarse.
	if (this._isMounted) {
		await this.cargarcapIncial()
		
	}
	this.setState({cargando:false})	 
  })
}

  irPrimerPagina=async()=>{
	  this.setState({indiceRegistros:0,
					cargando: true}, async() => {
						await this.cargarCapital()
						this.setState({cargando:false})			

					})
  }

  irUltimaPagina=async()=>{
	  this.setState({
		  indiceRegistros:Math.ceil(this.state.totalRegistros/this.registrosMostrar)-1,
		  cargando:true}, async () => {
			await this.cargarCapital()
			this.setState({cargando:false})			

		  })
  }

  cambiarIndiceMostrar=async(indice)=>{
	  this.setState({
		  indiceRegistros:indice,
			cargando:true}, async () => {
				await this.cargarCapital()
				this.setState({cargando:false})			

			})
  }

  botonesPaginacion=()=>{
        
	let botones=[]
	let totalPaginas=Math.ceil(this.state.totalRegistros/this.registrosMostrar)
	let restante=this.state.indiceRegistros+(totalPaginas-this.state.indiceRegistros)
	if(this.state.indiceRegistros>=3){
		botones.push(
			<Button>...</Button>
		)

	}
	for(let i=this.state.indiceRegistros>2?this.state.indiceRegistros-2:0;i<(this.state.indiceRegistros<2?(restante>=4?4:restante):(this.state.indiceRegistros+3)<totalPaginas?this.state.indiceRegistros+3:restante);i++){
		botones.push(
			<Button key={i} className={this.state.indiceRegistros===i?Classes.INTENT_PRIMARY:""} onClick={()=>{this.cambiarIndiceMostrar(i)}}>{i+1}</Button>
		)
	}

	if(this.state.indiceRegistros<10 && (this.state.indiceRegistros+3)<totalPaginas){
		botones.push(
			<Button>...</Button>
		)

	}
	return botones

}
	cellRendererNumSoc = rowIndex => {
		return (
		<Cell wrapText="false" truncated="false">{`${
			(rowIndex+1)+(this.state.indiceRegistros*this.registrosMostrar)
		}`}</Cell>
		);
	};
	cellRendererSocio = rowIndex => {
		return (
		<Cell wrapText="false" truncated="false">{`${
			this.state.capitalSocial[rowIndex].nombre
		}`}</Cell>
		);
	};
	cellRendererNacionalidad = rowIndex => {
		return (
			<Cell wrapText="false" truncated="false">{`${
				this.state.capitalSocial[rowIndex].NACIONALIDAD
			}`}</Cell>
		);
	};
	 cellRendererTipo = rowIndex => {
		return (
		<Cell wrapText="false" truncated="false">{`${
			this.state.capitalSocial[rowIndex].tipo
		}`}</Cell>
		);
	};
	 cellRendererNumero = rowIndex => {
		return (
		<Cell wrapText="false" truncated="false">{`${
			this.state.capitalSocial[rowIndex].NUMERO_TITULOS
		}`}</Cell>
		);
	};
	cellRendererPorc = rowIndex => {
		return (
		<Cell columnWidths  wrapText="false" truncated="false">{`${
			parseFloat((this.state.capitalSocial[rowIndex].NUMERO_TITULOS/this.state.numTitulos)*100).toFixed(2)
		}`}</Cell>
		);
	};
	cellRendererNumeracion = rowIndex => {
		return (
		<Cell wrapText="false" truncated="false">{`${
			this.state.capitalSocial[rowIndex].NUMERACION_TITULOS
		}`}</Cell>
		);
	};

	cellRendererGravamenes = rowIndex => {
	  return (
		<Cell wrapText="false" truncated="false">{`${
		  this.state.capitalSocial[rowIndex].GRAVAMENES?this.state.capitalSocial[rowIndex].GRAVAMENES:""
		}`}</Cell>
	  );
	};



   render() {

	const { steps } = this.state;

	document.title ="LexControl | Capital social inicial"

	const cerrarModalImprimir=()=>{
		this.setState({
			modalImprimir:false
		})
	}
	const formatearMiles=(numero)=>{
		return numero.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."); 
	}
	
	const cambiarValorTitulo = ev => {

		  //solo permitir en el input numeros,comas y puntos.
		  let pattern=/^[0-9,.]*$/
		  if(pattern.test(ev.currentTarget.value)===false)return

		  //comprobacion que sea un número válido
		  let pattern2=/^\d{1,3}(\.\d{3})*(\,\d{2})?$/
		  if(pattern2.test(formatearMiles(ev.currentTarget.value.replace(/\./g, '')))){
			  this.setState({valorTituloValido:true})
		  }else{
			  this.setState({valorTituloValido:false})
		  }

		  this.setState({valorTitulo:formatearMiles(ev.currentTarget.value.replace(/\./g, '')) })
	};

	const cerrarModal = () => {
	  this.setState({
		abrirModalGuardarDatos: false,
		abrirModalDistribucion: false,
		modalBorrarAbierto:false
	  });
	};

	const escogeFecha = nuevaFecha => {
	  this.setState({
		fechaSeleccionada: moment(nuevaFecha).format("YYYY-MM-DD")
	  });
	};
	const abrirModalGuardar = rowIndex => {

		//si no tiene permisos para editar la pantalla
		if(this.props.editable===false){
			alert("No tienes permisos para realizar esta acción")
			return
		}

		if(this.state.valorTitulo==="0"){
			alert("El valor del título debe ser mayor a 0.")
			return
		}

		this.setState({ abrirModalGuardarDatos: true });
	};

	const abrirModalDistribucion =() => {
		//si no tiene permisos para editar la pantalla
		if(this.props.editable===false){
			alert("No tienes permisos para realizar esta acción")
			return
		}

	  if(!this.state.existeCapitaSocial){
		alert("primero debe guardar la información del capital social inicial")
		return
	  }

	  this.setState({ 
		distribucionSeleccionada:null,
		nuevoActivado:true,
		editarActivado:false,
		borrarActivado:false,
		abrirModalDistribucion: true
	  });
	};

	const abrirModalDistribucionEditar=()=>{

		//si no tiene permisos para editar la pantalla
		if(this.props.editable===false){
			alert("No tienes permisos para realizar esta acción")
			return
		}

	  if(!this.state.distribucionSeleccionada){
		alert("Debe seleccionar una distribucion")
		return
	  }

	  this.setState({ 
		abrirModalDistribucion: true,
		nuevoActivado:false,
		editarActivado:true,
		borrarActivado:false
	  });

	}

	/* esto lo dijo por si las moscas */

	/* const abrirModalDistribucionBorrar=()=>{

	  //si no tiene permisos para editar la pantalla
	  if(this.props.editable===false){
		  alert("No tienes permisos para realizar esta acción")
		  return
	  }

	  if(!this.state.distribucionSeleccionada){
		alert("Debe seleccionar una distribucion")
		return
	  }

	  this.setState({ 
		modalBorrarAbierto: true,
		nuevoActivado:false,
		editarActivado:false,
		borrarActivado:true
	  });

	} */

	const getMomentFormatter = (format) => {
	  // note that locale argument comes from locale prop and may be undefined
	  return {
		formatDate: (date, locale) =>
		  moment(date)
			.locale(locale)
			.format(format),
		parseDate: (str, locale) =>
		  moment(str, format)
			.locale(locale)
			.toDate(),
		placeholder: format
	  };
	};

	const guardarCambiosCapitalSocial = () => {


	  let query = "";
	  if(!this.state.valorTituloValido)return

	  //si no existe el capital social lo creamos
	  if (!this.state.existeCapitaSocial) {
		query = gql`mutation{crearCapSocialInicial(VALORES:{FECHA_VALIDEZ:"${
		  moment(this.state.fechaSeleccionada).format("YYYY-MM-DD")
		}",VALOR_TITULO:${this.state.valorTitulo.replace(/\./g, '').replace(/\,/g, '.')}}){FECHA_VALIDEZ}}`;
	  } else {
		// de lo contrario, lo actualizariamos
		query = gql`mutation{actualizarCapSocialInicial(VALORES:{FECHA_VALIDEZ:"${
		  moment(this.state.fechaSeleccionada).format("YYYY-MM-DD")
		}",VALOR_TITULO:${this.state.valorTitulo.replace(/\./g, '').replace(/\,/g, '.')}}){FECHA_VALIDEZ}}`;
	  }

	  //guardar el capital social.
	  this.props.client.mutate({
		mutation: query
	  });

	  alert("Capital social actualizado")
	  
	  this.setState({existeCapitaSocial:true})

	  cerrarModal();
	};

	const aceptar = async () => {
		this.setState({ loading : true },async () => {
      if(!this.nuevoTitulo.state.socioSeleccionado) {
        alert("Seleccionar socio")
			  this.setState({loading : false})
        return
      } 

      let tipoTitulo=0
      for (let key in this.nuevoTitulo.state.activadoCheckbox) {
        //damos de alta los tipos de participaciones
        tipoTitulo+=parseInt(key.replace("\"",""))
      }

      let existe=false;
	  if(this.state.nuevoActivado){

		//comprobar que no exista un socio con ese tipo de titulo.
		// let existe=await this.props.client
		// .query({
		//   query: gql`
		// 	{
		// 	  capSocialInicialDistribucion (CAMPOS:"ID_PERSONA,NUMERO_TITULOS",FILTROS:{ID_PERSONA:${this.nuevoTitulo.state.socioSeleccionado.value},TIPO_TITULO:${tipoTitulo}}){
		// 		ID_PERSONA
		// 		NUMERO_TITULOS
		// 		PERSONA {
		// 		  TIPO_PERSONA {
		// 			ID_TIPO_PERSONA
		// 		  }
		// 		}
		// 	  }
		// 	}
		//   `,fetchPolicy:'network-only'
		// })
		// .then(result => {
		//   if(result.data.capSocialInicialDistribucion.length>0)return true;
		//   return false;
		// });
		this.props.capSocialInicialDistribucion.find(capital=>{
			if(capital.ID_PERSONA===this.nuevoTitulo.state.socioSeleccionado.value && capital.TIPO_TITULO===tipoTitulo){
				existe=true
			}
		})
		// if(this.state.capitalSocInicial.length>0){
		// 	existe=true
		// }
		if(existe===true){
			alert("Ya existe este socio con este tipo de título.")
			this.setState({loading : false})
		  return
		}

		await this.nuevoTitulo.aceptarDistribución()
		//si se ha creado el titulo
		if(this.nuevoTitulo.state.tituloCreado){
			let nuevoTitulo= this.nuevoTitulo.state.tituloCreado;
		  await this.cargarCapital()
		  await this.cargarNumTitulos()

		  //vamos a la utlima paginación.
		  await this.irUltimaPagina()

		  //seleccionamos el creado
		  let creado=await this.state.capitalSocial.find((capital,index)=>{
			  if(capital.ID_DISTRIBUCION===nuevoTitulo){
				  this.setState({regionSeleccionada:[Regions.row(index)]})
			  }
			  return capital.ID_DISTRIBUCION===nuevoTitulo

		  })
		  await this.setState({
			  distribucionSeleccionada: creado,
			  loading : false

		  })

		  cerrarModal()

		} else {
      this.setState({loading : false})
    } 
	  }else{

		//comprobar que no exista un socio con ese tipo de titulo.
		let existe=this.state.capitalSocial.find(cap=>{ return cap.ID_PERSONA===this.nuevoTitulo.state.socioSeleccionado.value && cap.TIPO_TITULO===tipoTitulo && cap.ID_DISTRIBUCION!==this.state.distribucionSeleccionada.ID_DISTRIBUCION})
		if(existe){
			alert("Ya existe este socio con este tipo de título.")
			this.setState({loading : false})
		  return
		}

		await this.nuevoTitulo.aceptarDistribución()
		//si se ha creado el titulo
		if(this.nuevoTitulo.state.tituloActualizado){
		  await this.cargarCapital()
		  await this.cargarNumTitulos()

		  //seleccionamos el creado
		  let creado=this.state.capitalSocial.find((capital,index)=>{
			if(capital.ID_DISTRIBUCION===this.nuevoTitulo.state.tituloActualizado){
				this.setState({regionSeleccionada:[Regions.row(index)]})
			} 
			return capital.ID_DISTRIBUCION===this.nuevoTitulo.state.tituloActualizado

		  })
			

		  this.setState({
			  distribucionSeleccionada: creado,
			  loading: false
		  })

		  cerrarModal()
    } else {
      this.setState({loading : false})
    }
		  
		}

    })
	  
	  
	  
	  
   
	};

	const seleccionarDistribucion=(value)=>{
	  if(value.length===0)return
	  let seleccionada=this.state.capitalSocial[value[0].rows[0]]                
	  this.setState({
		distribucionSeleccionada:seleccionada,
		regionSeleccionada:value
	  })
	}

	const eliminarDistribucion=async()=>{
	
	  //obtener variaciones distribucion
	  let vardis= await this.props.client.query({
		query:gql`{capSocialVariacionesDistribucion(FILTROS:{ID_DISTRIBUCION:${this.state.distribucionSeleccionada.ID_DISTRIBUCION}}){ID_VARIACION}}`,fetchPolicy:'network-only'
	  }).then(result=>{
		  ////console.log(result)
		  return result.data.capSocialVariacionesDistribucion
	  })

	  vardis.forEach(async dis=>{
		//eliminar las variaciones distribucion
		await this.props.client.mutate({
		  mutation:gql`mutation{eliminarCapSocialVariacionesDistribucion(ID_DISTRIBUCION:${this.state.distribucionSeleccionada.ID_DISTRIBUCION}){ID_VARIACION}}`
		})
		
		//si la variacion contiene 0 variaciones distribucion, tambien la eliminamos
		await this.props.client.query({
		  query:gql`{capSocialVariacionesDistribucion(CAMPOS:"ID_VARIACION",FILTROS:{ID_VARIACION:${dis.ID_VARIACION}}){ID_VARIACION}}`
		  ,fetchPolicy:'network-only'
		}).then(async result=>{

		  if(result.data.capSocialVariacionesDistribucion.length===0){
			await this.props.client.mutate({
				mutation:gql`mutation{eliminarCapSocialVariacion(ID_VARIACION:${dis.ID_VARIACION}){ID_VARIACION}}`
			}).catch(err=>{
				alert("Ha ocurrido un error al eliminar la transmisión.Pongase en contacto con el soporte técnico")
				return
			})
		  }
		})
	  })
	  
	  //eliminamos la distribucion
	  await this.props.client.mutate({
		mutation:gql`mutation{eliminarCapSocialInicialDistribucion(ID_DISTRIBUCION:${this.state.distribucionSeleccionada.ID_DISTRIBUCION}){ID_DISTRIBUCION}}`
	  })
	  .then(async result=>{

		await this.setState({
		  distribucionSeleccionada:null,
		  regionSeleccionada:null
		},()=>{this.tablaDistribuciones.clearSelection()})
		await this.cargarCapital()
		await this.cargarNumTitulos()
		if(this.state.capitalSocial.length===0){
		  await this.irUltimaPagina()
		}

		alert("El registro se ha eliminado correctamente.")

		cerrarModal()
		
	  }).catch(err=>{
		alert("Ha ocurrido un errar al eliminar la distribucion.Pongase en contacto con el soporte técnico.")
	  })

	  
	}

	const handleJoyrideCallback=async(data)=>{
            
		const { action, index, status, type } = data;
	 
		if(data.action==="close"){

			localStorage.setItem("ayuda",false)
			this.setState({abrirModalDistribucion:false})

		}else{


			if ([EVENTS.STEP_AFTER].includes(type)) {

				let paso=index+ (action === ACTIONS.PREV ? -1 : 1)
	
				//paso en el que nos encontremos.
				// eslint-disable-next-line default-case
				switch(paso){
			   
					case 1:
						await this.setState({abrirModalDistribucion:false})
					break;
					case 2:
						await this.setState({abrirModalDistribucion:true})
					break;
					case 6:
	
							let numeros=[]
							let numeracion="1-10"
							if(numeracion.includes(",")){
								numeracion=numeracion.split(",")
							}else{
								numeracion=[numeracion]
							}
							numeracion.forEach(num=>{
							if(num.includes("-")){
								let dis=num.split("-")
								for(let i=parseInt(dis[0]);i<=parseInt(dis[1]);i++){
									numeros.push(String(i))
								}
							}else{
								numeros.push(num)
							}
				
						})
						this.nuevoTitulo.setState({numeroTitulos:numeros.length,numeracionTitulos:"1-10"})
	
					break;
					case 9:
						await this.setState({abrirModalDistribucion:false})
					break;
			
				
				}
		  
				await this.setState({ pasoGuia: paso });   
	
	
			}
	
			//si termina la guia.
			if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
				localStorage.setItem("ayuda",false)
			}

		}



	}
	
	return (
	  <>
		  	{this.state.cargando === true?
		  		<Cargando />
				  :
				  <>
					<Dialog 
						transitionDuration={400}
						autoFocus={true}
						enforceFocus={true}
						icon="print"
						onClose={cerrarModalImprimir}
						title="Imprimir informe de capital social inicial"
						canEscapeKeyClose={true}
						canOutsideClickClose={true}
						isCloseButtonShown={true}
						usePortal={true}
						isOpen={this.state.modalImprimir}
						style={styles.dialog}
					>
						{!this.state.cargandoImprimir?
								<div style={{ width: '100%', height: '600px'}} id="generarImprimirCI-pdf"></div>
								:
									<Spinner />
							}
					</Dialog>	
					<Joyride
						run={localStorage.getItem("ayuda")==="true"?true:false}
						callback={handleJoyrideCallback}
						showProgress={true}
						stepIndex={this.state.pasoGuia}
						steps={steps}
						disableScrollParentFix ={true}
						continuous ={true}
						locale={{ back: 'Volver', close: 'Cerrar', last: 'Último', next: 'Siguiente', skip: 'Saltar' }}
						styles={{
							options: {
								arrowColor: '#e3ffeb',
								backgroundColor: '#e3ffeb',
								primaryColor: '#E31E24',
								}
						}}
					/>
				
					<Dialog
					transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="warning-sign"
					onClose={cerrarModal}
					title="Datos capital inicial"
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					usePortal={true}
					isOpen={this.state.abrirModalGuardarDatos}
					className="pixelAlrededor dialog-medio-ancho"

					>
					<div className={Classes.DIALOG_BODY}>
						<p>Datos del capital social inicial:</p>
						<br />
						<p>
						Fecha de validez{" "}
						{moment(this.state.fechaSeleccionada).format("DD-MM-YYYY")}
						</p>
						<p>Valor individual del titulo: {this.state.valorTitulo}</p>
						<br />
						<p>¿Guardar estos datos?</p>
					</div>
					<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Tooltip content="Guardar cambios." position="top">
							<AnchorButton
							intent={Intent.PRIMARY}
							onClick={guardarCambiosCapitalSocial}
							>
							Si
							</AnchorButton>
						</Tooltip>
						<Tooltip content="Cerrar y volver." position="top">
							<Button onClick={cerrarModal}>No</Button>
						</Tooltip>
						</div>
					</div>
					</Dialog>
					<Dialog
					transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="warning-sign"
					onClose={cerrarModal}
					title="Distribución del capital social."
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					usePortal={true}
					onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
					isOpen={this.state.abrirModalDistribucion}
					ref={(input) => { this.dialogDistribucion = input; }}
					>
				        
						<div id="modal-nuevo-titulo" className={Classes.DIALOG_BODY}>
							<NuevoTituloSocios datosDistribucion={this.state.distribucionSeleccionada} ref={(input) => { this.nuevoTitulo = input; }} loading={this.state.loading} />
						</div>

						<div className={Classes.DIALOG_FOOTER}>
							<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							<Tooltip
								content="Aceptar distribución del capital social."
								position="top"
							>
								<AnchorButton
									id="guardar-titulos"
									intent={Intent.PRIMARY}
									onClick={aceptar}
								>
								Aceptar
								</AnchorButton>
							</Tooltip>
							<Tooltip content="Cerrar y volver." position="top">
								<Button onClick={cerrarModal}>Cancelar</Button>
							</Tooltip>
							</div>
						</div>
						
					</Dialog>
					<Dialog transitionDuration={400}
						autoFocus={true}
						enforceFocus={true}
						icon="warning-sign"
						onClose={cerrarModal}
						title="Borrar una cuenta"
						canEscapeKeyClose={true}
						canOutsideClickClose={true}
						isCloseButtonShown={true}
						usePortal={true}
						isOpen={this.state.modalBorrarAbierto}
					>
							<div className={Classes.DIALOG_BODY}>
								<h2>¿Desea eliminar la distribucion?</h2>
							</div>
							<div className={Classes.DIALOG_FOOTER}>
							<div className={Classes.DIALOG_FOOTER_ACTIONS}>
								<Tooltip content="Eliminar credito." position="top">
									<AnchorButton
										intent={Intent.PRIMARY}
										onClick={eliminarDistribucion}
									>
										Si
									</AnchorButton>
								</Tooltip>
								<Tooltip content="Cerrar y volver." position="top">
									<Button onClick={cerrarModal}>No</Button>
								</Tooltip>
							</div>
						</div>
					</Dialog>
					<div className="tablaTreinta">
						<Card interactive={true} id="datos-capital-social-inicial" elevation={Elevation.TWO}>
							<Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Información del capital social</Callout>
						<br />
						<FormGroup label="Fecha de validez" labelFor="informacion">
							<div id="input-fecha-validez">	
								<DateInput minDate={new Date(1900, 1 ,1)}
									{...getMomentFormatter("DD-MM-YYYY")}
									locale="es"
									onChange={escogeFecha}
									value={new Date(this.state.fechaSeleccionada)}
								/>
							</div>
						</FormGroup>
						<br />
						<FormGroup 
							label="Valor del titulo" 
							labelFor="informacion"
							intent="danger"
							helperText={!this.state.valorTituloValido?"Debe escribir un valor válido":""}
						>
						<InputGroup
							id="input-valor-titulo"
							name="Valor del titulo"
							value={this.state.valorTitulo}
							onChange={cambiarValorTitulo}
							type="text"
							intent={!this.state.valorTituloValido?"danger":"primary"}
						/>
						</FormGroup>
						<br />
						<FormGroup label="Número de títulos" labelFor="informacion">
						<InputGroup
							id="input-total-titulo"
							name="Número de títulos"
							value={this.state.numTitulos}
							disabled
						/>
						</FormGroup>
						<br />
						<FormGroup
						label="Capital social inicial (total)"
						labelFor="informacion"
						>
						<InputGroup
							name="Capital social inicial (total)"
							id="input-total-cantidad"
							value={
								Intl.NumberFormat('es-ES').format(parseFloat(this.state.valorTitulo?this.state.valorTitulo.replace(/\./g, '').replace(/\,/g, '.'):0).toFixed(2) * this.state.numTitulos) + " €"
							}
							disabled
						/>
						</FormGroup>
						<Button
						alignText="right"
						icon="floppy-disk"
						text="Guardar"
						onClick={abrirModalGuardar}
						className="bp3-intent-danger iconoDerecha bp3-minimal"
						/>
						<br />
						<br />
					</Card>
					</div>
					<div className="tablaSetenta">
					<Card interactive={true} elevation={Elevation.TWO}>
					<Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Distribución del capital social inicial</Callout>
						<Navbar>
						<Navbar.Group>
							<NavbarHeading>
							Introduzca la distribución por socios y tipo de títulos del
						capital social inicial para obtener el número total de títulos y
						el valor total del capital social inicial.
							</NavbarHeading>
						</Navbar.Group>
						</Navbar>
						<Navbar>
						<Navbar.Group>
							<ButtonGroup align={Alignment.LEFT}>
							<Button
								className="bp3-minimal"
								id="nuevo-capital-social"
								icon="add"
								text="Nuevo"
								active={this.state.nuevoActivado}
								onClick={abrirModalDistribucion}
							/>
							<Button
								className="bp3-minimal"
								id="editar-capital-social"
								icon="edit"
								text="Editar"
								onClick={abrirModalDistribucionEditar}
								active={this.state.editarActivado}
							/>
							{/*<Button
								className="bp3-minimal"
								icon="delete"
								text="Borrar"
								onClick={abrirModalDistribucionBorrar}
								active={this.state.borrarActivado}
							/>*/}
							{/* {this.state.cargandoCsv===false?
							<Tooltip content="Recomendamos usar la orientación horizontal para imprimir esta pantalla">
								<ReactToPrint
									trigger={() => <Button id="boton-imprimir" className="iconoDerecha bp3-minimal" icon="print">Imprimir</Button>}
									content={() => this.tablaDistribucionesAImprimir}
								/>	
							</Tooltip>
							:	
							<Button
								className="bp3-minimal"
								icon="print"
								text="Cargando..."
							/>
							} */}
							<Button id="boton-imprimir" className="iconoDerecha bp3-minimal" icon="print" active={this.state.imprimirActivado} onClick={()=>this.generarImprimir()}>Imprimir</Button>
						
							{this.state.cargandoCsv===false?
								<Button
								className="bp3-minimal"
								icon="document"
								id="boton-exportar"
								>
								<CSVLink data={this.state.datosCsv} separator={";"} enclosingCharacter={``} filename={"Capital.csv"}>Exportar</CSVLink>
								</Button>
							:
								<Button
								className="bp3-minimal"
								icon="document"
								text="Cargando..."
								/>
							}
							</ButtonGroup>
						</Navbar.Group>
						</Navbar>
						<div id="tabla-distribucion">
							<Table
							columnWidths={this.state.columnWidths}
							numRows={this.state.capitalSocial.length}
							onSelection={seleccionarDistribucion}
							selectedRegions={this.state.regionSeleccionada}
							enableRowHeader={false}
							selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]}
							enableMultipleSelection={false} 
							ref={(input) => { this.tablaDistribuciones = input; }}
							onCompleteRender={() => {
								if (!this.state.resized) {
									this.resizeColumns();
								}
							}}
							>
								<Column name="" cellRenderer={this.cellRendererNumSoc} />
								<Column name="Socio" cellRenderer={this.cellRendererSocio} />
								<Column name="Nacionalidad" cellRenderer={this.cellRendererNacionalidad} />
								<Column name="Tipo de títulos" cellRenderer={this.cellRendererTipo} />
								<Column name="Num. Títulos" cellRenderer={this.cellRendererNumero} />
								<Column name="%" cellRenderer={this.cellRendererPorc} />
								<Column name="Númeracion" cellRenderer={this.cellRendererNumeracion} />
								<Column name="Gravamenes" cellRenderer={this.cellRendererGravamenes} />
							</Table>
						</div>
						<div className="centrar2">
						{this.state.totalRegistros>10?
							<ButtonGroup className="pixelAlrededorGrande">
							<Button icon="chevron-left" onClick={this.irPrimerPagina} disabled={this.state.indiceRegistros===0?true:false}></Button>
								{this.botonesPaginacion()}
							<Button icon="chevron-right" onClick={this.irUltimaPagina} disabled={this.state.indiceRegistros===Math.ceil(this.state.totalRegistros/this.registrosMostrar)-1?true:false}></Button>
							</ButtonGroup>
						:<></>}
						</div>
					</Card>
					</div>
				</>
			}
	  </>
	);
  }
  componentWillUnmount(props) {
	this._isMounted = false;
  }
}

export default withApollo(CapitalSocialInicial)