import React, { Component } from 'react';
import {Elevation, Callout, Card, Tabs, Tab, Navbar, ButtonGroup, AnchorButton,Alignment, Button, NavbarDivider, Dialog, Classes, Tooltip, Intent, Spinner } from "@blueprintjs/core"
import { Table, Column, Cell,RegionCardinality,Regions,SelectionModes } from "@blueprintjs/table"
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import InformacionGeneralNuevo from "../Pantallas/Elementos/InformacionGeneralNuevo"
import { DatosPersonasJuridicas } from "./DatosPersonasJuridicas";
import { withApollo } from 'react-apollo';
import gql from "graphql-tag";
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';

import styles from './stylesPrint';

class FondosInversion extends Component {

    state={
        modalNuevoAbierto: false,
        nuevoActivado:true,
        editarActivado: false,
        borrarActivado: false,
        buscarActivado:false,
        homologado: false,
        fondoSeleccionado:null,
        datosPersonaJuridica:null,
        datosContactos:null,
        clientes: [
        ],
        empresaSeleccionada:null,
        empresas:[],
        cargando:false,
        regionSeleccionada:null,
        cargandoForm:false,
        columnWidths: [100],
        resized: false,
        datosEmpresa:null,
        cargandoImprimirG:false,
        modalImprimirG: false,
        logotipo:null,
        personasFisicas:null,
        personasJuridicas:null,
    }

    cambiarPestaña = (navbarTabId) => this.setState({ pestaña: navbarTabId });

    obtenerNombresFondos=async(fondo)=>{
        // eslint-disable-next-line array-callback-return
        this.state.personasJuridicas.find((persona)=>{
            if (persona.ID_PERSONA===fondo.ID_FONDO_INVERSION) {
                fondo.nombre=`${persona.RAZON_SOCIAL}`
                fondo.ID_PERSONA=`${persona.ID_PERSONA}`
            }
        }) 
        
    }

    cargarPersonas=async()=>{

        this.setState({cargando:true})

        let fondos=await this.props.client
        .query({
            query: gql`
                {fondosInversiones{ID_FONDO_INVERSION}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return JSON.parse(JSON.stringify(result.data.fondosInversiones))
        })

        for(let fondo of fondos){
            await this.obtenerNombresFondos(fondo)
        }   
        this.setState({
            clientes:fondos,
            cargando:false
        })
        let personas=[]
        for(let persona of this.state.personasJuridicas){
            personas.push({"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 
        }
        
        this.setState({empresas:personas})
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }

     resizeColumns() {
        
        let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        for (let index = 0; index < this.tablaFondos.locator.grid.numCols; index++) {
            totalMinSizeReq = totalMinSizeReq + this.tablaFondos.locator.getWidestVisibleCellInColumn(index);
            cumulativeColumnWidths.push(this.tablaFondos.locator.getWidestVisibleCellInColumn(index));
        }
        if (totalMinSizeReq > containerSize) {
            this.setState({
                columnWidths: cumulativeColumnWidths,
                resized: true
            });
        } else {
            let diff = ((containerSize - totalMinSizeReq) / this.tablaFondos.locator.grid.numCols);
            this.setState({
                columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                resized: true
            });
        }
        
    }


    
    generarImprimirG= async() => {
        this.setState({
            cargandoImprimirG:true,
            modalImprimirG:true
        }, async () => {
             this.setState({cargandoImprimirG:false}, () => {
                 ReactDOM.render(this.ImprimirG(), document.getElementById('generarG-pdf'))
             })
        })
    }

    
    ImprimirG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>FONDOS INVERSIÓN</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>LISTA DE FONDOS</Text>
                </View>
                    {this.state.clientes?this.state.clientes.map((cliente, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>NOMBRE</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{cliente.nombre?cliente.nombre:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        let personasJuridicas=await this.cargarPersonasJuridicas()
        
        this.setState({
            datosEmpresa:datosEmpresa,
            personasJuridicas: personasJuridicas,
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

    
    cargarPersonasJuridicas= async () => {
        let personasJuridicas=this.props.client
        .query({
            query: gql`
            {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

       }).then(result=>{
         return result.data.personasJuridicas;
        })
        
        return personasJuridicas;
        
    }
    //Carga de las empresas
    componentDidMount(props){
        this.setState({
            cargandoForm: true
        },async () => {
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            await this.cargarPersonas()
            this.setState({cargandoForm: false})
        }); 
    }

    
    
    cellRendererNombre = (rowIndex) => {
        return <Cell loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.clientes[rowIndex].nombre}`}</Cell>
    }   

    render() {

        document.title ="LexControl | Fondos de inversión"

        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }
            //console.log("abrir modal");
            this.setState({
                modalNuevoAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                buscarActivado:false
            })
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            //console.log("abrir modal");
            if(!this.state.fondoSeleccionado){
                alert("Debes seleccionar un fondo")
                return
            }
            this.setState({
                modalEditarPersonaJuridica: true,
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                buscarActivado:false
        
            })
        }

        const abrirmodalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            //console.log("abrir modal");
            if(!this.state.fondoSeleccionado){
                alert("Debes seleccionar un fondo")
                return
            }
            this.setState({
                modalBorrarAbierto: true,
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                buscarActivado:false
            })
        }

        const cellRendererNombre = (rowIndex) => {
                return <Cell loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.clientes[rowIndex].nombre}`}</Cell>
        }

        const handleSeleccionarEmpresa = (value) => {
            if(value.length===0)return
            let seleccionada=this.state.empresas[value[0].rows[0]]            
            this.setState({empresaSeleccionada:seleccionada})
        }

        const handleSeleccionarFondo = (value) => {
            if(value.length===0)return
            let seleccionada=this.state.clientes[value[0].rows[0]]            
            this.setState({
                fondoSeleccionado:seleccionada,
                regionSeleccionada:null
            })
        }

        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
                modalEmpresas:false
            })
        }

        
        const guardarPersonaJuridica=async ()=>{


             
 
            

            let result=await this.informacion.handleGuardarDatos()
            if(!result)return
            await this.setState({modalNuevoAbierto:false})
            if(this.informacion.state.personaCreada){
                //creamos el fondo inversion.
                await this.props.client.mutate({
                    mutation:gql`mutation{crearFondoInversion(VALORES:{ID_FONDO_INVERSION:${this.informacion.state.personaCreada}}){ID_FONDO_INVERSION}}`
        
                }).then(async result=>{
                
                    alert("El fondo se ha insertado correctamente")
                    await this.cargarPersonas()

                    //reseleccionamos la que teniamos seleccionada
                    let sel=this.state.clientes.find((cliente,index)=>{
                        if(cliente.ID_FONDO_INVERSION===result.data.crearFondoInversion.ID_FONDO_INVERSION){
                            this.setState({regionSeleccionada:[Regions.row(index)]})
                        }
                        return cliente.ID_FONDO_INVERSION===result.data.crearFondoInversion.ID_FONDO_INVERSION

                    })

                    this.setState({
                        fondoSeleccionado:sel
                    })

                    cerrarModal()       
               
                }).catch(err=>{
                    alert("Ha ocurrido un error al crear el fondo de inversión.Pongase en contacto con el soporte técnico")
                })
              
            }
           
        }

        const abrirModalEmpresas=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            this.setState({
                modalEmpresas:true,
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:false,
                buscarActivado:true
            
            })
        }

        const seleccionarEmpresa=async()=>{

            if(!this.state.empresaSeleccionada){
                alert("Debe seleccionar una empresa")
                return
            }
            //comprobar que no esta en la lista de fondos.
            let existe=this.state.clientes.find(cliente=>{return cliente.ID_FONDO_INVERSION===this.state.empresaSeleccionada.ID_PERSONA})
            if(existe){
                alert("Esta persona ya esta en la lista de fondos")
                return
            }
            //creamos el fondo inversion.
            await this.props.client.mutate({
                mutation:gql`mutation{crearFondoInversion(VALORES:{ID_FONDO_INVERSION:${this.state.empresaSeleccionada.ID_PERSONA}}){ID_FONDO_INVERSION}}`
    
            }).then(async result=>{
               
                alert("El fondo se ha insertado correctamente")
                cerrarModal()       
                await this.cargarPersonas()

                //reseleccionamos la que teniamos seleccionada
                let sel=this.state.clientes.find((cliente,index)=>{
                    if(cliente.ID_FONDO_INVERSION===result.data.crearFondoInversion.ID_FONDO_INVERSION){
                        this.setState({regionSeleccionada:[Regions.row(index)]})
                    }
                    return cliente.ID_FONDO_INVERSION===result.data.crearFondoInversion.ID_FONDO_INVERSION

                })

                this.setState({
                    fondoSeleccionado:sel
                })
            
            }).catch(err=>{
                alert("Ha ocurrido un error al crear el fondo de inversión.Pongase en contacto con el soporte técnico")
            })
        }

        const cellRendererNombreEmp=(rowIndex)=>{
            return <Cell wrapText="false" truncated="false">{`${this.state.empresas[rowIndex].RAZON_SOCIAL}`}</Cell>
        }

        const eliminarFondo=()=>{
            
            //creamos el fondo inversion.
            this.props.client.mutate({
                mutation:gql`mutation{eliminarFondoInversion(ID_FONDO_INVERSION:${this.state.fondoSeleccionado.ID_FONDO_INVERSION}){ID_FONDO_INVERSION}}`
    
            }).then(async result=>{
               
                alert("El fondo se ha eliminado correctamente")
                cerrarModal()       
                await this.cargarPersonas()
                this.setState({
                    fondoSeleccionado:null,
                    regionSeleccionada:null
                },()=>{this.tablaFondos.clearSelection()})
           
            }).catch(err=>{
                alert("Ha ocurrido un error al eliminar el fondo de inversión.Pongase en contacto con el soporte técnico")
            })

        }

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimirG:false
            })
        }

        const cerrarModal2 = async () => {
            this.setState({
                modalEditarPersonaJuridica:false,
            },()=>{
                this.cargarPersonas()
            })
        }

        return (
            <>
            {this.state.cargandoForm===true?
                <Cargando/>
            :
            <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir fondos inversiones"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirG}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirG?
                            <div style={{ width: '100%', height: '500px'}} id='generarG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title="Propiedades de la empresa"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevoAbierto}
                    className="datos-empresa-dialog"
                >
                    <Tabs id="datos-empresa"
                        animate={this.animacion} 
                        onChange={this.cambiarPestaña} 
                        selectedTabId={this.state.pestaña}
                    >
                        <Tab id="informacion-general" title="Información general" panel={ <InformacionGeneralNuevo   ref={(input) => { this.informacion= input; }} /> } />
                    </Tabs>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content="Guardar." position="top">
                        <Button
                            alignText="left"
                            icon="floppy-disk"
                            text="Guardar"
                            intent="danger"
                            onClick={guardarPersonaJuridica}
                            className="iconoIzquierda bp3-minimal"
                        />
                    </Tooltip>
                    <Tooltip content="Cerrar." position="top">
                        <Button
                            alignText="left"
                            icon="cross"
                            text="Cerrar"
                            intent="danger"
                            onClick={cerrarModal}
                            className="iconoIzquierda bp3-minimal"
                        />
                    </Tooltip>
                        
                    </div>
                </div>  
                </Dialog>
                <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title="Borrar un fondo de inversion"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>¿Desea eliminar el fondo de inversion?</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Eliminar credito." position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarFondo}
                                >
                                    Si
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>No</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="LexControl"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalEmpresas}
                >
                    <div className={Classes.DIALOG_BODY}>
                    <Table fill={true}  enableMultipleSelection={false}  defaultColumnWidth={430} selectionModes={!this.state.controlesDesactivados?[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]:SelectionModes.NONE} onSelection={handleSeleccionarEmpresa} numRows={this.state.empresas.length}>
                        <Column name="Nombre"  cellRenderer={cellRendererNombreEmp}/>
                    </Table>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <AnchorButton intent={Intent.DANGER} onClick={seleccionarEmpresa}>
                            Aceptar
                        </AnchorButton>
                        <AnchorButton intent={Intent.DANGER} onClick={cerrarModal}>
                            Cerrar
                        </AnchorButton>
                    </div>
                    </div>
                </Dialog>
           
            <Dialog
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModal}
                title="Propiedades de la empresa"
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalEditarPersonaJuridica}
                className="datos-empresa-dialog"
            >
            <DatosPersonasJuridicas
                animacion={this.animacion}
                cambiarPestaña={this.cambiarPestaña}
                pestaña={this.state.pestaña}
                empresaSeleccionada={this.state.fondoSeleccionado?this.state.fondoSeleccionado:null}
            />
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS} />
                <ButtonGroup>
                    <Button 
                        alignText="right"
                        icon="log-out"
                        intent="danger"
                        text="salir"
                        onClick={cerrarModal2}
                    />
                </ButtonGroup>
            </div>
        </Dialog>
            <div className="pixelAlrededorGrande tablaSesenta">
                <Card>
                    <h4>Fondos de inversión</h4>
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                <Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarActivado}  onClick={abrirModalEditar} />
                                <Button className="bp3-minimal" icon="delete" text="Borrar" active={this.state.borrarActivado} onClick={abrirmodalBorrar} />
                                <Button className="bp3-minimal" icon="geosearch" text="Buscar en lista de personas" onClick={abrirModalEmpresas} active={this.state.buscarActivado} />
                                <NavbarDivider />
                                <Tooltip 
                                    content="Desea imprimir todos los socios gestores" 
                                    position="right" 
                                >
                                    <Button 
                                        className="bp3-minimal" 
                                        icon="print" 
                                        text="Imprimir" 
                                        onClick={()=>this.generarImprimirG()}
                                    />
                                </Tooltip>
                            </ButtonGroup>
                        </Navbar.Group>
                        
                    </Navbar>
                    <Table enableMultipleSelection={false} selectedRegions={this.state.regionSeleccionada}  selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} ref={(input) => { this.tablaFondos= input; }} onSelection={handleSeleccionarFondo} numRows={this.state.clientes.length} enableColumnResizing={false} columnWidths={this.state.columnWidths} onCompleteRender={() => {
                                            if (!this.state.resized) {
                                                this.resizeColumns();
                                            }
                                        }}>
                        <Column name="Nombre"  cellRenderer={cellRendererNombre}/>
                    </Table>
                    <br />
                </Card>
            </div>
            <div className="pixelAlrededorGrande tablaCuarenta">
                <Card interactive={true} elevation={Elevation.TWO}>
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Ley 35/2003, de 4 de noviembre, de Instituciones de Inversión Colectiva.</Callout>
                    <br />
                    <AnchorButton onClick={()=> window.open("https://www.boe.es/eli/es/l/2003/11/04/35/con", "_blank")}>Ley 35/2003</AnchorButton>
                    <br />
                </Card>
            </div>
            </>
            }
            </>
        )
    }
}

export default withApollo(FondosInversion)