import React , { Component } from "react"
import { Button, Collapse, Card, Elevation } from "@blueprintjs/core"

export default class DatosActa extends Component{

    state={
        abierto1: false,
        abierto2: false,
        abierto3: false,
    }
    
    render(){

        
        
        const handleClick1 = () => {
            this.setState({ abierto1: !this.state.abierto1, abierto2: false, abierto3: false });
        }

        const handleClick2 = () => {
            this.setState({ abierto1: false, abierto2: !this.state.abierto2, abierto3: false });
        }

        const handleClick3 = () => {
            this.setState({ abierto1: false, abierto2: false, abierto3: !this.state.abierto3 });
        }

        return(
            <>
                <Card id="card-informacion" interactive={true} elevation={Elevation.TWO}>
                    <div className="tablaTercio">
                        <Button icon="git-repo" onClick={handleClick1}>
                            Artículo 97.
                        </Button>
                    </div>
                    <div className="tablaTercio">
                        <Button icon="git-repo" onClick={handleClick2}>
                            Artículo 99.
                        </Button>
                    </div>
                    <div className="tablaTercio">
                        <Button icon="git-repo" onClick={handleClick3}>
                            Artículo 100.
                        </Button>
                    </div>
                    <br />
                    <br />
                    <br />
                    <Collapse isOpen={this.state.abierto1}>
                        <Card> 
                            <ol>
                                <li>
                                Los acuerdos de los órganos colegiados de las sociedades mercantiles se consignarán en acta, que se extenderá o transcribirá en el libro de actas correspondiente, con expresión de las siguientes circunstancias:
                                    <ol>
                                        <li>Fecha y lugar del territorio nacional o del extranjero en que se hubiere celebrado la reunión.</li>
                                        <li>Fecha y modo en que se hubiere efectuado la convocatoria, salvo que se trate de Junta o Asamblea universal. Si se tratara de Junta General o Especial de una sociedad anónima, se indicarán el «Boletín Oficial del Registro Mercantil» y el diario o diarios en que se hubiere publicado el anuncio de convocatoria.</li>
                                        <li>Texto íntegro de la convocatoria o, si se tratase de Junta o Asamblea universal, los puntos aceptados como orden del día de la sesión.</li>
                                        <li>En caso de Junta o Asamblea, el número de socios concurrentes con derecho a voto, indicando cuántos lo hacen personalmente y cuántos asisten por representación, así como el porcentaje de capital social que unos y otros representan. Si la Junta o Asamblea es universal, se hará constar, a continuación de la fecha y lugar y del orden del día, el nombre de los asistentes, que deberá ir seguido de la firma de cada uno de ellos. En caso de órganos colegiados de administración, se expresará el nombre de los miembros concurrentes, con indicación de los que asisten personalmente y de quienes lo hacen representados por otro miembro.</li>
                                        <li>Un resumen de los asuntos debatidos y de las intervenciones de las que se haya solicitado constancia.</li>
                                        <li>El contenido de los acuerdos adoptados.</li>
                                        <li>En el caso de Junta o Asamblea, la indicación del resultado de las votaciones, expresando las mayorías con que se hubiere adoptado cada uno de los acuerdos. Si se tratase de órganos colegiados de administración, se indicará el número de miembros que ha votado a favor del acuerdo. En ambos casos, y siempre que lo solicite quien haya votado en contra, se hará constar la oposición a los acuerdos adoptados.</li>
                                        <li>La aprobación del acta conforme al artículo 99.</li>
                                    </ol>
                                </li>
                            </ol>
                        </Card>
                    </Collapse>
                    <Collapse isOpen={this.state.abierto2}>
                        <Card>
                            <ol>
                                <li>Las actas de Junta o Asamblea se aprobarán en la forma prevista por la Ley o, en su defecto, por la escritura social. A falta de previsión específica, el acta deberá ser aprobada por el propio órgano al final de la reunión.</li>
                                <li>Las actas del órgano colegiado de administración se aprobarán en la forma prevista en la escritura social. A falta de previsión específica, el acta deberá ser aprobada por el propio órgano al final de la reunión o en la siguiente.</li>
                                <li>Una vez que conste en el acta su aprobación, será firmada por el Secretario del órgano o de la sesión, con el Visto Bueno de quien hubiera actuado en ella como Presidente.</li>
                                <li>Cuando la aprobación del acta no tenga lugar al final de la reunión, se consignará en ella la fecha y el sistema de aprobación.</li>
                            </ol>
                        </Card>
                    </Collapse>
                    <Collapse isOpen={this.state.abierto3}>
                        <Card>
                            <ol>
                                <li>Cuando la Ley no impida la adopción de acuerdos por correspondencia o por cualquier otro medio que garantice su autenticidad, las personas con facultad de certificar dejarán constancia en acta de los acuerdos adoptados, expresando el nombre de los socios o, en su BOLETÍN OFICIAL DEL ESTADO LEGISLACIÓN CONSOLIDADA Página 25 caso, de los administradores, y el sistema seguido para formar la voluntad del órgano social de que se trate, con indicación del voto emitido por cada uno de ellos. En este caso, se considerará que los acuerdos han sido adoptados en el lugar del domicilio social y en la fecha de recepción del último de los votos emitidos.</li>
                                <li>Si se tratare de acuerdos del órgano de administración adoptados por escrito y sin sesión, se expresará, además, que ningún miembro del mismo se ha opuesto a este procedimiento.</li>
                                <li>Salvo disposición contraria de la escritura social, el voto por correo deberá remitirse dentro del plazo de diez días a contar desde la fecha en que se reciba la solicitud de emisión del voto, careciendo de valor en caso contrario.</li>
                            </ol>
                        </Card>
                    </Collapse>
                </Card>
            </>
        )
    }
}