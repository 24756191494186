/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Card, FormGroup, Navbar, InputGroup,ButtonGroup, Alignment,Popover,Button, NavbarDivider, Elevation, Callout, Dialog, Classes, Tooltip, AnchorButton, Intent, Spinner } from "@blueprintjs/core"
import { Table, Column, Cell, RegionCardinality,Regions,SelectionModes } from "@blueprintjs/table"
import Select from "react-select"
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import { DatosPersonasJuridicas } from "./DatosPersonasJuridicas";
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas";
import InformacionGeneralNuevo from  "../Pantallas/Elementos/InformacionGeneralNuevo"
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from 'i18next';
import styles from './stylesPrint';

class ServiciosProfesionales extends Component {

    state={
        nuevoActivado: true,
        editarActivado: false,
        borrarActivado: false,
        personas:[],
        servicios: [
        ],
        abierto: false,
        value: null,
        enabled: true,
        actividadesSelect: [
        ],
        tipoSectorSelect: [
        ],
        tipoSectorVolumen: [
        ],
        perfilesRiesgo: [
        ],
        personaSeleccionada:null,
        sectorActividadSeleccionado:null,
        servicioVolumenSeleccionado:null,
        perfilesRiesgoSeleccionado:null,
        nuevoSector:null,
        servicioSeleccionado:null,
        todosServicios:[],
        cargando:false,
        regionSeleccionada:null,
        cargandoForm:false,
        columnWidths: [100,100,100,100],
        resized: false,
        datosEmpresa:null,
        cargandoImprimirP:false,
        modalImprimirP: false,
        cargandoImprimirPG:false,
        modalImprimirPG: false,
        logotipo:null,
        personasFisicas:null,
        personasJuridicas:null,
    }

    cargarservicios=async ()=>{

        this.setState({cargando:true})

        await this.props.client.query({
            query:gql`{serviciosIndependientes(ORDEN:"ID_SERVICIO_INDEPENDIENTE,ASC"){ID_SERVICIO_INDEPENDIENTE,TIPO,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}},ID_SECTOR_ACTIVIDAD,SECTOR_ACTIVIDAD{DESCRIPCION},ID_CLASIFICACION_VOLUMEN,CLASIFICACION_VOLUMEN{DESCRIPCION},ID_PERFIL_RIESGO,PERFIL_RIESGO{DESCRIPCION}}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

            //console.log(result)
            let servicios=[]
            for(let servicio of result.data.serviciosIndependientes){

                let nombreservicio=""
                ////console.log(servicio)
                //Si es persona fisica...
                if(servicio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){

                    this.state.personasFisicas.find((persona)=>{
                        if (persona.ID_PERSONA===servicio.ID_SERVICIO_INDEPENDIENTE) {
                            nombreservicio=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                        }
                    }) 
                   
                //Si es persona juridica
                }else if(servicio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    this.state.personasJuridicas.find((persona)=>{
                        if (persona.ID_PERSONA===servicio.ID_SERVICIO_INDEPENDIENTE) {
                            nombreservicio=`${persona.RAZON_SOCIAL}`
                        }
                    }) 
                }
                servicios.push({"id":servicio.ID_SERVICIO_INDEPENDIENTE,TIPO:servicio.TIPO,"sector":servicio.SECTOR_ACTIVIDAD.DESCRIPCION,"nombre":nombreservicio,"idSector":servicio.ID_SECTOR_ACTIVIDAD,"idTipoVolumen":servicio.ID_CLASIFICACION_VOLUMEN,"tipoVolumen":servicio.CLASIFICACION_VOLUMEN.DESCRIPCION,"idPerfilRiesgo":servicio.ID_PERFIL_RIESGO,"perfilRiesgo":servicio.PERFIL_RIESGO.DESCRIPCION}) 

            }
            this.setState({todosServicios:servicios})
            let ServiciosProfesionales=servicios.filter(servicio=>{return servicio.TIPO==="P"})
            //ordenacion por id ascendente
            ServiciosProfesionales=ServiciosProfesionales.sort((a,b)=>{
                return a.ID_SERVICIO_INDEPENDIENTE-b.ID_SERVICIO_INDEPENDIENTE
            })

            this.setState({
                servicios:ServiciosProfesionales,
                cargando:false
            })

        })

    }

    cargarActividades=async ()=>{

        //cargar combo sectores.
        await this.props.client.query({
            query:gql`{sectoresActividad{ID_SECTOR_ACTIVIDAD,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
            let actividades=[]
            result.data.sectoresActividad.forEach(async(actividad)=>{
             
                actividades.push({"value":actividad.ID_SECTOR_ACTIVIDAD,"label":actividad.DESCRIPCION}) 

            })
            this.setState({actividadesSelect:actividades})
        })
    }

    cargarPersonasFisicas= async () => {
        let personasFisicas=this.props.client
        .query({
            query: gql` 
            {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
         return result.data.personasFisicas;
        })
        
        return personasFisicas;
       
    }
    
    cargarPersonasJuridicas= async () => {
        let personasJuridicas=this.props.client
        .query({
            query: gql`
            {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

       }).then(result=>{
         return result.data.personasJuridicas;
        })
        
        return personasJuridicas;
        
    }

    cargarPersonas=async ()=>{

        let personas=[]

        for(let persona of this.state.personasFisicas){
            personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 

        }

        for(let persona of this.state.personasJuridicas){
            personas.push({"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 
        }

        this.setState({personas:personas})
    }

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    resizeColumns() {
        
        let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        for (let index = 0; index < this.tablaServicios.locator.grid.numCols; index++) {
            totalMinSizeReq = totalMinSizeReq + this.tablaServicios.locator.getWidestVisibleCellInColumn(index);
            cumulativeColumnWidths.push(this.tablaServicios.locator.getWidestVisibleCellInColumn(index));
        }
        if (totalMinSizeReq > containerSize) {
            this.setState({
                columnWidths: cumulativeColumnWidths,
                resized: true
            });
        } else {
            let diff = ((containerSize - totalMinSizeReq) / this.tablaServicios.locator.grid.numCols);
            this.setState({
                columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                resized: true
            });
        }
        
    }

    generarImprimirP= async() => {
        this.setState({
            cargandoImprimirP:true,
            modalImprimirP:true
        }, async () => {
             this.setState({cargandoImprimirP:false}, () => {
                 ReactDOM.render(this.ImprimirP(), document.getElementById('generarP-pdf'))
             })
        })
    }

    generarImprimirPG= async() => {
        this.setState({
            cargandoImprimirPG:true,
            modalImprimirPG:true
        }, async () => {
             this.setState({cargandoImprimirPG:false}, () => {
                 ReactDOM.render(this.ImprimirPG(), document.getElementById('generarPG-pdf'))
             })
        })
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }
    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        let personasFisicas=await this.cargarPersonasFisicas()
        let personasJuridicas=await this.cargarPersonasJuridicas()
        
        this.setState({
            datosEmpresa:datosEmpresa,
            personasFisicas: personasFisicas,
            personasJuridicas: personasJuridicas,
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   

   }

    cargarClasificacionesVolumen=async ()=>{
        //cargar combo tipo auditor sector.
        this.props.client.query({
            query:gql`{clasificacionesVolumen{ID_CLASIFICACION_VOLUMEN,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
            let clasificacionesVolumen=[]
            result.data.clasificacionesVolumen.forEach(async(tipo)=>{
            
                clasificacionesVolumen.push({"value":tipo.ID_CLASIFICACION_VOLUMEN,"label":tipo.DESCRIPCION}) 

            })
            this.setState({tipoSectorVolumen:clasificacionesVolumen})
        })
    }

    cargarPerfilesRiesgo=async ()=>{
        //cargar combo tipo auditor volumen.
        this.props.client.query({
            query:gql`{perfilesRiesgo{ID_PERFIL_RIESGO,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
            let perfilesRiesgo=[]
            result.data.perfilesRiesgo.forEach(async(tipo)=>{
            
                perfilesRiesgo.push({"value":tipo.ID_PERFIL_RIESGO,"label":tipo.DESCRIPCION}) 

            })
            this.setState({perfilesRiesgo:perfilesRiesgo})
        })
    }

    componentDidMount(props){

        this.setState({
            cargandoForm: true
        },async () => {
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            await this.cargarPersonas()
            await this.cargarservicios()
            await this.cargarActividades();
            await this.cargarClasificacionesVolumen();
            await this.cargarPerfilesRiesgo();
            
            this.setState({cargandoForm: false})
        });
        
    }

    cellRendererNombre = (rowIndex) => {
            return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.servicios[rowIndex].nombre}`}</Cell>
    }

    cellRendererSector = (rowIndex) => {
            return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.servicios[rowIndex].sector}`}</Cell>
    }

    cellRendererTipoVolumen = (rowIndex) => {
            return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.servicios[rowIndex].tipoVolumen}`}</Cell>
    }

    cellRendererPerfilRiesgo = (rowIndex) => {
            return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.servicios[rowIndex].perfilRiesgo}`}</Cell>
    }

    ImprimirP=()=>{
        let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.servicioSeleccionado.id})
        let sectorSel=this.state.actividadesSelect.find(sector=>{return sector.value===this.state.servicioSeleccionado.idSector})
        let volumenSel=this.state.tipoSectorVolumen.find(tipoVolumne=>{return tipoVolumne.value===this.state.servicioSeleccionado.idTipoVolumen})
        let perfilRiesgoSel=this.state.perfilesRiesgo.find(perfil=>{return perfil.value===this.state.servicioSeleccionado.idPerfilRiesgo})
        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>SERVICIOS PROFESIONALES</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>SERVICIO</Text>
                </View>
                    {this.state.servicioSeleccionado?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>NOMBRE:</Text>
                                    <Text style={styles.textData}>{personaSel.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>SECTOR DE ACTIVIDAD:</Text>
                                    <Text style={styles.textData}>{sectorSel.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>TIPO DE SERVICIO POR VOLUMEN:</Text>
                                    <Text style={styles.textData}>{volumenSel.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>PERFIL DE RIESGO:</Text>
                                    <Text style={styles.textData}>{perfilRiesgoSel.label}</Text>
                                </View>
                                
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    }

    ImprimirPG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>SERVICIOS PROFESIONALES</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>LISTA DE SERVICIOS PROFESIONALES</Text>
                </View>
                    {this.state.servicios?this.state.servicios.map((servicio, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'40%'}}>NOMBRE</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>SECTOR DE ACTIVIDAD</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>TIPO POR VOLUMEN</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'20%'}}>PERFIL DE RIESGO</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{servicio.nombre?servicio.nombre:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{servicio.sector?servicio.sector:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{servicio.tipoVolumen?servicio.tipoVolumen:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'20%'}}>{servicio.perfilRiesgo?servicio.perfilRiesgo:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    render() {

        document.title ="LexControl | Servicios independientes profesionales"

        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            this.setState({
                modalAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                personaSeleccionada:null,
                sectorActividadSeleccionado:null,
                servicioVolumenSeleccionado:null,
                perfilesRiesgoSeleccionado:null,
                servicioSeleccionado:null,
            })
    
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.servicioSeleccionado){
                alert("Debe seleccionar un servicio.")
                return
            }

            let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.servicioSeleccionado.id})
            let sectorSel=this.state.actividadesSelect.find(sector=>{return sector.value===this.state.servicioSeleccionado.idSector})
            let volumenSel=this.state.tipoSectorVolumen.find(tipoVolumne=>{return tipoVolumne.value===this.state.servicioSeleccionado.idTipoVolumen})
            let perfilRiesgoSel=this.state.perfilesRiesgo.find(perfil=>{return perfil.value===this.state.servicioSeleccionado.idPerfilRiesgo})

            this.setState({
                modalAbierto: true,
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                personaSeleccionada:personaSel,
                sectorActividadSeleccionado:sectorSel,
                servicioVolumenSeleccionado:volumenSel,
                perfilesRiesgoSeleccionado:perfilRiesgoSel,
            })
        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.servicioSeleccionado){
                alert("Debe seleccionar un servicio.")
                return
            }

            this.setState({modalBorrarAbierto: true})
            this.setState({nuevoActivado:false})
            this.setState({editarActivado:false})
            this.setState({borrarActivado:true})
        }


        const cellRendererNombre = (rowIndex) => {
                return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.servicios[rowIndex].nombre}`}</Cell>
        }

        const cellRendererSector = (rowIndex) => {
                return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.servicios[rowIndex].sector}`}</Cell>
        }

        const cellRendererTipoVolumen = (rowIndex) => {
                return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.servicios[rowIndex].tipoVolumen}`}</Cell>
        }

        const cellRendererPerfilRiesgo = (rowIndex) => {
                return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.servicios[rowIndex].perfilRiesgo}`}</Cell>
        }

      
        const cambiarnuevoSector=(ev)=>{
            this.setState({nuevoSector:ev.currentTarget.value})
        }


        const añadirNuevo = (value) => {


            if(!this.state.personaSeleccionada){
                return
            }
        
            if(!this.state.servicioVolumenSeleccionado){
                return
            }

            if(!this.state.perfilesRiesgoSeleccionado){
                return
            }

            //comprobar que no existe ya
            let existe=this.state.servicios.find(servicio=>{return servicio.id===this.state.personaSeleccionada.value})
            if(existe){
                alert("Este servicio ya existe")
                return
            }

            this.setState({pendienteGuardar:true})
            this.setState({controlesDesactivados:true})
            this.setState({regionSeleccionada:null})

            this.state.servicios.push({
                "id":0,
                "nombre": this.state.personaSeleccionada.label,
                "sector": this.state.sectorActividadSeleccionado.label,
                "tipoVolumen": this.state.servicioVolumenSeleccionado.label,
                "perfilRiesgo": this.state.perfilesRiesgoSeleccionado.label,
            })

            this.tablaServicios.clearSelection()

            cerrarModal()
        }

        const actualizarServicio = () => {

            if(!this.state.personaSeleccionada){
                return
            }

            if(!this.state.sectorActividadSeleccionado){
                return
            }

            if(!this.state.servicioVolumenSeleccionado){
                return
            }

            if(!this.state.perfilesRiesgoSeleccionado){
                return
            }

            //comprobar que no existe ya
            let existe=this.state.servicios.find(servicio=>{return (servicio.id===this.state.personaSeleccionada.value && servicio.id!==this.state.servicioSeleccionado.id)})
            if(existe){
                alert("Este servicio ya existe")
                return
            }

            let servicios=this.state.servicios
            this.state.servicios.find((servicio,index) =>{
                if(servicio.id===this.state.servicioSeleccionado.id){
    
                    servicios[index]["nombre"]=this.state.personaSeleccionada?this.state.personaSeleccionada.label:this.state.servicioSeleccionado.nombre
                    servicios[index]["sector"]=this.state.sectorActividadSeleccionado?this.state.sectorActividadSeleccionado.label:this.state.servicioSeleccionado.sector
                    servicios[index]["tipoVolumen"]=this.state.servicioVolumenSeleccionado?this.state.servicioVolumenSeleccionado.label:this.state.servicioSeleccionado.tipoVolumen
                    servicios[index]["perfilRiesgo"]=this.state.perfilesRiesgoSeleccionado?this.state.perfilesRiesgoSeleccionado.label:this.state.servicioSeleccionado.perfilRiesgo

                }
                return servicio.id===this.state.servicioSeleccionado.id
            })
            this.setState({pendienteActualizar:true})
            this.setState({controlesDesactivados:true})
            this.setState({servicios:servicios})
            this.setState({regionSeleccionada:null})

            this.tablaServicios.clearSelection()

            cerrarModal()
        }

        const eliminarServicio = () => {
            let servicios=this.state.servicios.filter(servicio => servicio.id !== this.state.servicioSeleccionado.id)
            this.setState({servicios:servicios})
            this.setState({pendienteBorrar:true})     
            this.setState({controlesDesactivados:true})
            cerrarModal()
        };

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
            })
        }

        const seleccionPersona  = (value) => { 
            this.setState({personaSeleccionada: value})
        }

        const seleccionActividad  = (value) => { 
            this.setState({sectorActividadSeleccionado: value})
        }
        const seleccionServicioVolumen  = (value) => { 
            this.setState({servicioVolumenSeleccionado: value})
        }
        const seleccionPerfilRiesgo  = (value) => { 
            this.setState({perfilesRiesgoSeleccionado: value})
        }
        const handleSeleccionarServicio = (value) => {
            if(value.length===0)return

            let seleccionada=this.state.servicios[value[0].rows[0]]                
            this.setState({
                servicioSeleccionado:seleccionada,
                regionSeleccionada:value
            })
        }


        const guardarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }
            
            if(this.state.pendienteGuardar){

                let existe=this.state.todosServicios.find(servicio=>{return servicio.id===this.state.personaSeleccionada.value})
                if(!existe){
                   
                    this.props.client.mutate({
                        mutation:gql`mutation{crearServicioIndependiente(VALORES:{ID_SERVICIO_INDEPENDIENTE:${this.state.personaSeleccionada.value},ID_SECTOR_ACTIVIDAD:${this.state.sectorActividadSeleccionado.value},ID_CLASIFICACION_VOLUMEN:${this.state.servicioVolumenSeleccionado.value},ID_PERFIL_RIESGO:${this.state.perfilesRiesgoSeleccionado.value},TIPO:"P"}){ID_SERVICIO_INDEPENDIENTE}}`
    
                    }).then(async result=>{
                       
                        alert("El registro se ha insertado correctamente.")
                        
                        await this.cargarservicios()

                        //reseleccionamos la que teniamos seleccionada
                        let sel=this.state.servicios.find((servicio,index)=>{
                            if(servicio.id===result.data.crearServicioIndependiente.ID_SERVICIO_INDEPENDIENTE){
                                this.setState({regionSeleccionada:[Regions.row(index)]})
                            }
                            return servicio.id===result.data.crearServicioIndependiente.ID_SERVICIO_INDEPENDIENTE

                        })
    
                        this.setState({
                            pendienteGuardar:false,
                            controlesDesactivados:false,
                            servicioSeleccionado:sel
                        })
                        
                    }).catch(err=>{
                        alert("Ha ocurrido un error al crear el servicio independiente.Pongase en contacto con el soporte tecnico")
                    })
    
                }else{

                    this.props.client.mutate({
                        mutation:gql`mutation{actualizarServicioIndependiente(ID_SERVICIO_INDEPENDIENTE:${this.state.personaSeleccionada.value},VALORES:{ID_SECTOR_ACTIVIDAD:${this.state.sectorActividadSeleccionado.value},ID_CLASIFICACION_VOLUMEN:${this.state.servicioVolumenSeleccionado.value},ID_PERFIL_RIESGO:${this.state.perfilesRiesgoSeleccionado.value},TIPO:"P"}){ID_SERVICIO_INDEPENDIENTE}}`
                    }).then(async result=>{
                        
                        alert("El registro se ha actualizado correctamente.")
                        await this.cargarservicios()

                        //reseleccionamos la que teniamos seleccionada
                        let sel=this.state.servicios.find((servicio,index)=>{
                            if(servicio.id===result.data.actualizarServicioIndependiente.ID_SERVICIO_INDEPENDIENTE){
                                this.setState({regionSeleccionada:[Regions.row(index)]})
                            }
                            return servicio.id===result.data.actualizarServicioIndependiente.ID_SERVICIO_INDEPENDIENTE

                        })
    
                        this.setState({
                            pendienteGuardar:false,
                            controlesDesactivados:false,
                            servicioSeleccionado:sel
                        })
                            
                    }).catch(err=>{
                        alert("Ha ocurrido un error al crear el servicio independiente.Pongase en contacto con el soporte tecnico")
                    })

                }


            }else if(this.state.pendienteActualizar){
              
                
                this.props.client.mutate({
                    mutation:gql`mutation{actualizarServicioIndependiente(ID_SERVICIO_INDEPENDIENTE:${this.state.servicioSeleccionado.id},VALORES:{ID_SECTOR_ACTIVIDAD:${this.state.sectorActividadSeleccionado.value},ID_CLASIFICACION_VOLUMEN:${this.state.servicioVolumenSeleccionado.value},ID_PERFIL_RIESGO:${this.state.perfilesRiesgoSeleccionado.value}}){ID_SERVICIO_INDEPENDIENTE}}`
                }).then(async result=>{
            
                    alert("El registro se ha actualizado correctamente.")
                    await this.cargarservicios()

                    //reseleccionamos la que teniamos seleccionada
                    let sel=this.state.servicios.find((servicio,index)=>{
                        if(servicio.id===this.state.servicioSeleccionado.id){
                            this.setState({regionSeleccionada:[Regions.row(index)]})
                        }
                        return servicio.id===this.state.servicioSeleccionado.id

                    })

                    this.setState({
                        pendienteActualizar:false,
                        controlesDesactivados:false,
                        servicioSeleccionado:sel
                    })

                
                }).catch(err=>{
                    alert("Ha ocurrido un error al actualizar el servicio independiente.Pongase en contacto con el soporte tecnico")
                })


            }else if(this.state.pendienteBorrar){

                this.props.client.mutate({
                    mutation:gql`mutation{eliminarServicioIndependiente(ID_SERVICIO_INDEPENDIENTE:${this.state.servicioSeleccionado.id}){ID_SERVICIO_INDEPENDIENTE}}`
                }).then(result=>{
                   
                    alert("El registro se ha eliminado correctamente.")
                    this.setState({
                        pendienteBorrar:false,
                        controlesDesactivados:false,
                        servicioSeleccionado:null,
                        regionSeleccionada:null
                    },()=>{this.tablaServicios.clearSelection()})
                
                }).catch(err=>{
                    alert("Ha ocurrido un error al eliminar el servicio independiente.Pongase en contacto con el soporte tecnico")
                })
            }
        }

        const cancelarCambios = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            this.setState({
                personaSeleccionada:null,
                servicioSeleccionado:null,
                controlesDesactivados:false,
                pendienteBorrar:false,
                pendienteActualizar:false,
                pendienteGuardar:false,
                regionSeleccionada:null
            },()=>{this.tablaServicios.clearSelection()})
            this.cargarservicios()
        }

        const abrirModalSector=()=>{
            this.setState({modalSectorAbierto:true})
        }


        const anadirSector=(ev)=>{


            if(!this.state.nuevoSector)return

            //comprobar que no exista un elemento con la misma descripción.
            let existe=this.state.actividadesSelect.find(sector=>{
                return sector.label===this.state.nuevoSector
            })

            if(existe){
                alert("Ya existe un sector con esta descripción")
                return
            }

            //creamos el modo convocatoria.
            this.props.client.mutate({
                mutation: gql`
                  mutation{crearSectorActividad(VALORES:{DESCRIPCION:"${this.state.nuevoSector}"}){ID_SECTOR_ACTIVIDAD}}
                `
                }).then(async result=>{
                
                    await this.cargarActividades()

                    let sectorSel=this.state.actividadesSelect.find(sector=>{
                        return sector.value===result.data.crearSectorActividad.ID_SECTOR_ACTIVIDAD
                    })

                    this.setState({sectorActividadSeleccionado:sectorSel})
                    cerrarModal2()

                }).catch(err=>{
                    alert("Ha ocurrido un error al crear la actividad.Pongase en contacto con el soporte tecnico")
                })


        }

        const crearPersona = async () => {
            await this.datosPersona.crearPersona();
            if (this.datosPersona.state.idPersonaCreada) {
                
                await this.cargarPersonas();
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})
                this.setState({personaSeleccionada:personaSel})
                await this.setState({modalNuevaJuridicaAbierto:false})
                //cerrar el modal
                cerrarModal2();
            }
        };
      
        const actualizarPersona = async () => {
            await this.datosPersona.actualizarPersona();
            if (this.datosPersona.state.idPersonaActualizada) {
                await this.cargarPersonas();
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaActualizada})
                this.setState({personaSeleccionada:personaSel})
                await this.setState({modalNuevaJuridicaAbierto:false})
                await this.cargarservicios()
                //cerrar el modal
                cerrarModal2();
            }
        };

        const abrirPopover = () => {
            this.setState({popoverAbierto: true})
        }
      
        const Botonera = () => {
            return(
                <>
                    <Button className="bp3-minimal" icon="new-link" text="Nueva Persona Física" fill={true} onClick={abrirModalNuevaPersona} />
                    <br />
                    <Button className="bp3-minimal" icon="git-new-branch" text="Nueva Persona Jurídica" onClick={abrirModalNuevaPersonaJurídica} />
                </>
            )
        }

        const abrirModalNuevaPersona=()=>{

            //abrir modal de persona física
            this.setState({personaSeleccionada:null})
            this.setState({modalPersonaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})

        }

        const abrirModalNuevaPersonaJurídica=()=>{

            //abrir modal de persona jurídica
            this.setState({modalNuevaJuridicaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})
            
        }

        const abrirModalEditarPersona=()=>{

            if(!this.state.personaSeleccionada){
                alert("Debe seleccionar una persona.")
                return
            }

            this.setState({nuevaPresonaActivado:false})
            this.setState({editarPersonaActivado:true})
                //si es personas física
            if(this.state.personaSeleccionada.tipo===1){
                this.setState({modalPersonaAbierto:true})

                //si es persona jurídica
            }else if(this.state.personaSeleccionada.tipo===2){
                this.setState({modalEditarPersonaJuridica:true})
            }


        }

        const guardarPersonaJuridica=async ()=>{
      
            let result=await this.informacion.handleGuardarDatos()
            if(!result)return
            await this.cargarPersonas()
            //seleccionamos a al persona creada en la lista.
            let personaSel=this.state.personas.find(persona=>{return persona.value===this.informacion.state.personaCreada})
            this.setState({personaSeleccionada:personaSel})
            await this.setState({modalNuevaJuridicaAbierto:false})

        }

        const cerrarModal2 = async () => {
            await this.cargarPersonas()
            if(this.state.personaSeleccionada && this.state.personaSeleccionada.tipo===2){
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                this.setState({personaSeleccionada:personaSel})
                this.setState({modalNuevaJuridicaAbierto:false})
            }
            this.cargarservicios()
            this.setState({
                modalEditarPersonaJuridica:false,
                modalPersonaAbierto:false,
                modalSectorAbierto: false
            })
        }

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimirP:false,
                modalImprimirPG:false
            })
        }

        return (
            <>
            {this.state.cargandoForm===true?
                <Cargando/>
            :
            <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir servicios profesionales"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirPG}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirPG?
                            <div style={{ width: '100%', height: '500px'}} id='generarPG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir servicio profesional seleccionado"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirP}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirP?
                            <div style={{ width: '100%', height: '500px'}} id='generarP-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoActivado?"Añadir nuevo servicio":"Editar servicio"}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                >
                    <div className="pixelAlrededor bp3-card separarBordes">
                        <FormGroup
                            label="Servicio:"
                            labelFor="servicio"
                            intent="danger"
                            helperText={!this.state.personaSeleccionada?"Debe seleccionar el servicio":""}
                        >
                        <div style={!this.state.personaSeleccionada?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.personas}
                                className="pixelAlrededor"
                                onChange={seleccionPersona}
                                isDisabled={this.state.editarActivado}
                                value={this.state.personaSeleccionada}
                            />
                        </div>
                        </FormGroup>
                        <FormGroup>
                            <Popover  disabled={this.state.editarActivado} content={<Botonera />} transitionDuration={400} target={<Button  disabled={this.state.editarActivado} className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevaPresonaActivado} onClick={abrirPopover} />} />
                            <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarPersonaActivado} onClick={abrirModalEditarPersona} />
                        </FormGroup> 
                        <FormGroup
                            label="Sector de actividad"
                            labelFor="sector-actividad"
                            intent="danger"
                            helperText={!this.state.sectorActividadSeleccionado?"Debe seleccionar el sector actividad":""}
                        >
                        <div style={!this.state.sectorActividadSeleccionado?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.actividadesSelect}
                                className="pixelAlrededor"
                                onChange={seleccionActividad}
                                value={this.state.sectorActividadSeleccionado}
                            />
                        </div>
                        </FormGroup>
                        <Button 
                            alignText="right"
                            icon="add"
                            className="iconoIzquierda"
                            onClick={abrirModalSector}
                        />
                        <FormGroup
                            label="Tipo de servicio por volumen:"
                            labelFor="tipo-servicio-volumen"
                            intent="danger"
                            helperText={!this.state.servicioVolumenSeleccionado?"Debe seleccionar el tipo servicio volumen":""}
                        >
                        <div style={!this.state.servicioVolumenSeleccionado?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.tipoSectorVolumen}
                                className="pixelAlrededor"
                                onChange={seleccionServicioVolumen}
                                value={this.state.servicioVolumenSeleccionado}
                            />
                        </div>
                        </FormGroup>
                        <FormGroup
                            label="Perfil de riesgo:"
                            labelFor="perfil-riesgo"
                            intent="danger"
                            helperText={!this.state.perfilesRiesgoSeleccionado?"Debe seleccionar el perfil riesgo":""}
                        >
                        <div style={!this.state.perfilesRiesgoSeleccionado?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.perfilesRiesgo}
                                className="pixelAlrededor"
                                onChange={seleccionPerfilRiesgo}
                                value={this.state.perfilesRiesgoSeleccionado}
                            />
                        </div>
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevoActivado?"Añadir servicio":"Editar servicio"} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevoActivado?añadirNuevo:actualizarServicio}
                            >
                            {this.state.nuevoActivado?"Añadir servicio":"Editar servicio"}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                 <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title="Crear sector actividad"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalSectorAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label="Introduzca el nombre del sector:"
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.nuevoSector?"":"Debe insertar el nombre del sector."}
                        >
                            <InputGroup id="texto-modo-convocatoria" onChange={cambiarnuevoSector}  placeholder="Escriba aquí el nombre del sector" intent={this.state.nuevoSector?"primary":"danger"} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Agregar sector." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirSector}
                            >
                                Aceptar
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal2}>Cancelar</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Borrar una servicio"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>¿Desea eliminar el servicio?</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Eliminar credito." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarServicio}
                            >
                                Si
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>No</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Dialog
        autoFocus={true}
        enforceFocus={true}
        icon="info-sign"
        onClose={cerrarModal2}
        title="Propiedades de la persona"
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={true}
        className="dialog-ancho"
        isOpen={this.state.modalPersonaAbierto}
        >
        <DatosPersonasFisicas
            datosPersona={
            this.state.personaSeleccionada
                ? this.state.personaSeleccionada.value
                : null
            }
            ref={input => {
            this.datosPersona = input;
            }}
            i18next={i18next}
        />
        <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Tooltip content="Cerrar y volver." position="top">
                <Button onClick={cerrarModal2}>Cerrar</Button>
            </Tooltip>
            <Tooltip
                content={
                this.state.nuevaPresonaActivado
                    ? "Añadir persona."
                    : "Actualizar persona."
                }
                position="top"
            >
                <AnchorButton
                intent={Intent.PRIMARY}
                onClick={
                    this.state.nuevaPresonaActivado
                    ? crearPersona
                    : actualizarPersona
                }
                >
                {this.state.nuevaPresonaActivado
                    ? "Añadir persona"
                    : "Actualizar Persona"}
                </AnchorButton>
            </Tooltip>
            </div>
        </div>
        </Dialog>
        <Dialog
            transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal}
            title="Propiedades de la empresa"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalEditarPersonaJuridica}
            className="datos-empresa-dialog"
            >
            <DatosPersonasJuridicas
                animacion={this.animacion}
                cambiarPestaña={this.cambiarPestaña}
                pestaña={this.state.pestaña}
                empresaSeleccionada={this.state.personaSeleccionada?this.state.personaSeleccionada:null}
            />
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS} />
                <ButtonGroup>
                    <Button 
                        alignText="right"
                        icon="log-out"
                        intent="danger"
                        text="salir"
                        onClick={cerrarModal2}
                    />
                </ButtonGroup>
            </div>
        </Dialog>
        <Dialog transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
            title="Propiedades de la empresa"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalNuevaJuridicaAbierto}
            className="datos-empresa-dialog"
        >
                <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} />  
            <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Tooltip content="Cancelar." position="top">          
                    <Button
                        alignText="left"
                        icon="cross"
                        text="Cancelar"
                        intent="danger"
                        onClick={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                        className="iconoIzquierda bp3-minimal"
                    />
                </Tooltip>
                <Tooltip content="Guardar." position="top">
                <Button
                    alignText="left"
                    icon="floppy-disk"
                    text="Guardar"
                    intent="danger"
                    onClick={guardarPersonaJuridica}
                    className="iconoIzquierda bp3-minimal"
                />
                </Tooltip>
            </div>
        </div>  
        </Dialog>
                <div className="pixelAlrededor tablaSesenta">
                <Card>
                    <h4>Servicios profesionales</h4>
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                <NavbarDivider />
                                {this.state.controlesDesactivados?[
                                    <React.Fragment>
                                        <Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevoActivado} disabled />
                                        <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarActivado} disabled />
                                        <Button className="bp3-minimal" icon="delete" text="Borrar" active={this.state.borrarActivado} disabled/>
                                        <Button className="bp3-minimal" icon="print"  text="Imprimir Individual" disabled/>
                                        <Button className="bp3-minimal" icon="print" text="Imprimir General" disabled/>
                                    </React.Fragment>
                                ]:[
                                    <React.Fragment>
                                        <Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                        <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarActivado} onClick={abrirModalEditar} />
                                        <Button className="bp3-minimal" icon="delete" text="Borrar" active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                        <Tooltip 
                                            content="Desea imprimir el servicio seleccionado" 
                                            position="right" 
                                        >
                                            <Button 
                                                className="bp3-minimal" 
                                                icon="print" 
                                                disabled={this.state.servicioSeleccionado?false:true}  
                                                text="Imprimir Individual" onClick={()=>this.generarImprimirP()}
                                            />
                                        </Tooltip>  
                                        <Tooltip 
                                            content="Desea imprimir todos los servicios profesionales" 
                                            position="right" 
                                        >
                                            <Button 
                                                className="bp3-minimal" 
                                                icon="print" 
                                                text="Imprimir General" 
                                                onClick={()=>this.generarImprimirPG()}
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                ]}
                            </ButtonGroup>
                        </Navbar.Group>
                        
                    </Navbar>
                    <Table  ref={input => {this.tablaServicios = input;}} selectedRegions={this.state.regionSeleccionada} selectionModes={!this.state.controlesDesactivados?[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]:SelectionModes.NONE} onSelection={handleSeleccionarServicio} numRows={this.state.servicios.length} enableColumnResizing={false} columnWidths={this.state.columnWidths} onCompleteRender={() => {
                                            if (!this.state.resized) {
                                                this.resizeColumns();
                                            }
                                        }}>
                        <Column name="Nombre"  cellRenderer={cellRendererNombre}/>   
                        <Column name="Sector de actividad"  cellRenderer={cellRendererSector}/>   
                        <Column name="Tipo por volumen"  cellRenderer={cellRendererTipoVolumen}/>   
                        <Column name="Perfil de riesgo"  cellRenderer={cellRendererPerfilRiesgo}/>   
                    </Table>
                    <br />
                    <Button 
                        alignText="right"
                        icon="floppy-disk"
                        text="Guardar"
                        intent="danger"
                        disabled={!this.state.controlesDesactivados}
                        onClick={guardarCambios}
                        className="iconoDerecha bp3-minimal"
                    />
                    <Button 
                        alignText="right"
                        icon="cross"
                        intent="danger"
                        text="Cancelar"
                        disabled={!this.state.controlesDesactivados}
                        onClick={cancelarCambios}
                        className="iconoDerecha bp3-minimal"
                    />
                    <br />
                    <br />
                </Card>
                </div>
                <div className="pixelAlrededor tablaCuarenta">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Artículo 1.544 del Código Civil</Callout>
                        <br />
                        <AnchorButton onClick={()=> window.open("https://boe.es/buscar/act.php?id=BOE-A-1889-4763&p=20180804&tn=1#art1544", "_blank")}>Artículo 1.544 del Código Civil</AnchorButton>
                        <br />
                    </Card>
                </div>
            </>
            }
            </>
        )
    }
}
export default withApollo(ServiciosProfesionales) 