import React from "react"
import CapitalSocial from "../Pantallas/CapitalSocial"

export default class EstructuraSocietaria extends React.Component {
    render(){
        return (
            <>
                <CapitalSocial editable={this.props.editable} />
            </>
        )
    }
}