import React from "react"
import { Tabs, Tab, Card, Elevation, Callout } from "@blueprintjs/core"
import PactosBloqueo from "./PactosBloqueo"
import PactosDefensa from "./PactosDefensa"
import PactosNoAgresion from "./PactosNoAgresion"
import AcuerdosConversionSalida from "./AcuerdosConversionSalida"

export default class PactoRelacion extends React.Component {

    state = {
        pestaña: "pactos-bloqueo",
        animacion: true,
        modalNuevoAbierto: false,
        modalEditarAbierto: false,
        modalBorrarAbierto: false,
        
    };

    render(){

        return (
            <>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                    <Callout className="bp3-intent-danger">
                        Pactos de Relación: regulan las relaciones directas de los socios.
                        Por ejemplo: Los acuerdos de adquisición preferente, obligación de ceder o adquirir participaciones en determinadas situaciones, pactos de no agresión, etc.
                    </Callout>
                    </Card>
                </div>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Tabs id="pactos-bloqueo"
                            animate={this.animacion} 
                            onChange={this.cambiarPestaña} 
                            selectedTabId={this.state.pestaña}
                            renderActiveTabPanelOnly={true}
                        >
                            <Tab id="pactos-bloqueo" title="Pactos de bloqueo" panel={<PactosBloqueo editable={this.props.editable} logotipo={this.props.logotipo} datosEmpresa={this.props.datosEmpresa} personasFisicas={this.props.personasFisicas} personasJuridicas={this.props.personasJuridicas} socios={this.props.socios}  ref={(input) => { this.pactosBloqueo= input; }} />} />
                            <Tab id="pactos-defensa" title="Pactos de defensa o de control" panel={<PactosDefensa editable={this.props.editable} logotipo={this.props.logotipo} datosEmpresa={this.props.datosEmpresa} personasFisicas={this.props.personasFisicas} personasJuridicas={this.props.personasJuridicas} socios={this.props.socios} ref={(input) => { this.pactosDefensa= input; }}  />} />
                            <Tab id="pactos-no-agresion" title="Pactos de no agresión" panel={<PactosNoAgresion editable={this.props.editable} logotipo={this.props.logotipo} datosEmpresa={this.props.datosEmpresa} personasFisicas={this.props.personasFisicas} personasJuridicas={this.props.personasJuridicas} socios={this.props.socios} ref={(input) => { this.pactoNoAgresion= input; }}  />} />
                            <Tab id="acuerdos-conversion-salida" title="Acuerdos de conversión y de salida" panel={<AcuerdosConversionSalida editable={this.props.editable} logotipo={this.props.logotipo} datosEmpresa={this.props.datosEmpresa} personasFisicas={this.props.personasFisicas} personasJuridicas={this.props.personasJuridicas} socios={this.props.socios}  ref={(input) => { this.AcuerdosConversionSalida= input; }} />} />
                        </Tabs>
                    </Card>
                </div>
            </>
            
        )
    }

    cambiarPestaña = (navbarTabId) => this.setState({ pestaña: navbarTabId });
}