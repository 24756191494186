/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import React from "react"
import { Spinner,Popover, Card, Elevation, Dialog, Navbar, ButtonGroup, Button, InputGroup, FormGroup, Alignment, Classes, Tooltip, AnchorButton, Intent, TextArea, Callout } from "@blueprintjs/core"
import { Table, Column, Cell,RegionCardinality,Regions } from "@blueprintjs/table"
import gql from "graphql-tag";
import moment from 'moment';
import { DateInput } from "@blueprintjs/datetime";
import { NuevoTituloSocios } from "./NuevoTituloSocios";
import { withApollo } from "react-apollo";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Cargando"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';

import styles from '../stylesPrint';


class Variaciones extends React.Component {

	state = {
		modalNuevoAbierto: false,
		modalBorrarAbierto:false,
		modalEditarAbierto:false,
		valorInicialVariaciones:null,
		nuevoActivado:true,
		editarActivado:false,
		borrarActivado:false,
		fechaVariacion:new Date(),
		nuevoValor:null,
		valorInicial:null,
		peditenteBorrar:false,
		idTipoVariacion:null,
		variacionSeleccionada:null,
		modalTitulos:false,
		variaciones: [
		],
		popoverAbierto: false,
		modalAumentoAbierto: false,
		modalDisminucionAbierto: false,
		modalIncremento:false,
		modalDecremento:false,
		numerosAumentar:1,
		numerosDecrementar:1,
		totalAumentados:0,
		totalDisminuidos:0,
		capitalSocial: [{
		}],
		aumentosRealizados:[],
		decrementosRealizados:[],
		variacionSeleccionadaAumento:null,
		variacionSeleccionadaDecremento:null,
		fechaAumento:new Date(),
		fechaDisminucion:new Date(),
		cargando:false,
		cargandoDis:true,
		existeCapitalSocial:false,
		regionVariaciones:null,
		regionDis:null,
		numeracionTitulos:null,
		mascara:'0[0][0][0][0][0][0][0][0]',
		numeracionSeleccionadasEliminar:[],
		tipoCapital:null,
		pasoGuia:0,
		steps: [

			{
			  target: '#boton-nueva-variacion',
			  content: "En esta pantalla se llevarán acabo las variaciones del capital social inicial.Para dar de alta una nueva variación hacemos clic en 'Nuevo'.",
			  selector: '#boton-nueva-variacion',
			  position: 'auto',
			  disableBeacon: true,
			  type: 'hover',
			  isFixed: true,
			  stepIndex: 0,
			},
			{
				target: '#boton-variacion-titulo',
				content: "Existen 3 tipos de variaciones: La primera es Aumento individual del valor del título.",
				selector: '#boton-variacion-titulo',
				position: 'auto',
				disableBeacon: true,
				type: 'hover',
				isFixed: true,
				stepIndex: 1,
			},
			{
				target: '#dialogo-variacion-capital',
				content: "Esta variación afecta al valor del capital social inicial.Puede ser aumentado o disminuido.",
				selector: '#dialogo-variacion-capital',
				position: 'auto',
				disableBeacon: true,
				type: 'hover',
				isFixed: true,
				stepIndex: 2,
			},
		 
			{
				target: '#boton-aumento-titulo',
				content: "El segundo tipo es aumento de títulos, el cuál nos perimitirá incrementar el número de títulos de las distribuciones del capital social inicial.",
				selector: '#boton-aumento-titulo',
				position: 'auto',
				disableBeacon: true,
				type: 'hover',
				isFixed: true,
				stepIndex: 5,
			},
			{
				target: '#tabla-aumento',
				content: "Aquí se muestran todas las distribuciones del capital social.Para realizar el aumento de títulos, debemos hacer doble clic sobre la fila la cuál queremos aumentar el número de títulos.",
				selector: '#tabla-aumento',
				position: 'auto',
				disableBeacon: true,
				type: 'hover',
				isFixed: true,
				stepIndex: 6,
			},
			{
				target: '#numeracion-aumentar',
				content: "Una vez hecho doble clic nos aparecerá el siguiente diálogo, pidiendonos la nueva numeración de los títulos a aumentar (mismo funcionamiento que para dar de alta una nueva distribución).",
				selector: '#numeracion-aumentar',
				position: 'auto',
				disableBeacon: true,
				type: 'hover',
				isFixed: true,
				stepIndex: 7,
			},
  
			{
				target: '#boton-disminucion-titulo',
				content: "Finalmente la última opción:Decremento de títulos. Es una funcionalidad muy similar a la anterior explicada.",
				selector: '#boton-disminucion-titulo',
				position: 'auto',
				disableBeacon: true,
				type: 'hover',
				isFixed: true,
				stepIndex: 8,
			},
			{
				target: '#tabla-decrementos',
				content: "La única diferencia es que si hacemos doble clic sobre la celda de una fila ",
				selector: '#tabla-decrementos',
				position: 'auto',
				disableBeacon: true,
				type: 'hover',
				isFixed: true,
				stepIndex: 9,
			},
			{
				target: '#titulos-decrementar',
				content: "Únicamente nos pedirá el número de títulos a decrementar.",
				selector: '#titulos-decrementar',
				position: 'auto',
				disableBeacon: true,
				type: 'hover',
				isFixed: true,
				stepIndex: 10,
			},
			{
				target: '#tabla-decrementos',
				content: "Una vez aceptado el decremento, podemos observar como se cálcula automáticamente la numeración que quedaría libre después del decremento en la columna 'Numeración de títulos decrementados', empezando siempre por el final de la numeración. Esta numeración quedaría libre para ser usada en otra distribución.",
				selector: '#tabla-decrementos',
				position: 'auto',
				disableBeacon: true,
				type: 'hover',
				isFixed: true,
				stepIndex: 11,
			},

			{
				target: '#detalle-variacion',
				content: "Si hacemos clic sobre alguna variación de la tabla de variaciones, se nos mostrará información mas detallada sobre la misma.",
				selector: '#detalle-variacion',
				position: 'auto',
				disableBeacon: true,
				type: 'hover',
				isFixed: true,
				stepIndex: 12,
			},
			
		],
		valorValido:true,
		val:null,

		modalImprimirVS:false,
		modalImprimirGral:false,
		cargandoIprimirVS:false,
		cargandoImprimirGral:false,
		columnWidths: [100,100],
        resized: false,
	}


    resizeColumns() {
        
        let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        for (let index = 0; index < this.tablaVariaciones.locator.grid.numCols; index++) {
            totalMinSizeReq = totalMinSizeReq + this.tablaVariaciones.locator.getWidestVisibleCellInColumn(index);
            cumulativeColumnWidths.push(this.tablaVariaciones.locator.getWidestVisibleCellInColumn(index));
        }
        if (totalMinSizeReq > containerSize) {
            this.setState({
                columnWidths: cumulativeColumnWidths,
                resized: true
            });
        } else {
            let diff = ((containerSize - totalMinSizeReq) / this.tablaVariaciones.locator.grid.numCols);
            this.setState({
                columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                resized: true
            });
        }
        
    }

	hayRepetidos=(num)=>{

		let numeros=[]
		let numeracion=num
		if(numeracion.includes(",")){
		  numeracion=numeracion.split(",")
		}else{
		  numeracion=[numeracion]
		}
		numeracion.forEach(num=>{
		  if(num.includes("-")){
			  let dis=num.split("-")
			  for(let i=parseInt(dis[0]);i<=parseInt(dis[1]);i++){
				  numeros.push(String(i))
			  }
		  }else{
			  numeros.push(num)
		  }

	  })

	  let findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) !== index)

	  if(findDuplicates(numeros).length>0)return true

	  return false

	}

	hayCeros=(num)=>{

		let numeros=[]
		let numeracion=num
		if(numeracion.includes(",")){
		  numeracion=numeracion.split(",")
		}else{
		  numeracion=[numeracion]
		}
		numeracion.forEach(num=>{
		  if(num.includes("-")){
			  let dis=num.split("-")
			  for(let i=parseInt(dis[0]);i<=parseInt(dis[1]);i++){
				  numeros.push(String(i))
			  }
		  }else{
			  numeros.push(num)
		  }

	  })

	  if(numeros.find(num=>{return num==="0"}))return true

	  return false

	}

	validarNumeracion=(numeracion)=>{
		//validamos la entrada de la numeracion de titulos.
		let pattern = /^[0-9]{1,9}([,-]{1}[0-9]+)*$/;
		if (pattern.test(numeracion)) {
			return true;
		} 
		return false;
	}
	obtenerPotencias=(n)=>{
		let array = [];
		let binaryRepresentation = parseInt(n, 10).toString(2);
		binaryRepresentation = binaryRepresentation.split("").reverse().join(""); // You need to reverse the string to get the power of 2 corresponding
		for(let i = binaryRepresentation.length - 1; i >= 0; i--){
		if(parseInt(binaryRepresentation[i]) === 1){
				array[Math.pow(2, i)]=true
			}
	
		}
	
		return array
	}
	convertirNumerosArray=(num)=>{

		let numeros=[]
		let numeracion=num.split(",")
		numeracion.forEach(num=>{
			if(num.includes("-")){
				let dis=num.split("-")
				for(let i=dis[0];i<=dis[1];i++){
					numeros.push(String(i))
				}
			}else{
				numeros.push(num)
			}
		
		})
		return numeros
		
	}

	convertirArrayNumeros=(arr)=>{

		//union de los titulos.
		for(let i = 0;i < arr.length;i++)
		{
		arr[i] = parseInt(arr[i]);
		}

		let numeros=""

		var results = [];
		var limit   = arr.length-1; 

		
		for (var i = 0; i <= limit; ++i) {
		if(i<limit){
			if((arr[i]+1)===(arr[i+1])){results.push(arr[i])}else{
			if(results.length>0){
				results.push(arr[i])
				numeros+=results[0]+"-"+results[results.length-1]+","
				results = []
			}else{
				numeros+=arr[i]+","
				results = []
			}
			}
		}else if(i===limit){
			if((arr[i]-1)===(arr[i-1])){results.push(arr[i])}else{
			if(results.length>0){
				results.push(arr[i])
				console.log(results)
				numeros+=results[0]+"-"+results[results.length-1]+","
				results = []
			}else{
				numeros+=arr[i]+","
				results = []
			}
			}
		}
		}

		if(results.length>0){
		if(numeros.includes(results[0]+"-"+results[results.length-1])===false){
			numeros+=results[0]+"-"+results[results.length-1]+","
		}
		}

		if(numeros[numeros.length-1]===",")numeros=numeros.substring(0,numeros.length-1)

		return numeros
	}


	// cargarPersonasFisicas= async()=>{
	// 	let personasFisicas=await this.props.client
	// 	.query({
	// 		query: gql`
	// 			{personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}},
	// 		`,
	// 		fetchPolicy:'network-only'

	// 	}).then(result=>{ 
	// 		return result.data.personasFisicas
	// 	})
	// 	return personasFisicas
	// }
	// cargarPersonasJuridicas=async()=>{
	// 	let personasJuridicas= await  this.props.client
	// 	.query({
	// 		query: gql`
	// 			{personasJuridicas{ID_PERSONA,RAZON_SOCIAL,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
	// 		`,
	// 		fetchPolicy:'network-only'

	// 	}).then(result=>{ 
	// 		return result.data.personasJuridicas
	// 	})
	// 	return personasJuridicas	
	// }
	// cargarCapInicialDist= async()=>{
	// let capital=
	// await this.props.client.query({
	// 	query:gql`{capSocialInicialDistribucion{
	// 	  ID_DISTRIBUCION,
	// 	  ID_PERSONA,
	// 	  PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
	// 	  TIPO_TITULO,
	// 	  NUMERO_TITULOS,
	// 	  NUMERACION_TITULOS
	// 	}}`,fetchPolicy:'network-only'
	//   }).then(async(result)=>{
	// 	  return JSON.parse(JSON.stringify(result.data.capSocialInicialDistribucion))
	//   })
	//   return capital
	// } 

	// cargarDatos= async()=>{
	
	// 	let capital= await this.cargarCapInicialDist()
	// 	this.setState({
			
	// 		capInicial:capital
	// 	})
	// }
	calcularValor=async (fecha)=>{
		let capitalInicial= this.props.capSocialInicial
		//  await this.props.client.query({
		// 	query:gql`{capSocialInicial{VALOR_TITULO}}`,fetchPolicy:'network-only'
		// }).then(result=>{
		// 	return result.data.capSocialInicial
		// })
		let capitalVariac=await this.props.client.query({
			query:gql`{capSocialVariaciones(FILTROS:{ID_TIPO_VARIACION:2,FECHA:{DESDE:"1970-01-01",HASTA:"${moment(moment(fecha).subtract(1,"days")).format("YYYY-MM-DD")}"}}){ID_VARIACION,FECHA,ID_TIPO_VARIACION,CAMBIO_VALOR_TITULO,CAMBIO_NUMERO_TITULOS}}`,
			fetchPolicy:'network-only'
		}).then(result=>{
			return result.data.capSocialVariaciones
		})
		let val2=0;
		let existe=false;
			
		if(capitalInicial.length>0){
			existe=true
			capitalInicial.forEach(capital=>{
				val2=parseFloat(capital.VALOR_TITULO)
			})
		}
		capitalVariac.forEach(capital=>{
			val2+= parseFloat(capital.CAMBIO_VALOR_TITULO)
		})
		 this.setState({
			existeCapitalSocial:existe,
			valorAnterior:val2
		 })

		// let val2=0
		// //obtencion del valor del capital social´
		// await this.props.client.query({
		// 	query:gql`{capSocialInicial{VALOR_TITULO}}`,fetchPolicy:'network-only'
		// }).then(result=>{
		// 	if(result.data.capSocialInicial.length>0){
		// 		val2=parseFloat(result.data.capSocialInicial[0].VALOR_TITULO)
		// 		this.setState({existeCapitalSocial:true})
		// 	}
		// })
		
		// //obtenemos el valor del titulo para la fecha seleccioanda.
		// 	await this.props.client.query({
		// 	query:gql`{capSocialVariaciones(FILTROS:{ID_TIPO_VARIACION:2,FECHA:{DESDE:"1970-01-01",HASTA:"${moment(moment(fecha).subtract(1,"days")).format("YYYY-MM-DD")}"}}){ID_VARIACION,FECHA,ID_TIPO_VARIACION,CAMBIO_VALOR_TITULO,CAMBIO_NUMERO_TITULOS}}`,
		// 	fetchPolicy:'network-only'
		// }).then(result=>{
		// 	result.data.capSocialVariaciones.forEach(capital=>{
		// 		val2+=parseFloat(capital.CAMBIO_VALOR_TITULO)
		// 	})
		// 	this.setState({valorAnterior:val2})
		// })
	}


	obtenerNombreMiembros=async(persona)=>{
		let nombre=''
		if(persona.TIPO_PERSONA.ID_TIPO_PERSONA===1){
			this.props.personasFisicas.find(miembro=>{
				if(miembro.ID_PERSONA===persona.ID_PERSONA){
					nombre= `${miembro.APELLIDO1} ${miembro.APELIDO2?miembro.APELLIDO2:''} ${miembro.NOMBRE}`
				}
			})
		
 
		 //Si es persona juridica
		 }else if(persona.TIPO_PERSONA.ID_TIPO_PERSONA===2){
			 this.props.personasJuridicas.find(miembro=>{
				 if(miembro.ID_PERSONA===persona.ID_PERSONA){
					 nombre=miembro.RAZON_SOCIAL
				 }
			 })
			
 
		 }
		 persona.nombre=nombre
	}

	

	recargarVariaciones=async() => {
		this.setState({cargandoDis:true})
		let capSocInicial= this.props.capSocialInicial
		// await this.props.client
		// .query({
		// 	query: gql`
		// 		{capSocialInicial{FECHA_VALIDEZ,VALOR_TITULO}}
		// 	`,fetchPolicy:'network-only'
			
		// }).then(result=>{ 
		// 	return result.data.capSocialInicial
		// })
		//CAP SOCIAL INICIAL VARIACIONES	
		let capVariaciones= await this.props.client
		 .query({
			 query: gql`
				 {capSocialVariaciones(ORDEN:"FECHA,ID_VARIACION,ASC"){ID_VARIACION,FECHA,ID_TIPO_VARIACION,TIPO_VARIACION_CAPITAL_SOCIAL{ID_TIPO_VARIACION_CAPITAL_SOCIAL,DESCRIPCION},CAMBIO_NUMERO_TITULOS,CAMBIO_VALOR_TITULO}}
			 `,fetchPolicy:'network-only'
		 }).then(async result=>{ 
			 return result.data.capSocialVariaciones
		 })	
		
		console.log(capVariaciones)
			if(capSocInicial.length>0){
				capSocInicial.forEach(capital=>{
					this.setState({valorInicial:capital.VALOR_TITULO})	
				})
			}
			let copyVariaciones=[];
			for(let variacion of capVariaciones){
				let tipo=""
				if(variacion.ID_TIPO_VARIACION===2){
					if(parseFloat(variacion.CAMBIO_VALOR_TITULO)>0){
						tipo="Aumento del valor del título"  
					}else{
						tipo="Disminución del valor del título"
					}
				}else{
					if(parseInt(variacion.CAMBIO_NUMERO_TITULOS)>0){
						tipo="Aumento del número del títulos"  
					}else{
						tipo="Disminución del número del títulos"
					}
				}
						// obtenemos el valor del titulo para la fecha seleccioanda.
				let distribuciones=await this.props.client.query({
					query:gql`{capSocialVariacionesDistribucion(FILTROS:{ID_VARIACION:${variacion.ID_VARIACION}}){ID_DISTRIBUCION,ID_VARIACION,ID_PERSONA,NUMERACION_TITULOS,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},TIPO_TITULO,VARIACION}}`
					,fetchPolicy:'network-only'
				}).then(result=>{
					return result.data.capSocialVariacionesDistribucion
				})
					
				let index=0;
					for(let distribucion of distribuciones){
						
						let nombre="";
						if(distribucion.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
							this.props.personasFisicas.find(persona=>{
								if(persona.ID_PERSONA===distribucion.ID_PERSONA){
									nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
								}
							})
						}else if(distribucion.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
							this.props.personasJuridicas.find(persona=>{
								if(persona.ID_PERSONA===distribucion.ID_PERSONA){
									nombre=persona.RAZON_SOCIAL
								}
							})

						}
						distribuciones[index].nombre= nombre

						// tipos titulo
						let tiposKeys=Object.keys(this.obtenerPotencias(distribucion.TIPO_TITULO))
						let tipos=""
						tiposKeys.forEach(t=>{
							let tipoSel=this.state.tiposParticipacion.find(tipo=>{return this.state.tipoCapital===1?tipo.ID_TIPO_ACCION===parseInt(t):tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
							if(tipos===""){
							tipos+=tipoSel.DESCRIPCION
							}else{
				
							tipos+=","+tipoSel.DESCRIPCION
							}
						
						})
						distribuciones[index].tiposTitulo=tipos
						distribuciones[index].NUMERACION_TITULOS=distribucion.NUMERACION_TITULOS
						index++
					}
						copyVariaciones.push({"id":variacion.ID_VARIACION,"idTipo":variacion.ID_TIPO_VARIACION,"tipo":tipo,"fecha":variacion.FECHA,"valorInicial":this.state.valorInicial,"valorFinal":variacion.CAMBIO_VALOR_TITULO,"numTitulos":variacion.CAMBIO_NUMERO_TITULOS,"distribucion":distribuciones,"numeracion":variacion.NUMERACION_TITULOS})

				}	
							console.log(copyVariaciones)
							this.setState({variaciones:copyVariaciones})
							this.setState({cargandoDis:false})	
 	}



	

	cargarCapital=async(fecha,tipo)=>{

		this.setState({cargando:true})
		let transmisiones=await this.props.client.query({
					query:gql`{capSocialTransmisiones(FILTROS:{FECHA:{DESDE:"1970-01-01",HASTA:"${fecha}"}}){
						ID_TRANSMISION,
						FECHA,
						ORIGEN,
						FK_ORIGEN{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
						DESTINO,
						FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
						TIPO_ACCION,
						NUMERO_TITULOS,
						NUMERACION_TITULOS
					}}`,fetchPolicy:'network-only'
				}).then(async(result)=>{
					return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
				})
		

		let capital=this.props.capSocialInicialDistribucion
		console.log(capital)
		// await this.props.client.query({
		//   query:gql`{capSocialInicialDistribucion{
		// 	ID_DISTRIBUCION,
		// 	ID_PERSONA,
		// 	PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
		// 	TIPO_TITULO,
		// 	NUMERO_TITULOS,
		// 	NUMERACION_TITULOS
		//   }}`,fetchPolicy:'network-only'
		// }).then(async(result)=>{
		// 	return JSON.parse(JSON.stringify(result.data.capSocialInicialDistribucion))
		// })
	
		//recorremos el capital distribucion
		for(let cap of capital){

		  //buscamos el nombre de la persona
		  let nombre=""
		  if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
			  this.props.personasFisicas.find(persona=>{
				  if(persona.ID_PERSONA===cap.ID_PERSONA){
					  nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
				  }
			  })
	
		
	
		 //Si es persona juridica
		 }else if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
			 this.props.personasJuridicas.find(persona=>{
				 if(persona.ID_PERSONA===cap.ID_PERSONA){
					 nombre= persona.RAZON_SOCIAL
				 }
			 })
		
		  }

		  cap.nombre=nombre
		  cap.Aumento=0
		  cap.Decremento=0
		  cap.numTitulos= cap.NUMERO_TITULOS
		  cap.numFinalTitulos=cap.NUMERO_TITULOS
		  //cap.numeracionVariacion=cap.NUMERACION_TITULOS
			   
		}
		for(let transmision of transmisiones){
					await this.obtenerNombreMiembros(transmision.FK_ORIGEN)
					await this.obtenerNombreMiembros(transmision.FK_DESTINO)
		
					//obtencion del tipo del titulo.
					let tiposKeys=Object.keys(this.obtenerPotencias(transmision.TIPO_ACCION))
					let tipos=""
		
					tiposKeys.forEach(t=>{
		
						let tipoSel=this.state.tiposParticipacion.find(tipo=>{return this.state.tipoCapital===1?tipo.ID_TIPO_ACCION===parseInt(t):tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
						if(tipos===""){
						tipos+=tipoSel.DESCRIPCION
						}else{
		
						tipos+=","+tipoSel.DESCRIPCION
		
						}
		
					})
		
					transmision.tipo=tipos

		// let transmisiones=await this.cargarTransmisiones(fecha)
		
		  //por cada tramision, si ya exista la ditribucion le sumamos el numero de titulos de la transmision,
		  //de lo contrario agregarmos al transmision como si se tratara de una distribucion mas
		//   for(let transmision of transmisiones){

			  let existe=await capital.find(cap=>{
				  return cap.ID_PERSONA===transmision.DESTINO && cap.TIPO_TITULO===transmision.TIPO_ACCION
			  })
  
			  //si no existe lo agregamos al capital.
			  if(!existe){

				await capital.push({
					  "Aumento":0,
					  "Decremento":0,
					  "ID_PERSONA":transmision.DESTINO,
					  "nombre": transmision.FK_DESTINO.nombre,
					  "TIPO_TITULO":transmision.TIPO_ACCION,
					  "NUMERO_TITULOS": transmision.NUMERO_TITULOS,
					  "NUMERACION_TITULOS": transmision.NUMERACION_TITULOS,
					  "numTitulos":transmision.NUMERO_TITULOS,
					  "numFinalTitulos":transmision.NUMERO_TITULOS
				})
				console.log(capital)
			  }else if(existe){

				//si existe le sumamos el numero de titulos y la numeracion

				existe.numTitulos+=transmision.NUMERO_TITULOS
				existe.numFinalTitulos+=transmision.NUMERO_TITULOS
  
				let numerosActuales=this.convertirNumerosArray(existe.NUMERACION_TITULOS)
				let numerosAndadir=this.convertirNumerosArray(transmision.NUMERACION_TITULOS)
				for(let num of numerosAndadir){
					numerosActuales.push(num)
				}
				let numerosFinal= this.convertirArrayNumeros(numerosActuales)
				existe.NUMERACION_TITULOS=numerosFinal

			  }

		}


		//APLICAMOS LAS VARIACIONES AL CAPITAL.

		for(let cap of capital){

			//buscamos las variaciones de distribucion de titulo
			let variaciones=[]
			//buscamos las varaciones que sean de tipo 1 (incremento/decremento del numero de titulo) y que sean menor o igual a la fecha pasada como parámetro.
			variaciones=this.state.variaciones.filter(variacion=>{return (variacion.idTipo===1 && new Date(moment(variacion.fecha).format("YYYY-MM-DD")).getTime()<=new Date(fecha).getTime()) })
			
			for(let variacion of variaciones){
				//por cada distribucion de la variacion...
				for(let dir of variacion.distribucion){
					// si la variacion de distribución coincide con la distribucion del capital social.
					if(cap.ID_DISTRIBUCION){
						if(dir.ID_DISTRIBUCION===cap.ID_DISTRIBUCION){
							//aplicamos las variaciones
							cap.numTitulos+=dir.VARIACION
							cap.numFinalTitulos=cap.numTitulos
							if(dir.VARIACION>0){

								let numerosActuales=this.convertirNumerosArray(cap.NUMERACION_TITULOS)
								let numerosAndadir= this.convertirNumerosArray(dir.NUMERACION_TITULOS)
								for(let num of numerosAndadir){
									numerosActuales.push(num)
								}
								let numerosFinal= this.convertirArrayNumeros(numerosActuales)
								cap.NUMERACION_TITULOS=numerosFinal

							}else{

								let numerosActuales= this.convertirNumerosArray(cap.NUMERACION_TITULOS)
								let numerosQuitar=this.convertirNumerosArray(dir.NUMERACION_TITULOS)

								//eliminamos del array de actuales los elementos del array a quitar.
								for(let numquitar of numerosQuitar){
									numerosActuales=numerosActuales.filter(el=>{return el!==numquitar})
								}
								let numerosFinal= this.convertirArrayNumeros(numerosActuales)

								cap.NUMERACION_TITULOS=numerosFinal

							}
								
						}
					}else{

						if(dir.ID_PERSONA===cap.ID_PERSONA && dir.TIPO_TITULO===cap.TIPO_TITULO){
							//aplicamos las variaciones
							cap.numTitulos+=dir.VARIACION
							cap.numFinalTitulos=cap.numTitulos
							if(dir.VARIACION>0){

								let numerosActuales=this.convertirNumerosArray(cap.NUMERACION_TITULOS)
								let numerosAndadir= this.convertirNumerosArray(dir.NUMERACION_TITULOS)

								for(let num of numerosAndadir){
									numerosActuales.push(num)
								}
								let numerosFinal= this.convertirArrayNumeros(numerosActuales)
								cap.NUMERACION_TITULOS=numerosFinal
								
							}else{

								let numerosActuales= this.convertirNumerosArray(cap.NUMERACION_TITULOS)
								let numerosQuitar= this.convertirNumerosArray(dir.NUMERACION_TITULOS)

								//eliminamos del array de actuales los elementos del array a quitar.
								for(let numquitar of numerosQuitar){
									numerosActuales=numerosActuales.filter(el=>{return el!==numquitar})
								}
								let numerosFinal= this.convertirArrayNumeros(numerosActuales)
								cap.NUMERACION_TITULOS=numerosFinal

							}
								
						}

					}      
						
				}
			}

		}

		
		for(let transmision of transmisiones){

			  //a la distribucion origen de la transmision le restamos el numero de titulos,
			//y la numeracion de la transmision.

			let capitalEncontrado=await capital.find(cap=>{
				return cap.ID_PERSONA===transmision.ORIGEN && cap.TIPO_TITULO===transmision.TIPO_ACCION
			})

			capitalEncontrado.numTitulos-=transmision.NUMERO_TITULOS
			capitalEncontrado.numFinalTitulos-=transmision.NUMERO_TITULOS
			
			let numerosActuales=this.convertirNumerosArray(capitalEncontrado.NUMERACION_TITULOS)
			let numerosQuitar=this.convertirNumerosArray(transmision.NUMERACION_TITULOS)
			//eliminamos del array de actuales los elementos del array a quitar.
			for(let numquitar of numerosQuitar){
				numerosActuales=numerosActuales.filter(el=>{return el!==numquitar})
			}
			let numerosFinal= this.convertirArrayNumeros(numerosActuales)

			capitalEncontrado.NUMERACION_TITULOS=numerosFinal

		
				
		}
		
		  
		console.log(capital)

		this.setState({
			capitalSocial:capital,
			cargando:false
		})

	}    

	
	cargarCapitalEditar=async(fecha,tipo)=>{

		this.setState({cargando:true})

		
		let transmisiones=await this.props.client.query({
			query:gql`{capSocialTransmisiones(FILTROS:{FECHA:{DESDE:"1970-01-01",HASTA:"${fecha}"}}){
				ID_TRANSMISION,
				FECHA,
				ORIGEN,
				FK_ORIGEN{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
				DESTINO,
				FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
				TIPO_ACCION,
				NUMERO_TITULOS,
				NUMERACION_TITULOS
			}}`,fetchPolicy:'network-only'
		}).then(async(result)=>{
			return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
		})
		let capital=this.props.capSocialInicialDistribucion
		console.log(capital)
		// await this.props.client.query({
		//   query:gql`{capSocialInicialDistribucion{
		// 	ID_DISTRIBUCION,
		// 	ID_PERSONA,
		// 	PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
		// 	TIPO_TITULO,
		// 	NUMERO_TITULOS,
		// 	NUMERACION_TITULOS
		//   }}`,fetchPolicy:'network-only'
		// }).then(async(result)=>{
		// 	return JSON.parse(JSON.stringify(result.data.capSocialInicialDistribucion))
		// })
		//  let personasFisicas= await this.props.client
		//  .query({
		// 	 query: gql` 
		// 		 {personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
		// 	 `,fetchPolicy:'network-only'
		//  }).then(result=>{ 
		// 	 return result.data.personasFisicas
		//  })	
		//  let personasJuridicas= await this.props.client
		//  .query({
		// 	 query: gql`
		// 		 {personasJuridicas{ID_PERSONA,RAZON_SOCIAL}}
		// 	 `,fetchPolicy:'network-only'
		//  }).then(result=>{ 
		// 	 return result.data.personasJuridicas
		//  })	 
	
		//recorremos el capital distribucion
		for(let cap of capital){

		  //buscamos el nombre de la persona
		  let nombre=""
		  if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
			  this.props.personasFisicas.find(persona=>{
				  if(persona.ID_PERSONA===cap.ID_PERSONA){
					  nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLID2:''} ${persona.NOMBRE}`
				  }
			  })
	
			
		 //Si es persona juridica
		 }else if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
			 this.props.personasJuridicas.find(persona=>{
				 if(persona.ID_PERSONA===cap.ID_PERSONA){
					 nombre= persona.RAZON_SOCIAL
				 }
			 })
		
	
		  }

		  cap.nombre=nombre
		  cap.Aumento=0
		  cap.Decremento=0
		  cap.numTitulos= cap.NUMERO_TITULOS
		  cap.numFinalTitulos=cap.NUMERO_TITULOS          
		 
		}
		for(let transmision of transmisiones){
			await this.obtenerNombreMiembros(transmision.FK_ORIGEN)
			await this.obtenerNombreMiembros(transmision.FK_DESTINO)

			//obtencion del tipo del titulo.
			let tiposKeys=Object.keys(this.obtenerPotencias(transmision.TIPO_ACCION))
			let tipos=""

			tiposKeys.forEach(t=>{

				let tipoSel=this.state.tiposParticipacion.find(tipo=>{return this.state.tipoCapital===1?tipo.ID_TIPO_ACCION===parseInt(t):tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
				if(tipos===""){
				tipos+=tipoSel.DESCRIPCION
				}else{

				tipos+=","+tipoSel.DESCRIPCION

				}

			})

			transmision.tipo=tipos
		// }	

		// let transmisiones=await this.cargarTransmisiones(fecha)
		
			//por cada tramision, si ya exista la ditribucion le sumamos el numero de titulos de la transmision,
			//de lo contrario agregarmos al transmision como si se tratara de una distribucion mas
			// for(let transmision of transmisiones){
		
				let existe=await capital.find(cap=>{
					return cap.ID_PERSONA===transmision.DESTINO && cap.TIPO_TITULO===transmision.TIPO_ACCION
				})
  
				if(existe){
				  existe.numTitulos+=transmision.NUMERO_TITULOS
				  existe.numFinalTitulos+=transmision.NUMERO_TITULOS
	
				  let numerosActuales=this.convertirNumerosArray(existe.NUMERACION_TITULOS)
				  let numerosAndadir=this.convertirNumerosArray(transmision.NUMERACION_TITULOS)
				  for(let num of numerosAndadir){
					  numerosActuales.push(num)
				  }
				  let numerosFinal=this.convertirArrayNumeros(numerosActuales)
				  existe.NUMERACION_TITULOS=numerosFinal
	
				}else if(!existe){
					capital.push({
						"Aumento":0,
						"Decremento":0,
						"ID_PERSONA":transmision.DESTINO,
						"nombre": transmision.FK_DESTINO.nombre,
						"TIPO_TITULO":transmision.TIPO_ACCION,
						"NUMERO_TITULOS": transmision.NUMERO_TITULOS,
						"NUMERACION_TITULOS": transmision.NUMERACION_TITULOS,
						"numTitulos":transmision.NUMERO_TITULOS,
						"numFinalTitulos":transmision.NUMERO_TITULOS
					})
				}
  
				
				let capitalEncontrado=await capital.find(cap=>{
					return cap.ID_PERSONA===transmision.ORIGEN && cap.TIPO_TITULO===transmision.TIPO_ACCION
				})

				capitalEncontrado.numTitulos-=transmision.NUMERO_TITULOS
				capitalEncontrado.numFinalTitulos-=transmision.NUMERO_TITULOS
				
				
				let numerosActuales=this.convertirNumerosArray(capitalEncontrado.NUMERACION_TITULOS)
				let numerosQuitar= this.convertirNumerosArray(transmision.NUMERACION_TITULOS)

				//eliminamos del array de actuales los elementos del array a quitar.
				for(let numquitar of numerosQuitar){
					numerosActuales=numerosActuales.filter(el=>{return el!==numquitar})
				}
				let numerosFinal= this.convertirArrayNumeros(numerosActuales)

				capitalEncontrado.NUMERACION_TITULOS=numerosFinal

		}

		for(let cap of capital){
			
			//buscamos las variaciones de distribucion de titulo
			let variaciones=[]
			let aumentosRealizados=[]
			let decrementosRealizados=[]

			//buscamos las varaciones que sean de tipo 1 (incremento/decremento del numero de titulo) y que sean menor o igual a la fecha pasada como parámetro.
			variaciones=this.state.variaciones.filter(variacion=>{return (variacion.idTipo===1 && new Date(moment(variacion.fecha).format("YYYY-MM-DD")).getTime()<=new Date(fecha).getTime()) })
		  
			variaciones.forEach(variacion=>{

				//por cada distribucion de la variacion...
				variacion.distribucion.forEach(async dir=>{

					//si es una variacion basada en el incremento de titulos.
					if(tipo==="aumento" && new Date(moment(this.state.variacionSeleccionada.fecha).format("YYYY-MM-DD")).getTime()===new Date(fecha).getTime() && this.state.variacionSeleccionada.id===variacion.id){
						if(dir.ID_DISTRIBUCION){
							aumentosRealizados['"'+dir.ID_DISTRIBUCION+'"']={persona:dir.ID_PERSONA,titulos:dir.TIPO_TITULO,aumento:parseInt(dir.VARIACION),idDis:dir.ID_DISTRIBUCION,numeracionVariacion:dir.NUMERACION_TITULOS}
						}else{
							aumentosRealizados['"'+dir.ID_PERSONA+'-'+dir.TIPO_TITULO+'"']={persona:dir.ID_PERSONA,titulos:dir.TIPO_TITULO,aumento:parseInt(dir.VARIACION),idDis:dir.ID_DISTRIBUCION,numeracionVariacion:dir.NUMERACION_TITULOS}
						}

						this.setState({aumentosRealizados:aumentosRealizados})

					// si es de decremento
					}else if(tipo==="decremento" && new Date(moment(this.state.variacionSeleccionada.fecha).format("YYYY-MM-DD")).getTime()===new Date(fecha).getTime() && this.state.variacionSeleccionada.id===variacion.id){

						if(dir.ID_DISTRIBUCION){
							decrementosRealizados['"'+dir.ID_DISTRIBUCION+'"']={persona:dir.ID_PERSONA,titulos:dir.TIPO_TITULO,decremento:Math.abs(dir.VARIACION),idDis:dir.ID_DISTRIBUCION,numeracionVariacion:dir.NUMERACION_TITULOS}
						}else{
							decrementosRealizados['"'+dir.ID_PERSONA+'-'+dir.TIPO_TITULO+'"']={persona:dir.ID_PERSONA,titulos:dir.TIPO_TITULO,decremento:Math.abs(dir.VARIACION),idDis:dir.ID_DISTRIBUCION,numeracionVariacion:dir.NUMERACION_TITULOS}
						}
						this.setState({decrementosRealizados:decrementosRealizados})

					}

					// si la variacion de distribución coincide con la distribucion del capital social.
					if(dir.ID_DISTRIBUCION){

						if((dir.ID_DISTRIBUCION===cap.ID_DISTRIBUCION)){


							console.log(dir)
							//aplicamos las variaciones que esten por debajo de la fecha de la varacion
							if(new Date(moment(variacion.fecha).format("YYYY-MM-DD")).getTime()<new Date(this.state.variacionSeleccionada.fecha).getTime()){
								cap.numTitulos+=dir.VARIACION

								if(dir.VARIACION>0){

									let numerosActuales=this.convertirNumerosArray(cap.NUMERACION_TITULOS)
									let numerosAndadir= this.convertirNumerosArray(dir.NUMERACION_TITULOS)
									for(let num of numerosAndadir){
										numerosActuales.push(num)
									}
									let numerosFinal=this.convertirArrayNumeros(numerosActuales)
									cap.NUMERACION_TITULOS=numerosFinal

								}else{

									let numerosActuales=this.convertirNumerosArray(cap.NUMERACION_TITULOS)
									let numerosQuitar= this.convertirNumerosArray(dir.NUMERACION_TITULOS)

									//eliminamos del array de actuales los elementos del array a quitar.
									for(let numquitar of numerosQuitar){
										numerosActuales=numerosActuales.filter(el=>{return el!==numquitar})
									}
									let numerosFinal= this.convertirArrayNumeros(numerosActuales)

									cap.NUMERACION_TITULOS=numerosFinal

								}

							}
							
							//si estamos editando y la fecha de variacion coincide con la fecha de la edicion
							if(new Date(moment(this.state.variacionSeleccionada.fecha).format("YYYY-MM-DD")).getTime()===new Date(fecha).getTime() && this.state.variacionSeleccionada.id===variacion.id){


								if(tipo==="aumento"){
									cap.Aumento=dir.VARIACION
									cap.numeracionVariacion=dir.NUMERACION_TITULOS
									
								}
								if(tipo==="decremento"){
									cap.Decremento=dir.VARIACION 
									cap.numeracionVariacion=dir.NUMERACION_TITULOS
								}
							}
						}
					}else{

						if((dir.ID_PERSONA===cap.ID_PERSONA && dir.TIPO_TITULO===cap.TIPO_TITULO)){

							//aplicamos las variaciones que esten por debajo de la fecha de la varacion
							if(new Date(moment(variacion.fecha).format("YYYY-MM-DD")).getTime()<new Date(this.state.variacionSeleccionada.fecha).getTime()){
								cap.numTitulos+=dir.VARIACION
							}

							//si estamos editando y la fecha de variacion coincide con la fecha de la edicion
							if(new Date(moment(this.state.variacionSeleccionada.fecha).format("YYYY-MM-DD")).getTime()===new Date(fecha).getTime() && this.state.variacionSeleccionada.id===variacion.id){
								if(tipo==="aumento"){
									cap.Aumento=dir.VARIACION
									cap.numeracionVariacion=dir.NUMERACION_TITULOS
								}
								if(tipo==="decremento"){
									cap.Decremento=dir.VARIACION  
									cap.numeracionVariacion=dir.NUMERACION_TITULOS

								}
							}

						}

					}
				   
				})    
			})

			//el calculo final del numero de titulos.
			cap.numFinalTitulos=cap.numTitulos+(tipo==="decremento"?cap.Decremento:cap.Aumento)
		
		}

		console.log(capital)

		this.setState({
			capitalSocial:capital,
			cargando:false
		})

	}    

	cargarTiposAccion=async()=>{
		let personasJuridicas= this.props.empresaPrincipal
		// await this.props.client
		// .query({
		// 	query: gql`
		// 	{
		// 		personasJuridicas(CAMPOS:"ID_PERSONA,ID_TIPO_CAPITAL_SOCIAL",FILTROS:{EMPRESA_PRINCIPAL:true}){ID_PERSONA,ID_TIPO_CAPITAL_SOCIAL}
		// 	}
		// 	`,
		// 	fetchPolicy:'network-only'
		// }).then(result=>{ 
		// 	return result.data.personasJuridicas	   
		// })
		// let tiposAccion=await this.props.client.query({
		// 	query:gql`{tiposAccion{
		// 		ID_TIPO_ACCION,
		// 		DESCRIPCION,
		// 		INCOMPATIBLE,
		// 	}}`
		// }).then(async(result)=>{
		// 	return result.data.tiposAccion
		// })
		// let tiposParticipacion= await this.props.client.query({
		// 	query:gql`{tiposParticipacion{
		// 		ID_TIPO_PARTICIPACION,
		// 		DESCRIPCION,
		// 		INCOMPATIBLE,
		// 	}}`
		// }).then(async(result)=>{
		// 	return result.data.tiposParticipacion
		// })
		
		personasJuridicas.forEach(persona=>{
			if(persona.ID_TIPO_CAPITAL_SOCIAL===1){
				this.setState({
					tiposParticipacion:this.props.tiposAccion
				})
			}else{
				this.setState({
					tiposParticipacion:this.props.tiposParticipacion
				})
			}
			this.setState({
				tipoCapital: persona.ID_TIPO_CAPITAL_SOCIAL
			})
		})
		
	}
	

	componentDidMount=async(props)=>{

		this.setState({cargando: true}, async() =>{
			await this.cargarTiposAccion()
			await this.calcularValor(moment().format('YYYY-MM-DD'))
			await this.recargarVariaciones()			
			this.setState({cargando: false})
		})
		
	}
	generarImprimirGral= async () =>{
		this.setState({
			cargandoImprimirGral:true,
			modalImprimirGral:true
		}, async () =>{
			this.setState({cargandoImprimirGral:false}, ()=>{
				ReactDOM.render(this.ImprimirGral(), document.getElementById('generarImprimirGral-pdf'))
			})
		})
	}
	generarImprimirVS= async () =>{
		console.log(this.state.variacionSeleccionada)
		this.setState({
			cargandoImprimirVS:true,
			modalImprimirVS:true			
		}, async () =>{
			this.setState({
				cargandoImprimirVS:false
			}, () =>{
				ReactDOM.render(this.ImprimirVS(), document.getElementById('generarImprimirVS-pdf'))
			})
		})
	}
	ImprimirGral=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>ESTRUCTURA SOCIETARIA</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>VARIACIONES</Text>
                </View>
                    {this.state.variaciones?this.state.variaciones.map((variacion, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'40%'}}>FECHA</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'60%'}}>TIPO VARIACIÓN</Text>                                                                
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{variacion.fecha?moment(variacion.fecha).format('DD-MM-YYYY'):''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'60%'}}>{variacion.tipo?variacion.tipo:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
	ImprimirVS=()=>(
		
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>ESTRUCTURA SOCIETARIA</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>VARIACIONES</Text>
                </View>
                    {this.state.variacionSeleccionada && this.state.variacionSeleccionada.idTipo===2?
								
							<View >
								<View style={styles.rowTitleOne}>
                    				<Text style={styles.labelTitle}>Información sobre la variación del capital social</Text>
                				</View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>FECHA MODIFICACIÓN:</Text>
                                    <Text style={styles.textData}>{moment(this.state.variacionSeleccionada.fecha).format('DD-MM-YYYY')}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>VALOR ANTERIOR DE TÍTULO:</Text>
                                    <Text style={styles.textData}>{parseFloat(this.state.valorAnterior).toFixed(2)}</Text>
                                </View>
								<View style={styles.row}>
                                    <Text style={styles.labelData}>VALOR ACTUAL DE TÍTULO:</Text>
                                    <Text style={styles.textData}>{parseFloat(this.state.valorAnterior+this.state.variacionSeleccionada.valorFinal).toFixed(2)}</Text>
                                </View>
                                
                            </View>
							:
										
						<View>
							<View style={styles.rowTitleOne}>
                    				<Text style={styles.labelTitle}>Con fecha {moment(this.state.variacionSeleccionada.fecha).format('DD-MM-YYYY')} se ha producido {this.state.variacionSeleccionada.numTitulos>0?"un incremento":"una disminución"} en el número de títulos con la siguiente distribución:</Text>
                			</View>
							{this.state.variacionSeleccionada.distribucion.map((distr, index)=>{
								let tipos=this.tipoTitulo(distr.TIPO_TITULO)
								return(
									<View key={index}>
										{index===0 &&(
											<View style={styles.colHead}>
												<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>SOCIO</Text>
												<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>TIPO DE TÍTULOS</Text>                                                                
												<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>NUMERACIÓN DE TÍTULOS</Text>
												<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{this.state.variacionSeleccionada.numTitulos>0?"AUMENTO":"DISMINUCIÓN"}</Text>                                                                
											</View>	
										)}
											<View style={styles.colHead}>
												<Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{distr.nombre}</Text>
												<Text style={{paddind:0, textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'30%'}}>{tipos}</Text>
												<Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'30%'}}>{distr.NUMERACION_TITULOS}</Text>
												
												<Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{Math.abs(distr.VARIACION)}</Text>
											</View>                      
									</View>									
								)} 
							)}      
					 
					 </View>
					}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
	 )
	

	tipoTitulo= (data)=>{
		let tiposKeys=Object.keys(this.obtenerPotencias(data))
		let tipos=""
		tiposKeys.forEach(t=>{
			let tipoSel=this.state.tiposParticipacion.find(tipo=>{return this.state.tipoCapital===1?tipo.ID_TIPO_ACCION===parseInt(t):tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
			if(tipos===""){
			  tipos+=tipoSel.DESCRIPCION
			}else{
  
			  tipos+=","+tipoSel.DESCRIPCION
			}
		   
		})
		
		return tipos
	}
	
	

	render(){

		const { steps } = this.state;

		document.title ="LexControl | Variaciones"
		
		const abrirModalNuevo = async() => {

			if(!this.state.existeCapitalSocial){
				alert("primero debe guardar la información del capital social inicial")
				return
			}

			this.calcularValor(moment().format('YYYY-MM-DD'))

			this.setState({
				modalNuevoAbierto: true, 
				nuevoActivado: true,
				editarActivado: false,
				borrarActivado: false,
				popoverAbierto: false,
				valorFinal:null,
				valorValido:false,
				val:null
			})
		}

		
		const abrirPopover = () => {
			
			//si no tiene permisos para editar la pantalla
			if(this.props.editable===false){
				alert("No tienes permisos para realizar esta acción")
				return
			}

			if(!this.state.existeCapitalSocial){
				alert("primero debe guardar la información del capital social inicial")
				return
			}

			this.setState({popoverAbierto: true})
		}

		const abrirModalBorrar = () => {

			//si no tiene permisos para editar la pantalla
			if(this.props.editable===false){
				alert("No tienes permisos para realizar esta acción")
				return
			}

			if(!this.state.variacionSeleccionada){
				alert("Debe seleccionar una variacion")
				return
			}

			this.setState({modalBorrarAbierto: true})
			this.setState({nuevoActivado: false})
			this.setState({editarActivado: false})
			this.setState({borrarActivado: true})
		}
		const abrirModalEditar =async () => {

			//si no tiene permisos para editar la pantalla
			if(this.props.editable===false){
				alert("No tienes permisos para realizar esta acción")
				return
			}

			if(!this.state.variacionSeleccionada){
				alert("Debe seleccionar una variacion")
				return
			}

			if(this.state.variacionSeleccionada.idTipo===1){
				
				await this.cargarCapitalEditar(moment(this.state.variacionSeleccionada.fecha).format("YYYY-MM-DD"),(this.state.variacionSeleccionada.numTitulos>0?"aumento":"decremento"))
			 
				if(this.state.variacionSeleccionada.numTitulos>0){
					this.setState({fechaAumento:new Date(this.state.variacionSeleccionada.fecha)})
					this.setState({totalAumentados:this.state.variacionSeleccionada.numTitulos})
					//abrir modal incremento
					this.setState({modalAumentoAbierto:true})
				}else{
					this.setState({fechaDisminucion:new Date(this.state.variacionSeleccionada.fecha)})
					this.setState({totalDisminuidos:this.state.variacionSeleccionada.numTitulos})
					//abrir modal incremento
					this.setState({modalDisminucionAbierto:true})       
				}
			}else{
				await this.setState({fechaVariacion: new Date(this.state.variacionSeleccionada.fecha)})
				await this.calcularValor(moment(this.state.variacionSeleccionada.fecha).format("YYYY-MM-DD"))
				await this.setState({modalEditarAbierto: true,valorValido:true,val:this.state.variacionSeleccionada? Intl.NumberFormat('es-ES').format(this.state.valorAnterior+this.state.variacionSeleccionada.valorFinal):"",valorFinal:this.state.variacionSeleccionada?parseFloat(this.state.valorAnterior+this.state.variacionSeleccionada.valorFinal).toFixed(2):""})
			}

			this.setState({nuevoActivado: false})
			this.setState({editarActivado: true})
			this.setState({borrarActivado: false})
		}
		const cerrarModal = () => {
			this.setState({
				modalNuevoAbierto: false,
				modalBorrarAbierto: false,
				modalEditarAbierto: false,
				modalDisminucionAbierto: false,
				modalAumentoAbierto: false,
			})
		}

		const cerrarModal2 = () => {
			this.setState({
				modalIncremento: false,
				modalDecremento:false,
			})
		}

		const cerrarModalTitulo=()=>{
			this.setState({
				modalTitulos:false
			})
		}

		const formatearMiles=(numero)=>{
			return numero.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."); 
		}

		const cambiarValor = (ev) => {

			//solo permitir en el input numeros,comas y puntos.
			let pattern=/^[0-9,.]*$/
			if(pattern.test(ev.currentTarget.value)===false)return

			//comprobacion que sea un número válido
			let pattern2=/^\d{1,3}(\.\d{3})*(\,\d{2})?$/
			if(pattern2.test(formatearMiles(ev.currentTarget.value.replace(/\./g, '')))){
				this.setState({valorValido:true})
			}else{
				this.setState({valorValido:false})
			}
			
			/* alert(parseFloat(ev.currentTarget.value.replace(/\./g, '').replace(/\,/g, '.')-this.state.valorAnterior).toFixed(2)) */
			this.setState({val:formatearMiles(ev.currentTarget.value.replace(/\./g, '')) ,valorFinal:parseFloat(ev.currentTarget.value.replace(/\./g, '').replace(/\,/g, '.')-this.state.valorAnterior).toFixed(2)})
		}

		const escogeFecha = (nuevaFecha) => {
			this.calcularValor(moment(nuevaFecha).format('YYYY-MM-DD'))
			this.setState({fechaVariacion:nuevaFecha})
		}

		const handleSeleccionarVariacion=(variacion)=>{
			if(variacion.length===0)return

			let seleccionada=this.state.variaciones[variacion[0].rows[0]]
			this.calcularValor(seleccionada.fecha)
			this.setState({variacionSeleccionada:seleccionada})
			this.setState({regionVariaciones:variacion})

		}

		const seleccionarVariacionAumento=(variacion)=>{
			if(variacion.length===0)return

			let seleccionada=this.state.capitalSocial[variacion[0].rows[0]]
			this.setState({variacionSeleccionadaAumento:seleccionada})
		}

		const seleccionarVariacionDisminucion=(variacion)=>{
			if(variacion.length===0)return

			let seleccionada=this.state.capitalSocial[variacion[0].rows[0]]
			this.setState({variacionSeleccionadaDecremento:seleccionada})

		}

		const eliminarVariacion = async () => {
			
			//si la variacion es de tipo 2
			if(this.state.variacionSeleccionada.idTipo===2){

				await this.props.client.mutate({
					mutation:gql`mutation{eliminarCapSocialVariacion(ID_VARIACION:${this.state.variacionSeleccionada.id}){ID_VARIACION}}`
				}).then(async result=>{
				
					alert("El registro se ha eliminado correctamente.")
					await this.recargarVariaciones()

					this.setState({
						variacionSeleccionada:null,
						regionVariaciones:null
					},()=>{this.tablaVariaciones.clearSelection()})

					cerrarModal()
						
				}).catch(err=>{
					alert("Ha ocurrido un error al eliminar la variación.Pongase en contacto con el soporte técnico")
				})

			}else{

				//eliminamos primero las dsitribucion
				await this.props.client.mutate({
					mutation:gql`mutation{eliminarCapSocialVariacionesDistribucion(ID_VARIACION:${this.state.variacionSeleccionada.id}){ID_DISTRIBUCION}}`
				})

				//y despues la variacion
				await this.props.client.mutate({
					mutation:gql`mutation{eliminarCapSocialVariacion(ID_VARIACION:${this.state.variacionSeleccionada.id}){ID_VARIACION}}`
				}).then(result=>{
				
					alert("El registro se ha eliminado correctamente.")
					this.setState({variacionSeleccionada:null})
					cerrarModal()
					this.recargarVariaciones()
						
				}).catch(err=>{
					alert("Ha ocurrido un error al eliminar la variación.Pongase en contacto con el soporte técnico")
				})


			}
	 
		};

		const añadirVariacion = async() => {


			if(this.state.modalNuevoAbierto){

				if(!this.state.valorInicial || !this.state.valorValido){
					return
				}

			}

			await  this.props.client.mutate({
				mutation:gql`mutation{crearCapSocialVariacion(VALORES:{FECHA:"${moment(this.state.fechaVariacion).format("YYYY-MM-DD")}",ID_TIPO_VARIACION:2,CAMBIO_VALOR_TITULO:${this.state.valorFinal}}){ID_VARIACION}}`
			}).then(async result=>{

				alert("La variación se ha insertado correctamente")
				await this.recargarVariaciones()
				await this.calcularValor(moment(this.state.fechaVariacion).format('YYYY-MM-DD'))

				//seleccionamos el creado
				let creado=this.state.variaciones.find((variacion,index)=>{
					if(variacion.id===result.data.crearCapSocialVariacion.ID_VARIACION){
						this.setState({regionVariaciones:[Regions.row(index)]})
					}
					return variacion.id===result.data.crearCapSocialVariacion.ID_VARIACION
				})

				this.setState({
					variacionSeleccionada:creado
				})
				
			}).catch(err=>{
				alert("Ha ocurrido un error al crear la variación.Pongase en contacto con el soporte técnico")
			})

			cerrarModal()
		}

		const editarVariacion = async() => {
	 
			await this.props.client.mutate({
				mutation:gql`mutation{actualizarCapSocialVariacion(ID_VARIACION:${this.state.variacionSeleccionada.id},VALORES:{FECHA:"${moment(this.state.fechaVariacion).format("YYYY-MM-DD")}",CAMBIO_VALOR_TITULO:${this.state.valorFinal?(this.state.valorFinal):this.state.variacionSeleccionada.valorFinal}}){ID_VARIACION}}`
			}).then(async result=>{
	   
				alert("El registro se ha actualizado correctamente.")
				await this.recargarVariaciones()
				await this.calcularValor(moment(this.state.variacionSeleccionada.fecha).format('YYYY-MM-DD'))
				//seleccionamos el creado
				let creado=this.state.variaciones.find((variacion,index)=>{
					if(variacion.id===this.state.variacionSeleccionada.id){
						this.setState({regionVariaciones:[Regions.row(index)]})
					}
					return variacion.id===this.state.variacionSeleccionada.id
				})

				this.setState({variacionSeleccionada:creado})
				
			}).catch(err=>{
				alert("Ha ocurrido un error al actualizar la variación.Pongase en contacto con el soporte técnico")
			})
			
			cerrarModal()

		}

		const getMomentFormatter=(format) => {
			// note that locale argument comes from locale prop and may be undefined
			return {
				formatDate: (date, locale) => moment(date).locale(locale).format(format),
				parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
				placeholder: format,
			}
		};

		const cellRendererFecha = (rowIndex) => {
			return <Cell wrapText="false" truncated="false">{`${moment(this.state.variaciones[rowIndex].fecha,"YYYY-MM-DD").format("DD-MM-YYYY")}`}</Cell>
		}

		const cellRendererTipo = (rowIndex) => {
			return <Cell wrapText="false" truncated="false">{`${this.state.variaciones[rowIndex].tipo}`}</Cell>
		}

		const cellRendererSocioAumento = (rowIndex) => {
			return  <Cell loading={this.state.cargando} wrapText="false"   interactive={true}  truncated="false"><div onDoubleClick={abrirModalIncremento}>{`${this.state.capitalSocial[rowIndex].nombre}`}</div></Cell>
		}

		const cellRendererSocioDisminucion = (rowIndex) => {
			return  <Cell loading={this.state.cargando} wrapText="false"   interactive={true}  truncated="false"><div onDoubleClick={abrirModalDecremento}>{`${this.state.capitalSocial[rowIndex].nombre}`}</div></Cell>
				
		}


		const cellRendererTipoTitulosAumento = (rowIndex) => {


			 //tipos titulo
			 let tiposKeys=Object.keys(this.obtenerPotencias(this.state.capitalSocial[rowIndex].TIPO_TITULO))
			 let tipos=""
			 tiposKeys.forEach(t=>{
				 let tipoSel=this.state.tiposParticipacion.find(tipo=>{return this.state.tipoCapital===1?tipo.ID_TIPO_ACCION===parseInt(t):tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
				 if(tipos===""){
				   tipos+=tipoSel.DESCRIPCION
				 }else{
	   
				   tipos+=","+tipoSel.DESCRIPCION
				 }
				
			 })

			return  <Cell loading={this.state.cargando} wrapText="false" truncated="false"><div onDoubleClick={abrirModalIncremento}>{`${tipos}`}</div></Cell>

		}

		const cellRendererTipoTitulosDisminucion = (rowIndex) => {

			//tipos titulo
			let tiposKeys=Object.keys(this.obtenerPotencias(this.state.capitalSocial[rowIndex].TIPO_TITULO))
			let tipos=""
			tiposKeys.forEach(t=>{
				let tipoSel=this.state.tiposParticipacion.find(tipo=>{return this.state.tipoCapital===1?tipo.ID_TIPO_ACCION===parseInt(t):tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
				if(tipos===""){
				  tipos+=tipoSel.DESCRIPCION
				}else{
	  
				  tipos+=","+tipoSel.DESCRIPCION
				}
			   
			})

			return  <Cell loading={this.state.cargando} wrapText="false" truncated="false"><div onDoubleClick={abrirModalDecremento}>{`${tipos}`}</div></Cell>
					
		}

		const cellRendererNumeroTitulosAumento = (rowIndex) => {
			return  <Cell loading={this.state.cargando} wrapText="false" truncated="false"><div onDoubleClick={abrirModalIncremento}>{`${this.state.capitalSocial[rowIndex].numTitulos}`}</div> </Cell>
					 
		}

		const cellRendererNumeroTitulosDecremento = (rowIndex) => {
			return  <Cell loading={this.state.cargando} wrapText="false" truncated="false"><div onDoubleClick={abrirModalDecremento} >{`${this.state.capitalSocial[rowIndex].numTitulos}`}</div></Cell>
		}

		const cellRendererAumento = (rowIndex) => {
			return  <Cell loading={this.state.cargando} wrapText="false" truncated="false"><div onDoubleClick={abrirModalIncremento}>{`${this.state.capitalSocial[rowIndex].Aumento}`}</div></Cell>
					
		}

		const cellRendererDisminucion = (rowIndex) => {
			return  <Cell loading={this.state.cargando} wrapText="false" truncated="false"><div onDoubleClick={abrirModalDecremento}>{`${Math.abs(this.state.capitalSocial[rowIndex].Decremento)}`}</div></Cell>
					
		}


		const cellRendererNumFinalAumento = (rowIndex) => {
			return  <Cell loading={this.state.cargando} wrapText="false" truncated="false"><div onDoubleClick={abrirModalIncremento}>{`${this.state.capitalSocial[rowIndex].numFinalTitulos}`}</div></Cell>
		}

		const cellRendererNumeracionAumento = (rowIndex) => {
			return  <Cell loading={this.state.cargando} wrapText="false" truncated="false"><div  style={{width:"100%",display:"inline-block"}} onDoubleClick={abrirModalIncremento}>{`${this.state.capitalSocial[rowIndex].NUMERACION_TITULOS?this.state.capitalSocial[rowIndex].NUMERACION_TITULOS:"SIN_DATOS"}`}</div></Cell>
		}

		const cellRendererNumeracionAumentoAumentados = (rowIndex) => {
			return  <Cell loading={this.state.cargando} wrapText="false" truncated="false"><div  style={{width:"100%",display:"inline-block"}} onDoubleClick={abrirModalIncremento}>{`${this.state.capitalSocial[rowIndex].numeracionVariacion?this.state.capitalSocial[rowIndex].numeracionVariacion:"SIN_DATOS"}`}</div></Cell>
		}

		const cellRendererNumFinalDisminucion = (rowIndex) => {
			return  <Cell loading={this.state.cargando} wrapText="false" truncated="false"><div onDoubleClick={abrirModalDecremento}>{`${this.state.capitalSocial[rowIndex].numFinalTitulos}`}</div></Cell>     
		}

		const cellRendererNumeracionDisminucion = (rowIndex) => {
			return  <Cell loading={this.state.cargando} wrapText="false" truncated="false"><div style={{width:"100%",display:"inline-block"}} onDoubleClick={abrirModalDecremento}>{`${this.state.capitalSocial[rowIndex].NUMERACION_TITULOS?this.state.capitalSocial[rowIndex].NUMERACION_TITULOS:"SIN_DATOS"}`}</div></Cell>
		}

		const cellRendererNumeracionDisminucionDecrementados = (rowIndex) => {
			return  <Cell loading={this.state.cargando} wrapText="false" truncated="false"><div style={{width:"100%",display:"inline-block"}} onDoubleClick={abrirModalDecremento}>{`${this.state.capitalSocial[rowIndex].numeracionVariacion?this.state.capitalSocial[rowIndex].numeracionVariacion:"SIN_DATOS"}`}</div></Cell>
		}

		const cellRendererSocioDis = (rowIndex) => {

			return  <Cell loading={this.state.cargandoDis} wrapText="false" truncated="false">{this.state.variacionSeleccionada.distribucion[rowIndex].nombre}</Cell>
					
		}

		const cellRendererNumeracionDis = (rowIndex) => {

			return  <Cell loading={this.state.cargandoDis} wrapText="false" truncated="false">{this.state.variacionSeleccionada.distribucion[rowIndex].NUMERACION_TITULOS}</Cell>
					
		}

		const cellRendererTipoTituloDis = (rowIndex) => {
			 //tipos titulo
			 let tiposKeys=Object.keys(this.obtenerPotencias(this.state.variacionSeleccionada.distribucion[rowIndex].TIPO_TITULO))
			 let tipos=""
			 tiposKeys.forEach(t=>{
				 let tipoSel=this.state.tiposParticipacion.find(tipo=>{return this.state.tipoCapital===1?tipo.ID_TIPO_ACCION===parseInt(t):tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
				 if(tipos===""){
				   tipos+=tipoSel.DESCRIPCION
				 }else{
	   
				   tipos+=","+tipoSel.DESCRIPCION
				 }
				
			 })
			

			return  <Cell loading={this.state.cargandoDis} wrapText="false" truncated="false">{tipos}</Cell>
					
		}

		const cellRendererNumTituloDis = (rowIndex) => {
			return  <Cell loading={this.state.cargandoDis} wrapText="false" truncated="false">{Math.abs(this.state.variacionSeleccionada.distribucion[rowIndex].VARIACION)}</Cell>
					
		}

		const Botonera = () => {
			return(
				<>
					<Button className="bp3-minimal" id="boton-variacion-titulo" icon="changes" text="Variaciones del valor individual del título" fill={true} onClick={abrirModalNuevo} />
					<br />
					<Button className="bp3-minimal" id="boton-aumento-titulo" icon="git-new-branch" text="Aumento del número de títulos" onClick={abrirModalAumento} />
					<br />
					<Button className="bp3-minimal" id="boton-disminucion-titulo" icon="git-merge" text="Disminución del número de títulos" onClick={abrirModalDisminucion} />
				</>
			)
		}

		const abrirModalAumento = async () => {

			await this.cargarCapital(moment().format("YYYY-MM-DD"),"aumento")

			 this.setState({
				modalAumentoAbierto: true,
				editarActivado: false,
				borrarActivado: false,
				popoverAbierto: false,
				totalAumentados:0,
				aumentosRealizados:[],
				fechaAumento:new Date(),
				regionDis:null
			})
			await this.tablaDis.clearSelection()

		}

		const abrirModalDisminucion = async() => {
			
			await this.cargarCapital(moment().format("YYYY-MM-DD"),"decremento")

			this.setState({
				modalDisminucionAbierto: true,
				editarActivado: false,
				borrarActivado: false,
				popoverAbierto: false,
				totalDisminuidos:0,
				decrementosRealizados:[],
				fechaDisminucion:new Date()
			})
		}

		const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

		const abrirModalTitulos=(e)=>{
			this.setState({
				modalTitulos:true,
			})
		}

		const abrirModalIncremento=()=>{
   
			this.setState({
				numeracionTitulos:null,
				numerosAumentar:this.state.variacionSeleccionadaAumento && this.state.variacionSeleccionadaAumento.Aumento>0?this.state.variacionSeleccionadaAumento.Aumento:1,
				modalIncremento:true,
			})

			this.setState({
				numeracionTitulos:this.state.variacionSeleccionadaAumento.numeracionVariacion
			})

		}

		const abrirModalDecremento=async()=>{
			 this.setState({
				numerosDecrementar:Math.abs(this.state.variacionSeleccionadaDecremento.Decremento),
				modalDecremento:true
			})
			await this.tablaDisDecremento.clearSelection()
		}

		const handleNumerosAumentar=(ev)=>{
			this.setState({numerosAumentar:ev.currentTarget.value})
			let mascara='0[0][0][0][0][0][0][0][0]'
			for(let i=1;i<ev.currentTarget.value;i++){
				mascara+=",0[0][0][0][0][0][0][0][0]"
			}
			this.setState({
				//numeracionTitulos:null,
				mascara:mascara
			})
		}

		const handleNumerosDecrementar=(ev)=>{
			this.setState({numerosDecrementar:ev.currentTarget.value})
		}

		const incrementarTitulos=async()=>{
			
			if(!this.state.numerosAumentar || this.state.numerosAumentar===0)return

			if(this.validarNumeracion(this.state.numeracionTitulos)===false){
				alert("Debe insertar una numeración válida.")
				return
			}

			if(this.hayRepetidos(this.state.numeracionTitulos)){
				alert("No puede haber elementos repetidos en la numeracion de los titulos.")
				return
			}

			if(this.hayCeros(this.state.numeracionTitulos)){
				alert("0 no es una numeración válida")
				return
			}

		
			let queryComp=gql`{numeracionLibreCapital(NUMERACION_TITULOS:"${this.state.numeracionTitulos}",TIPO:"I")}`

			if(this.state.editarActivado){
				if(this.state.variacionSeleccionadaAumento.ID_DISTRIBUCION){
					queryComp=gql`{numeracionLibreCapital(ID_DISTRIBUCION:${this.state.variacionSeleccionadaAumento.ID_DISTRIBUCION},ID_PERSONA:${this.props.datosDistribucion.ID_DISTRIBUCION},TIPO_ACCION:${this.props.datosDistribucion.TIPO_TITULO},NUMERACION_TITULOS:"${this.state.numeracionTitulos}",TIPO:"I")}`
				}else{
					queryComp=gql`{numeracionLibreCapital(ID_PERSONA:${this.state.variacionSeleccionadaAumento.ID_PERSONA},TIPO_ACCION:${this.state.variacionSeleccionadaAumento.TIPO_TITULO},NUMERACION_TITULOS:"${this.state.numeracionTitulos}",TIPO:"I")}`
				}
			}    
	

			//comprobar que la numeracion de título no este en uso.
			let numTitulos=await this.props.client.query({
				query:queryComp,fetchPolicy:'network-only'
			}).then(async(result)=>{
				return result.data.numeracionLibreCapital
			})

			console.log(numTitulos)

			if(numTitulos>0){
				alert("El número "+this.state.numeracionTitulos+" ya está siendo utilizado como numeración.")
				return
			}
			
			let capitalSocial=this.state.capitalSocial
			capitalSocial.find((cap)=>{
				if(cap.ID_DISTRIBUCION){

					if((cap.ID_DISTRIBUCION===this.state.variacionSeleccionadaAumento.ID_DISTRIBUCION)){
						cap.Aumento=this.state.numerosAumentar
						cap.numFinalTitulos=parseInt(cap.numTitulos)+parseInt(this.state.numerosAumentar)
						cap.numeracionVariacion=this.state.numeracionTitulos
						//ACTUALIZACION UI
						//cap.NUMERACION_TITULOS+=","+this.state.numeracionTitulos
					}

				}else{

					if((cap.ID_PERSONA===this.state.variacionSeleccionadaAumento.ID_PERSONA && this.state.variacionSeleccionadaAumento.TIPO_TITULO===cap.TIPO_TITULO)){
						cap.Aumento=this.state.numerosAumentar
						cap.numFinalTitulos=parseInt(cap.numTitulos)+parseInt(this.state.numerosAumentar)
						cap.numeracionVariacion=this.state.numeracionTitulos
						//ACTUALIZACION UI
						//cap.NUMERACION_TITULOS+=","+this.state.numeracionTitulos
					}
				}
				
				// eslint-disable-next-line array-callback-return
				return
			})

			//calculo de todos los titulos aumentados
			let totalAumentados=0
			capitalSocial.forEach(cap=>{
				totalAumentados+=parseInt(cap.Aumento)
			})

			this.setState({totalAumentados:totalAumentados})
			this.setState({capitalSocial:capitalSocial})

			//almacenamos en un array los cambios realizados
			let aumentosRealizados=this.state.aumentosRealizados
			if(this.state.variacionSeleccionadaAumento.ID_DISTRIBUCION){
				aumentosRealizados['"'+this.state.variacionSeleccionadaAumento.ID_DISTRIBUCION+'"']={persona:this.state.variacionSeleccionadaAumento.ID_PERSONA,titulos:this.state.variacionSeleccionadaAumento.TIPO_TITULO,aumento:parseInt(this.state.numerosAumentar),idDis:this.state.variacionSeleccionadaAumento.ID_DISTRIBUCION,numeracionVariacion:this.state.numeracionTitulos}
			}else{
				aumentosRealizados['"'+this.state.variacionSeleccionadaAumento.ID_PERSONA+'-'+this.state.variacionSeleccionadaAumento.TIPO_TITULO+'"']={persona:this.state.variacionSeleccionadaAumento.ID_PERSONA,titulos:this.state.variacionSeleccionadaAumento.TIPO_TITULO,aumento:parseInt(this.state.numerosAumentar),idDis:this.state.variacionSeleccionadaAumento.ID_DISTRIBUCION,numeracionVariacion:this.state.numeracionTitulos}
			}
			console.log(aumentosRealizados)
			this.setState({aumentosRealizados:aumentosRealizados})

			cerrarModal2()
		}

		const guardarAumentos=async()=>{


			if(Object.keys(this.state.aumentosRealizados).length===0){
				alert("Para guardar los cambios, debe realizar almenos alguna variación")
				return
			}
		
			if(!this.state.editarActivado){

				//mutacion para crear las variaciones.
				await this.props.client.mutate({
					mutation:gql`mutation{crearCapSocialVariacion(
						VALORES:{
							FECHA:"${moment(this.state.fechaAumento).format("YYYY-MM-DD")}",
							ID_TIPO_VARIACION:${1},
							CAMBIO_VALOR_TITULO:${null},
							CAMBIO_NUMERO_TITULOS:${this.state.totalAumentados}
						}){ID_VARIACION}}`
				}).then(async result=>{
					
					alert("Variacion creada correctamente")
						
					let aumentos=this.state.aumentosRealizados
					//insertamos las distribucion por socio.
					for(let key in aumentos){
						console.log(aumentos[key])

						//mutacion para crear las variaciones.
						await this.props.client.mutate({

							mutation:gql`mutation{crearCapSocialVariacionesDistribucion(
								VALORES:{
									ID_VARIACION:${result.data.crearCapSocialVariacion.ID_VARIACION},
									ID_PERSONA:${aumentos[key].persona},
									ID_DISTRIBUCION:${aumentos[key].idDis?aumentos[key].idDis:null},
									TIPO_TITULO:${aumentos[key].titulos},
									VARIACION:${aumentos[key].aumento},
									NUMERACION_TITULOS:"${aumentos[key].numeracionVariacion}"
								}){ID_DISTRIBUCION}}`
						}).then(async res=>{

							await this.recargarVariaciones()
							//seleccionamos el creado
							let creado=this.state.variaciones.find((variacion,index)=>{
								if(variacion.id===result.data.crearCapSocialVariacion.ID_VARIACION){
									this.setState({regionVariaciones:[Regions.row(index)]})
								}
								return variacion.id===result.data.crearCapSocialVariacion.ID_VARIACION

							})

							this.setState({
								variacionSeleccionada:creado
							})

							cerrarModal()
							
						}).catch(err=>{
							alert("Ha ocurrido un error al insertar la variación.Pongase en contacto con el soporte técnico")
						})
						
					}
				})

			}else{

				//actualizamos la variacion.
				await this.props.client.mutate({
					mutation:gql`mutation{actualizarCapSocialVariacion(
						ID_VARIACION:${this.state.variacionSeleccionada.id},
						VALORES:{
							FECHA:"${moment(this.state.fechaAumento).format("YYYY-MM-DD")}",
							CAMBIO_NUMERO_TITULOS:${this.state.totalAumentados}
						}){ID_VARIACION}}`
				}).then(async result=>{
			
					alert("Actualizado correctamente")

					//eliminamos las viejas variaciones distribucion.
					await this.props.client.mutate({
						mutation:gql`mutation{eliminarCapSocialVariacionesDistribucion(ID_VARIACION:${this.state.variacionSeleccionada.id}){ID_DISTRIBUCION}}`
					})
					
					//creamos las nuevas variaciones distribucion
					let aumentos=this.state.aumentosRealizados
					//insertamos las distribucion por socio.
					for(let key in aumentos){

						//mutacion para crear las variaciones.
						await this.props.client.mutate({
							mutation:gql`mutation{crearCapSocialVariacionesDistribucion(
								VALORES:{
									ID_VARIACION:${this.state.variacionSeleccionada.id},
									ID_DISTRIBUCION:${aumentos[key].idDis?aumentos[key].idDis:null},
									ID_PERSONA:${aumentos[key].persona},
									TIPO_TITULO:${aumentos[key].titulos},
									VARIACION:${aumentos[key].aumento}
									NUMERACION_TITULOS:"${aumentos[key].numeracionVariacion}"
								}){ID_DISTRIBUCION}}`
						})

					}
					cerrarModal()
					await this.recargarVariaciones()
					//seleccionamos el creado
					let creado=this.state.variaciones.find((variacion,index)=>{
						if(variacion.id===this.state.variacionSeleccionada.id){
							this.setState({regionVariaciones:[Regions.row(index)]})
						}
						return variacion.id===this.state.variacionSeleccionada.id

					})

					this.setState({
						variacionSeleccionada:creado
					})
					//para que se recargue la tabla de variacoines de la card de la derecha.
					let sel=this.state.variaciones.find(variacion=>{return variacion.id===this.state.variacionSeleccionada.id})
					await this.setState({variacionSeleccionada:sel})
				   
				}).catch(err=>{
					alert("Ha ocurrido un error al actualizar la variación.Pongase en contacto con el soporte técnico")
				})
			   
			}
		}

		const unionNumeros=(nums)=>{
			let arr=nums.split(",")
			for(let i = 0;i < arr.length;i++)
			{
				arr[i] = parseInt(arr[i]);
			}

			let numeros=""

			var results = [];
			var limit   = arr.length-1; 
		
			for (var i = 0; i <= limit; ++i) {
				if(i<limit){
					if((arr[i]+1)===(arr[i+1])){results.push(arr[i])}else{
						if(results.length>0){
							results.push(arr[i])
							numeros+=results[0]+"-"+results[results.length-1]+","
							results = []
						}else{
							numeros+=arr[i]+","
							results = []
						}
					}
				}else if(i===limit){
					if((arr[i]-1)===(arr[i-1])){results.push(arr[i])}else{
						if(results.length>0){
							results.push(arr[i])
							console.log(results)
							numeros+=results[0]+"-"+results[results.length-1]+","
							results = []
						}else{
							numeros+=arr[i]+","
							results = []
						}
					}
				}
			}

			if(results.length>0){
				if(numeros.includes(results[0]+"-"+results[results.length-1])===false){
					numeros+=results[0]+"-"+results[results.length-1]+","
				}
			}

			if(numeros[numeros.length-1]===",")numeros=numeros.substring(0,numeros.length-1)
			
			return numeros
		}
	   
		const decrementarTitulos=async()=>{

			if(!this.state.numerosDecrementar || this.state.numerosDecrementar===0)return
			if(this.state.numerosDecrementar>this.state.variacionSeleccionadaDecremento.numTitulos){
				alert("No hay suficientes titulos para decrementar")
				return
			}
			let capitalSocial=this.state.capitalSocial

			let capi=""
			if(this.state.variacionSeleccionadaDecremento.ID_DISTRIBUCION){
				capi= capitalSocial.find((cap)=>{
					return (cap.ID_DISTRIBUCION===this.state.variacionSeleccionadaDecremento.ID_DISTRIBUCION)
				})
			}else{
				capi= capitalSocial.find((cap)=>{
					return ((cap.ID_PERSONA===this.state.variacionSeleccionadaDecremento.ID_PERSONA && this.state.variacionSeleccionadaDecremento.TIPO_TITULO===cap.TIPO_TITULO))
				})
			}
		   
			let numerosActuales=await this.convertirNumerosArray(capi.NUMERACION_TITULOS)

			//quitamos el array el numero de titulos especificados, empezanso por el final.
			let restados=numerosActuales.splice((numerosActuales.length)-this.state.numerosDecrementar,this.state.numerosDecrementar)
			
			capi.Decremento=this.state.numerosDecrementar
			capi.numFinalTitulos=parseInt(capi.numTitulos)-parseInt(this.state.numerosDecrementar)
			capi.numeracionVariacion=this.convertirArrayNumeros(restados)
			//capi.NUMERACION_TITULOS=numeracionFinal
			//calculo de todos los titulos aumentados
			let totalDecrementados=0
			capitalSocial.forEach(cap=>{
				totalDecrementados+=Math.abs(cap.Decremento)
			})
			this.setState({totalDisminuidos:totalDecrementados})
			this.setState({capitalSocial:capitalSocial})

			//almacenamos en un array los cambios realizados
			let decrementosRealizados=this.state.decrementosRealizados
			if(this.state.variacionSeleccionadaDecremento.ID_DISTRIBUCION){
				decrementosRealizados['"'+this.state.variacionSeleccionadaDecremento.ID_DISTRIBUCION+'"']={persona:this.state.variacionSeleccionadaDecremento.ID_PERSONA,titulos:this.state.variacionSeleccionadaDecremento.TIPO_TITULO,decremento:Math.abs(this.state.numerosDecrementar),idDis:this.state.variacionSeleccionadaDecremento.ID_DISTRIBUCION,numeracionVariacion:unionNumeros(String(restados))}
			}else{
				decrementosRealizados['"'+this.state.variacionSeleccionadaDecremento.ID_PERSONA+'-'+this.state.variacionSeleccionadaDecremento.TIPO_TITULO+'"']={persona:this.state.variacionSeleccionadaDecremento.ID_PERSONA,titulos:this.state.variacionSeleccionadaDecremento.TIPO_TITULO,decremento:Math.abs(this.state.numerosDecrementar),idDis:this.state.variacionSeleccionadaDecremento.ID_DISTRIBUCION,numeracionVariacion:unionNumeros(String(restados))}
			}

			cerrarModal2()
		}

		const guardarDecrementos=async()=>{


			if(Object.keys(this.state.decrementosRealizados).length===0){
				alert("Para guardar los cambios, debe realizar almenos alguna variación")
				return
			}
		
			if(!this.state.editarActivado){

				//mutacion para crear las variaciones.
				await this.props.client.mutate({
					mutation:gql`mutation{crearCapSocialVariacion(
						VALORES:{
							FECHA:"${moment(this.state.fechaDisminucion).format("YYYY-MM-DD")}",
							ID_TIPO_VARIACION:${1},
							CAMBIO_VALOR_TITULO:${null},
							CAMBIO_NUMERO_TITULOS:${this.state.totalDisminuidos>0?-this.state.totalDisminuidos:this.state.totalDisminuidos}
						}){ID_VARIACION}}`
				}).then(async result=>{
				 
						alert("La variacion se ha insertado correctamente")

						let decrementos=this.state.decrementosRealizados
						//insertamos las distribucion por socio.
						for(let key in decrementos){
							console.log(decrementos[key])
							//mutacion para crear las variaciones.
							await this.props.client.mutate({
								mutation:gql`mutation{crearCapSocialVariacionesDistribucion(
									VALORES:{
										ID_VARIACION:${result.data.crearCapSocialVariacion.ID_VARIACION},
										ID_PERSONA:${decrementos[key].persona},
										ID_DISTRIBUCION:${decrementos[key].idDis?decrementos[key].idDis:null},
										TIPO_TITULO:${decrementos[key].titulos},
										VARIACION:${decrementos[key].decremento>0?-decrementos[key].decremento:decrementos[key].decremento},
										NUMERACION_TITULOS:"${decrementos[key].numeracionVariacion}"
									}){ID_DISTRIBUCION}}`
							}).then(async res=>{
							  
								await this.recargarVariaciones()
								//seleccionamos el creado
								let creado=this.state.variaciones.find((variacion,index)=>{
									if(variacion.id===result.data.crearCapSocialVariacion.ID_VARIACION){
										this.setState({regionVariaciones:[Regions.row(index)]})
									}
									return variacion.id===result.data.crearCapSocialVariacion.ID_VARIACION

								})

								this.setState({
									variacionSeleccionada:creado
								})

								cerrarModal()

							})
			
						}
				})

			}else{
			
				//actualizamos la variacion.
				await this.props.client.mutate({
					mutation:gql`mutation{actualizarCapSocialVariacion(
						ID_VARIACION:${this.state.variacionSeleccionada.id}
						VALORES:{
							FECHA:"${moment(this.state.fechaDisminucion).format("YYYY-MM-DD")}",
							CAMBIO_NUMERO_TITULOS:${this.state.totalDisminuidos>0?-this.state.totalDisminuidos:this.state.totalDisminuidos}
						}){ID_VARIACION}}`
				}).then(async result=>{

					alert("Actualizado correctamente")

					//eliminamos las viejas variaciones distribucion.
					await this.props.client.mutate({
						mutation:gql`mutation{eliminarCapSocialVariacionesDistribucion(ID_VARIACION:${this.state.variacionSeleccionada.id}){ID_DISTRIBUCION}}`
					})
					
					console.log(this.state.decrementosRealizados)
					//creamos las nuevas variaciones distribucion
					let decrementos=this.state.decrementosRealizados
					//insertamos las distribucion por socio.
					for(let key in decrementos){

						//mutacion para crear las variaciones.
						await this.props.client.mutate({
							mutation:gql`mutation{crearCapSocialVariacionesDistribucion(
								VALORES:{
									ID_VARIACION:${this.state.variacionSeleccionada.id},
									ID_PERSONA:${decrementos[key].persona},
									ID_DISTRIBUCION:${decrementos[key].idDis?decrementos[key].idDis:null},
									TIPO_TITULO:${decrementos[key].titulos},
									VARIACION:${decrementos[key].decremento>0?-decrementos[key].decremento:decrementos[key].decremento},
									NUMERACION_TITULOS:"${decrementos[key].numeracionVariacion}"
								}){ID_DISTRIBUCION}}`
						})

					}
					cerrarModal()
					await this.recargarVariaciones()

					//seleccionamos el creado
					let creado=this.state.variaciones.find((variacion,index)=>{
						if(variacion.id===this.state.variacionSeleccionada.id){
							this.setState({regionVariaciones:[Regions.row(index)]})
						}
						return variacion.id===this.state.variacionSeleccionada.id

					})

					this.setState({
						variacionSeleccionada:creado
					})

					//para que se recargue la tabla de variacoines de la card de la derecha.
					let sel=this.state.variaciones.find(variacion=>{return variacion.id===this.state.variacionSeleccionada.id})
					await this.setState({variacionSeleccionada:sel})
					

				})
			   
			}
		}

		const cambiarFechaAumento=async(value)=>{
			await this.setState({fechaAumento:value})
			if(this.state.editarActivado){
				await this.cargarCapitalEditar(moment(value).format("YYYY-MM-DD"),"aumento")
			}else{
				await this.cargarCapital(moment(value).format("YYYY-MM-DD"),"aumento")
			}
			await this.setState({totalAumentados:0})
			await this.setState({aumentosRealizados:[]})

		}

		const cambiarFechaDisminucion=async(value)=>{
			if(this.state.editarActivado){
				await this.cargarCapitalEditar(moment(value).format("YYYY-MM-DD"),"decremento")
			}else{
				await this.cargarCapital(moment(value).format("YYYY-MM-DD"),"decremento")
			}
			await this.setState({
				fechaDisminucion:value,
				totalDisminuidos:0,
				decrementosRealizados:[],
			})
		}

		const aceptar = async() => {

			let tipoTitulo=0
			for (let key in this.nuevoTitulo.state.activadoCheckbox) {
				//damos de alta los tipos de participaciones
				tipoTitulo+=parseInt(key.replace("\"",""))
			}

			if(!this.nuevoTitulo.state.socioSeleccionado)return

			//comprobar que no exista un socio con ese tipo de titulo.
			let existe=this.state.capitalSocial.find(cap=>{ return cap.ID_PERSONA===this.nuevoTitulo.state.socioSeleccionado.value && cap.TIPO_TITULO===tipoTitulo})
			if(existe){
				alert("Ya existe este socio con este tipo de título.")
				return
			}

			await this.nuevoTitulo.aceptarDistribución()
			//si se ha creado el titulo
			if(this.nuevoTitulo.state.tituloCreado){
				await this.cargarCapital(this.state.variacionSeleccionada?moment(this.state.variacionSeleccionada.fecha).format("YYYY-MM-DD"):moment().format("YYYY-MM-DD"),(this.state.modalAumentoAbierto?"aument":"decremento"))
				cerrarModalTitulo()
				//seleccionamos titulos marcado
				// eslint-disable-next-line array-callback-return
				await this.state.capitalSocial.find((cap,index)=>{if(cap.ID_DISTRIBUCION===this.nuevoTitulo.state.tituloCreado){
					this.setState({regionDis:[Regions.row(index)]})//seleccionamos en la tabla la nueva fila creada.
				}})

			}
		 
		}

/*          esto soltaba un warning

			const cambiado=(ev)=>{

			console.log(ev.currentTarget.value)
			this.setState({numeracionTitulos:ev.currentTarget.value})
			console.log(ev.currentTarget.value)
		}
	
		const onSeleccionarNumeracionEliminar=(value)=>{
			this.setState({
				numeracionSeleccionadasEliminar:value,
				numerosDecrementar:value.length
			})
		}
		*/

		const camibarNumtParticipaciones=(ev)=>{

			//si la nuemeracion es válida.
		  let numeros=[]
		  let numeracion=ev.currentTarget.value
		  if(numeracion.includes(",")){
			numeracion=numeracion.split(",")
		  }else{
			numeracion=[numeracion]
		  }
		  numeracion.forEach(num=>{
			if(num.includes("-")){
				let dis=num.split("-")
				for(let i=parseInt(dis[0]);i<=parseInt(dis[1]);i++){
					numeros.push(String(i))
				}
			}else{
				numeros.push(num)
			}

		})

		this.setState({numerosAumentar:numeros.length,numeracionTitulos:ev.currentTarget.value})
		 
		}
	
/*         var digitsMask = new IMask.createMask( {
		mask:this.state.mascara,
		lazy:false,
		placeholderChar: '_'
		}); */

		const handleJoyrideCallback=async(data)=>{
			
			const { action, index, status, type } = data;
		 
			if(data.action==="close"){

				localStorage.setItem("ayuda",false)
				this.setState({
					popoverAbierto:false,
					modalNuevoAbierto:false,
					modalAumentoAbierto:false,
					modalIncremento:false,
					modalDisminucionAbierto:false,
					modalDecremento:false
				})

			}else{

				if ([EVENTS.STEP_AFTER].includes(type)) {
	
					let paso=index+ (action === ACTIONS.PREV ? -1 : 1)
	
					//paso en el que nos encontremos.
					// eslint-disable-next-line default-case
					switch(paso){
						
						case 0:
							await this.setState({popoverAbierto:false})
						break;
						case 1:
							await this.setState({popoverAbierto:true,modalNuevoAbierto:false})
						break;
						case 2:
							await this.setState({modalNuevoAbierto:true})
						break;
						case 3:
							await this.setState({modalNuevoAbierto:false,modalAumentoAbierto:false})
						break;
						case 4:
							this.cargarCapital()
							await this.setState({modalAumentoAbierto:true,modalIncremento:false})
						break;
						case 5:
							await this.setState({modalIncremento:true})
						break;
						case 6:
							await this.setState({modalAumentoAbierto:false,modalIncremento:false,modalDisminucionAbierto:false})
						break;
						case 7:
							await this.setState({modalDisminucionAbierto:true,modalDecremento:false})
	
						break;
						case 8:
							await this.setState({modalDecremento:true})
						break;
						case 9:
							await this.setState({modalDisminucionAbierto:true,modalDecremento:false})
						break;
						case 10:
							await this.setState({modalDisminucionAbierto:false,modalDecremento:false})
						break;
	
	
					 
					}
			  
					await this.setState({ pasoGuia: paso });   
		
		
				}
		
				//si termina la guia.
				if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
					localStorage.setItem("ayuda",false)
				}

			}
	
		   
	
		}
		const cerrarModalImprimir=()=>{
			this.setState({
				modalImprimirVS:false,
				modalImprimirGral:false
			})
		}

		return (
			<>
			{
				this.state.cargando === true?
				<Cargando />
				:
				<>
				<Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Variación seleccionada"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirVS}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirVS?
                            <div style={{ width: '100%', height: '600px'}} id='generarImprimirVS-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Informe de Variaciones"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirGral}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirGral?
                            <div style={{ width: '100%', height: '600px'}} id='generarImprimirGral-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
				<Joyride
					run={localStorage.getItem("ayuda")==="true"?true:false}
					callback={handleJoyrideCallback}
					showProgress={true}
					stepIndex={this.state.pasoGuia}
					steps={steps}
					continuous ={true}
					locale={{ back: 'Volver', close: 'Cerrar', last: 'Último', next: 'Siguiente', skip: 'Saltar' }}
					styles={{
						options: {
							arrowColor: '#e3ffeb',
							backgroundColor: '#e3ffeb',
							primaryColor: '#E31E24',
							}
					}}
				/>
				<Dialog 
					transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="info-sign"
					onClose={cerrarModal}
					title="Añadir variacion"
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					usePortal={true}
					onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
					isOpen={this.state.modalNuevoAbierto}
				>
					<Card interactive={true} elevation={Elevation.TWO}>
						<div id="dialogo-variacion-capital">
							<FormGroup
								label="Fecha efecto"
							>
								<div id="fecha-efecto">
									<DateInput minDate={new Date(1900, 1 ,1)}
										{...getMomentFormatter("DD-MM-YYYY")} locale="es" 
										onChange={escogeFecha}
										defaultValue={new Date()} 
									/>
								</div>
							</FormGroup>
							<FormGroup
								label="Valor actual"
							>
								<InputGroup id="valor-actual" disabled value={ Intl.NumberFormat('es-ES').format(this.state.valorAnterior)} intent="primary" />
							</FormGroup>
							<FormGroup
								label="Nuevo valor"
								intent="danger"
								helperText={!this.state.valorFinal?"Debe insertar el valor":!this.state.valorValido?"Debe insertar un valor válido":""}
							>
								<InputGroup id="valor-nuevo" intent={!this.state.valorFinal || !this.state.valorValido?"danger":"primary"} type="string" placeholder="Nuevo valor" value={this.state.val} onChange={cambiarValor}/>
							</FormGroup>
						</div>
					</Card>
					<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Tooltip content="Cerrar y volver." position="top">
							<Button onClick={cerrarModal}>Cerrar</Button>
						</Tooltip>
						<Tooltip content="Añadir variacion." position="top">
							<AnchorButton
								intent={Intent.PRIMARY}
								onClick={añadirVariacion}
							>
								Añadir variacion
							</AnchorButton>
						</Tooltip>
					</div>
				</div>
				</Dialog>
				<Dialog 
					transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="info-sign"
					onClose={cerrarModal}
					title="Editar variacion"
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					usePortal={true}
					isOpen={this.state.modalEditarAbierto}
				>
				<Card interactive={true} elevation={Elevation.TWO}>
					<div>
						<FormGroup
							label="Fecha efecto"
						>
							<DateInput minDate={new Date(1900, 1 ,1)}
								{...getMomentFormatter("DD-MM-YYYY")} locale="es" 
								onChange={escogeFecha}
								value={this.state.fechaVariacion} 
							/>
						</FormGroup>
						<FormGroup
									label="Valor anterior"
						>
							<InputGroup id="valor-medio" disabled value={ Intl.NumberFormat('es-ES').format(this.state.valorAnterior)} intent="primary" />
						</FormGroup>
						<FormGroup
									label="Valor actual"
						></FormGroup>
						<FormGroup
							label="Nuevo valor"
							intent="danger"
							helperText={!this.state.valorFinal?"Debe insertar el valor":!this.state.valorValido?"Debe insertar un valor válido":""}
						>
							<InputGroup id="valor-medio" value={this.state.val} placeholder="Nuevo valor" onChange={cambiarValor} intent="primary" />
						</FormGroup>

					</div>
				</Card>
				<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Tooltip content="Cerrar y volver." position="top">
						<Button onClick={cerrarModal}>Cerrar</Button>
					</Tooltip>
					<Tooltip content="Editar variacion." position="top">
						<AnchorButton
							intent={Intent.PRIMARY}
							onClick={editarVariacion}
						>
							Editar variacion
						</AnchorButton>
					</Tooltip>
				</div>
			</div>
			</Dialog>
				<Dialog 
					transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="warning-sign"
					onClose={cerrarModal}
					title="Borrar una cuenta"
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					usePortal={true}
					isOpen={this.state.modalBorrarAbierto}
				>
					<div className={Classes.DIALOG_BODY}>
						<h2>¿Desea eliminar la variacion seleccionada?</h2>
					</div>
					<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Tooltip content="Eliminar variacion." position="top">
							<AnchorButton
								intent={Intent.PRIMARY}
								onClick={eliminarVariacion}
							>
								Si
							</AnchorButton>
						</Tooltip>
						<Tooltip content="Cerrar y volver." position="top">
							<Button onClick={cerrarModal}>No</Button>
						</Tooltip>
					</div>
				</div>
			</Dialog>
				<Dialog 
					transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="warning-sign"
					onClose={cerrarModal}
					title="Aumento del número de títulos"
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					usePortal={true}
					onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
					isOpen={this.state.modalAumentoAbierto}
					className="dialog-ancho"
				>
					<div className={Classes.DIALOG_BODY}>
						<Card interactive={true} elevation={Elevation.TWO}>
							<FormGroup
								label="Fecha de valor de cambio:"
								labelFor="fecha-cambio"
								inline={true}
								className="pixelAlrededor" 
							>   
								<div id="fecha-efecto-aumento">
									<DateInput minDate={new Date(1900, 1 ,1)}
										{...jsDateFormatter} 
										onChange={cambiarFechaAumento}
										defaultValue={new Date()}
										align={Alignment.RIGHT}
										value={this.state.fechaAumento}
									/>
								</div>
							</FormGroup>
							<FormGroup
								label="Número total de títulos en los que aumenta el capital social:"
								labelFor=""
								inline={true}
								className="pixelAlrededor" 
							>
								<InputGroup id="total-aumentados" readOnly={true} value={this.state.totalAumentados} />
							</FormGroup>
							<p>Indique el aumento, socio a socio, del número de títulos. Haga doble click sobre una fila para editarla. Si el aumento se ha producido sobre un nuevo socio o tipo de socios, haga click <button onClick={abrirModalTitulos}>aqui</button>.</p>
							<div id="tabla-aumento">
								<Table defaultColumnWidth={200} selectedRegions={this.state.regionDis} ref={(input) => { this.tablaDis= input; }} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} onSelection={seleccionarVariacionAumento} numRows={this.state.capitalSocial.length} className="acortarTablaRebelde">
									<Column name="Socio"  cellRenderer={cellRendererSocioAumento}/>   
									<Column name="Tipo de Titulos" cellRenderer={cellRendererTipoTitulosAumento}/>   
									<Column name="Número de Titulos" cellRenderer={cellRendererNumeroTitulosAumento}/>   
									<Column name="Númeracion de títulos" cellRenderer={cellRendererNumeracionAumento}/>  
									<Column name="Númeracion de títulos aumentados" cellRenderer={cellRendererNumeracionAumentoAumentados}/>    
									<Column name="Aumento" cellRenderer={cellRendererAumento}/>   
									<Column name="Nº final de títulos" cellRenderer={cellRendererNumFinalAumento}/>   
								</Table>
							</div>
							<br />
							<br />
						</Card>
					</div>
					<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Tooltip content="Aumentar títulos" position="top">
							<AnchorButton
								id="aceptar-aumentos"
								intent={Intent.PRIMARY}
								onClick={guardarAumentos}
							>
								Aceptar
							</AnchorButton>
						</Tooltip>
						<Tooltip content="Cerrar y volver." position="top">
							<Button onClick={cerrarModal}>Cancelar</Button>
						</Tooltip>
					</div>
				</div>
			</Dialog>
				<Dialog 
					transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="warning-sign"
					onClose={cerrarModal}
					title="Disminución del número de títulos"
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					usePortal={true}
					onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
					isOpen={this.state.modalDisminucionAbierto}
					className="dialog-ancho"
				>
					<div className={Classes.DIALOG_BODY}>
						<Card interactive={true} elevation={Elevation.TWO}>
							<FormGroup
								label="Fecha de valor de cambio:"
								labelFor="fecha-cambio"
								inline={true}
								className="pixelAlrededor" 
							>
								<DateInput minDate={new Date(1900, 1 ,1)}
									{...jsDateFormatter} 
									onChange={cambiarFechaDisminucion}
									defaultValue={new Date()}
									align={Alignment.RIGHT}
									value={this.state.fechaDisminucion}
								/>
							</FormGroup>
							<FormGroup
								label="Número total de títulos en los que disminuye el capital social:"
								labelFor="numero-disminucion"
								inline={true}
								className="pixelAlrededor" 
							>
								<InputGroup value={Math.abs(this.state.totalDisminuidos)} readOnly={true} />
							</FormGroup>
							<p>Indique la Disminución, socio a socio, del número de títulos. Haga doble click sobre una fila para editarla. Si la disminución se ha producido sobre un nuevo socio o tipo de socios, haga click <button onClick={abrirModalTitulos}>aqui</button>.</p>
							<div id="tabla-decrementos">
								<Table defaultColumnWidth={200} selectedRegions={this.state.regionDis} ref={(input) => { this.tablaDisDecremento= input; }} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} onSelection={seleccionarVariacionDisminucion} numRows={this.state.capitalSocial.length} className="acortarTablaRebelde">
									<Column name="Socio"  cellRenderer={cellRendererSocioDisminucion}/>   
									<Column name="Tipo de Titulos" cellRenderer={cellRendererTipoTitulosDisminucion}/>   
									<Column name="Número de Titulos" cellRenderer={cellRendererNumeroTitulosDecremento}/>   
									<Column name="Númeracion de títulos" cellRenderer={cellRendererNumeracionDisminucion}/>  
									<Column name="Númeracion de títulos decrementados" cellRenderer={cellRendererNumeracionDisminucionDecrementados}/>    
									<Column name="Disminución" cellRenderer={cellRendererDisminucion}/>   
									<Column name="Nº final de títulos" cellRenderer={cellRendererNumFinalDisminucion}/>   
								</Table>
							</div>
							<br />
							<br />
						</Card>
					</div>
					<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Tooltip content="Disminuir títulos?" position="top">
							<AnchorButton
								id="aceptar-decrementos"
								intent={Intent.PRIMARY}
								onClick={guardarDecrementos}
							>
								Aceptar
							</AnchorButton>
						</Tooltip>
						<Tooltip content="Cerrar y volver." position="top">
							<Button onClick={cerrarModal}>Cancelar</Button>
						</Tooltip>
					</div>
				</div>
			</Dialog>
			<Dialog transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="info-sign"
					onClose={cerrarModal2}
					title="Aumento de titulo"
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					usePortal={true}
					onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
					isOpen={this.state.modalIncremento}
				>
					<div className={Classes.DIALOG_BODY}>
						<FormGroup
						label="Indíque el número de títulos a incrementar"
						labelFor="informacion"
						intent="danger"
						helperText={this.state.numerosAumentar?"":"Debe insertar el número de titulos aumentar"}
						>
							<InputGroup type="number" min={1} step="1" id="texto-numero-aumentar" readOnly={true} onChange={handleNumerosAumentar} value={this.state.numerosAumentar}  placeholder="Escriba aqui el número de título a aumentar" intent={this.state.numerosAumentar?"primary":"danger"} />
						</FormGroup>
						<FormGroup
							label="Numeración de títulos:"
							labelFor="participaciones"
						>
							<InputGroup id="numeracion-aumentar" onChange={camibarNumtParticipaciones} value={this.state.numeracionTitulos}/>
						</FormGroup>

					</div>
					<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Tooltip content="Aumentar títulos." position="top">
							<AnchorButton
								intent={Intent.PRIMARY}
								onClick={incrementarTitulos}
								id="guardar-aumento"
							>
								Aceptar
							</AnchorButton>
						</Tooltip>
						<Tooltip content="Cerrar y volver." position="top">
							<Button onClick={cerrarModal2}>Cancelar</Button>
						</Tooltip>
					</div>
				</div>
				</Dialog>
				<Dialog transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="info-sign"
					onClose={cerrarModal2}
					title="Decremento de titulo"
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
					usePortal={true}
					isOpen={this.state.modalDecremento}
				>
					<div className={Classes.DIALOG_BODY}>
						<FormGroup
						label="Indíque el número de títulos a decrementar"
						labelFor="informacion"
						>
							<InputGroup id="titulos-decrementar" type="number" min={1} step="1" onChange={handleNumerosDecrementar} value={this.state.numerosDecrementar} />
						</FormGroup>
					
					</div>
					<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Tooltip content="Aumentar títulos." position="top">
							<AnchorButton
								intent={Intent.PRIMARY}
								onClick={decrementarTitulos}
							>
								Aceptar
							</AnchorButton>
						</Tooltip>
						<Tooltip content="Cerrar y volver." position="top">
							<Button onClick={cerrarModal2}>Cancelar</Button>
						</Tooltip>
					</div>
				</div>
				</Dialog>
				<Dialog
					transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="warning-sign"
					onClose={cerrarModalTitulo}
					title="Distribución del capital social."
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					usePortal={true}
					isOpen={this.state.modalTitulos}
				>
				<NuevoTituloSocios datosDistribucion={this.state.distribucionSeleccionada} ref={(input) => { this.nuevoTitulo= input; }} />
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Tooltip
						content="Aceptar distribución del capital social."
						position="top"
					>
						<AnchorButton
						intent={Intent.PRIMARY}
						onClick={aceptar}
						>
						Aceptar
						</AnchorButton>
					</Tooltip>
					<Tooltip content="Cerrar y volver." position="top">
						<Button onClick={cerrarModalTitulo}>Cancelar</Button>
					</Tooltip>
					</div>
				</div>
				</Dialog>
				<div className="tablaMitadIzquierda pixelAlrededor">
					<Card interactive={true} elevation={Elevation.TWO}>
						<Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Listado de variaciones del capital social</Callout>
						<Navbar>
						<Navbar.Group>
							<ButtonGroup align={Alignment.LEFT}>
								<Popover onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}} isOpen={this.state.popoverAbierto} position="top-left" content={<Botonera />} transitionDuration={400} target={<Button id="boton-nueva-variacion"  disabled={this.state.editando} icon="add" text="Nuevo" className="bp3-minimal" active={this.state.abrirModalNuevo} onClick={abrirPopover} />} />
								<Button className="bp3-minimal" icon="edit" id="boton-editar-variacion" text="Editar" active={this.state.editarActivado} onClick={abrirModalEditar}/>
								<Button className="bp3-minimal" icon="delete" id="boton-borrar-variacion" text="Borrar" active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
								<Tooltip content="Desea imprimir la variación seleccionada" position="right" >
                            		<Button className="bp3-minimal" icon="print" disabled={this.state.variacionSeleccionada?false:true}  text="Imprimir Variación" onClick={()=>this.generarImprimirVS()}/>
                            	</Tooltip>
                        		<Tooltip content="Desea imprimir todas las variaciones" position="right" >
                            		<Button className="bp3-minimal" icon="print" text="Imprimir General" onClick={()=>this.generarImprimirGral()}/>
                        		</Tooltip>							
							</ButtonGroup>
						</Navbar.Group>
					</Navbar>
					<div id="tabla-variaciones">
						<Table onSelection={handleSeleccionarVariacion} ref={(input) => { this.tablaVariaciones= input; }} selectedRegions={this.state.regionVariaciones} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} columnWidths={this.state.columnWidths} numRows={this.state.variaciones.length} onCompleteRender={() => {
                            if (!this.state.resized) {
                                this.resizeColumns();
                            }
                        }}>
							<Column name="Fecha"  cellRenderer={cellRendererFecha}/>   
							<Column name="Tipo de variación" cellRenderer={cellRendererTipo}/>   
						</Table>
					</div>
					</Card>
				</div>
				<div className="tablaMitadDerecha pixelAlrededor">
					<Card id="detalle-variacion" interactive={true} elevation={Elevation.TWO}>
						{this.state.variacionSeleccionada && this.state.variacionSeleccionada.idTipo===2?
							<React.Fragment>
								<Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Información sobre la variación del capital social</Callout>
								<TextArea
									fill={true}
									intent={Intent.PRIMARY}
									disabled={true}
									value={"Con fecha: " +(this.state.variacionSeleccionada && this.state.variacionSeleccionada.idTipo===2?moment(this.state.variacionSeleccionada.fecha).format("DD-MM-YYYY"):"") + " el valor individual del título pasó de: " + (this.state.modalNuevoAbierto?"":parseFloat(this.state.valorAnterior).toFixed(2) ) + " a " + (this.state.modalNuevoAbierto?"":parseFloat(this.state.valorAnterior+this.state.variacionSeleccionada.valorFinal).toFixed(2)) }
								/> 
							</React.Fragment>
						:
							
							{...this.state.variacionSeleccionada && this.state.variacionSeleccionada.idTipo===1?
								<React.Fragment>
									<Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Con fecha <b>{moment(this.state.variacionSeleccionada.fecha).format("DD-MM-YYYY")}</b> se ha producido {this.state.variacionSeleccionada.numTitulos>0?"un incremento":"una disminución"} en el número de títulos con la siguiente distribución:</Callout>
									<Table defaultColumnWidth={200} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} numRows={this.state.variacionSeleccionada.distribucion.length} className="acortarTablaRebelde">
										<Column name="Socio" cellRenderer={cellRendererSocioDis}/>   
										<Column name="Tipo de Titulos" cellRenderer={cellRendererTipoTituloDis} />   
										<Column name="Numeración de Titulos" cellRenderer={cellRendererNumeracionDis} />   
										<Column name={this.state.variacionSeleccionada.numTitulos>0?"Aumento":"Disminución"} cellRenderer={cellRendererNumTituloDis} />    
									</Table> 
								</React.Fragment>
							:
								<p>Seleccione una opción del captial social en la lista de la izquierda para ver información sobre la misma</p>
							}
						}
						
					</Card>
				</div>

			</>
			}
			</>
		)
	}
}
export default withApollo(Variaciones)