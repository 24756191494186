import React, { Component } from 'react';
import { Callout, Card, FormGroup, Navbar, ButtonGroup, Alignment, Button, NavbarDivider, Dialog, Classes, Tooltip, AnchorButton, Intent, Elevation, TextArea, Spinner } from "@blueprintjs/core"
import { Table, Column, Cell, Regions,RegionCardinality,SelectionModes } from "@blueprintjs/table"
import Select from "react-select"
import gql from "graphql-tag";
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas"
import { withApollo } from 'react-apollo';
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from 'i18next';
import styles from './stylesPrint';

class PersonalAltaDireccion extends Component {

    state={
        nuevoActivado: false,
        editarActivado: false,
        borrarActivado: false,
        personaSeleccionada:false,
        pendienteGuardar:false,
        pendienteActualizar:false,
        pendienteBorrar:false,
        atribucion:null,
        retribucion:null,
        indemnizaciones:null,
        personas:[],
        personal: [
        ],
        abierto: false,
        personalAltaSeleccionado:null,
        regionSeleccionada:null,
        cargandoForm:false,
        columnWidths: [100],
        resized: false,
        datosEmpresa:null,
        cargandoImprimirP:false,
        modalImprimirP: false,
        cargandoImprimirPG:false,
        modalImprimirPG: false,
        logotipo:null,
    }

    resizeColumns() {
        
        let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        for (let index = 0; index < this.tablaPersonal.locator.grid.numCols; index++) {
            totalMinSizeReq = totalMinSizeReq + this.tablaPersonal.locator.getWidestVisibleCellInColumn(index);
            cumulativeColumnWidths.push(this.tablaPersonal.locator.getWidestVisibleCellInColumn(index));
        }
        if (totalMinSizeReq > containerSize) {
            this.setState({
                columnWidths: cumulativeColumnWidths,
                resized: true
            });
        } else {
            let diff = ((containerSize - totalMinSizeReq) / this.tablaPersonal.locator.grid.numCols);
            this.setState({
                columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                resized: true
            });
        }
        
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }
    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        this.setState({
            datosEmpresa:datosEmpresa
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

    obtenerNombrePersonal=async(personal)=>{
        await this.props.client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${personal.ID_PERSONAL_ALTA_DIRECCION}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            personal.nombre=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
        })
    }

    cargarPersonal=async ()=>{

        let personal=await this.props.client.query({
            query:gql`{personalAltaDireccion{ID_PERSONAL_ALTA_DIRECCION,ATRIBUCIONES,RETRIBUCION,INDEMNIZACIONES}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
           return JSON.parse(JSON.stringify(result.data.personalAltaDireccion))
        })

        //console.log(result)
        let index=0

        for(let persona of personal){
             let nombre='';   
            //await this.obtenerNombrePersonal(persona)
            // eslint-disable-next-line array-callback-return
            this.state.personas.find((pers)=>{
                if (pers.value===persona.ID_PERSONAL_ALTA_DIRECCION) {
                    nombre=pers.label;
                }
            })
            personal[index].nombre=nombre
            personal[index].id=persona.ID_PERSONAL_ALTA_DIRECCION
            personal[index].atribucion=persona.ATRIBUCIONES
            personal[index].retribucion=persona.RETRIBUCION
            personal[index].indemnizaciones=persona.INDEMNIZACIONES

            index++
        }

        this.setState({personal:personal})

    }

    cargarPersonas=async()=>{
        await this.props.client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
            let personas=[]
            result.data.personasFisicas.forEach(async(persona)=>{
                
                personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`}) 

            })
            this.setState({personas:personas})
        })
    }
    componentDidMount(props){
        this.setState({
            cargandoForm: true
        },async () => {
            await this.cargarPersonas()
            await this.cargarPersonal()
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            this.setState({cargandoForm: false})
        });
    }

    generarImprimirP= async() => {
        this.setState({
            cargandoImprimirP:true,
            modalImprimirP:true
        }, async () => {
             this.setState({cargandoImprimirP:false}, () => {
                 ReactDOM.render(this.ImprimirP(), document.getElementById('generarP-pdf'))
             })
        })
    }

    generarImprimirPG= async() => {
        this.setState({
            cargandoImprimirPG:true,
            modalImprimirPG:true
        }, async () => {
             this.setState({cargandoImprimirPG:false}, () => {
                 ReactDOM.render(this.ImprimirPG(), document.getElementById('generarPG-pdf'))
             })
        })
    }

    ImprimirP=()=>{
        let personalSel=this.state.personas.find(persona=>{return persona.value===this.state.personalAltaSeleccionado.id})
                        
        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>PERSONAL ALTA DIRECCIÓN</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>PERSONAL</Text>
                </View>
                    {this.state.personalAltaSeleccionado?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>PERSONA:</Text>
                                    <Text style={styles.textData}>{personalSel.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>RETRIBUCIÓN:</Text>
                                    <Text style={styles.textData}>{this.state.personalAltaSeleccionado.retribucion}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>ATRIBUCIONES:</Text>
                                    <Text style={styles.textData}>{this.state.personalAltaSeleccionado.atribucion}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>INDEMNIZACIONES:</Text>
                                    <Text style={styles.textData}>{this.state.personalAltaSeleccionado.indemnizaciones}</Text>
                                </View>
                                
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    }

    ImprimirPG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>PERSONAL ALTA DIRECCIÓN</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>LISTA DE PERSONAL</Text>
                </View>
                    {this.state.personal?this.state.personal.map((pers, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>NOMBRE</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{pers.nombre?pers.nombre:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    render() {

            document.title ="LexControl | Personal alta dirección"

            const abrirModalNuevo = () => {

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert("No tienes permisos para realizar esta acción")
                    return
                }

                this.setState({
                    modalAbierto: true,
                    personaSeleccionada:null,
                    personalAltaSeleccionado:null,
                    nuevoActivado:true,
                    editarActivado:false,
                    borrarActivado:false,
                    atribucion:null,
                    retribucion:null,
                    indemnizaciones:null,
                    regionSeleccionada:null,
                },()=>{this.tablaPersonal.clearSelection()})
            }

            const abrirModalEditar = () => {

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert("No tienes permisos para realizar esta acción")
                    return
                }

                if(!this.state.personalAltaSeleccionado){
                    alert("Debe seleccionar un personal")
                    return
                }

                let personalSel=this.state.personas.find(persona=>{return persona.value===this.state.personalAltaSeleccionado.id})
                
                this.setState({
                    modalAbierto: true,
                    personaSeleccionada:personalSel,
                    nuevoActivado:false,
                    editarActivado:true,
                    borrarActivado:false,
                    atribucion:this.state.personalAltaSeleccionado.atribucion,
                    retribucion:this.state.personalAltaSeleccionado.retribucion,
                    indemnizaciones:this.state.personalAltaSeleccionado.indemnizaciones,

                })
            }

            const abrirModalBorrar = () => {

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert("No tienes permisos para realizar esta acción")
                    return
                }
                
                if(!this.state.personalAltaSeleccionado){
                    alert("Debe seleccionar un personal")
                    return
                }
                this.setState({modalBorrarAbierto: true})
            }

            const cellRendererNombre = (rowIndex) => {
                    return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} wrapText="false" truncated="false">{`${this.state.personal[rowIndex].nombre}`}</Cell>
            }

            const seleccionPersona  = (value) => { 
                this.setState({personaSeleccionada: value})
            }

            const cambiarAtribucion  = (ev) => { 
                this.setState({atribucion: ev.currentTarget.value})
            }

            const cambiarRetribucion  = (ev) => { 
                this.setState({retribucion: ev.currentTarget.value})
            }

            const cambiarIndemnizaciones  = (ev) => { 
                this.setState({indemnizaciones: ev.currentTarget.value})
            }

            const cerrarModal = () => {
                this.setState({
                    modalAbierto: false,
                    modalBorrarAbierto: false,
                })
            }

            const handleSeleccionarPersonal = (value) => {

                if(value.length===0)return

                let seleccionada=this.state.personal[value[0].rows[0]]            
                this.setState({
                    personalAltaSeleccionado:seleccionada,
                    regionSeleccionada:value
                })
            }

            
            const añadirNuevo = (value) => {
                
                if(!this.state.personaSeleccionada){
                    return
                }

                //comporbacion de que existe en la tabla.
                let existe=this.state.personal.find(personal=>{return personal.id===this.state.personaSeleccionada.value})
                if(existe){
                    alert("Esta persona ya esta dada de alta como personal")
                    return
                }

                this.setState({pendienteGuardar:true})
                this.setState({controlesDesactivados:true})
                this.setState({regionSeleccionada:null})
                
                this.state.personal.push({
                    "id":0,
                    "nombre": this.state.personaSeleccionada.label
                })

                this.tablaPersonal.clearSelection()
                
                cerrarModal()
            }

            const actualizarPersonal = () => {

                if(!this.state.personaSeleccionada){
                    return
                }

                let personal=this.state.personal
                this.state.personal.find((pAlta,index) =>{
                    if(pAlta.id===this.state.personalAltaSeleccionado.id){
                        personal[index]["nombre"]=this.state.personaSeleccionada?this.state.personaSeleccionada.label:this.state.personalAltaSeleccionado.nombre
                    }
                    return pAlta.id===this.state.personalAltaSeleccionado.id
                })
                this.setState({pendienteActualizar:true})
                this.setState({controlesDesactivados:true})
                this.setState({personal:personal})
                this.setState({regionSeleccionada:null})
                this.tablaPersonal.clearSelection()

                cerrarModal()
            }

            const elimiaranPersonalAlta = () => {
                let personal=this.state.personal.filter(personal => personal.id !== this.state.personalAltaSeleccionado.id)
                this.setState({personal:personal})
                this.setState({pendienteBorrar:true})     
                this.setState({controlesDesactivados:true})
                this.setState({regionSeleccionada:true},()=>{this.tablaPersonal.clearSelection()})

                cerrarModal()
            };

            const guardarCambios=()=>{

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert("No tienes permisos para realizar esta acción")
                    return
                }
                
                if(this.state.pendienteGuardar){

                    this.props.client.mutate({
                        mutation:gql`mutation{crearPersonalAltaDireccion(VALORES:{ID_PERSONAL_ALTA_DIRECCION:${this.state.personaSeleccionada.value},ATRIBUCIONES:${this.state.atribucion?'"""'+this.state.atribucion+'"""':null},RETRIBUCION:${this.state.retribucion?'"""'+this.state.retribucion+'"""':null},INDEMNIZACIONES:${this.state.indemnizaciones?'"""'+this.state.indemnizaciones+'"""':null}}){ID_PERSONAL_ALTA_DIRECCION}}`
                    }).then(async result=>{
                     
                        alert("El registro se ha insertado correctamente.")
                        await this.cargarPersonal()

                        //reseleccionamos la que teniamos seleccionada
                        let sel=this.state.personal.find((persona,index)=>{
                            if(persona.id===result.data.crearPersonalAltaDireccion.ID_PERSONAL_ALTA_DIRECCION){
                                this.setState({regionSeleccionada:[Regions.row(index)]})
                            }
                            return persona.id===result.data.crearPersonalAltaDireccion.ID_PERSONAL_ALTA_DIRECCION

                        })
                        this.setState({personalAltaSeleccionado:sel})

                    }).catch(err=>{
                        alert("Ha ocurrido un error al crear el personal de alta direccion.Pongase en contacto con el soporte")
                    })

                    this.setState({pendienteGuardar:false})

                }else if(this.state.pendienteActualizar){

                    this.props.client.mutate({
                        mutation:gql`mutation{actualizarPersonalAltaDireccion(ID_PERSONAL_ALTA_DIRECCION:${this.state.personalAltaSeleccionado.id},VALORES:{ATRIBUCIONES:${this.state.atribucion?'"""'+this.state.atribucion+'"""':null},RETRIBUCION:${this.state.retribucion?'"""'+this.state.retribucion+'"""':null},INDEMNIZACIONES:${this.state.indemnizaciones?'"""'+this.state.indemnizaciones+'"""':null}}){ID_PERSONAL_ALTA_DIRECCION}}`
                    }).then(async result=>{

                        alert("El registro se ha actualizado correctamente.")
                        await this.cargarPersonal()
                        
                        //reseleccionamos la que teniamos seleccionada
                        let sel=this.state.personal.find((persona,index)=>{ 
                            if(persona.id===this.state.personalAltaSeleccionado.id){
                                this.setState({regionSeleccionada:[Regions.row(index)]})
                            }
                            return persona.id===this.state.personalAltaSeleccionado.id

                        })
                        this.setState({personalAltaSeleccionado:sel})

                    }).catch(err=>{
                        alert("Ha ocurrido un error al actualizar el personal de alta direccion.Pongase en contacto con el soporte")
                    })


                    this.setState({pendienteActualizar:false})

                }else if(this.state.pendienteBorrar){

                    this.props.client.mutate({
                        mutation:gql`mutation{eliminarPersonalAltaDireccion(ID_PERSONAL_ALTA_DIRECCION:${this.state.personalAltaSeleccionado.id}){ID_PERSONAL_ALTA_DIRECCION}}`
                    }).then(result=>{
                        alert("El registro se ha eliminado correctamente.")
                        this.cargarPersonal()
                        this.setState({
                            pendienteBorrar:false,
                            personalAltaSeleccionado:null
                        })

                    }).catch(err=>{
                        alert("Ha ocurrido un error al eliminar el personal de alta direccion.Pongase en contacto con el soporte")
                    })
                }

                this.setState({controlesDesactivados:false})

        
            }


            const cancelarCambios=()=>{

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert("No tienes permisos para realizar esta acción")
                    return
                }
                
                this.setState({
                    personaSeleccionada:null,
                    personalAltaSeleccionado:null,
                    atribucion:null,
                    retribucion:null,
                    indemnizaciones:null,
                    pendienteGuardar:false,
                    pendienteActualizar:false,
                    pendienteBorrar:false,
                    controlesDesactivados:false,
                    regionSeleccionada:null
                },()=>{this.tablaPersonal.clearSelection()})

                this.cargarPersonal()
            }

            const abrirModalNuevaPersona=()=>{
    
                //abrir modal de persona física
                this.setState({modalPersonaAbierto:true})
                this.setState({popoverAbierto:false})
                this.setState({nuevaPresonaActivado:true})
                this.setState({editarPersonaActivado:false})
    
            }
    
            const cerrarModal2 = () => {
                this.setState({
                    modalPersonaAbierto: false,
                })
            }

            
            const abrirModalEditarPersona=()=>{

                if(!this.state.personaSeleccionada){
                    alert("Debe seleccionar una persona.")
                    return
                }

                this.setState({nuevaPresonaActivado:false})
                this.setState({editarPersonaActivado:true})
                //si es personas física
                this.setState({modalPersonaAbierto:true})
            }

            const crearPersona=async()=>{

                await this.datosPersona.crearPersona()
                if(this.datosPersona.state.idPersonaCreada){
                    await this.cargarPersonas()
                    //seleccionar empleado creado

                    let personaSel=await this.state.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})

                    this.setState({personaSeleccionada:personaSel})
                    //cerrar el modal
                    cerrarModal2()
                }
    
            }
    
            const actualizarPersona=async ()=>{
    
                await this.datosPersona.actualizarPersona()
                if(this.datosPersona.state.idPersonaActualizada){
                    await this.cargarPersonas()
                    await this.cargarPersonal()

                    //seleccionar empleado creado
                    let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                    this.setState({personaSeleccionada:personaSel})
                    //cerrar el modal
                    cerrarModal2()
                }
    
            }

            const cerrarModalImprimir= () => {
                this.setState({
                    modalImprimirP:false,
                    modalImprimirPG:false
                })
            }

            return (
                <>
                {this.state.cargandoForm===true?
                    <Cargando/>
                :
                <>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title="Imprimir peronal de alta dirección"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimirPG}
                        style={styles.dialogL}
                    >
                        {!this.state.cargandoImprimirPG?
                                <div style={{ width: '100%', height: '500px'}} id='generarPG-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title="Imprimir personal seleccionado"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimirP}
                        style={styles.dialogL}
                    >
                        {!this.state.cargandoImprimirP?
                                <div style={{ width: '100%', height: '500px'}} id='generarP-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>
                    
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={this.state.nuevoActivado?"Nuevo personal":"Actualizar personal"}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAbierto}
                    >
                        <div className="pixelAlrededor bp3-card separarBordes">
                            <FormGroup
                                label="Persona:"
                                labelFor="persona"
                                intent="danger"
                                helperText={!this.state.personaSeleccionada?"Debe seleccionar el personal.":""}
                            >
                                <div style={this.state.personaSeleccionada?{}:{border:"1px solid red"}}>
                                    <Select
                                        isDisabled={this.state.editarActivado}
                                        options={this.state.personas}
                                        className="pixelAlrededor"
                                        onChange={seleccionPersona}
                                        value={this.state.personaSeleccionada}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Button  disabled={this.state.editarActivado} className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevaPresonaActivado} onClick={abrirModalNuevaPersona} />
                                <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarPersonaActivado} onClick={abrirModalEditarPersona} />
                            </FormGroup>
                            <FormGroup
                                label="Retribución:"
                                labelFor="retribucion"
                            >
                                <TextArea id="retribucion" maxlength='250' value={this.state.retribucion} onChange={cambiarRetribucion} fill={true} />
                            </FormGroup>
                            <FormGroup
                                label="Atribuciones:"
                                labelFor="atribuciones"
                            >
                                <TextArea id="atribuciones" maxlength='250' value={this.state.atribucion} onChange={cambiarAtribucion} fill={true} />
                            </FormGroup>
                            <FormGroup
                                label="Indemnizaciones:"
                                labelFor="indemnizaciones"
                            >
                                <TextArea id="indemnizaciones" maxlength='250' value={this.state.indemnizaciones} onChange={cambiarIndemnizaciones} fill={true} />
                            </FormGroup>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>Cerrar</Button>
                            </Tooltip>
                            <Tooltip content={this.state.nuevoActivado?"Añadir personal de alta dirección.":"Editar personal de alta dirección"} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?añadirNuevo:actualizarPersonal}
                                >
                                {this.state.nuevoActivado?"Añadir personal de alta dirección.":"Editar personal de alta dirección"}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </Dialog>
                    <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title="Propiedades de la persona"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPersonaAbierto}
                >
                    <DatosPersonasFisicas i18next={i18next} datosPersona={this.state.personaSeleccionada?this.state.personaSeleccionada.value:null} ref={(input) => { this.datosPersona= input; }} />
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal2}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevaPresonaActivado?"Añadir persona.":"Actualizar persona."} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevaPresonaActivado?crearPersona:actualizarPersona} 
                            >
                            {this.state.nuevaPresonaActivado?"Añadir persona":"Actualizar Persona"} 
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title="Borrar una cuenta"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>¿Desea eliminar el personal alta seleccionado?</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Eliminar credito." position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={elimiaranPersonalAlta}
                                >
                                    Si
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>No</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                    <div className="pixelAlrededor tablaSesenta">
                    <Card>
                        <h4>Personal de alta dirección</h4>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    {this.state.controlesDesactivados?[
                                        <React.Fragment>
                                            <Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevoActivado} disabled />
                                            <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarActivado} disabled/>
                                            <Button className="bp3-minimal" icon="delete" text="Borrar" active={this.state.borrarActivado} disabled/>
                                            <NavbarDivider />
                                            <Button className="bp3-minimal" icon="print"  text="Imprimir Individual" disabled/>
                                            <Button className="bp3-minimal" icon="print" text="Imprimir General" disabled/>
                                        </React.Fragment>
                                    ]:[
                                        <React.Fragment>
                                            <Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                            <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                            <Button className="bp3-minimal" icon="delete" text="Borrar" active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                            <NavbarDivider />
                                            <Tooltip 
                                                content="Desea imprimir el personal seleccionado" 
                                                position="right" 
                                            >
                                                <Button 
                                                    className="bp3-minimal" 
                                                    icon="print" 
                                                    disabled={this.state.personaSeleccionada?false:true}  
                                                    text="Imprimir Individual" onClick={()=>this.generarImprimirP()}
                                                />
                                            </Tooltip>  
                                            <Tooltip 
                                                content="Desea imprimir todo el personal" 
                                                position="right" 
                                            >
                                                <Button 
                                                    className="bp3-minimal" 
                                                    icon="print" 
                                                    text="Imprimir General" 
                                                    onClick={()=>this.generarImprimirPG()}
                                                />
                                            </Tooltip>
                                            
                                        </React.Fragment>
                                    ]}
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        <Table enableMultipleSelection={false} selectedRegions={this.state.regionSeleccionada} ref={(input) => { this.tablaPersonal= input; }} selectionModes={!this.state.controlesDesactivados?[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]:SelectionModes.NONE} onSelection={handleSeleccionarPersonal} numRows={this.state.personal.length} enableColumnResizing={false} columnWidths={this.state.columnWidths} onCompleteRender={() => {
                                            if (!this.state.resized) {
                                                this.resizeColumns();
                                            }
                                        }}>
                            <Column name="Nombre"  cellRenderer={cellRendererNombre}/>   
                        </Table>
                        <br />
                        <Button 
                            alignText="right"
                            icon="floppy-disk"
                            text="Guardar"
                            intent="danger"
                            disabled={!this.state.controlesDesactivados}
                            onClick={guardarCambios}
                            className="iconoDerecha bp3-minimal"
                        />
                        <Button 
                            alignText="right"
                            icon="cross"
                            intent="danger"
                            text="Cancelar"
                            disabled={!this.state.controlesDesactivados}
                            onClick={cancelarCambios}
                            className="iconoDerecha bp3-minimal"
                        />
                        <br />
                        <br />
                    </Card>
                    </div>
                    <div className="pixelAlrededor tablaCuarenta">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Real Decreto 1382/1985, de 1 de agosto, por el que se regula la relación laboral de carácter especial del personal de alta dirección.</Callout>
                            <br />
                            <AnchorButton onClick={()=> window.open("https://www.boe.es/eli/es/rd/1985/08/01/1382", "_blank")}>Real Decreto 1382/1985, de 1 de agosto</AnchorButton>
                            <br />
                        </Card>
                    </div>
                </>
                }
                </>
            )    
        
    } 

}

export default withApollo(PersonalAltaDireccion)