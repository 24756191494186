/* eslint-disable array-callback-return */
import React from "react"
import { Spinner,Dialog, Classes, Tooltip, AnchorButton, Intent, Navbar, ButtonGroup, Alignment, Button, FormGroup, InputGroup, Card, Elevation } from "@blueprintjs/core"
import Select from "react-select"
import gql from "graphql-tag";
import { DatosPersonasFisicas } from "../Elementos/DatosPersonasFisicas"
import moment from "moment"
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import ReactDOM from 'react-dom'
import open_sans_bold from '../../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "../BootstrapTable";
import { Cargando } from "../Cargando";
import ApolloClient from "apollo-boost";

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });

const styles = StyleSheet.create({
    dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:95,
    paddingTop:30,
    paddingLeft: 20,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  colHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  }, 
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  }, 
  
});

export default class ComitesConsultivos extends React.Component {

    state={
        modalAbierto: false,
        nombreComite:null,
        miembrosSeleccionados:[],
        miembros:[],
        comite:[
        ],
        nuevaPresonaActivado:true,
        cargandoCC: false,
        logotipo:null,
        datosEmpresa:null,
        cargandoSOA:false,
        modalImprimir:false,
        cargandoImprimir:false,
        cargandoGrid:false
    }
    clamp(val, min, max) {
        if (val == null) {
            return val;
        }

        return Math.min(Math.max(val, min), max);
    }

    cargarPersonas=async ()=>{
  
       let personas=[]

       let personasFisicas= await client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-only'
        }).then(result=>{
          return  result.data.personasFisicas
        }) 
        personasFisicas.find(persona=>{
                personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`})
        })
                this.setState({miembros:personas})
              

    }

    cargarComites=async ()=>{

        let comites=[]

      let agrupaciones= await client.query({
            query:gql`{agrupaciones{ID_AGRUPACION,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
           return result.data.agrupaciones
        })

        let agrupacionesPersonas=await client.query({
            query:gql`{agrupacionesPersonas{
                ID_AGRUPACION,ID_PERSONA
            }}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.agrupacionesPersonas
        })
           
       
        agrupaciones.forEach(async(agrupacion)=>{
                // console.log(agrupacion)
                let miembrosPersonas=[]
                agrupacionesPersonas.find(persona=>{
                    if (persona.ID_AGRUPACION===agrupacion.ID_AGRUPACION) {
                        miembrosPersonas.push(this.state.miembros.find(miembro=>{
                            return miembro.value===persona.ID_PERSONA}))
                    }
                })
                comites.push({"id":agrupacion.ID_AGRUPACION,"nombre":agrupacion.DESCRIPCION,"miembros":miembrosPersonas}) 

            })
            
        this.setState({comite:comites}) 
        this.props.setComites(comites)      

    }
    

    componentDidMount(props){

        this.setState({cargandoCC: true}, async () => {
            await  this.cargarPersonas()
            await  this.cargarComites()
            this.setState({cargandoCC:false})
        })
    }

    generarImprimirCC= async()=> {
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true,
            cargandoGrid:true,
            comiteSeleccionado:null
        }, async () => {
            this.setState({cargandoImprimir:false}, () => {
                ReactDOM.render(this.ImprimirCC(), document.getElementById("generarCC-pdf"))
            })
        })
    }

    ImprimirCC= () =>(
                <PDFViewer style={{width: '100%', height: '100%'}}>
                    <Document>
                    <Page size="A4" style={styles.page}>
                        <View style={styles.body}>
                            <View fixed style={styles.row}>
                            <Image
                                style={styles.imageHead}
                                src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                                />
                            </View>
                            <View fixed style={styles.rowHead}>
                                <Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                                <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                            </View>
                            <View fixed style={styles.rowHeadTwo}>
                                <Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                                <Text style={styles.text}>{this.props.i18next.t("organoadministracion.title",{ns:"page"}).toUpperCase()}</Text>
                            </View>
                            <View style={styles.rowTitleOne}>
                                <Text style={styles.labelTitle}>{this.props.i18next.t("organoadministracion.comites",{ns:"page"}).toUpperCase()}</Text>
                            </View>
                                {this.state.comite?this.state.comite.map((comite, index)=>{ 
                                    return(
                                        <View key={index}>
                                        {index ===0 && (
                                            <View style={styles.colHead}>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>{this.props.i18next.t("name",{ns:"global"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>{this.props.i18next.t("organoadministracion.nromiembro",{ns:"page"})}</Text>                                                                
                                        </View>
                                        )}
                                            <View style={styles.colHead}>
                                                <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{comite.nombre?comite.nombre:''}</Text>
                                                <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{comite.miembros?(comite.miembros).length:''}</Text>
                                            </View>                               
                                        
                                    </View>
                                    )
                                }):<></>}      
                        </View>
            
                        <Text
                            style={styles.footerPage}
                            render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
                            fixed
                        />
                        <Image
                                style={styles.icono}
                                src={iconoFooter}
                                fixed
                                />
                        <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
                    </Page>
                    </Document>
                 </PDFViewer>            
        )

    render() {

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimir:false,
                cargandoGrid:false
            })
        }

        function cantMiembrosFormatter(cell, row) {
            
            return (
                cell.length
            );
        }

        const columns = [
            {
            dataField: 'nombre',
            text: this.props.i18next.t("name",{ns:"global"})
            },{
                dataField: 'miembros',
                text: this.props.i18next.t("organoadministracion.nromiembro",{ns:"page"}),
                formatter: cantMiembrosFormatter
                }
            ];

        
        const handleSeleccionarMiembro = (value) => {

            this.setState({miembrosSeleccionados:value})

        }
    

        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                modalAbierto: true,
                nombreComite:null,
                miembrosSeleccionados:[],
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                comiteSeleccionado:null,
                cargandoGrid:true
            })
        }       
        
        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.comiteSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                modalAbierto: true,
                nombreComite:this.state.comiteSeleccionado.nombre,
                miembrosSeleccionados:this.state.comiteSeleccionado.miembros,
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                cargandoGrid:true
            })
        } 

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.comiteSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({modalBorrarAbierto: true})
            this.setState({nuevoActivado:false})
            this.setState({editarActivado:false})
            this.setState({borrarActivado:true})
        }

        const añadirNuevo = (value) => {

            if(!this.state.nombreComite){
                return
            }
            if(this.state.miembrosSeleccionados.length===0){
                return
            }

            let existe=this.state.comite.find(com=>{
                return com.nombre===this.state.nombreComite
            })

            if(existe){
                alert(this.props.i18next.t("yaexiste",{ns:"global"}))
                return
            }

            //mutacion para la creacion del comité.
            client.mutate({
                mutation:gql`mutation{crearAgrupacion(VALORES:{DESCRIPCION:"${this.state.nombreComite}"}){ID_AGRUPACION}}`
            }).then(async result=>{

                alert(this.props.i18next.t("insertcorrecto",{ns:"global"}))

                //damos de alta cada miembro 
                this.state.miembrosSeleccionados.forEach(async miembro=>{
                    await client.mutate({
                        mutation:gql`mutation{crearAgrupacionPersona(VALORES:{ID_AGRUPACION:${result.data.crearAgrupacion.ID_AGRUPACION},ID_PERSONA:${miembro.value}}){ID_AGRUPACION,ID_PERSONA}}`
                    })
                })
                await this.cargarComites()   
                
                
            }).catch(err=>{
                console.log(err)
                alert(this.props.i18next.t("error",{ns:"global"}))
            })

            cerrarModal()
            
        }

        const editarComite = (value) => {

            if(!this.state.nombreComite){
                return
            }
            if(this.state.miembrosSeleccionados.length===0){
                return
            }

            let existe=this.state.comite.find(com=>{
                return com.nombre===this.state.nombreComite && com.id!==this.state.comiteSeleccionado.id 
            })

            if(existe){
                alert(this.props.i18next.t("yaexiste",{ns:"global"}))
                return
            }
      
            //mutacion para la creacion del comité.
            client.mutate({
                mutation:gql`mutation{actualizarAgrupacion(ID_AGRUPACION:${this.state.comiteSeleccionado.id},VALORES:{DESCRIPCION:"${this.state.nombreComite}"}){ID_AGRUPACION}}`
            }).then(async result=>{
            
                alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))

                //eliminamos los viejos miembros.
                await client.mutate({
                    mutation:gql`mutation{eliminarAgrupacionePersona(ID_AGRUPACION:${this.state.comiteSeleccionado.id}){ID_AGRUPACION,ID_PERSONA}}`
                })

                //damos de alta cada miembro 
                this.state.miembrosSeleccionados.forEach(async miembro=>{
                    await client.mutate({
                        mutation:gql`mutation{crearAgrupacionPersona(VALORES:{ID_AGRUPACION:${this.state.comiteSeleccionado.id},ID_PERSONA:${miembro.value}}){ID_AGRUPACION,ID_PERSONA}}`
                    })
                })

                await this.cargarComites()

            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
            })

            cerrarModal()
            
        }

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
                cargandoGrid:false
            })
        }

        const cambiarComite=(ev)=>{
            this.setState({nombreComite:ev.currentTarget.value})
        }

        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
        comiteSeleccionado: row
        });
        }
        return true;
        }

        const eliminarComite = async() => {

            let comite=this.state.comite.filter(comite => comite.id !== this.state.comiteSeleccionado.id)
            this.setState({comite:comite})

            //mutataciones para eliminar el comite seleccionado
            await client.mutate({
                mutation:gql`mutation{eliminarAgrupacionePersona(ID_AGRUPACION:${this.state.comiteSeleccionado.id}){ID_AGRUPACION,ID_PERSONA}}`
            }).then(async()=>{

                await client.mutate({
                    mutation:gql`mutation{eliminarAgrupacion(ID_AGRUPACION:${this.state.comiteSeleccionado.id}){ID_AGRUPACION}}`
                }).then(result=>{
    
                    alert(this.props.i18next.t("deletecorrecto",{ns:"global"}))
                    this.setState({
                        comiteSeleccionado:null
                    })
                    
                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })

            })
            
            
            cerrarModal()
        };
        const cerrarModal2 = () => {
            this.setState({
                modalPersonaAbierto: false,
            })
        }
        
        const crearPersona=async()=>{

            let ar=[]

            await this.datosPersona.crearPersona()
            if(this.datosPersona.state.idPersonaCreada){
                await this.cargarPersonas()
                //seleccionar empleado creado
                let miemb=this.state.miembrosSeleccionados
                let personaSel=await this.state.miembros.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})
                miemb.forEach(m=>{
                    ar.push(m)
                })
                ar.push(personaSel)

                this.setState({miembrosSeleccionados:ar})
                //cerrar el modal
                cerrarModal2()
            }

        }

        const actualizarPersona=async ()=>{

            await this.datosPersona.actualizarPersona()
            if(this.datosPersona.state.idPersonaActualizada){
                await this.cargarPersonas()
                
                //seleccionar empleado creado
                let personaSel=this.state.administradores.find(persona=>{return persona.value===this.state.adminstradorSeleccionado.value})
                this.setState({adminstradorSeleccionado:personaSel})
                //cerrar el modal
                cerrarModal2()
            }

        }

        const abrirModalNuevaPersona=()=>{

            //abrir modal de persona física
            this.setState({modalPersonaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})

        }


        return (
            <>
            {this.state.cargandoCC === true?
                <Spinner />
                :
                <>
                    <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={this.props.i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generarCC-pdf"></div>
                            :
                                <Spinner />
                        }

                </Dialog>
                    <Dialog
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal2}
                        title={this.props.i18next.t("organosinstruccion.propiedadpersona",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        className="dialog-ancho"
                        isOpen={this.state.modalPersonaAbierto}
                    >
                        <DatosPersonasFisicas i18next={this.props.i18next} datosPersona={this.state.adminstradorSeleccionado?this.state.adminstradorSeleccionado.value:null} ref={(input) => { this.datosPersona= input; }}/>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal2}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={this.state.nuevaPresonaActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevaPresonaActivado?crearPersona:actualizarPersona} 
                                >
                                {this.state.nuevaPresonaActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})} 
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </Dialog>
                
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title="Selección de miembros"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAbierto}
                    >
                        <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                            <FormGroup
                                label={this.props.i18next.t("organoadministracion.namecomite",{ns:"page"})+":"}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.nombreComite?"":this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("organoadministracion.namecomite",{ns:"page"})}
                            >
                                <InputGroup id="texto-modo-convocatoria" onChange={cambiarComite} value={this.state.nombreComite}  placeholder="Escriba aquí el nombre del comité" intent={this.state.nombreComite?"primary":"danger"} />
                            </FormGroup>
                            <FormGroup
                                label={this.props.i18next.t("organoadministracion.members",{ns:"page"})+":"}
                                labelFor="miembros"
                                fill={true}
                                intent="danger"
                                helperText={!this.state.miembrosSeleccionados.length>0?this.props.i18next.t("selectempty",{ns:"global"})+this.props.i18next.t("organoadministracion.members",{ns:"page"}):""}
                            >
                            <div style={!this.state.miembrosSeleccionados.length>0?{border:"1px solid red"}:{}}>
                            <Select
                                    isMulti
                                    isClearable
                                    isSearchable
                                    name="miebmros"
                                    onChange={handleSeleccionarMiembro}
                                    options={this.state.miembros}
                                    value={this.state.miembrosSeleccionados}
                                />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Button  disabled={this.state.editando} className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevaPresonaActivado} onClick={abrirModalNuevaPersona} />
                            </FormGroup>
                        </Card>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={this.props.i18next.t("agregar",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?añadirNuevo:editarComite}
                                >
                                    {this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>  
                    </Dialog>
                    <Dialog
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={this.props.i18next.t("delete",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{this.props.i18next.t("organoadministracion.questiondelcomite",{ns:"page"})}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={this.props.i18next.t("eliminar",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarComite}
                                >
                                    {this.props.i18next.t("yes",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{this.props.i18next.t("no",{ns:"global"})}</Button>
                            </Tooltip>
                        </div>
                    </div>
                    </Dialog>
                    {this.state.cargandoGrid?
                        <Cargando />
                        :
                        <>
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                <Button className="bp3-minimal" icon="add" text={this.props.i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                <Button className="bp3-minimal" icon="edit" text={this.props.i18next.t("edit",{ns:"global"})} active={this.state.editarActivado}  onClick={abrirModalEditar}/>
                                <Button className="bp3-minimal" icon="delete" text={this.props.i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                <Button className="bp3-minimal" icon="print" text={this.props.i18next.t("print",{ns:"global"})} active={this.state.imprimirActivado} onClick={()=>this.generarImprimirCC()}/>

                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    <BootstrapTable
                    keyField='id'
                    data={ this.state.comite }
                    columns={ columns }
                    selectRow={ const_table.selectRow(handleOnSelect) }
                    pagination={ paginationFactory(const_table.options) }
                    headerClasses="header-class"
                    rowClasses="row-class"
                    hover
                    condensed
                    noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
                    />
                    </>
                    }
                </>
            }
            </>
        )
    }
}
