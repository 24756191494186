/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Card, FormGroup, Navbar,ButtonGroup,Popover,Alignment, Button, NavbarDivider, Dialog, Classes, Tooltip, Callout, AnchorButton, Intent, Switch, Elevation, Spinner } from "@blueprintjs/core"
import { Table, Column, Cell,RegionCardinality,SelectionModes,Regions} from "@blueprintjs/table"
import Select from "react-select"
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import { DatosPersonasJuridicas } from "./DatosPersonasJuridicas";
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas";
import InformacionGeneralNuevo from  "../Pantallas/Elementos/InformacionGeneralNuevo"
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from 'i18next';
import styles from './stylesPrint';

class Clientes extends Component {

    state={
        nuevoActivado: true,
        editarActivado: false,
        borrarActivado: false,
        personas:[],
        homologado: false,
        clientes: [
        ],
        abierto: false,
        value: null,
        enabled: true,
        actividadesSelect: [
        ],
        tipoSectorSelect: [
        ],
        tipoSectorVolumen: [
        ],
        perfilesRiesgo: [
        ],
        personaSeleccionada:null,
        clienteVolumenSeleccionado:null,
        perfilesRiesgoSeleccionado:null,
        nuevoSector:null,
        clienteSeleccionado:null,
        cargando:false,
        regionSeleccionada:null,
        cargandoForm:false,
        columnWidths: [100,100,100,100],
        resized: false,
        datosEmpresa:null,
        cargandoImprimirC:false,
        modalImprimirC: false,
        cargandoImprimirCG:false,
        modalImprimirCG: false,
        logotipo:null,
        personasFisicas:null,
        personasJuridicas:null,
    }

    cargarClientes=async ()=>{

        this.setState({cargando:true})

        await this.props.client.query({
            query:gql`{clientes{ID_CLIENTE,CLIENTE{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}},ID_TIPO_CLIENTE_VOLUMEN,TIPO_CLIENTE_VOLUMEN{DESCRIPCION},ID_PERFIL_RIESGO,PERFIL_RIESGO{DESCRIPCION},HOMOLOGADO}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

            //console.log(result)
            let clientes=[]
            for(let cliente of result.data.clientes){

                let nombrecliente=""
                //Si es persona fisica...
                if(cliente.CLIENTE.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    this.state.personasFisicas.find((persona)=>{
                        if (persona.ID_PERSONA===cliente.ID_CLIENTE) {
                            nombrecliente=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                        }
                    }) 
                   
                //Si es persona juridica
                }else if(cliente.CLIENTE.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    this.state.personasJuridicas.find((persona)=>{
                        if (persona.ID_PERSONA===cliente.ID_CLIENTE) {
                            nombrecliente=`${persona.RAZON_SOCIAL}`
                        }
                    }) 
                    

                }
                clientes.push({"id":cliente.ID_CLIENTE,"nombre":nombrecliente,"idTipoVolumen":cliente.ID_TIPO_CLIENTE_VOLUMEN,"tipoVolumen":cliente.TIPO_CLIENTE_VOLUMEN.DESCRIPCION,"idPerfilRiesgo":cliente.ID_PERFIL_RIESGO,"perfilRiesgo":cliente.PERFIL_RIESGO.DESCRIPCION,"homologado":cliente.HOMOLOGADO}) 
            }
        
            this.setState({
                clientes:clientes,
                cargando:false
            })

        })

    }

    cargarActividades=async ()=>{


        //cargar combo sectores.
        await this.props.client.query({
            query:gql`{sectoresActividad{ID_SECTOR_ACTIVIDAD,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
            let actividades=[]
            result.data.sectoresActividad.forEach(async(actividad)=>{
             
                actividades.push({"value":actividad.ID_SECTOR_ACTIVIDAD,"label":actividad.DESCRIPCION}) 

            })
            this.setState({actividadesSelect:actividades})
        })
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }

     resizeColumns() {
        
        let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        for (let index = 0; index < this.tablaClientes.locator.grid.numCols; index++) {
            totalMinSizeReq = totalMinSizeReq + this.tablaClientes.locator.getWidestVisibleCellInColumn(index);
            cumulativeColumnWidths.push(this.tablaClientes.locator.getWidestVisibleCellInColumn(index));
        }
        if (totalMinSizeReq > containerSize) {
            this.setState({
                columnWidths: cumulativeColumnWidths,
                resized: true
            });
        } else {
            let diff = ((containerSize - totalMinSizeReq) / this.tablaClientes.locator.grid.numCols);
            this.setState({
                columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                resized: true
            });
        }
        
    }


    generarImprimirC= async() => {
        this.setState({
            cargandoImprimirC:true,
            modalImprimirC:true
        }, async () => {
             this.setState({cargandoImprimirC:false}, () => {
                 ReactDOM.render(this.ImprimirC(), document.getElementById('generarC-pdf'))
             })
        })
    }

    generarImprimirCG= async() => {
        this.setState({
            cargandoImprimirCG:true,
            modalImprimirCG:true
        }, async () => {
             this.setState({cargandoImprimirCG:false}, () => {
                 ReactDOM.render(this.ImprimirCG(), document.getElementById('generarCG-pdf'))
             })
        })
    }

    ImprimirC=()=>{
        let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.clienteSeleccionado.id})
        let volumenSel=this.state.tipoSectorVolumen.find(tipoVolumne=>{return tipoVolumne.value===this.state.clienteSeleccionado.idTipoVolumen})
        let perfilRiesgoSel=this.state.perfilesRiesgo.find(perfil=>{return perfil.value===this.state.clienteSeleccionado.idPerfilRiesgo})

        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>CLIENTES</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>CLIENTE</Text>
                </View>
                    {this.state.clienteSeleccionado?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>NOMBRE:</Text>
                                    <Text style={styles.textData}>{personaSel.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>TIPO DE CLIENTE POR VOLUMEN:</Text>
                                    <Text style={styles.textData}>{volumenSel.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>PERFIL DE RIESGO:</Text>
                                    <Text style={styles.textData}>{perfilRiesgoSel.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>HOMOLOGADO:</Text>
                                    <Text style={styles.textData}>{this.state.clienteSeleccionado.homologado?'SI':'NO'}</Text>
                                </View>
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    }

    ImprimirCG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>CLIENTES</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>LISTA DE CLIENTES</Text>
                </View>
                    {this.state.clientes?this.state.clientes.map((cliente, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'40%'}}>NOMBRE</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>TIPO POR VOLUMEN</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>PERFIL DE RIESGO</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'20%'}}>¿HOMOLOGADO?</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{cliente.nombre?cliente.nombre:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{cliente.tipoVolumen?cliente.tipoVolumen:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{cliente.perfilRiesgo?cliente.perfilRiesgo:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'20%'}}>{cliente.homologado?'SI':'NO'}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        let personasFisicas=await this.cargarPersonasFisicas()
        let personasJuridicas=await this.cargarPersonasJuridicas()
        
        this.setState({
            datosEmpresa:datosEmpresa,
            personasFisicas: personasFisicas,
            personasJuridicas: personasJuridicas,
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

    cargarPersonasFisicas= async () => {
        let personasFisicas=this.props.client
        .query({
            query: gql` 
            {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
         return result.data.personasFisicas;
        })
        
        return personasFisicas;
       
    }
    
    cargarPersonasJuridicas= async () => {
        let personasJuridicas=this.props.client
        .query({
            query: gql`
            {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

       }).then(result=>{
         return result.data.personasJuridicas;
        })
        
        return personasJuridicas;
        
    }

    cargarPersonas=async ()=>{

        let personas=[]

        for(let persona of this.state.personasFisicas){
            personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 

        }

        for(let persona of this.state.personasJuridicas){
            personas.push({"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 
        }

        this.setState({personas:personas})
    }

    cargarClasificacionesVolumen=async ()=>{
        //cargar combo tipo auditor sector.
        this.props.client.query({
            query:gql`{tiposClienteVolumen{ID_TIPO_CLIENTE_VOLUMEN,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
            let tiposClienteVolumen=[]
            result.data.tiposClienteVolumen.forEach(async(tipo)=>{
                tiposClienteVolumen.push({"value":tipo.ID_TIPO_CLIENTE_VOLUMEN,"label":tipo.DESCRIPCION}) 
            })
            this.setState({tipoSectorVolumen:tiposClienteVolumen})
        })
    }

    cargarPerfilesRiesgo=async ()=>{
        //cargar combo tipo auditor volumen.
        this.props.client.query({
            query:gql`{perfilesRiesgo{ID_PERFIL_RIESGO,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
            let perfilesRiesgo=[]
            result.data.perfilesRiesgo.forEach(async(tipo)=>{
                perfilesRiesgo.push({"value":tipo.ID_PERFIL_RIESGO,"label":tipo.DESCRIPCION}) 
            })
            this.setState({perfilesRiesgo:perfilesRiesgo})
        })
    }
   
    componentDidMount(props){

        this.setState({
            cargandoForm: true
        },async () => {
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            await this.cargarPersonas()
            await this.cargarClientes()
            await this.cargarActividades()
            await this.cargarClasificacionesVolumen();
            await this.cargarPerfilesRiesgo();
            this.setState({cargandoForm: false})
        });
        

        
        
    }

    
    cellRendererNombre = (rowIndex) => {
        return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.clientes[rowIndex].nombre}`}</Cell>
    }

    cellRendererTipoVolumen = (rowIndex) => {
            return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.clientes[rowIndex].tipoVolumen}`}</Cell>
    }

    cellRendererPerfilRiesgo = (rowIndex) => {
            return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.clientes[rowIndex].perfilRiesgo}`}</Cell>
    }

    cellRendererHomologado = (rowIndex) => {
            return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{this.state.clientes[rowIndex].homologado? "SI" : "NO"}</Cell>
    }

    
    render() {

        document.title ="LexControl | Clientes"

        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            this.setState({
                modalAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                personaSeleccionada:null,
                clienteVolumenSeleccionado:null,
                perfilesRiesgoSeleccionado:null,
                clienteSeleccionado:null,
                homologado:false
            })
    
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.clienteSeleccionado){
                alert("Debe seleccionar un cliente.")
                return
            }

            let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.clienteSeleccionado.id})
            let volumenSel=this.state.tipoSectorVolumen.find(tipoVolumne=>{return tipoVolumne.value===this.state.clienteSeleccionado.idTipoVolumen})
            let perfilRiesgoSel=this.state.perfilesRiesgo.find(perfil=>{return perfil.value===this.state.clienteSeleccionado.idPerfilRiesgo})

            this.setState({
                modalAbierto: true,
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                personaSeleccionada:personaSel,
                clienteVolumenSeleccionado:volumenSel,
                perfilesRiesgoSeleccionado:perfilRiesgoSel,
                homologado:this.state.clienteSeleccionado.homologado        
            })
        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.clienteSeleccionado){
                alert("Debe seleccionar un cliente.")
                return
            }

            this.setState({modalBorrarAbierto: true})
            this.setState({nuevoActivado:false})
            this.setState({editarActivado:false})
            this.setState({borrarActivado:true})
        }


        const cellRendererNombre = (rowIndex) => {
                return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.clientes[rowIndex].nombre}`}</Cell>
        }

        const cellRendererTipoVolumen = (rowIndex) => {
                return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.clientes[rowIndex].tipoVolumen}`}</Cell>
        }

        const cellRendererPerfilRiesgo = (rowIndex) => {
                return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{`${this.state.clientes[rowIndex].perfilRiesgo}`}</Cell>
        }

        const cellRendererHomologado = (rowIndex) => {
                return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} loading={this.state.cargando} wrapText="false" truncated="false">{this.state.clientes[rowIndex].homologado? "SI" : "NO"}</Cell>
        }

        const añadirNuevo = (value) => {


            if(!this.state.personaSeleccionada){
                return
            }

            if(!this.state.clienteVolumenSeleccionado){
                return
            }

            if(!this.state.perfilesRiesgoSeleccionado){
                return
            }

            //comprobar que no existe ya
            let existe=this.state.clientes.find(cliente=>{return cliente.id===this.state.personaSeleccionada.value})
            if(existe){
                alert("Este cliente ya existe")
                return
            }

            this.setState({pendienteGuardar:true})
            this.setState({controlesDesactivados:true})
            this.setState({regionSeleccionada:null})

            this.state.clientes.push({
                "id":0,
                "nombre": this.state.personaSeleccionada.label,
                "tipoVolumen": this.state.clienteVolumenSeleccionado.label,
                "perfilRiesgo": this.state.perfilesRiesgoSeleccionado.label,
                "homologado": this.state.homologado
            })

            this.tablaClientes.clearSelection()

            cerrarModal()
        }

        const actualizarCliente = () => {

            if(!this.state.personaSeleccionada && !this.state.clienteSeleccionado){
                return
            }

            if(!this.state.clienteVolumenSeleccionado && !this.state.clienteSeleccionado){
                return
            }

            if(!this.state.perfilesRiesgoSeleccionado && !this.state.clienteSeleccionado){
                return
            }

            //comprobar que no existe ya
            let existe=this.state.clientes.find(cliente=>{return (cliente.id===this.state.personaSeleccionada.value && cliente.id!==this.state.clienteSeleccionado.id)})
            if(existe){
                alert("Este cliente ya existe")
                return
            }

            let clientes=this.state.clientes
            this.state.clientes.find((cliente,index) =>{
                if(cliente.id===this.state.clienteSeleccionado.id){
    
                    clientes[index]["nombre"]=this.state.personaSeleccionada?this.state.personaSeleccionada.label:this.state.clienteSeleccionado.nombre
                    clientes[index]["tipoVolumen"]=this.state.clienteVolumenSeleccionado?this.state.clienteVolumenSeleccionado.label:this.state.clienteSeleccionado.tipoVolumen
                    clientes[index]["perfilRiesgo"]=this.state.perfilesRiesgoSeleccionado?this.state.perfilesRiesgoSeleccionado.label:this.state.clienteSeleccionado.perfilRiesgo
                    clientes[index]["homologado"]=this.state.homologado

                }
                return cliente.id===this.state.clienteSeleccionado.id
            })
            this.setState({pendienteActualizar:true})
            this.setState({controlesDesactivados:true})
            this.setState({clientes:clientes})
            this.setState({regionSeleccionada:null})

            this.tablaClientes.clearSelection()
    
            cerrarModal()
        }

        const eliminarCliente = () => {
            let clientes=this.state.clientes.filter(cliente => cliente.id !== this.state.clienteSeleccionado.id)
            this.setState({clientes:clientes})
            this.setState({pendienteBorrar:true})     
            this.setState({controlesDesactivados:true})
            cerrarModal()
        };

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
            })
        }

        const handleHomologado = () => {
            this.setState({
                homologado: !this.state.homologado
            })
        }

        const seleccionPersona  = (value) => { 
            this.setState({personaSeleccionada: value})
        }

        const seleccionClienteVolumen  = (value) => { 
            this.setState({clienteVolumenSeleccionado: value})
        }
        const seleccionPerfilRiesgo  = (value) => { 

            this.setState({perfilesRiesgoSeleccionado: value})
        }

        const handleSeleccionarCliente = (value) => {

            if(value.length===0)return
            let seleccionada=this.state.clientes[value[0].rows[0]]                
            this.setState({
                clienteSeleccionado:seleccionada,
                homologado:seleccionada.homologado,
                regionSeleccionada:value
            })

        }


        const guardarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(this.state.pendienteGuardar){
         
                this.props.client.mutate({
                    mutation:gql`mutation{crearCliente(VALORES:{ID_CLIENTE:${this.state.personaSeleccionada.value},ID_TIPO_CLIENTE_VOLUMEN:${this.state.clienteVolumenSeleccionado.value},ID_PERFIL_RIESGO:${this.state.perfilesRiesgoSeleccionado.value},HOMOLOGADO:${this.state.homologado}}){ID_CLIENTE}}`

                }).then(async result=>{
          
                    alert("El registro se ha insertado correctamente.")
                    await this.cargarClientes()

                    //reseleccionamos la que teniamos seleccionada
                    let sel=this.state.clientes.find((cliente,index)=>{
                        if(cliente.id===result.data.crearCliente.ID_CLIENTE){
                            this.setState({regionSeleccionada:[Regions.row(index)]})
                        }
                        return cliente.id===result.data.crearCliente.ID_CLIENTE
                    })


                    this.setState({
                        pendienteGuardar:false,
                        controlesDesactivados:false,
                        clienteSeleccionado:sel
                    })
                    
                }).catch(err=>{
                    alert("Ha ocurrido un error al crear el cliente.Pongase en contacto con el soporte tecnico")
                })

            }else if(this.state.pendienteActualizar){
              
                
                this.props.client.mutate({
                    mutation:gql`mutation{actualizarCliente(ID_CLIENTE:${this.state.clienteSeleccionado.id},VALORES:{ID_TIPO_CLIENTE_VOLUMEN:${this.state.clienteVolumenSeleccionado?this.state.clienteVolumenSeleccionado.value:this.state.clienteSeleccionado.idTipoVolumen},ID_PERFIL_RIESGO:${this.state.perfilesRiesgoSeleccionado?this.state.perfilesRiesgoSeleccionado.value:this.state.clienteSeleccionado.idPerfilRiesgo},HOMOLOGADO:${this.state.homologado?this.state.homologado:this.state.clienteSeleccionado.homologado}}){ID_CLIENTE}}`
                }).then(async result=>{
               
                    alert("El registro se ha actualizado correctamente.")
                    await this.cargarClientes()
                  
                    //reseleccionamos la que teniamos seleccionada
                    let sel=this.state.clientes.find((cliente,index)=>{
                        if(cliente.id===this.state.clienteSeleccionado.id){
                            this.setState({regionSeleccionada:[Regions.row(index)]})
                        }
                        return cliente.id===this.state.clienteSeleccionado.id

                    })

                    this.setState({
                        pendienteActualizar:false,
                        controlesDesactivados:false,
                        clienteSeleccionado:sel
                    })
                    
                }).catch(err=>{
                    alert("Ha ocurrido un error al actualizar el cliente.Pongase en contacto con el soporte tecnico")
                })


                this.setState({pendienteActualizar:false})

            }else if(this.state.pendienteBorrar){

                this.props.client.mutate({
                    mutation:gql`mutation{eliminarCliente(ID_CLIENTE:${this.state.clienteSeleccionado.id}){ID_CLIENTE}}`
                }).then(result=>{
              
                    alert("El registro se ha eliminado correctamente.")
                    this.cargarClientes()
                    this.setState({
                        pendienteBorrar:false,
                        controlesDesactivados:false,
                        clienteSeleccionado:false,
                        regionSeleccionada:null
                    },()=>{this.tablaClientes.clearSelection()})
                    
                }).catch(err=>{
                    alert("Ha ocurrido un error al eliminar el cliente.Pongase en contacto con el soporte tecnico")
                })
            }
        }

        const cancelarCambios = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            this.setState({
                personaSeleccionada:null,
                clienteVolumenSeleccionado:null,
                perfilesRiesgoSeleccionado:null,
                clienteSeleccionado:null,
                controlesDesactivados:false,
                pendienteBorrar:false,
                pendienteActualizar:false,
                pendienteGuardar:false,
                regionSeleccionada:null
            },()=>{this.tablaClientes.clearSelection()})
            this.cargarClientes()
        }

        const crearPersona = async () => {
            await this.datosPersona.crearPersona();
            if (this.datosPersona.state.idPersonaCreada) {
                
                await this.cargarPersonas();
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})
                this.setState({personaSeleccionada:personaSel})
                await this.setState({modalNuevaJuridicaAbierto:false})
                //cerrar el modal
                cerrarModal2();
            }
        };
      
        const actualizarPersona = async () => {
            await this.datosPersona.actualizarPersona();
            if (this.datosPersona.state.idPersonaActualizada) {
                await this.cargarPersonas();
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaActualizada})
                this.setState({personaSeleccionada:personaSel})
                await this.setState({modalNuevaJuridicaAbierto:false})
                await this.cargarClientes()
                //cerrar el modal
                cerrarModal2();
            }
        };

        const abrirPopover = () => {
            this.setState({popoverAbierto: true})
        }
      
        const Botonera = () => {
            return(
                <>
                    <Button className="bp3-minimal" icon="new-link" text="Nueva Persona Física" fill={true} onClick={abrirModalNuevaPersona} />
                    <br />
                    <Button className="bp3-minimal" icon="git-new-branch" text="Nueva Persona Jurídica" onClick={abrirModalNuevaPersonaJurídica} />
                </>
            )
        }

        const abrirModalNuevaPersona=()=>{

            //abrir modal de persona física
            this.setState({personaSeleccionada:null})
            this.setState({modalPersonaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})

        }

        const abrirModalNuevaPersonaJurídica=()=>{

            //abrir modal de persona jurídica
            this.setState({modalNuevaJuridicaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})
            
        }

        const abrirModalEditarPersona=()=>{

            if(!this.state.personaSeleccionada){
                alert("Debe seleccionar una persona.")
                return
            }

            this.setState({nuevaPresonaActivado:false})
            this.setState({editarPersonaActivado:true})
                //si es personas física
            if(this.state.personaSeleccionada.tipo===1){
                this.setState({modalPersonaAbierto:true})

                //si es persona jurídica
            }else if(this.state.personaSeleccionada.tipo===2){
                this.setState({modalEditarPersonaJuridica:true})
            }


        }

        const guardarPersonaJuridica=async ()=>{

        
             
 
            

            let result=await this.informacion.handleGuardarDatos()
            if(!result)return
            await this.cargarPersonas()
            //seleccionamos a al persona creada en la lista.
            let personaSel=this.state.personas.find(persona=>{return persona.value===this.informacion.state.personaCreada})
            this.setState({personaSeleccionada:personaSel})
            await this.setState({modalNuevaJuridicaAbierto:false})

        }

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimirC:false,
                modalImprimirCG:false
            })
        }

        const cerrarModal2 = async () => {
            await this.cargarPersonas()
            if(this.state.personaSeleccionada && this.state.personaSeleccionada.tipo===2){
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                this.setState({personaSeleccionada:personaSel})
                this.setState({modalNuevaJuridicaAbierto:false})
            }
            this.cargarClientes()
            this.setState({
                modalEditarPersonaJuridica:false,
                modalPersonaAbierto:false
            })
        }
      
        return (
            <>
            {this.state.cargandoForm===true?
                <Cargando/>
            :
            <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir clientes"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirCG}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirCG?
                            <div style={{ width: '100%', height: '500px'}} id='generarCG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir cliente seleccionado"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirC}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirC?
                            <div style={{ width: '100%', height: '500px'}} id='generarC-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoActivado?"Añadir nuevo cliente":"Editar cliente"}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                >
                    <div className="pixelAlrededor bp3-card separarBordes">
                        <FormGroup
                            label="Cliente:"
                            labelFor="cliente"
                            intent="danger"
                            helperText={!this.state.personaSeleccionada?"Debe selecccionar el cliente":""}
                        >
                        <div style={!this.state.personaSeleccionada?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.personas}
                                className="pixelAlrededor"
                                onChange={seleccionPersona}
                                isDisabled={this.state.editarActivado}
                                value={this.state.personaSeleccionada}
                            />
                        </div>
                        </FormGroup>
                        <FormGroup>
                            <Popover  disabled={this.state.editarActivado} content={<Botonera />} transitionDuration={400} target={<Button  disabled={this.state.editarActivado} className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevaPresonaActivado} onClick={abrirPopover} />} />
                            <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarPersonaActivado} onClick={abrirModalEditarPersona} />
                        </FormGroup> 
                        <FormGroup
                            label="Tipo de cliente por volumen:"
                            labelFor="tipo-cliente-volumen"
                            intent="danger"
                            helperText={!this.state.clienteVolumenSeleccionado?"Debe selecccionar el tipo cliente volumen":""}
                        >
                        <div style={!this.state.clienteVolumenSeleccionado?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.tipoSectorVolumen}
                                className="pixelAlrededor"
                                onChange={seleccionClienteVolumen}
                                value={this.state.clienteVolumenSeleccionado}
                            />
                        </div>
                        </FormGroup>
                        <FormGroup
                            label="Perfil de riesgo:"
                            labelFor="perfil-riesgo"
                            intent="danger"
                            helperText={!this.state.perfilesRiesgoSeleccionado?"Debe selecccionar el perfil riesgo":""}
                        >
                        <div style={!this.state.perfilesRiesgoSeleccionado?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.perfilesRiesgo}
                                className="pixelAlrededor"
                                onChange={seleccionPerfilRiesgo}
                                value={this.state.perfilesRiesgoSeleccionado}
                            />
                        </div>
                        </FormGroup>
                        <Switch checked={this.state.homologado} label="¿Homologado?" alignIndicator={Alignment.RIGHT} onChange={handleHomologado} />
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevoActivado?"Añadir cliente":"Editar cliente"} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevoActivado?añadirNuevo:actualizarCliente}
                            >
                            {this.state.nuevoActivado?"Añadir cliente":"Editar cliente"}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Borrar una cliente"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>¿Desea eliminar el cliente?</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Eliminar credito." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarCliente}
                            >
                                Si
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>No</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title="Propiedades de la persona"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPersonaAbierto}
                >
        <DatosPersonasFisicas
            datosPersona={
            this.state.personaSeleccionada
                ? this.state.personaSeleccionada.value
                : null
            }
            ref={input => {
            this.datosPersona = input;
            }}
            i18next={i18next}
        />
        <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Tooltip content="Cerrar y volver." position="top">
                <Button onClick={cerrarModal2}>Cerrar</Button>
            </Tooltip>
            <Tooltip
                content={
                this.state.nuevaPresonaActivado
                    ? "Añadir persona."
                    : "Actualizar persona."
                }
                position="top"
            >
                <AnchorButton
                intent={Intent.PRIMARY}
                onClick={
                    this.state.nuevaPresonaActivado
                    ? crearPersona
                    : actualizarPersona
                }
                >
                {this.state.nuevaPresonaActivado
                    ? "Añadir persona"
                    : "Actualizar Persona"}
                </AnchorButton>
            </Tooltip>
            </div>
        </div>
        </Dialog>
        <Dialog
            transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal}
            title="Propiedades de la empresa"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalEditarPersonaJuridica}
            className="datos-empresa-dialog"
            >
            <DatosPersonasJuridicas
                animacion={this.animacion}
                cambiarPestaña={this.cambiarPestaña}
                pestaña={this.state.pestaña}
                empresaSeleccionada={this.state.personaSeleccionada?this.state.personaSeleccionada:null}
            />
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS} />
                <ButtonGroup>
                    <Button 
                        alignText="right"
                        icon="log-out"
                        intent="danger"
                        text="salir"
                        onClick={cerrarModal2}
                    />
                </ButtonGroup>
            </div>
        </Dialog>
        <Dialog transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
            title="Propiedades de la empresa"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalNuevaJuridicaAbierto}
            className="datos-empresa-dialog"
        >
                <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} />  
            <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Tooltip content="Cancelar." position="top">          
                    <Button
                        alignText="left"
                        icon="cross"
                        text="Cancelar"
                        intent="danger"
                        onClick={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                        className="iconoIzquierda bp3-minimal"
                    />
                </Tooltip>
                <Tooltip content="Guardar." position="top">
                <Button
                    alignText="left"
                    icon="floppy-disk"
                    text="Guardar"
                    intent="danger"
                    onClick={guardarPersonaJuridica}
                    className="iconoIzquierda bp3-minimal"
                />
                </Tooltip>
            </div>
        </div>  
        </Dialog>
            <div className="pixelAlrededor tablaSesenta">
                <Card interactive={true} elevation={Elevation.TWO} >
                    <h4>Clientes</h4>
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                <NavbarDivider />
                                {this.state.controlesDesactivados?[
                                    <React.Fragment>
                                        <Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevoActivado} disabled />
                                        <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarActivado} disabled />
                                        <Button className="bp3-minimal" icon="delete" text="Borrar" active={this.state.borrarActivado} disabled/>
                                        <Button className="bp3-minimal" icon="print"  text="Imprimir Individual" disabled/>
                                        <Button className="bp3-minimal" icon="print" text="Imprimir General" disabled/>
                                    </React.Fragment>
                                ]:[
                                    <React.Fragment>
                                        <Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                        <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarActivado} onClick={abrirModalEditar} />
                                        <Button className="bp3-minimal" icon="delete" text="Borrar" active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                        <Tooltip 
                                            content="Desea imprimir el cliente seleccionado" 
                                            position="right" 
                                        >
                                            <Button 
                                                className="bp3-minimal" 
                                                icon="print" 
                                                disabled={this.state.clienteSeleccionado?false:true}  
                                                text="Imprimir Individual" onClick={()=>this.generarImprimirC()}
                                            />
                                        </Tooltip>  
                                        <Tooltip 
                                            content="Desea imprimir todos los clientes" 
                                            position="right" 
                                        >
                                            <Button 
                                                className="bp3-minimal" 
                                                icon="print" 
                                                text="Imprimir General" 
                                                onClick={()=>this.generarImprimirCG()}
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                ]}
                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    <Table selectedRegions={this.state.regionSeleccionada} enableMultipleSelection={false} ref={input => {this.tablaClientes = input;}}  selectionModes={!this.state.controlesDesactivados?[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]:SelectionModes.NONE} onSelection={handleSeleccionarCliente} numRows={this.state.clientes.length} enableColumnResizing={false} columnWidths={this.state.columnWidths} onCompleteRender={() => {
                                            if (!this.state.resized) {
                                                this.resizeColumns();
                                            }
                                        }}>
                        <Column name="Nombre"  cellRenderer={cellRendererNombre}/>   
                        <Column name="Tipo por volumen"  cellRenderer={cellRendererTipoVolumen}/>   
                        <Column name="Perfil de riesgo"  cellRenderer={cellRendererPerfilRiesgo}/>   
                        <Column name="¿Homologado?"  cellRenderer={cellRendererHomologado}/>   
                    </Table>
                    <br />
                    <Button 
                        alignText="right"
                        icon="floppy-disk"
                        text="Guardar"
                        intent="danger"
                        disabled={!this.state.controlesDesactivados}
                        onClick={guardarCambios}
                        className="iconoDerecha bp3-minimal"
                    />
                    <Button 
                        alignText="right"
                        icon="cross"
                        intent="danger"
                        text="Cancelar"
                        disabled={!this.state.controlesDesactivados}
                        onClick={cancelarCambios}
                        className="iconoDerecha bp3-minimal"
                    />
                    <br />
                    <br />
                </Card>
                </div>
                <div className="pixelAlrededor tablaCuarenta">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios<br /> Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico</Callout>
                        <br />
                        <AnchorButton onClick={()=> window.open("https://www.boe.es/eli/es/rdlg/2007/11/16/1/con", "_blank")}>RDL 1/2007</AnchorButton>
                        <AnchorButton onClick={()=> window.open("https://www.boe.es/eli/es/l/2002/07/11/34/con", "_blank")}>Ley 34/2002</AnchorButton>
                        <br />
                    </Card>
                </div>
            </>
            }
            </>
        )
    }
}

export default withApollo(Clientes)