/* eslint-disable no-useless-escape */
import React, { Component } from "react"
import { Card, Callout, Elevation,Tooltip,Dialog, Classes, AnchorButton, Intent, Navbar, Button, Alignment, Switch, InputGroup, FormGroup, TextArea, Spinner } from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime"
import moment from 'moment'
import Select from "react-select"
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png";
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from './BootstrapTable';


import styles from './stylesPrint';

class Consejeros extends Component {

    state = {
        consejeros: [
        ],
        modalConsejeros: false,
        personaFoJ: false,
        nombreConsejero: null,
        CIF: null,
        fechaNombramiento: new Date(),
        fechaCese: new Date(),
        cese: false,
        tipoConsejeros: [
        ],
        consejeroElegido: null,
        retribucion: false,
        tipoRetribucion: null,
        cuantia: 0,
        relacionLaboral: false,
        observaciones: null,
        cifInvalido:false,
        dniInvalido:false,
        cuantiaValido:true,
        cargandoForm:false,
        columnWidths: [100,100,100,100,100,100,100,100,100],
        resized: false,
        datosEmpresa:null,
        cargandoImprimirCG:false,
        modalImprimirCG: false,
        logotipo: null,
        reloadTable: false
    }

    cargarFormas=async()=>{
        await this.props.client
        .query({
          query: gql`
            {tiposConsejeros{ID_TIPO_CONSEJERO,DESCRIPCION}}
          `
        })
        .then(result =>{
            let opciones=[]
            result.data.tiposConsejeros.forEach(forma=>{
                opciones.push({"value":forma.ID_TIPO_CONSEJERO,"label":forma.DESCRIPCION})
            })
            this.setState({tipoConsejeros:opciones})
        });
    }

    cargarConsejeros=async()=>{

        let consejeros=await this.props.client
        .query({
            query: gql`
            {
                Consejeros{
                    ID_CONSEJERO,
                    PERSONA_FISICA,
                    NOMBRE,
                    DOCUMENTO_IDENTIDAD,
                    FECHA_NOMBRAMIENTO,
                    FECHA_CESE,
                    TIPO,
                    TIPO_CONSEJERO{DESCRIPCION}
                    RETRIBUCION,
                    CLASE_RETRIBUCION,
                    CUANTIA_ANUAL,
                    RELACION_LABORAL,
                    OBSERVACIONES
                }
            }
            `,fetchPolicy:'network-only'
        }).then((result)=>{
            return JSON.parse(JSON.stringify(result.data.Consejeros))
        })


        this.setState({consejeros:consejeros})
        
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
    }

    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        this.setState({
            datosEmpresa:datosEmpresa
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
    }

    componentDidMount=()=>{

        this.setState({
            cargandoForm: true
        },async () => {
        
            await this.cargarConsejeros()
            await this.cargarFormas()
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            this.setState({cargandoForm: false})
        });
    }

    generarImprimirCG= async() => {
        this.setState({
            cargandoImprimirCG:true,
            modalImprimirCG:true,
            reloadTable:true,
            consejeroSeleccionado:null
        }, async () => {
             this.setState({cargandoImprimirCG:false}, () => {
                 ReactDOM.render(this.ImprimirCG(), document.getElementById('generarCG-pdf'))
             })
        })
    }

    ImprimirCG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("partesInteresadas.counselors", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("partesInteresadas.counselorsList", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                    {this.state.consejeros?this.state.consejeros.map((consejero, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("partesInteresadas.nameReason", { ns: "page2" }).toUpperCase()}</Text>
								   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("partesInteresadas.appoint", { ns: "page2" }).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("partesInteresadas.cease", { ns: "page2" }).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("partesInteresadas.admonType", { ns: "page2" }).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("partesInteresadas.statRet", { ns: "page2" }).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("partesInteresadas.compClass", { ns: "page2" }).toUpperCase()}N</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("partesInteresadas.annualAmt", { ns: "page2" }).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("partesInteresadas.laborRel", { ns: "page2" }).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'25%'}}>OBSERVACIONES</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{consejero.NOMBRE}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{consejero.FECHA_NOMBRAMIENTO?moment(consejero.FECHA_NOMBRAMIENTO).format('DD-MM-YYYY'):''}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{consejero.FECHA_CESE?moment(consejero.FECHA_CESE).format('DD-MM-YYYY'):''}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{consejero.TIPO_CONSEJERO?consejero.TIPO_CONSEJERO.DESCRIPCION:''}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{consejero.RETRIBUCION?i18next.t("yes", { ns: "global" }).toUpperCase():i18next.t("no", { ns: "global" }).toUpperCase()}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{consejero.CLASE_RETRIBUCION?consejero.CLASE_RETRIBUCION:''}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{consejero.CUANTIA_ANUAL?consejero.CUANTIA_ANUAL:''}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{consejero.RELACION_LABORAL?i18next.t("yes", { ns: "global" }).toUpperCase():i18next.t("no", { ns: "global" }).toUpperCase()}</Text>
									<Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'25%'}}>{consejero.OBSERVACIONES?consejero.OBSERVACIONES:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `${i18next.t("page",{ns:"global"})} ${pageNumber}${i18next.t("de",{ns:"global"})} ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
            </Page>
        </Document>
      </PDFViewer>
    )

    
    render(){

        document.title =`${i18next.t("lexcontrol", {ns:"global"})} | ${i18next.t("partesInteresadas.counselors", { ns: "page2" })}`

        function dateFormatter(cell, row) {
            return (
                cell? moment(cell).format("DD/MM/YYYY") : ""
            );
        }

        function conditionalFormatter(cell, row) {
            return (
                cell?i18next.t("yes", { ns: "global" }): i18next.t("no", { ns: "global" })
            );
        }

        const columns = [
            {
                dataField: 'NOMBRE',
                text: i18next.t("partesInteresadas.nameReason", { ns: "page2" }),
                style: { minWidth: '150px' }
            },
            {
                dataField: 'FECHA_NOMBRAMIENTO',
                text: i18next.t("partesInteresadas.appoint", { ns: "page2" }),
                formatter: dateFormatter
            },
            {
                dataField: 'FECHA_CESE',
                text: i18next.t("partesInteresadas.cease", { ns: "page2" }),
                formatter: dateFormatter,
                style: { minWidth: '90px' }
            },
            {
                dataField: 'TIPO_CONSEJERO.DESCRIPCION',
                text: i18next.t("partesInteresadas.admonType", { ns: "page2" })
            },
            {
                dataField: 'RETRIBUCION',
                text: i18next.t("partesInteresadas.statComp", { ns: "page2" }),
                formatter: conditionalFormatter
            },
            {
                dataField: 'CLASE_RETRIBUCION',
                text: i18next.t("partesInteresadas.compClass", { ns: "page2" })
            },
            {
                dataField: 'CUANTIA_ANUAL',
                text: i18next.t("partesInteresadas.annualAmt", { ns: "page2" })
            },
            {
                dataField: 'RELACION_LABORAL',
                text: i18next.t("partesInteresadas.laborRel", { ns: "page2" }),
                formatter: conditionalFormatter
            }
        ];

        const handleOnSelect = (row, isSelect) => {
            if (isSelect) {
                this.setState({
                    consejeroSeleccionado: row
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };

        const guardarCambios = () => {
            
            if(!this.state.nombreConsejero){return}
            if(!this.state.CIF)return
            if(!this.state.tipoRetribucion)return
            if(!this.state.consejeroElegido)return
            if(!this.state.cuantiaValido)return

            let existe=this.state.consejeros.find(consejero=>{return consejero.DOCUMENTO_IDENTIDAD===this.state.CIF})
            if(existe){
                alert(i18next.t("partesInteresadas.existingCounselor", { ns: "page2" }))
                return
            }

            //validacion del dni o cif
            if (!this.state.personaFoJ) {
              this.setState({ dniInvalido: false });
            } else {
              this.setState({ cifInvalido: false });
            }

            //damos de alta el consejero
            this.props.client.mutate({
                mutation:gql`mutation{crearConsejero(
                   VALORES:{
                        PERSONA_FISICA:${this.state.personaFoJ}
                        NOMBRE:"${this.state.nombreConsejero}"
                        DOCUMENTO_IDENTIDAD:"${this.state.CIF}"
                        FECHA_NOMBRAMIENTO:"${moment(this.state.fechaNombramiento).format("YYYY-MM-DD")}"
                        FECHA_CESE:${this.state.cese?'"'+moment(this.state.fechaCese).format("YYYY-MM-DD")+'"':null}
                        TIPO:${this.state.consejeroElegido.value}
                        RETRIBUCION:${this.state.retribucion}
                        CLASE_RETRIBUCION:"${this.state.tipoRetribucion}"
                        CUANTIA_ANUAL:${this.state.cuantia.replace(/\./g, '').replace(/\,/g, '.')}
                        RELACION_LABORAL:${this.state.relacionLaboral}
                        OBSERVACIONES:${this.state.observaciones?'"""'+this.state.observaciones+'"""':null}
                   } 
                ){ID_CONSEJERO}}`
            }).then(async result=>{
            
                alert(i18next.t("insertcorrecto", { ns: "global" }))
                cerrarModal()
                await this.cargarConsejeros()
                
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error", { ns: "global" }))
            })


        }

        const actualizarConsejero=()=>{

            if(!this.state.nombreConsejero)return
            if(!this.state.CIF)return
            if(!this.state.tipoRetribucion)return
            if(!this.state.consejeroElegido)return
            if(!this.state.cuantiaValido)return

            let existe=this.state.consejeros.find(consejero=>{return consejero.DOCUMENTO_IDENTIDAD===this.state.CIF && consejero.ID_CONSEJERO!==this.state.consejeroSeleccionado.ID_CONSEJERO})
            if(existe){
                alert(i18next.t("partesInteresadas.existingCounselor", { ns: "page2" }))
                return
            }

            //validacion del dni o cif
            if (!this.state.personaFoJ) {
              this.setState({ dniInvalido: false });
            } else {
            }   

            //damos de alta el consejero
            this.props.client.mutate({
                mutation:gql`mutation{actualizarConsejero(
                    ID_CONSEJERO:${this.state.consejeroSeleccionado.ID_CONSEJERO},
                    VALORES:{
                        PERSONA_FISICA:${this.state.personaFoJ}
                        NOMBRE:"${this.state.nombreConsejero}"
                        DOCUMENTO_IDENTIDAD:"${this.state.CIF}"
                        FECHA_NOMBRAMIENTO:"${moment(this.state.fechaNombramiento).format("YYYY-MM-DD")}"
                        FECHA_CESE:${this.state.cese?'"'+moment(this.state.fechaCese).format("YYYY-MM-DD")+'"':null}
                        TIPO:${this.state.consejeroElegido.value}
                        RETRIBUCION:${this.state.retribucion}
                        CLASE_RETRIBUCION:"${this.state.tipoRetribucion}"
                        CUANTIA_ANUAL:${this.state.cuantia.replace(/\./g, '').replace(/\,/g, '.')}
                        RELACION_LABORAL:${this.state.relacionLaboral}
                        OBSERVACIONES:${this.state.observaciones?'"""'+this.state.observaciones+'"""':null}
                   } 
                ){ID_CONSEJERO}}`
            }).then(async result=>{
            
                alert(i18next.t("updatecorrecto", { ns: "global" }))
                cerrarModal()
                await this.cargarConsejeros()

                
            }).catch(err=>{
                alert(i18next.t("error", { ns: "global" }))
            })


        }

        const borrarConsejero = () => {
            if(!this.state.consejeroSeleccionado){
                alert(i18next.t("partesInteresadas.selectCounselor", { ns: "page2" }))
                return
            }

            //eliminamos el consejero
            this.props.client.mutate({
                mutation:gql`mutation{eliminarConsejero(
                    ID_CONSEJERO:${this.state.consejeroSeleccionado.ID_CONSEJERO},
                ){ID_CONSEJERO}}`
            }).then(async result=>{
            
                alert(i18next.t("deletecorrecto", { ns: "global" }))
                cerrarModal()
                await this.cargarConsejeros()

                this.setState({
                    regionSeleccionada:null,
                    consejeroSeleccionado:null
                })
                
            }).catch(err=>{
                alert(i18next.t("error", { ns: "global" }))
            })
            
        }

        const cerrarModal = () => {
            this.setState({ modalConsejeros: false,modalBorrarAbierto:false, reloadTable:false, consejeroSeleccionado:null })
        }
    
        const abrirModal = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            this.setState({
                dniInvalido:false,
                cifInvalido:false,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                personaFoJ: false,
                nombreConsejero: null,
                CIF: null,
                fechaNombramiento: new Date(),
                fechaCese: new Date(),
                cese: false,
                consejeroElegido: null,
                retribucion: false,
                tipoRetribucion: null,
                cuantia: 0,
                relacionLaboral: false,
                observaciones: null,
                modalConsejeros: true,
                consejeroSeleccionado:null,
                regionSeleccionada:null,
                reloadTable: true
            })
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            if(!this.state.consejeroSeleccionado){
                alert(i18next.t("partesInteresadas.selectCounselor", { ns: "page2" }))
                return
            }

            //buscamos el tipo seleccionado
            let tipo=this.state.tipoConsejeros.find(tipo=>{return tipo.value===this.state.consejeroSeleccionado.TIPO})

            this.setState({
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                personaFoJ: this.state.consejeroSeleccionado.PERSONA_FISICA,
                nombreConsejero: this.state.consejeroSeleccionado.NOMBRE,
                CIF: this.state.consejeroSeleccionado.DOCUMENTO_IDENTIDAD,
                fechaNombramiento: new Date(this.state.consejeroSeleccionado.FECHA_NOMBRAMIENTO),
                fechaCese: (this.state.consejeroSeleccionado.FECHA_CESE?new Date(this.state.consejeroSeleccionado.FECHA_CESE):null),
                cese: (this.state.consejeroSeleccionado.FECHA_CESE?true:false),
                consejeroElegido: tipo,
                retribucion: this.state.consejeroSeleccionado.RETRIBUCION,
                tipoRetribucion: this.state.consejeroSeleccionado.CLASE_RETRIBUCION,
                cuantia: Intl.NumberFormat('es-ES').format(this.state.consejeroSeleccionado.CUANTIA_ANUAL),
                relacionLaboral: this.state.consejeroSeleccionado.RELACION_LABORAL,
                observaciones: this.state.consejeroSeleccionado.OBSERVACIONES,
                modalConsejeros: true,
                cuantiaValido:true,
                reloadTable:true
            })

        }

        const abrirModalBorrar=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }
            
            this.setState({
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                modalBorrarAbierto:true
            })
        }

        const cambiarTipoPersona = () => {            
            this.setState ({
                personaFoJ: !this.state.personaFoJ
            })
        }

        const cambiarNombre = (e) => {
            this.setState({nombreConsejero: e.currentTarget.value})
        }

        const cambiarCif = (e) => {
            this.setState({CIF: e.currentTarget.value})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        
        const escogeFechaNombramiento = (nuevaFecha) => {
            this.setState({ fechaNombramiento: nuevaFecha })
        }

        
        const escogeFechaCese = (nuevaFecha) => {
            this.setState({ fechaCese: nuevaFecha })
        }

        const handleSiSeCesaOQue = () => {
            let val=!this.state.cese
            if(val){
                this.setState({fechaCese:new Date()})
            }else{
                this.setState({fechaCese:null})
            }
            this.setState({ cese:val  })
        }

        const handleRetribucion = () => {
            this.setState({ retribucion: !this.state.retribucion })
        }

        const handleRelacionLaboral = () => {
            this.setState({ relacionLaboral: !this.state.relacionLaboral })
        }

        const seleccionTipo  = (value) => {
            this.setState({consejeroElegido: value})
        }

        const cambiarTipoRetribucion = (e) => {
            this.setState({ tipoRetribucion: e.currentTarget.value })
        }

        const formatearMiles=(numero)=>{
            return numero.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."); 
        }

        const cambiarCuantia = (ev) => {

            //solo permitir en el input numeros,comas y puntos.
            let pattern=/^[0-9,.]*$/
            if(pattern.test(ev.currentTarget.value)===false)return

            //comprobacion que sea un número válido
            let pattern2=/^\d{1,3}(\.\d{3})*(\,\d{2})?$/
            if(pattern2.test(formatearMiles(ev.currentTarget.value.replace(/\./g, '')))){
                this.setState({cuantiaValido:true})
            }else{
                this.setState({cuantiaValido:false})
            }

            this.setState({cuantia:formatearMiles(ev.currentTarget.value.replace(/\./g, '')) })
            
        }

        const cambiarObservaciones = (e) => {
            this.setState({ observaciones: e.currentTarget.value })
        }

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimirCG:false,
                reloadTable:false
            })
        }

        return(
            <>
            {this.state.cargandoForm===true?
                    <Cargando/>
                :
            <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={`${i18next.t("print", { ns: "global" })} ${i18next.t("partesInteresadas.counselors", { ns: "page2" })}`}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirCG}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirCG?
                            <div style={{ width: '100%', height: '500px'}} id='generarCG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={`${i18next.t("create", { ns: "global" })}/${i18next.t("edit", { ns: "global" })} ${i18next.t("partesInteresadas.counselor", { ns: "page2" })}`}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalConsejeros}
                    className="dialog-ancho"
                >
                <div>
                <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                <Switch label={i18next.t("partesInteresadas.natJurPerson", { ns: "page2" })} innerLabel={i18next.t("partesInteresadas.person", { ns: "page2" })} innerLabelChecked={i18next.t("partesInteresadas.legalEntity", { ns: "page2" })} checked={this.state.personaFoJ} onChange={cambiarTipoPersona} />
                    {!this.state.personaFoJ
                    ? 
                    <>
                        <FormGroup
                            label={`${i18next.t("name", { ns: "global" })}:`}
                            labelFor="nombre"
                        >
                            <InputGroup value={this.state.nombreConsejero} onChange={cambiarNombre} placeholder={i18next.t("partesInteresadas.personName", { ns: "page2" })} intent={this.state.nombreConsejero?"primary":"danger"} />
                        </FormGroup>
                        <FormGroup
                            label={`${i18next.t("partesInteresadas.nif", { ns: "page2" })}:`}
                            labelFor="NIF"
                            intent="danger"
                            helperText={(this.state.dniInvalido?i18next.t("partesInteresadas.validNif", { ns: "page2" }):"")}
                        >
                            <InputGroup value={this.state.CIF} onChange={cambiarCif}  placeholder={i18next.t("partesInteresadas.writeNif", { ns: "page2" })} intent={!this.state.CIF || this.state.dniInvalido ?"danger":"primary"} />
                        </FormGroup>
                    </>
                    : 
                    <>
                    <FormGroup
                        label={`${i18next.t("partesInteresadas.businessName", { ns: "page2" })}:`}
                        labelFor="razon-social"
                    >
                        <InputGroup value={this.state.nombreConsejero} onChange={cambiarNombre}  placeholder={i18next.t("partesInteresadas.writeCounselorBusinessName", { ns: "page2" })} intent={this.state.nombreConsejero?"primary":"danger"} />
                    </FormGroup>
                    <FormGroup
                        label={`${i18next.t("partesInteresadas.cif", { ns: "page2" })}:`}
                        labelFor="CIF"
                        intent="danger"
                        helperText={(this.state.cifInvalido?"Debe escribir un CIF válido":"")}
                    >
                        <InputGroup value={this.state.CIF} onChange={cambiarCif}  placeholder={i18next.t("partesInteresadas.writeCif", { ns: "page2" })} intent={!this.state.CIF || this.state.cifInvalido ?"danger":"primary"} />
                    </FormGroup>
                    </>
                    }

                    <FormGroup
                        label={`${i18next.t("partesInteresadas.appointDate", { ns: "page2" })}:`}
                        labelFor="fecha-nombramiento"
                    >

                    <DateInput minDate={new Date(1900, 1 ,1)}
                        {...jsDateFormatter} 
                        onChange={escogeFechaNombramiento}
                        value={this.state.fechaNombramiento}
                    />
                    </FormGroup>

                    <FormGroup
                        label={`${i18next.t("partesInteresadas.ceaseDate", { ns: "page2" })}:`}
                        labelFor="fecha-cese"
                    >
                    
                    <Switch onChange={handleSiSeCesaOQue} checked={this.state.cese} innerLabelChecked={i18next.t("partesInteresadas.ceases", { ns: "page2" })} innerLabel={i18next.t("partesInteresadas.notCease", { ns: "page2" })} />

                    <DateInput minDate={new Date(1900, 1 ,1)}
                        {...jsDateFormatter} 
                        onChange={escogeFechaCese}
                        value={this.state.fechaCese}
                        disabled={!this.state.cese}
                    />
                    </FormGroup>
                    
                    <FormGroup
                        label={`${i18next.t("partesInteresadas.counselorType", { ns: "page2" })}:`}
                        labelFor="tipo-consejero"
                    >
                        <div style={!this.state.consejeroElegido?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.tipoConsejeros}
                                className="pixelAlrededor"
                                onChange={seleccionTipo}
                                backspaceRemovesValue={true}
                                isClearable={true}
                                value={this.state.consejeroElegido}
                            />
                        </div>
                    </FormGroup>
                    
                    <FormGroup
                        label={`${i18next.t("partesInteresadas.statComp", { ns: "page2" })}:`}
                        labelFor="retribucion-estatutaria"
                        inline={true}
                    >
                        <Switch onChange={handleRetribucion} checked={this.state.retribucion} innerLabelChecked={i18next.t("yes", { ns: "global" })} innerLabel={i18next.t("no", { ns: "global" })} />
                    </FormGroup>

                    <FormGroup
                        label={`${i18next.t("partesInteresadas.compClass", { ns: "page2" })}:`}
                        labelFor="clase-retribucion"
                        inline={true}
                    >
                        <InputGroup value={this.state.tipoRetribucion} onChange={cambiarTipoRetribucion}  intent={this.state.tipoRetribucion?"primary":"danger"} />
                    </FormGroup>

                    <FormGroup
                        label={`${i18next.t("partesInteresadas.annualAmt", { ns: "page2" })}:`}
                        labelFor="cuantia-anual"
                        inline={true}
                    >
                        <InputGroup type="text"  intent={!this.state.cuantiaValido?"danger":"primary"} value={this.state.cuantia} onChange={cambiarCuantia} />
                    </FormGroup>

                    <FormGroup
                        label={`${i18next.t("partesInteresadas.laborRelSoc", { ns: "page2" })}:`}
                        labelFor="relacion-laboral"
                        inline={true}
                    >
                        <Switch onChange={handleRelacionLaboral} checked={this.state.relacionLaboral} innerLabelChecked={i18next.t("yes", { ns: "global" })} innerLabel={i18next.t("no", { ns: "global" })} />
                    </FormGroup>

                    <FormGroup
                        label={`${i18next.t("partesInteresadas.observations", { ns: "page2" })}:`}
                        labelFor="observaciones"
                    >
                        <TextArea fill={true} onChange={cambiarObservaciones} value={this.state.observaciones} />
                    </FormGroup>

                </Card>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <AnchorButton intent={Intent.PRIMARY} onClick={this.state.nuevoActivado?guardarCambios:actualizarConsejero}>
                        {i18next.t("guardar", { ns: "global" })}
                    </AnchorButton>
                    <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal}>
                        {i18next.t("close", { ns: "global" })}
                    </AnchorButton>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("partesInteresadas.deleteAcc", { ns: "page2" })}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{i18next.t("partesInteresadas.confirmDeleteCons", { ns: "page2" })}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={`${i18next.t("delete", { ns: "global" })} ${i18next.t("partesInteresadas.counselor", { ns: "page2" })}`} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={borrarConsejero}
                                >
                                    {i18next.t("yes", { ns: "global" })}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("no", { ns: "global" })}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>

                <div className="tablaSesenta pixelAlrededor">

                <Card interactive={true} elevation={Elevation.TWO}>

                    <Navbar>
                        <Navbar.Group align={Alignment.LEFT}>
                            <Button 
                                alignText="right"
                                icon="add"
                                text={i18next.t("addnew", { ns: "global" })}
                                onClick={abrirModal}
                                className="iconoDerecha bp3-minimal"
                            />
                            <Button 
                                alignText="right"
                                icon="edit"
                                text={i18next.t("edit", { ns: "global" })}
                                onClick={abrirModalEditar}
                                className="iconoDerecha bp3-minimal"
                            />
                            <Button 
                                alignText="right"
                                icon="cross"
                                text={`${i18next.t("delete", { ns: "global" })} ${i18next.t("partesInteresadas.counselor", { ns: "page2" })}`}
                                onClick={abrirModalBorrar}
                                className="iconoDerecha bp3-minimal"
                            />
                            <Tooltip 
                                content={i18next.t("partesInteresadas.printCounselors", { ns: "page2" })}
                                position="right" 
                            >
                                <Button 
                                    className="bp3-minimal" 
                                    icon="print" 
                                    text={i18next.t("printgral", { ns: "global" })} 
                                    onClick={()=>this.generarImprimirCG()}
                                />
                            </Tooltip>
                        </Navbar.Group>
                        </Navbar>
                        {this.state.reloadTable?
                            <Cargando/>
                        :
                            <BootstrapTable
                                keyField="ID_CONSEJERO"
                                data={this.state.consejeros}
                                columns={columns}
                                selectRow={selectRow}
                                pagination={ paginationFactory(const_table.generateOptions(4,0,5)) }
                                headerClasses="header-class"
                                rowClasses="row-class"
                                hover
                                noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            />
                        }
                </Card>
                
                </div>

                <div className="pixelAlrededor tablaCuarenta">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("partesInteresadas.chapterViLsc", { ns: "page2" })}</Callout>
                        <br />
                        <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544&p=20181229&tn=1#cvi", "_blank")}>{i18next.t("partesInteresadas.chapterViLsc", { ns: "page2" })}</AnchorButton>
                        <br />
                    </Card>
                </div>
            </>
            }
            </>
        )
    }
}export default withTranslation()(withApollo(Consejeros))