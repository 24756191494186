/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Card, Elevation, Navbar,Button,Tree,Tooltip,Position,Callout,ButtonGroup,Alignment,Dialog,Spinner} from "@blueprintjs/core"
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import 'react-orgchart/index.css';
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../Assets/lc.png';
import iconoArea from '../../Assets/home.png';
import iconoDpto from '../../Assets/briefcase.png';
import iconoEmpl from '../../Assets/people.png';
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    Image,
} from '@react-pdf/renderer';

import styles from './stylesPrint';

class Organigrama extends Component {

    state={
        areas:[],
        areasDesplegadas:[],
        modalImprimirO:false,
        cargando: false,
        cargandoImprimir:false
    }

    cargarArbol=async()=>{

        let areas=[]

        // obtencion de las areas
        await this.props.client.query({
            query:gql`{departamentosAreas(FILTROS:{PREDEFINIDA:false}){ID_AREA,DESCRIPCION,PREDEFINIDA}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
   
            result.data.departamentosAreas.forEach(async(area)=>{
           
                   areas.push({id:area.ID_AREA,value:area.ID_AREA,name:area.DESCRIPCION,tipo:"area"})
               
            })  
        
        })
     
          
        //por cada area obtenemos su departamento.
        for(let area of areas){

            let deps=await this.props.client.query({
                query:gql`{departamentos(FILTROS:{ID_AREA:${area.id}}){ID_DEPARTAMENTO,DESCRIPCION}}`
                ,
                fetchPolicy:'network-only'
            }).then(async(result)=>{
                return result.data.departamentos
             
            })
            
            let index=0
            for(let dep of deps){

                deps[index].name=dep.DESCRIPCION
                //obtenemos los empleados del dep.
                let empleadosDep=await this.props.client
                .query({
                    query: gql`
                    {departamentosEmpleados(FILTROS:{ID_DEPARTAMENTO:${dep.ID_DEPARTAMENTO}}){ID_EMPLEADO}}
                    `,fetchPolicy:'network-only'
                }).then(result=>{ 
                    return result.data.departamentosEmpleados
                })

                let index2=0
                for(let empleado of empleadosDep){

                    //obtencion del nombre del empleado.
                    let nombre=await this.props.client.query({
                        query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC"FILTROS:{ID_PERSONA:${empleado.ID_EMPLEADO}}){NOMBRE,APELLIDO1,APELLIDO2}}`
                        ,fetchPolicy:'network-only'
                    }).then(async(result)=>{
                        //console.log(result)
                        let persona=result.data.personasFisicas[0]
                        return `${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                    }) 
                    empleadosDep[index2].name=nombre
                    index2++
                }
    
                //si tenemos empleados en el dep.
                if(empleadosDep.length>0){
                    deps[index].children=empleadosDep                    
                }
            
                index++                 
            }

            area.children=deps
           
        }

        console.log(areas)
        this.setState({
           areas:areas
        })
     

    }
    
    cargarAreas=async()=>{
        let areas_tree=[]
        let areas=await this.props.client.query({
            query:gql`{departamentosAreas(FILTROS:{PREDEFINIDA:false}){ID_AREA,DESCRIPCION,PREDEFINIDA}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.departamentosAreas
        })
        let departamentos=await this.props.client.query({
            query:gql`{departamentos(FILTROS:{PREDEFINIDO:false}){ID_DEPARTAMENTO,DESCRIPCION,ID_AREA}}`
            ,fetchPolicy:'network-only'
        // eslint-disable-next-line no-loop-func
        }).then(async(result)=>{
            return result.data.departamentos
        })
        let empleados=await this.props.client.query({
            query: gql`
            {departamentosEmpleados{ID_EMPLEADO,ID_DEPARTAMENTO,PERS_FISICA{APELLIDO1},PERS_FISICA{APELLIDO2},PERS_FISICA{NOMBRE}}}
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            return result.data.departamentosEmpleados
        })
        for(let area of areas){
            let depSel=[]
            departamentos.find(dep=>{
                let empSel=[]
                if (dep.ID_AREA===area.ID_AREA) {
                    empleados.find(emp=>{
                        if (emp.ID_DEPARTAMENTO===dep.ID_DEPARTAMENTO) {
                            let nombre=`${emp.PERS_FISICA.APELLIDO1} ${emp.PERS_FISICA.APELLIDO2?emp.PERS_FISICA.APELLIDO2:""} ${emp.PERS_FISICA.NOMBRE}`
                                 

                            empSel.push({id:emp.ID_EMPLEADO,value:emp.ID_EMPLEADO,label:nombre,tipo:"empleado",icon:"people"})
                        }
                    })
            
                    depSel.push({id:dep.ID_DEPARTAMENTO,label:"D"+dep.ID_DEPARTAMENTO+" - "+dep.DESCRIPCION,tipo:"departamento",padre:area.ID_AREA,icon:"briefcase",childNodes:empSel})
                }
            })
            areas_tree.push({id:area.ID_AREA,value:area.ID_AREA,label:"A"+area.ID_AREA+" - "+area.DESCRIPCION,tipo:"area",icon:"home",childNodes:depSel})
        }
        this.setState({areas:areas_tree})
    }

    
    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    cargarDatosEmpresa=async()=>{

        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //console.log(result.data.personasJuridicas[0]);
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }

            }
            
        })

        
        
        return datosEmpresa
    }

    cargarDatos = async () => {
        let datosEmpresa=await this.cargarDatosEmpresa();
        this.setState({
            cargandoImprimir:false,
            datosEmpresa:datosEmpresa
        })
    }

    generarInformeO =async () => {
        this.setState({
            modalImprimirO: true,
            cargandoImprimir:true
        }, async () => {
            if (this.state.datosEmpresa==null) {
                await this.cargarDatos();
                ReactDOM.render(this.InformeO(), document.getElementById('informe-pdf-o'))
            } else {
                this.setState({
                    cargandoImprimir:false,
                },()=>{
                    ReactDOM.render(this.InformeO(), document.getElementById('informe-pdf-o'))
                })
            }
        })
        
    }

    InformeO = () => (
      <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>ORGANIGRAMA</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>ORGANIGRAMA</Text>
                </View>
                {this.state.areas?this.state.areas.map((area, index)=>{
                        return (
                            <View>
                                <View style={styles.viewArea}>
                                    <Image
                                        style={styles.iconoArea}
                                        src={iconoArea}
                                        />
                                    <Text key={index} style={styles.textDataAreas}>{area.label}</Text>
                                </View>
                                {this.state.areas[index].childNodes.map((child, indexC)=>{
                                    return (
                                        <View>
                                            <View style={styles.viewDpto}>
                                                <Image
                                                style={styles.iconoDpto}
                                                src={iconoDpto}
                                                />
                                                <Text key={indexC} style={styles.textDataDeptoO}>{child.label}</Text>
                                            </View>
                                            {this.state.areas[index].childNodes[indexC].childNodes.map((childE, indexE)=>{
                                                return (
                                                    <View style={styles.viewEmp}>
                                                        <Image
                                                        style={styles.iconoEmpl}
                                                        src={iconoEmpl}
                                                        />
                                                        <Text key={indexE} style={styles.textDataEmp}>{childE.label}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                    )
                                })}
                            </View>
                        )
                    }):<></>}
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    componentDidMount(props){

        this.setState({ 
            cargando: true
        },async () => {
            this.obtenerLogoEmpresa()
            await this.cargarAreas()
            this.setState({cargando: false})                
        })

        //this.obtenerLogoEmpresa()
        //this.handleNodeExpandParaImpresion(this.state.areas)

        

    }

    /*handleNodeExpandParaImpresion = (nodeData) => {
        let areasADesplegar = this.state.areas
        console.log(areasADesplegar);
    }*/

    

    render() {

        document.title ="LexControl | Organigrama"

        const cerrarModalImprimirO=()=>{
            this.setState({modalImprimirO: false})
        }
        
        const handleNodeCollapse = (nodeData: ITreeNode) => {
            if(this.state.controlesDesactivados)return

            nodeData.isExpanded = false;
            this.setState(this.state);
        };
    
        const handleNodeExpand = (nodeData: ITreeNode) => {
            if(this.state.controlesDesactivados)return

            nodeData.isExpanded = true;
            this.setState(this.state);
        }

        
        const desplegarArbol=(hijos)=>{
            
            hijos.forEach(hijo=>{
                  hijo.isExpanded=true
                  if(hijo.childNodes)desplegarArbol(hijo.childNodes)
            })  
            this.setState({arbol:hijos})
        
        }
        
        const replegarArbol=(hijos)=>{
              
            hijos.forEach(hijo=>{
                hijo.isExpanded=false
                if(hijo.childNodes)replegarArbol(hijo.childNodes)
            })  
            this.setState({arbol:hijos})
        
        }

        return (
            <>
            {this.state.cargando === true?
                <Cargando />
                :
                <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimirO}
                    /* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
                    title="Imprimir Organigrama"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                    usePortal={true}
                    isOpen={this.state.modalImprimirO}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="informe-pdf-o">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                    <div className="pixelAlrededor contenedorOrganigrama" >
                        <Card interactive={true} elevation={Elevation.TWO} >
                            <Callout  className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Organigrama</Callout>
                            <Navbar>
                                <Navbar.Group>
                                    <ButtonGroup align={Alignment.LEFT}>
                                        <>
                                            <Button key="botonImprimir" className="bp3-minimal" icon="print" text="Imprimir" id="boton-imprimir" active={this.state.imprimirActivado} onClick={() => this.generarInformeO()} />
                                            
                                        </>
                                    </ButtonGroup>
                                </Navbar.Group>
                            </Navbar>
                            <Card interactive={true} elevation={Elevation.TWO} className="contenedorOrganigrama">
                                <div ref={(input) => { this.organigrama= input; }} >
                                    <Tooltip content="Replegar arbol." position={Position.TOP} ><Button icon="exclude-row" onClick={()=>replegarArbol(this.state.areas)}/></Tooltip>
                                    <Tooltip content="Desplegar arbol." position={Position.TOP} ><Button icon="add-column-right" onClick={()=>desplegarArbol(this.state.areas)}/></Tooltip>
                                    <Tree 
                                        contents={this.state.areas}
                                        onNodeCollapse={handleNodeCollapse}
                                        onNodeExpand={handleNodeExpand}
                                        ref={(input) => { this.arbol= input; }}
                                    />
                                </div>
                            </Card>
                        </Card>
                        
                    </div>
                </>
            }
            </>
        )
    }
}
export default withApollo(Organigrama)