import React, {Component} from 'react';
import { FormGroup, TextArea, Card, Elevation } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';

class Impugnacion extends Component {
    state={
        textoImpugnacion:'',
        textoResolucion:'',
        textoAnulacion:'',
        textoSubsanacion:'',
        fechaEfectoImpugnacion:new Date(),
    }

    componentDidMount=()=>{

        if(this.props.datosImpugnacion && Object.keys(this.props.datosImpugnacion)){
  
          this.setState({
            textoImpugnacion:this.props.datosImpugnacion.TEXTO,
            textoResolucion:this.props.datosImpugnacion.RESOLUCION,
            textoAnulacion:this.props.datosImpugnacion.ANULACION,
            textoSubsanacion:this.props.datosImpugnacion.SUBSANACION,
            fechaEfectoImpugnacion:new Date(this.props.datosImpugnacion.FECHA_EFECTO),
          })
  
        }
  
    }

    render() {

        const cambiarTextoImpugnacion = e => {
            this.setState({ textoImpugnacion: e.currentTarget.value });
        };
    
        const cambiarTextoResolucion = e => {
            this.setState({ textoResolucion: e.currentTarget.value });
        };
    
        const cambiarTextoAnulacion = e => {
            this.setState({ textoAnulacion: e.currentTarget.value });
        };
    
        const cambiarTextoSubsanacion = e => {
            this.setState({ textoSubsanacion: e.currentTarget.value });
        };
    
        const escogeFechaEfectoImpugnacion = nuevaFecha => {
            this.setState({ fechaEfectoImpugnacion: nuevaFecha });
        };

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        return (
            <div>
                <Card interactive={true} elevation={Elevation.TWO}>
                    <FormGroup label="Texto de la impugnación:">
                        <TextArea
                        value={this.state.textoImpugnacion}
                        fill={true}
                        onChange={cambiarTextoImpugnacion}
                        intent={this.state.textoImpugnacion ? "primary" : "danger"}
                        />
                    </FormGroup>
                    <FormGroup label="Resolucion:">
                        <TextArea
                        value={this.state.textoResolucion}
                        fill={true}
                        onChange={cambiarTextoResolucion}
                        intent={"primary"}
                        />
                    </FormGroup>
                    <FormGroup label="Anulación:">
                        <TextArea
                        value={this.state.textoAnulacion}
                        fill={true}
                        onChange={cambiarTextoAnulacion}
                        intent={"primary"}
                        />
                    </FormGroup>
                    <FormGroup label="Subsanación:">
                        <TextArea
                        value={this.state.textoSubsanacion}
                        fill={true}
                        onChange={cambiarTextoSubsanacion}
                        intent={"primary"}
                        />
                    </FormGroup>
                    <FormGroup label="fecha de efecto:" inline={true}>
                        <DateInput minDate={new Date(1900, 1 ,1)}
                        {...jsDateFormatter}
                        onChange={escogeFechaEfectoImpugnacion}
                        value={this.state.fechaEfectoImpugnacion}
                        />
                    </FormGroup>
                </Card>
            </div>
        );
    }
}

export default Impugnacion;