/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { Spinner, Card, FormGroup, InputGroup, NumericInput, Navbar, Callout, ButtonGroup, Button, Alignment, Dialog, Classes, Tooltip, AnchorButton, Intent, FileInput, TextArea, Elevation } from "@blueprintjs/core"
import gql from "graphql-tag";
import { DateInput } from '@blueprintjs/datetime';
import moment from 'moment';
import axios from 'axios';
import { withApollo } from 'react-apollo';
import ReactDOM from 'react-dom'
import { Cargando } from "../Pantallas/Cargando"
import iconoFooter from '../../Assets/lc.png';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";
import styles from './stylesPrint';

class CuentasAnuales extends Component {

    state={
        modalAbierto: false,
        modalEditarAbierto: false,
        modalBorrarAbierto: false,
        totalPartidas: 0.00,
        totalPartidasValido:true,
        importeNetoCifraAnual:0.00,
        importeNetoCifraAnualValido:true,
        numeroMedioTrabajadores: 0,
        periodoMedioPago: "",
        medidasAplicarReduccion: "",
        titulo262:null,
        contenidoRealDecreto:null,
        contenido262:null,
        existeCuentaAnual:null,
        idPersona:null,
        presentaciones: [
        ],
        fecha: new Date(),
        presentacionSeleccionada:null,
        cargando: false,
        cargandoImprimirGral:false,
        cargandoImprimirP:false,
        modalImprimirGral:false,
        modalImprimirP:false,
        datosEmpresa:[],
        logotipo:null,
        cargandoGrid:false
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
        await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
 
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
 
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
 
             }
         })
     
     }
     cargarEmpresa= async() => {
         
         //DATOS JURIDICOS
         let datosEmpresa= await this.props.client
         .query({
             query: gql`
             {
                 personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
             }
             `,
             fetchPolicy: 'network-only'
 
         }).then(result=>{
             //si tenemos razon social.
             if(result.data.personasJuridicas.length>0){
                 return {
                     "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                 }
 
             }
             
         })
 
        return datosEmpresa
    
    }

    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        this.setState({
            datosEmpresa:datosEmpresa
        })

    }


    recargarCuentas=async()=>{

        //buscamos las cuentas
        let cuentas=await this.props.client
        .query({
            query: gql`
                {cuentasAnuales{ID_CUENTA_ANUAL,FECHA_PRESENTACION_RM}}
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.cuentasAnuales
        })
        //buscamos sus documentos
        for(let cuenta of cuentas){
            let adjuntos=await this.props.client
            .query({
                query: gql`
                    {adjuntos(FILTROS:{ID_TIPO_ADJUNTO:5,ID_ENTIDAD_ASOCIADA:4,ID_ASOCIADO:${cuenta.ID_CUENTA_ANUAL}}){
                        ID_ADJUNTO,
                        NOMBRE,
                        DESCRIPCION,
                        DATOS
                    }}
                `,fetchPolicy:'network-only'
            }).then(result=>{ 
                return result.data.adjuntos
            })            
            if(adjuntos.length>0){
                adjuntos.forEach(adjunto=>{
                   cuenta.idAdjunto=adjunto.ID_ADJUNTO
                   cuenta.nombre=adjunto.NOMBRE
                   cuenta.descripcion=adjunto.DESCRIPCION
                   cuenta.ruta=adjunto.DATOS
                })
            }    

          
    

    }
    this.setState({presentaciones:cuentas})

    }   

    cargarDatosEmpresa=async()=>{   
        //DATOS EMPRESA
     let personas=await this.props.client
        .query({
            query: gql`
                {personasJuridicas(FILTROS:{EMPRESA_PRINCIPAL:true}){ID_PERSONA,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}}
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.personasJuridicas
        })    
            personas.forEach(persona=>{
                this.setState({
                    idPersona: persona.ID_PERSONA,
                    totalPartidas: new Intl.NumberFormat('es-ES').format(persona.TOTAL_PARTIDAS_ACTIVO, {maximumFractionDigits: 2}),
                    importeNetoCifraAnual: new Intl.NumberFormat('es-ES').format(persona.CIFRA_ANUAL_NEGOCIO)
                })
                if(persona.NUMERO_MEDIO_TRABAJADORES>0){
                    this.setState({
                        numeroMedioTrabajadores: parseInt(persona.NUMERO_MEDIO_TRABAJADORES)
                    })
                }
            })
        //   this.setState({
        //         idPersona:result.data.personasJuridicas[0].ID_PERSONA,
        //         totalPartidas:new Intl.NumberFormat('es-ES').format(result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO, {maximumFractionDigits: 2}),
        //         importeNetoCifraAnual:new Intl.NumberFormat('es-ES').format(result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO)
        //     })

        //     if(result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES>0){
        //         this.setState({numeroMedioTrabajadores:parseInt(result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES)})
        //     }
        // })
    }

    cargarCuentaAnual=async()=>{
        
        //DATOS CUENTA ANUAL
        let cuentasAnual= await this.props.client
        .query({
            query: gql`
                {cuentasAnualesOtrosDatos{PERIODO_MEDIO_PAGO,MEDIDAS_ADOPTAR}}
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
           return result.data.cuentasAnualesOtrosDatos
        })    

            if(cuentasAnual.length>0){
                cuentasAnual.forEach(cuenta=>{
                    this.setState({
                        existeCuentaAnual:true,
                        periodoMedioPago:cuenta.PERIODO_MEDIO_PAGO,
                        medidasAplicarReduccion:cuenta.MEDIDAS_ADOPTAR
                    })
                })
                // this.setState({
                //     existeCuentaAnual:true,
                //     periodoMedioPago:result.data.cuentasAnualesOtrosDatos[0].PERIODO_MEDIO_PAGO,
                //     medidasAplicarReduccion:result.data.cuentasAnualesOtrosDatos[0].MEDIDAS_ADOPTAR
                // })           
             }

        // })
    }
    cargarArticulo= async() =>{
          //ARTICULO 262.
         await this.props.client
          .query({
              query: gql`
                  {htmlinfo(FILTROS:{ID_INFO:9}){TITULO,HTML}}
              `,fetchPolicy:'network-only'
          }).then(result=>{ 
             this.setState({contenido262:`${result.data.htmlinfo[0].TITULO} ${result.data.htmlinfo[0].HTML}`})
          })
 
          //REAL DECRETO 1/2010.
          await this.props.client
          .query({
              query: gql`
                  {htmlinfo(FILTROS:{ID_INFO:1}){TITULO,HTML}}
              `,fetchPolicy:'network-only'
          }).then(result=>{ 
             this.setState({contenidoRealDecreto:`${result.data.htmlinfo[0].TITULO} ${result.data.htmlinfo[0].HTML}`})
          })
    }
    componentDidMount(){
        this.setState({cargando: true}, async()=>{
            await this.cargarDatosEmpresa()
            await this.cargarCuentaAnual()
            await this.cargarArticulo()
            await this.recargarCuentas()
            await this.cargarDatos()
            await this.obtenerLogoEmpresa()
            this.setState({cargando:false})

        })        
    }

    generarImprimirPresentacion=async()=>{
        console.log(this.state.presentacionSeleccionada)
        this.setState({
            cargandoImprimirP:true,
            cargandoGrid:true,
            modalImprimirP:true}, async() =>{
                this.setState({cargandoImprimirP:false}, () =>{
                    ReactDOM.render(this.ImprimirPresentacion(), document.getElementById('generarImprimirP-pdf'))
                })
            })
    }
    ImprimirPresentacion=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.body}>
                        <View fixed style={styles.row}>
                        <Image
                            style={styles.imageHead}
                            src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                            />
                        </View>
                        <View fixed style={styles.rowHead}>
                            <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                            <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                        </View>
                        <View fixed style={styles.rowHeadTwo}>
                            <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                            <Text style={styles.text}>{i18next.t("cuentasanuales.title",{ns:"page"}).toUpperCase()}</Text>
                        </View>
                        <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}>{i18next.t("cuentasanuales.titlepresentacion",{ns:"page"})}</Text>
                        </View>
                            {this.state.presentacionSeleccionada?
                                    <View >
                                        <View style={styles.row}>
                                            <Text style={styles.labelData}>{i18next.t("cuentasanuales.fecha",{ns:"page"}).toUpperCase()}:</Text>
                                            <Text style={styles.textData}>{this.state.presentacionSeleccionada.FECHA_PRESENTACION_RM?moment(this.state.presentacionSeleccionada.FECHA_PRESENTACION_RM).format('DD-MM-YYYY'):''}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.labelData}>{i18next.t("cuentasanuales.name",{ns:"page"}).toUpperCase()}:</Text>
                                            <Text style={styles.textData}>{this.state.presentacionSeleccionada.nombre?this.state.presentacionSeleccionada.nombre:''}</Text>
                                        </View>
                                         <View style={styles.row}>
                                            <Text style={styles.labelData}>{i18next.t("cuentasanuales.descripcion",{ns:"page"}).toUpperCase()}:</Text>
                                            <Text style={styles.textData}>{this.state.presentacionSeleccionada.descripcion?this.state.presentacionSeleccionada.descripcion:''}</Text>
                                        </View>
                                        
                                    </View>
                            :<></>}      
                    </View>        
                    <Text
                        style={styles.footerPage}
                        render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                        fixed
                    />
                    <Image
                            style={styles.icono}
                            src={iconoFooter}
                            fixed
                            />
                    <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
                </Page>
            </Document>
      </PDFViewer>

    )

    generarImprimirCA=async()=>{
        this.setState({
            cargandoImprimirGral:true,
            cargandoGrid:true,
            presentacionSeleccionada:null,
            modalImprimirGral:true}, async() =>{
                this.setState({cargandoImprimirGral:false}, () =>{
                    ReactDOM.render(this.ImprimirPresentacionGral(), document.getElementById('generarImprimirGral-pdf'))
                })
            })
    }
    ImprimirPresentacionGral=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("cuentasanuales.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                                <Text style={styles.labelTitle}>{i18next.t("cuentasanuales.titledatos",{ns:"page"}).toUpperCase()}</Text>
                        </View>
                             <View >                 
                           
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("cuentasanuales.partidasactivo",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.totalPartidas?this.state.totalPartidas:''}€</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("cuentasanuales.importe",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.importeNetoCifraAnual?this.state.importeNetoCifraAnual:''}€</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("cuentasanuales.nromedio",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.numeroMedioTrabajadores?this.state.numeroMedioTrabajadores:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("cuentasanuales.medipago",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.periodoMedioPago?this.state.periodoMedioPago:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("cuentasanuales.medidas",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.medidasAplicarReduccion?this.state.medidasAplicarReduccion:"No existen medidas para la reducción"}</Text>
                                </View>                               
                            </View>
                            <View style={{marginTop:10}}></View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("cuentasanuales.titlepresentacion",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.presentaciones?this.state.presentaciones.map((presentacion, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:9, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>{i18next.t("cuentasanuales.fecha",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 0, fontSize:9, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>{i18next.t("cuentasanuales.name",{ns:"page"}).toUpperCase()}</Text>                                                                
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{presentacion.FECHA_PRESENTACION_RM?moment(presentacion.FECHA_PRESENTACION_RM).format('DD-MM-YYYY'):''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 0,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{presentacion.nombre?presentacion.nombre:''}</Text>
                                </View>                                
                        </View>
                        )
                    }):<></>}
                                                          

            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("cuentasanuales.title",{ns:"page"});
        
        const añadirNuevo = async() => {
            
            const api = axios.create({
                withCredentials: true
            });

            if(!this.state.archivo)return
            if(!this.state.nombre)return
            if(!this.state.descripcion)return
            //creamos la cuenta
            await this.props.client.mutate({
                mutation:gql`mutation{crearCuentaAnual(VALORES:{FECHA_PRESENTACION_RM:"${moment(this.state.fecha).format("YYYY-MM-DD")}"}){ID_CUENTA_ANUAL}}`
            }).then(async result=>{
           
                    alert(i18next.t("insertcorrecto",{ns:"global"}))
                    //adjuntamos el archivo
                    var formData = new FormData();
                    formData.append("files",this.state.archivo);
                    await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:5,NOMBRE:"${this.state.nombre}",ID_ENTIDAD_ASOCIADA:4,ID_ASOCIADO:${result.data.crearCuentaAnual.ID_CUENTA_ANUAL},DESCRIPCION:"""${this.state.descripcion}"""}){ID_ADJUNTO}}`, formData, {
                        headers: {
                        'Content-Type': 'multipart/form-data'
                        }
                    })
                    await this.recargarCuentas()  

                    
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

        
            cerrarModal()
        }

        const editarCuenta=async()=>{
            
            if(!this.state.nombre)return
            if(!this.state.descripcion)return

            const api = axios.create({
                withCredentials: true
            });

        
            //creamos la cuenta
            await this.props.client.mutate({
                mutation:gql`mutation{actualizarCuentaAnual(ID_CUENTA_ANUAL:${this.state.presentacionSeleccionada.ID_CUENTA_ANUAL},VALORES:{FECHA_PRESENTACION_RM:"${moment(this.state.fecha).format("YYYY-MM-DD")}"}){ID_CUENTA_ANUAL}}`
            }).then(async result=>{
 
                alert(i18next.t("updatecorrecto",{ns:"global"}))
                await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{actualizarAdjunto(ID_ADJUNTO:${this.state.presentacionSeleccionada.idAdjunto},VALORES:{NOMBRE:"${this.state.nombre}",DESCRIPCION:"""${this.state.descripcion}"""}){ID_ADJUNTO}}`)
                await this.recargarCuentas()  

                
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

            cerrarModal()

        }

        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            this.setState({
                modalAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                fecha:new Date(),
                archivo:null,
                tipoArchivo:null,
                nombre:null,
                descripcion:null,
                cargandoGrid:true,
                presentacionSeleccionada:null
            })
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.presentacionSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({
                modalAbierto: true,
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                fecha:new Date(moment(this.state.presentacionSeleccionada.FECHA_PRESENTACION_RM).format("YYYY-MM-DD")),
                nombre:this.state.presentacionSeleccionada.nombre,
                descripcion:this.state.presentacionSeleccionada.descripcion,
                cargandoGrid:true
            })
        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            if(!this.state.presentacionSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({modalBorrarAbierto: true})
        }

        const eliminarPresentacion =async() =>{
            if(!this.state.presentacionSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            //eliminamos el registros de la tabla.
            let presentaciones=this.state.presentaciones.filter(presentacion=>{return presentacion.ID_CUENTA_ANUAL!==this.state.presentacionSeleccionada.ID_CUENTA_ANUAL})
            this.setState({presentaciones:presentaciones})

            //eliminamos su documento
            this.props.client.mutate({
                mutation:gql`mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.presentacionSeleccionada.idAdjunto}){ID_ADJUNTO}}`
            })

            //eliminamos la presentacion
            this.props.client.mutate({
                mutation:gql`mutation{eliminarCuentaAnual(ID_CUENTA_ANUAL:${this.state.presentacionSeleccionada.ID_CUENTA_ANUAL}){ID_CUENTA_ANUAL}}`
            }).then(result=>{
                this.setState({
                    presentacionSeleccionada:null
                })
                alert(i18next.t("deletecorrecto",{ns:"global"}))
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

            cerrarModal()
        }
        
        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
                cargandoGrid:false,
                presentacionSeleccionada:null
            })
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const handleCambiarDescripcion = (ev) => {
            this.setState({descripcion:ev.currentTarget.value})
        };

        const handleCambiarNombre = (ev) => {
            this.setState({nombre:ev.currentTarget.value})
        };

        const archivoSubido=(ev)=>{           

            let extension=ev.target.files[0].name.split('.').pop();
            this.setState({archivo:ev.target.files[0]})
            this.setState({tipoArchivo:ev.target.files[0].type})
            this.setState({nombre:ev.target.files[0].name.replace("."+extension,"")})
          
        }

        const formatearMiles=(numero)=>{
           return numero.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."); 
        }

        const cambiarTotal = (ev) => {

            //solo permitir en el input numeros,comas y puntos.
            let pattern=/^[0-9,.]*$/
            if(pattern.test(ev.currentTarget.value)===false)return

            //comprobacion que sea un número válido
            let pattern2=/^(?!0\,00)(?!0\d)(?!0\.)\d{1,3}(\.\d{3})*(\,\d{2})?$/
            if(pattern2.test(formatearMiles(ev.currentTarget.value.replace(/\./g, '')))){
                this.setState({totalPartidasValido:true})
            }else{
                this.setState({totalPartidasValido:false})
            }

            this.setState({totalPartidas:formatearMiles(ev.currentTarget.value.replace(/\./g, '')) })

        }
        const cambiarImporte = (ev) => {

            //solo permitir en el input numeros,comas y puntos.
            let pattern=/^[0-9,.]*$/
            if(pattern.test(ev.currentTarget.value)===false)return

            //comprobacion que sea un número válido
            let pattern2=/^(?!0\,00)(?!0\d)(?!0\.)\d{1,3}(\.\d{3})*(\,\d{2})?$/
            if(pattern2.test(formatearMiles(ev.currentTarget.value.replace(/\./g, '')))){
                this.setState({importeNetoCifraAnualValido:true})
            }else{
                this.setState({importeNetoCifraAnualValido:false})
            }

            this.setState({importeNetoCifraAnual:formatearMiles(ev.currentTarget.value.replace(/\./g, '')) })


        }

        const cambiarNumTrab = (value) => {
            this.setState({numeroMedioTrabajadores:value})
            ////console.log(this.state.numeroMedioTrabajadores);
        }

        const cambiarPeriodo = (ev) => {
            this.setState({periodoMedioPago: ev.currentTarget.value})
            ////console.log(this.state.periodoMedioPago);
        }

        const cambiarMedidas = (ev) => {
            this.setState({medidasAplicarReduccion: ev.currentTarget.value})
            ////console.log(this.state.medidasAplicarReduccion);
        }

        function dateFormatter(cell, row) {
            return (
                cell? moment(cell).format("DD/MM/YYYY") : ""
            );
        }

        const columns = [
        {
        dataField: 'FECHA_PRESENTACION_RM',
        text: i18next.t("cuentasanuales.fecha",{ns:"page"}),
        formatter: dateFormatter
        },{
            dataField: 'nombre',
            text: i18next.t("cuentasanuales.name",{ns:"page"})
        }
        ];

        const guardarCambios = () => {

                let error=false

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("sinpermiso",{ns:"global"}))
                    return
                }
           
                if(!this.state.totalPartidas || this.state.totalPartidas<=0 || this.state.totalPartidasValido===false){
                    ReactDOM.findDOMNode(this.totalPartidasInput).children[0].focus()
                    return
                }
                if(!this.state.importeNetoCifraAnual || this.state.importeNetoCifraAnual<=0 || this.state.importeNetoCifraAnualValido===false){
                    ReactDOM.findDOMNode(this.importeAnualInput).children[0].focus()
                    return
                }

                if(this.state.numeroMedioTrabajadores<0)this.setState({numeroMedioTrabajadores:0})
                //guardamos los cambios

                this.props.client.mutate({
                    mutation:gql`mutation{actualizarPersonaJuridica(ID_PERSONA:${this.state.idPersona},VALORES:{TOTAL_PARTIDAS_ACTIVO:${this.state.totalPartidas.replace(/\./g, '').replace(/\,/g, '.')},NUMERO_MEDIO_TRABAJADORES:${this.state.numeroMedioTrabajadores},CIFRA_ANUAL_NEGOCIO:${this.state.importeNetoCifraAnual.replace(/\./g, '').replace(/\,/g, '.')}}){ID_PERSONA}}`
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                    error=true
                    return
                })

                if(error)return

                if(this.state.existeCuentaAnual){

                    this.props.client.mutate({
                        mutation:gql`mutation{actualizarCuentasAnualesOtrosDatos(VALORES:{PERIODO_MEDIO_PAGO:${this.state.periodoMedioPago?'"'+this.state.periodoMedioPago+'"':null},MEDIDAS_ADOPTAR:${this.state.medidasAplicarReduccion?'"""'+this.state.medidasAplicarReduccion+'"""':null}}){PERIODO_MEDIO_PAGO}}`
                    }).catch(err=>{
                        alert(i18next.t("error",{ns:"global"}))
                        error=true
                        return
                    })


                }else{

                    if(this.state.periodoMedioPago || this.state.medidasAplicarReduccion){

                        this.props.client.mutate({
                            mutation:gql`mutation{crearCuentasAnualesOtrosDatos(VALORES:{PERIODO_MEDIO_PAGO:${this.state.periodoMedioPago?'"'+this.state.periodoMedioPago+'"':null},MEDIDAS_ADOPTAR:${this.state.medidasAplicarReduccion?'"""'+this.state.medidasAplicarReduccion+'"""':null}}){PERIODO_MEDIO_PAGO}}`
                        }).then(result=>{
                       
                            this.setState({existeCuentaAnual:true})
                            
                        }).catch(err=>{
                            alert(i18next.t("error",{ns:"global"}))
                            error=true
                            return
                        })
        
                    }
                    
                }

                if(error)return

                alert(i18next.t("updatecorrecto",{ns:"global"}))

        }

        const escogeFecha = (nuevaFecha) => {
            this.setState({fecha: nuevaFecha})
        }

        const handleOnSelect = (row, isSelect) => {
            if (isSelect) {
                this.setState({
                    presentacionSeleccionada: row
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const descargarArchivo = () => {
            let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.presentacionSeleccionada.ruta.replace(".",""), '_blank')
            win.focus()
        }
        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimirGral:false,
                modalImprimirP:false,
                cargandoGrid:false
            })
        }
    
        return (
            <>
                {
                    this.state.cargando === true?
                     <Cargando />
                    :
                    <>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={i18next.t("print",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimirP}
                        style={styles.dialog}
                    >
                        {!this.state.cargandoImprimirP?
                                <div style={{ width: '100%', height: '600px'}} id='generarImprimirP-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>

                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={i18next.t("print",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimirGral}
                        style={styles.dialog}
                    >
                        {!this.state.cargandoImprimirGral?
                                <div style={{ width: '100%', height: '600px'}} id='generarImprimirGral-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>
                        <Dialog transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="info-sign"
                            onClose={cerrarModal}
                            title={i18next.t("cuentasanuales.titlepresentacion",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalAbierto}
                            className="dialog-ancho"
                        >
                            <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                            <div className="pixelAlrededor">
                                <FormGroup
                                    label={i18next.t("cuentasanuales.fecha",{ns:"page"})+":"}
                                    labelFor="fecha"
                                >
                                    <DateInput minDate={new Date(1900, 1 ,1)}
                                        {...jsDateFormatter} 
                                        onChange={escogeFecha}
                                        value={this.state.fecha}
                                        align={Alignment.RIGHT}
                                    />
                                </FormGroup>
                            </div>
                            {this.state.nuevoActivado?
                            <div className="pixelAlrededor">
                                    <FormGroup
                                        label={i18next.t("identificacion.adjunto",{ns:"page"})+":"}
                                        labelFor="informacion"
                                        intent="danger"
                                        className="pixelAlrededor"
                                        helperText={this.state.archivo?"":i18next.t("selectempty",{ns:"global"})+i18next.t("identificacion.adjunto",{ns:"page"})}
                                    >
                                        <FileInput  text={this.state.archivo?this.state.archivo.name:""} onInputChange={archivoSubido} name="files" style={this.state.archivo?{}:{border:"1px solid red", padding: "15px"}} className="pixelAlrededor" fill={true} />
                                    </FormGroup>
                                </div>
                                :<></>
                            }
                            
                            <div className="pixelAlrededor">
                                <FormGroup
                                        label={i18next.t("cuentasanuales.name",{ns:"page"})+":"}
                                        labelFor="informacion"
                                        intent="danger"
                                        helperText={this.state.nombre?"":i18next.t("emptyfield",{ns:"global"})+i18next.t("cuentasanuales.name",{ns:"page"})}
                                >
                                    <InputGroup id="nombre-documento" onChange={handleCambiarNombre}  value={this.state.nombre} placeholder={i18next.t("placeholder",{ns:"global"})} intent={this.state.nombre?"primary":"danger"} />
                                </FormGroup>
                            </div>
                            <div className="pixelAlrededor">
                                <FormGroup
                                        label={i18next.t("cuentasanuales.descripcion",{ns:"page"})+":"}
                                        labelFor="descripcion"
                                        intent="danger"
                                        helperText={this.state.descripcion?"":i18next.t("cuentasanuales.emptyfield",{ns:"page"})+i18next.t("cuentasanuales.descripcion",{ns:"page"})}
                                >
                                    <TextArea
                                        large={true}
                                        fill={true}
                                        intent={this.state.descripcion?Intent.PRIMARY:Intent.DANGER}
                                        onChange={handleCambiarDescripcion}
                                        value={this.state.descripcion}
                                    />                    
                                </FormGroup>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                    <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                                </Tooltip>
                                <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        onClick={this.state.nuevoActivado?añadirNuevo:editarCuenta}
                                    >
                                    {this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                                    </AnchorButton>
                                </Tooltip>
                            </div>
                        </div>
                        </Card>
                        </Dialog>

                        <Dialog transitionDuration={400}
                                autoFocus={true}
                                enforceFocus={true}
                                icon="warning-sign"
                                onClose={cerrarModal}
                                title={i18next.t("delete",{ns:"global"})}
                                canEscapeKeyClose={true}
                                canOutsideClickClose={true}
                                isCloseButtonShown={true}
                                usePortal={true}
                                isOpen={this.state.modalBorrarAbierto}
                            >
                                <div className={Classes.DIALOG_BODY}>
                                    <h2>{i18next.t("cuentasanuales.questiondel",{ns:"page"})}</h2>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                                        <AnchorButton
                                            intent={Intent.PRIMARY}
                                            onClick={eliminarPresentacion}
                                        >
                                            {i18next.t("yes",{ns:"global"})}
                                        </AnchorButton>
                                    </Tooltip>
                                    <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                        <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Dialog>
        
                        <div className="pixelAlrededor tablaSesenta">
                            <Card>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("cuentasanuales.titledatos",{ns:"page"})}</Callout>
                                <FormGroup
                                    label={i18next.t("cuentasanuales.partidasactivo",{ns:"page"})+":"}
                                    labelFor="cuentas-anuales"
                                    helperText={!this.state.totalPartidas?i18next.t("emptyfield",{ns:"global"})+i18next.t("cuentasanuales.partidasactivo",{ns:"page"}):(this.state.totalPartidas<=0?i18next.t("cuentasanuales.importemayor",{ns:"page"}):"")}
                                >
                                    <InputGroup type="text" rightElement={<span className="euro">€</span>} id="total-partidas-activo"  ref={(input) => { this.totalPartidasInput= input; }} intent={!this.state.totalPartidas || this.state.totalPartidas<=0 || this.state.totalPartidasValido===false?"danger":""} value={this.state.totalPartidas} fill={true} min={0} onChange={cambiarTotal} />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("cuentasanuales.importe",{ns:"page"})+":"}
                                    labelFor="cuentas-anuales"
                                    helperText={!this.state.importeNetoCifraAnual?i18next.t("emptyfield",{ns:"global"})+i18next.t("cuentasanuales.importe",{ns:"page"}):(this.state.importeNetoCifraAnual<=0?i18next.t("cuentasanuales.importemayor",{ns:"page"}):"")}

                                >
                                    <InputGroup type="text" rightElement={<span className="euro">€</span>} id="importe-neto-anual" value={this.state.importeNetoCifraAnual} ref={(input) => { this.importeAnualInput= input; }} intent={!this.state.importeNetoCifraAnual || this.state.importeNetoCifraAnual<=0 || this.state.importeNetoCifraAnualValido===false?"danger":""} fill={true} min={0}  onChange={cambiarImporte} />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("cuentasanuales.nromedio",{ns:"page"})+":"}
                                    labelFor="cuentas-anuales"

                                >
                                    <NumericInput id="num-medio-trabajadores" min={0} fill={true} value={this.state.numeroMedioTrabajadores} intent="" placeholder={i18next.t("placeholder",{ns:"global"})} onValueChange={cambiarNumTrab} />
                                </FormGroup>
                                <br />
                                <FormGroup
                                    label={i18next.t("cuentasanuales.mediopago",{ns:"page"})+":"}
                                    labelFor="cuentas-anuales"
                                >
                                    <InputGroup id="periodo-medio-pago" value={this.state.periodoMedioPago} fill={true} placeholder={i18next.t("placeholder",{ns:"global"})} onChange={cambiarPeriodo} />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("cuentasanuales.medidas",{ns:"page"})+":"}
                                    labelFor="cuentas-anuales"

                                >
                                    <TextArea id="medidas-aplicar" fill={true} value={this.state.medidasAplicarReduccion} placeholder={i18next.t("placeholder",{ns:"global"})} onChange={cambiarMedidas} />
                                </FormGroup>
                                <br/>
                                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("cuentasanuales.titlepresentacion",{ns:"page"})}</Callout>
                                {this.state.cargandoGrid?
                                <Cargando />
                                :
                                <>
                                    <Navbar>
                                    <Navbar.Group>
                                        <ButtonGroup align={Alignment.LEFT}>
                                            <Button className="bp3-minimal" icon="new-text-box" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                            <Button className="bp3-minimal" icon="text-highlight" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                            <Button className="bp3-minimal" icon="trash" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar} />
                                            <Tooltip content={i18next.t("print",{ns:"global"})} position="right" >
                                                <Button className="bp3-minimal" icon="print" disabled={this.state.presentacionSeleccionada?false:true}  text={i18next.t("print",{ns:"global"})} onClick={()=>this.generarImprimirPresentacion()}/>
                                            </Tooltip>
                                            <Navbar.Divider />
                                            <Button className="bp3-minimal" icon="document-open" text={i18next.t("openfile",{ns:"global"})} disabled={!this.state.presentacionSeleccionada}  onClick={descargarArchivo} />
                                        </ButtonGroup>
                                    </Navbar.Group>
                                </Navbar>
                                <BootstrapTable
                                keyField='idAdjunto'
                                data={ this.state.presentaciones }
                                columns={ columns }
                                selectRow={ const_table.selectRow(handleOnSelect) }
                                pagination={ paginationFactory(const_table.options) }
                                headerClasses="header-class"
                                rowClasses="row-class"
                                hover
                                condensed
                                noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                />
                                <br />
                                <br />
                                <Button 
                                    alignText="right"
                                    icon="floppy-disk"
                                    text={i18next.t("guardar",{ns:"global"})}
                                    onClick={guardarCambios}
                                    className="bp3-intent-danger iconoDerecha bp3-minimal"
                                />
                                <Button 
                                    alignText="right"
                                    icon="print"
                                    text={i18next.t("printgral",{ns:"global"})}
                                    onClick={()=>this.generarImprimirCA()}
                                    className="bp3-intent-danger iconoDerecha bp3-minimal"
                                />
                                <br />
                                <br />
                                </>
                                }
                            </Card>
                        </div>
                        <div className="pixelAlrededor tablaCuarenta">
                            <Card>
                                
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("cuentasanuales.titleley",{ns:"page"})}</Callout>

                            <br />

                            <a role="button" target="_blank"  rel="noopener noreferrer" href="https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544&tn=1&p=20181229#tvii" class="bp3-button bp3-icon-git-repo">{i18next.t("cuentasanuales.ley",{ns:"page"})}</a>


                            </Card>
                        </div>
                    </>
                }
            </>
        )
    }
}

export default withTranslation()(withApollo(CuentasAnuales))